import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import each from 'lodash/each';
import includes from 'lodash/includes';
import size from 'lodash/size';
import remove from 'lodash/remove';
import { searchableFileds } from 'src/js/constants/advancedFilteringConstants';
import { Icon } from 'src/js/components/static';

import { PAGE_LIMIT } from 'src/js/constants/dataConstants';
import { getUrlParam } from 'src/js/helpers/strings';
import { withTranslation } from 'react-i18next';

class TableSearchItems extends Component {
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        document.addEventListener('advancedFilterClear', () => {
            this.clearSearchItems();
        });
    }

    componentWillUnmount() {
        document.removeEventListener('advancedFilterClear', () => {
            this.clearSearchItems();
        });
    }

    /**
     * Clear all from Search.
     */
    clearSearchItems(event, item) {
        const { queryObject, basicFilterObj, updateFiltersState } = this.props;
        const params = { page: 1, page_size: getUrlParam('page_size') || PAGE_LIMIT }

        let newQueryObject = cloneDeep(queryObject);
        let newFilters = basicFilterObj;

        if (event) {
            event.stopPropagation();
        }

        if (item) {
            let musts = get(newQueryObject, 'query.query.bool.must', []).filter(
                (item) =>
                    !item.query_string ||
                    item.query_string.default_field !== item.label
            );

            remove(musts, (must) => {
                return (
                    must.query_string &&
                    must.query_string.default_field === item.label && must.query_string.default_field !== 'licensor_organisation_id'
                );
            });

            newQueryObject.query.query.bool.must = musts;
            delete newFilters[item.label];
        } else {
            remove(newQueryObject.query.query.bool.must, (q) => {
                return !!q.query_string && q.query_string.default_field  !== 'licensor_organisation_id';
            });

            each(newFilters, (filter, key) => {
                if (includes(searchableFileds, key)) {
                    if (key !== 'licensor_organisation_id') {
                        delete newFilters[key];
                    }
                }
            });
        }

        updateFiltersState(newQueryObject, newFilters, true, params);
    }

    render() {
        const {
            searchItems,
            searchTableRef,
            tableWidth,
            sortedHeaders,
            openSearchBox,
            t
        } = this.props;

        remove(searchItems, item => item.label === 'licensor_organisation_id');

        return (
            <Fragment>
                {!!searchItems.length && (
                    <table
                        className="c-rec-table-search"
                        style={{ width: tableWidth }}
                        ref={searchTableRef}
                    >
                        <tbody>
                        <tr className="c-rec-table__primary-row">
                            <td
                                className="c-rec-table__cell c-rec-table__checkbox"
                                colSpan={size(sortedHeaders) + 5}
                            >
                                <div className="c-rec-table-search__applied-search">
                                        <span className="title">
                                            {t('search_items.applied_search')}{' '}
                                        </span>
                                    <span
                                        onClick={(event) =>
                                            this.clearSearchItems(event)
                                        }
                                        className="c-rec-table-search__applied-search__button c-rec-table-search__applied-search__button--clear"
                                    >
                                            {t('buttons.clear_all')}
                                        </span>

                                    {searchItems.map((item) => {
                                        return (
                                            <div
                                                className="c-search-items u_pointer"
                                                key={item.label}
                                                onClick={() =>
                                                    openSearchBox(item)
                                                }
                                            >
                                                    <span className="c-search-items--item">
                                                        <span className="name">
                                                            {t(`table_headers.${item.label}`)}
                                                        </span>
                                                        :
                                                        <span className="value">
                                                            {' '}
                                                            {item.value}
                                                        </span>
                                                        <Icon
                                                            className="u-clickable clear-icon"
                                                            onClick={(event) =>
                                                                this.clearSearchItems(
                                                                    event,
                                                                    item
                                                                )
                                                            }
                                                            icon="close"
                                                            fill="black"
                                                        />
                                                    </span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                )}
            </Fragment>
        );
    }
}

export default connect(
    (state) => ({ ...state }),
    (dispatch) => ({ dispatch })
)((withTranslation())(TableSearchItems));
