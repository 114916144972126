import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MainOffice } from 'src/js/components/static/Icon/svg-icons/main-office.svg';
import { ReactComponent as RecipientDetails } from 'src/js/components/static/Icon/svg-icons/recipient-details.svg';
import { ReactComponent as BusinessInfo } from 'src/js/components/static/Icon/svg-icons/business-info.svg';
import find from 'lodash/find';
import { RECIPIENT_TYPES } from '../../Constants/TrademarkAuthorisationLetterFormConstants';
import moment from 'moment';

function InformationReview({
                               requestType,
                               businessInfo,
                               contractDetails,
                               recipientDetails,
                               xelacore,
                               recipientType,
                               licensor,
                               onEditStep,
                               licensorsArray
                           }) {
    const { t } = useTranslation();
    const {
        auth: { companyData = {} } = {}
    } = xelacore;

    const findLicensor = (licensor) => {
        return find(licensorsArray, el => el.value === licensor);
    };

    const findRecipient = (recipient) => {
        return find(RECIPIENT_TYPES, el => el.value === recipient);
    };

    return (
        <div className='form-wrapper get-started'>
            <div className='form-group-title u-flex u-flex-between'>
                <div className={'u-flex u-flex-between'}>
                    <MainOffice />
                    <div className={'u-margin-left'}>Request Type</div>
                </div>
                <span className='link-color u-bold c-pointer' onClick={() => onEditStep(0)}>Edit</span>
            </div>

            <div className='form-item'>
                <label className={'main-label'}>
                    {t('toolkit.trademark_request_form.request_type')}
                </label>
                <div className='field-item-wrapper value'>
                    {requestType === 'licensee' ? `${companyData.name}` : '3rd party'}
                </div>
            </div>

            <div>
                <div className='form-group-title u-flex u-flex-between'>
                    <div className={'u-flex u-flex-between'}>
                        <BusinessInfo />
                        <div className={'u-margin-left'}>{t('toolkit.trademark_request_form.business_info')}</div>
                    </div>
                    <span className='link-color u-bold c-pointer' onClick={() => onEditStep(0)}>Edit</span>
                </div>

                <div className='form-item'>
                    <label className={'main-label'}>
                        {t('toolkit.trademark_request_form.legal_company_name')}
                    </label>
                    <div className='field-item-wrapper value'>
                        {businessInfo.legalCompanyName || 'N/A'}
                    </div>
                </div>

                <div className='form-item'>
                    <label className={'main-label'}>
                        {t('toolkit.trademark_request_form.company_registration_name')}
                    </label>
                    <div className='field-item-wrapper value'>
                        {businessInfo.companyRegistrationNumber || 'N/A'}
                    </div>
                </div>

                <div className='form-item'>
                    <label className={'main-label'}>
                        {t('toolkit.trademark_request_form.vat')}
                    </label>
                    <div className='field-item-wrapper value'>
                        {businessInfo.vat || 'N/A'}
                    </div>
                </div>

                <div className='form-group-title u-flex u-flex-between'>
                    <div className={'u-flex u-flex-between'}>
                        <MainOffice />
                        <div className={'u-margin-left'}>{t('toolkit.trademark_request_form.main_office_address')}</div>
                    </div>
                    <span className='link-color u-bold c-pointer' onClick={() => onEditStep(0)}>Edit</span>
                </div>

                <div className='form-item'>
                    <label className={'main-label'}>
                        {t('toolkit.trademark_request_form.street_address')}
                    </label>
                    <div className='field-item-wrapper value'>
                        {businessInfo.streetAddress || 'N/A'}
                    </div>
                </div>

                <div className='form-group-title u-flex u-flex-between'>
                    <div className={'u-flex u-flex-between'}>
                        <MainOffice />
                        <div className={'u-margin-left'}>Contract Details</div>
                    </div>
                    <span className='link-color u-bold c-pointer' onClick={() => onEditStep(1)}>Edit</span>
                </div>

                <div className='form-item'>
                    <label className={'main-label'}>
                        {t('toolkit.trademark_request_form.licensor')}
                    </label>
                    <div className='field-item-wrapper value'>
                        {(findLicensor(licensor) && findLicensor(licensor).label) || ''}
                    </div>
                </div>

                {contractDetails && contractDetails.length > 0 && contractDetails.filter((contract) => {
                    return contract.selected;
                }).map((contract, index) => (
                    <div className={'contract-details-wrapper'} key={index}>
                        <div className={'form-item'}>
                            <label className={'main-label'}>
                                Contract Number
                            </label>
                            <div className='field-item-wrapper value'>
                                {contract.agreement_id || 'N/A'}
                            </div>
                        </div>

                        <div className={'form-item'}>
                            <label className={'main-label'}>
                                Contract Name
                            </label>
                            <div className='field-item-wrapper value'>
                                {contract.agreement_name || 'N/A'}
                            </div>
                        </div>

                        <div className={'form-item'}>
                            <label className={'main-label'}>
                                Contract Status
                            </label>
                            <div className='field-item-wrapper value'>
                                {contract.contract_status || 'N/A'}
                            </div>
                        </div>

                        <div className={'form-item'}>
                            <label className={'main-label'}>
                                Terms
                            </label>
                            <div className='u-flex'>
                                <div className='field-item-wrapper value'>
                                    Begin date: {moment(contract.start_date).format('DD MMM YYYY') || 'N/A'}
                                </div>
                                <div className='field-item-wrapper value'>
                                    End date: {moment(contract.expiration_date).format('DD MMM YYYY') || 'N/A'}
                                </div>
                            </div>
                        </div>

                        <div className={'form-item'}>
                            <label className={'main-label u-flex u-flex-between'}>
                                Contracted Territories
                                <span className='link-color u-bold c-pointer' onClick={() => onEditStep(1)}>Edit</span>
                            </label>
                            <div className='field-item-wrapper value'>
                                {contract.contracted_territories &&
                                    contract.contracted_territories.licensee_request.length ? contract.contracted_territories.licensee_request.map((item, i) => (
                                        <span key={i}>{item + ' '}</span>
                                    )) : 'N/A'
                                }
                            </div>
                        </div>

                        <div className={'form-item'}>
                            <label className={'main-label u-flex u-flex-between'}>
                                Contracted Territories Exception
                                <span className='link-color u-bold c-pointer' onClick={() => onEditStep(1)}>Edit</span>
                            </label>
                            <div className='field-item-wrapper value'>
                                {contract.contracted_territories &&
                                    contract.contracted_territories.licensee_exception.length ? contract.contracted_territories.licensee_exception.map((item, i) => (
                                        <span key={i}>{item + ' '}</span>
                                    )) : 'N/A'
                                }
                            </div>
                        </div>

                        <div className={'form-item'}>
                            <label className={'main-label u-flex u-flex-between'}>
                                IPs
                                <span className='link-color u-bold c-pointer' onClick={() => onEditStep(1)}>Edit</span>
                            </label>
                            <div className='field-item-wrapper value'>
                                {contract.ips &&
                                    contract.ips.licensee_request.length ? contract.ips.licensee_request.map((item, i) => (
                                        <span key={i}>{item + ' '}</span>
                                    )) : 'N/A'
                                }
                            </div>
                        </div>

                        <div className={'form-item'}>
                            <label className={'main-label u-flex u-flex-between'}>
                                Ips Exception
                                <span className='link-color u-bold c-pointer' onClick={() => onEditStep(1)}>Edit</span>
                            </label>
                            <div className='field-item-wrapper value'>
                                {contract.ips &&
                                    contract.ips.licensee_exception.length ? contract.ips.licensee_exception.map((item, i) => (
                                        <span key={i}>{item + ' '}</span>
                                    )) : 'N/A'
                                }
                            </div>
                        </div>

                        <div className={'form-item'}>
                            <label className={'main-label u-flex u-flex-between'}>
                                Categories
                                <span className='link-color u-bold c-pointer' onClick={() => onEditStep(1)}>Edit</span>
                            </label>
                            <div className='field-item-wrapper value'>
                                {contract.categories &&
                                    contract.categories.licensee_request.length ? contract.categories.licensee_request.map((item, i) => (
                                        <span key={i}>{item + ' '}</span>
                                    )) : 'N/A'
                                }
                            </div>
                        </div>

                        <div className={'form-item'}>
                            <label className={'main-label u-flex u-flex-between'}>
                                Categories Exception
                                <span className='link-color u-bold c-pointer' onClick={() => onEditStep(1)}>Edit</span>
                            </label>
                            <div className='field-item-wrapper value'>
                                {contract.categories &&
                                    contract.categories.licensee_exception.length ? contract.categories.licensee_exception.map((item, i) => (
                                        <span key={i}>{item + ' '}</span>
                                    )) : 'N/A'
                                }
                            </div>
                        </div>
                    </div>
                ))}

                <div className='form-group-title u-flex u-flex-between'>
                    <div className={'u-flex u-flex-between'}>
                        <RecipientDetails />
                        <div className={'u-margin-left'}>Recipient’s Details (if applicable)</div>
                    </div>
                    <span className='link-color u-bold c-pointer' onClick={() => onEditStep(2)}>Edit</span>
                </div>

                <div className={'form-item'}>
                    <label className={'main-label'}>
                        Recipient Type
                    </label>
                    <div className='field-item-wrapper value'>
                        {(findRecipient(recipientType) && findRecipient(recipientType).label) || 'N/A'}
                    </div>
                </div>

                <div className={'form-item'}>
                    <label className={'main-label'}>
                        {t('toolkit.trademark_request_form.recipients_name')}
                    </label>
                    <div className='field-item-wrapper value'>
                        {recipientDetails.recipientsName || 'N/A'}
                    </div>
                </div>

                <div className={'form-item'}>
                    <label className={'main-label'}>
                        {t('toolkit.trademark_request_form.company_name')}
                    </label>
                    <div className='field-item-wrapper value'>
                        {recipientDetails.companyName || 'N/A'}
                    </div>
                </div>

                <div className={'form-item'}>
                    <label className={'main-label'}>
                        {t('toolkit.trademark_request_form.company_address')}
                    </label>
                    <div className='field-item-wrapper value'>
                        {recipientDetails.companyAddress || 'N/A'}
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ xelacore, dispatch }) => ({
    xelacore,
    dispatch
});

export default connect(mapStateToProps)(InformationReview);
