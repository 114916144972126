import React from 'react';
import { useTranslation } from 'react-i18next';
import MediaView from 'components/MediaView';
import recordPlaceholder from 'statics/imgs/record-placeholder.png';
import { ReactComponent as DocumentIcon } from 'src/js/components/static/Icon/svg-icons/business-info.svg';
import { ReactComponent as BusinessInfoIcon } from 'src/js/components/static/Icon/svg-icons/business-info.svg';
import { ReactComponent as AddressIcon } from 'src/js/components/static/Icon/svg-icons/main-office.svg';
import { ReactComponent as ContractIcon } from 'src/js/components/static/Icon/svg-icons/business-info.svg';

const RequestHeader = ({ data }) => {
    const { t } = useTranslation();

    if (!data || !data.trademarkRequest) return null;

    const trademarkRequest = data.trademarkRequest;
    const companyInfo = trademarkRequest.company_info || {};
    const primaryContact = companyInfo.primary_contact || {};
    const address = companyInfo.address || {};

    return (
        <div className="authorization-request-header">
            <div className="header-company-info">
                <div className="logo-container">
                    <MediaView
                        src={`https://assets.xelacore.io/org/${trademarkRequest.licensee_organisation_id}.png`}
                        alt={companyInfo.legal_company_name || t('toolkit.trademark_authorization.details.unknown_company')}
                        fallbackSrc={recordPlaceholder}
                        useImgTag
                    />
                </div>
                <div className="company-details">
                    <h2>{companyInfo.legal_company_name || t('toolkit.trademark_authorization.details.unknown_company')}</h2>
                    <p className="request-by">
                        {t('toolkit.trademark_authorization.details.requested_by')}: {primaryContact.legal_name || ''}
                    </p>
                </div>
            </div>

            <div className="request-details-sections">
                <div className="details-section">
                    <div className="section-header">
                        <DocumentIcon className="section-icon" />
                        <h3>{t('toolkit.trademark_authorization.details.request_type')}</h3>
                    </div>
                    <div className="section-content">
                        <div className="info-row">
                            <span
                                className="info-label">{t('toolkit.trademark_authorization.details.request_for')}</span>
                            <span className="info-value">
                                {trademarkRequest.request_type !== 'licensee' ? trademarkRequest.request_type : t('toolkit.trademark_authorization.details.myself')}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="details-section">
                    <div className="section-header">
                        <BusinessInfoIcon className="section-icon" />
                        <h3>{t('toolkit.trademark_authorization.details.business_info.title')}</h3>
                    </div>
                    <div className="section-content">
                        <div className="info-row">
                            <span
                                className="info-label">{t('toolkit.trademark_authorization.details.business_info.legal_name')}</span>
                            <span
                                className="info-value">{companyInfo.legal_company_name || t('toolkit.trademark_authorization.details.business_info.not_provided')}</span>
                        </div>
                        <div className="info-row">
                            <span
                                className="info-label">{t('toolkit.trademark_authorization.details.business_info.reg_number')}</span>
                            <span
                                className="info-value">{companyInfo.company_registration_number || t('toolkit.trademark_authorization.details.business_info.not_provided')}</span>
                        </div>
                        <div className="info-row">
                            <span
                                className="info-label">{t('toolkit.trademark_authorization.details.business_info.vat')}</span>
                            <span className="info-value">
                                {companyInfo.company_tax_number || t('toolkit.trademark_authorization.details.business_info.not_provided')}
                            </span>
                        </div>
                        <div className="info-row">
                            <span
                                className="info-label">{t('toolkit.trademark_authorization.details.business_info.phone')}</span>
                            <span className="info-value">
                                {primaryContact && primaryContact.phone_number ? primaryContact.phone_number : t('toolkit.trademark_authorization.details.business_info.not_provided')}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="details-section">
                    <div className="section-header">
                        <AddressIcon className="section-icon" />
                        <h3>{t('toolkit.trademark_authorization.details.address.title')}</h3>
                    </div>
                    <div className="section-content">
                        <div className="info-row">
                            <span
                                className="info-label">{t('toolkit.trademark_authorization.details.address.street')}</span>
                            <span className="info-value">
                                {`${address.street_address || ''}, ${address.city || ''}, ${address.region || ''}, ${address.country || ''}, ${address.postcode || ''}`}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="details-section">
                    <div className="section-header">
                        <ContractIcon className="section-icon" />
                        <h3>{t('toolkit.trademark_authorization.details.contract.title')}</h3>
                    </div>
                    <div className="section-content">
                        <div className="info-row">
                            <span className="info-label">{t('toolkit.contract_details.licensor')}</span>
                            <span className="info-value">
                                {trademarkRequest.licensor_organisation_id || t('toolkit.trademark_authorization.details.business_info.not_provided')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestHeader;
