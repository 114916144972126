import React from 'react';
import { Button, Icon } from 'src/js/components/static';
import { useTranslation } from 'react-i18next';

export default function OpaSearchPanelTop({ toggleOpaPanel, goBack }) {
    const { t } = useTranslation();

    return (
        <div className="c-opa-product__top-block u-padding">
            <div>
                <Button onClick={goBack} square>
                    <span>
                        <Icon
                            icon="arrow_left"
                            left
                            width="13"
                            height="13"
                            top={2}
                        />{' '}
                        {t('buttons.back')}
                    </span>
                </Button>
            </div>
            <div>
                <h2>{t('modules.product_detail.opa_search_panel_top.link_product_concept')}</h2>
            </div>
            <div className="c-opa-product__close" onClick={toggleOpaPanel}>
                <Icon icon="close2" fill="black" height="20" width="20" />
            </div>
        </div>
    );
}
