/* eslint-disable no-unreachable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isUpdated, buildSearchQuery } from 'src/js/helpers/dataHelpers';
import { parseV1Response } from 'src/js/helpers/arrays';
import get from 'lodash/get';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import imgPinkSpinner from 'statics/imgs/desktop/pink-spinner.gif';

import {
    fetchBrandAgencies
} from 'src/js/apicalls/other/categories';

// TODO: Move these from out of dataManager

import {
    displayNotification,
    hideModal,
    showModal
} from 'src/js/actions/xelacore';
import { getProductInfo, updateProductInfo } from 'src/js/actions/dataManager';
import { queryOpaData } from 'src/js/actions/opa';

import { Modal } from 'src/js/components/static';
import { removeUnderscore, getUrlParam } from 'src/js/helpers/strings';
import { nullToString } from 'src/js/helpers/objects';

import { setUpdatedMyRecord } from 'src/js/actions/myRecords';

import ProductDetailTab from './Components/ProductDetailTab/ProductDetailTab';
import SetRefreshModal from 'src/js/components/Modals/SetRefreshModal';
import { isAgent, isLicensor } from 'src/js/helpers/permissions';
import {
    getCategoryAttributes
} from 'src/js/actions/categories';

import {
    fetchBrandStaticAttributes,
    fetchBrandConcepts
} from 'src/js/apicalls/other/categories';

import {
    fetchIpsLevels,
    fetchIpsLevelsv3
} from 'src/js/apicalls/other/ips';
import { findIndex } from 'lodash';
import { withTranslation } from 'react-i18next';

class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            originalData: null,
            cloneData: null,
            opaSearch: false,
            opaDataMatch: {},
            edit: false,
            initialFetched: false,
            numberOfIterations: 0,
            categoryAttributes: [],
            ipLevels: [],
            switchTime: Date.now(),
            esgGrouped: {},
            cloneData: {},
            agenciesData: [],
            selectedLicensor: !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {}
        };
        this.notifiedAt = Date.now();
    }

    componentDidMount() {
        this.checkEdit();
    }

    componentDidUpdate() {
        const { data } = this.state;
        if (!data) return;

        const messages = get(this, 'props.xelacore.socket.messages', []);
        if (isUpdated(messages, 'postgres', this.notifiedAt, data.record_id)) {
            this.notifiedAt = Date.now();
            this.getProductData(
                true,
                data.record_id,
                data.version_id,
                data.validation_version_id
            ).then(() => {
                this.setState({ fetching: false });
                this.setNotification();
            });
        }
    }


    fetchAgencies(data) {
        if(!!data.licensor_brand_id) {
            return fetchBrandAgencies(data.licensor_brand_id).then(response => {
                this.setState({
                    agenciesData: response && response.data && map(response.data, item => {
                        return {
                            label: item.agentOrganisationName,
                            value: item.agentOrganisationId
                        }
                    })
                })
            })
        }
    }

    checkEdit() {
        setTimeout(() => {
            // Put in a settimeout becase Firefox is a bit slow at getting the props
            const can = get(
                this,
                'props.xelacore.auth.userPermissions.modules.registration.can',
                []
            );
            const edit =
                getUrlParam('edit') === 'true' &&
                (can || []).indexOf('manage') !== -1;
            this.setState(
                {
                    edit
                },
                () => this.getProductData()
            );
        }, 500);
    }

    getBrandStaticAttributes(data) {
        const { xelacore } = this.props;

        const {
            auth: {
                companyData: { organisation_id }
            }
        } = xelacore;

        fetchBrandStaticAttributes(organisation_id, data.ips[0][0].ip_id).then(response => {
            this.setState({
                staticBrandAttrs: !!response.data && response.data.static_attribute_definitions
            });
        });
    }
 
    getProductData(isUpdate, recordId, versionId, validationVersionId) {
        const { dispatch, params: { productType, id } = {} } = this.props;

        return dispatch(getProductInfo(productType, id)).then((info) => {
            const {
                record,
                record: { version_id, validation_version_id } = {}
            } = info.data;
            const { numberOfIterations } = this.state;

            if (
                false &&
                recordId &&
                versionId &&
                validationVersionId &&
                numberOfIterations < 20
            ) {
                if (
                    version_id === versionId ||
                    validation_version_id === validationVersionId
                ) {
                    this.setState({
                        numberOfIterations: numberOfIterations + 1
                    });
                    return new Promise((resolve) =>
                        setTimeout(
                            () =>
                                this.getProductData(
                                    isUpdate,
                                    recordId,
                                    versionId,
                                    validationVersionId
                                ).then(resolve),
                            10000
                        )
                    );
                }
            }
            // If this method called with parameter - update MyRecord in the Redux Store as well
            if (isUpdate) {
                const { data, totalItems, page, page_size } = this.props;

                dispatch(
                    setUpdatedMyRecord(
                        record,
                        data,
                        totalItems,
                        page,
                        page_size
                    )
                );
            }

            this.getBrandStaticAttributes(record);
            this.fetchAgencies(record);

            this.setState(
                {
                    originalData: info.data,
                    cloneData: cloneDeep(record),
                    data: record,
                    initialFetched: true,
                    numberOfIterations: 0,
                    esgGrouped: this.groupAttributes(record)

                },
                () => {
                    this.fetchCategoryAttributes(record);
                    this.fetchIpsLevelsFunction(record);

                    return this.checkIfProductHasMatchingOpaRecord(
                        record['concept_code_confirmed_info']
                    );
                }
            );

            if(window.location.href.indexOf('records') > -1) {
                this.fetchConfirmedInfoConcepts();
            }
        });
    }

    fetchConfirmedInfoConcepts() {
        const { data } = this.state;
        let clonedData = cloneDeep(data);

        let query = buildSearchQuery(!!data && data.concept_code_confirmed_info, data);

        return queryOpaData(query, data.licensor_organisation_id, {}).then((response) => {
            each(!!response && response.data, res => {
                let index = !!clonedData && findIndex(clonedData.concept_code_confirmed_info, code => code.xelacore_concept_id === res.xelacoreConceptId);

                if(index > -1) {
                    clonedData.concept_code_confirmed_info[index].concept_code = res.approvalCode;
                }
            });

            this.setState({data: clonedData});
        });
    }

    checkIfProductHasMatchingOpaRecord(aprCode) {
        const { xelacore } = this.props;

        const {
            auth: {
                companyData
            }
        } = xelacore;

        if (!aprCode) return this.setState({ opaDataMatch: {} });
        const { data } = this.state;
        const query = isAgent(companyData) ? {
            exact: {
                licensor_brand: data.licensor_brand
            }
        } : buildSearchQuery(aprCode, data);
        if (!query) return;

        if(isAgent(companyData)) {
            return fetchBrandConcepts(query, data.licensor_organisation_id).then(response => {
                const records = response && response.data && map(response.data, item => {
                    item = {...item.properties, ...item};
                    return parseV1Response(item);
                });
                
                if (!response || !records) return;
                const source =
                    Array.isArray(records) && records.length ? records : '';
                this.setState({ opaDataMatch: source });
            })
        } else {
            return queryOpaData(query, data.licensor_organisation_id, {}).then((response) => {
                const records = response && response.data && map(response.data, item => {
                    item = {...item.properties, ...item};
                    return parseV1Response(item);
                });

                if (!records) return;
                const source =
                    Array.isArray(records) && records.length ? records : '';
                this.setState({ opaDataMatch: source });
            });
        }
    }

    // Helper function to display the notification
    setNotification(type = 'success') {
        const { dispatch, t } = this.props;
        const message = {
            error: t('modules.product_detail.error_updating_record'),
            success: t('modules.product_detail.successfully_updated')
        };

        return dispatch(
            displayNotification({
                type,
                message: message[type]
            })
        );
    }

    updateData(updateData, key, callBack = null) {
        window.scrollTo(0, 0);
        const { dispatch, params, t } = this.props;
        const { data, originalData } = this.state;
        let obj = {};
        if (key) {
            obj[key] = updateData;
        } else {
            obj = updateData;
        }

        data.record_id = originalData.record.record_id;
        data.version_id = originalData.record.version_id;

        const updatedData = Object.assign({}, data, obj);
        const record = nullToString(Object.assign({}, { ...updatedData }));

        // TODO: This is awful. Yes I know. It's a hack to fix shite backend. Blame Steven the prick
        const otherImages = () => {
            if (
                record.other_images === '' ||
                record.other_images === null ||
                record.other_images === undefined
            )
                return [];

            if (typeof record.other_images === 'string')
                return [record.other_images];

            return record.other_images;
        };

        record.other_images = otherImages();

        this.setState({
            opaSearch: false,
            data: record,
            fetching: true,
            cloneData: record
        });

        return dispatch(updateProductInfo(params.id, { record }))
            .then(() => {
                return;
                return this.getProductData(
                    true,
                    params.id,
                    record.version_id,
                    record.validation_version_id
                ).then(() => {
                    callBack && typeof callBack === 'function'
                        ? callBack()
                        : this.setNotification();
                });
            })
            .catch((error) => {
                const { response: { data: { code } = {} } = {} } = error;
                if (code === 'catalog.update-VersionMismatch') {
                    return SetRefreshModal({
                        dispatch,
                        refetchAndClose: () => this.refetchAndClose(),
                        isModal: true
                    });
                }
                dispatch(
                    displayNotification({
                        message:
                            t('modules.product_detail.error_request'),
                        type: 'error',
                        timeOut: 5000
                    })
                );
            });
    }

    refetchAndClose() {
        const { dispatch } = this.props;
        dispatch(hideModal());
        this.getProductData(true);
    }

    displayConfirmationModal(data, key, text = null, showKey = true) {
        const { dispatch, t} = this.props;
        const color = ['Not Available', 'Discontinued'].includes(data)
            ? 'red'
            : 'green';
        const content = () => (
            <div>
                {text && text}
                {!text && <p>{t('modules.product_detail.successfully_updated_the_following')}</p>}
                {data && key && showKey && (
                    <ul className={`o-list o-list--${color}-list`}>
                        <li>
                            <p className="u-color-black">
                                <b className="u-capitalize">
                                    {removeUnderscore(key)}
                                </b>
                                : {data}
                            </p>
                        </li>
                    </ul>
                )}
            </div>
        );

        const modal = (
            <Modal alert body={content()} title={t('modules.product_detail.successful_update')} />
        );
        dispatch(showModal(modal));
    }

    fetchCategoryAttributes(record) {
        if (!record.licensor_organisation_id || !record.licensor_category_root_id) {
            this.setState({
                categoryAttributes: []
            });

            return;
        }

        getCategoryAttributes(record.licensor_organisation_id, record.licensor_category_root_id).then(response => {
            this.setState({
                categoryAttributes: !!response.data && !!response.data.category_attribute_definitions && response.data.category_attribute_definitions
            });
        });
    }

    flatArrayToTree(data) {
        const nest = (items, ip_id = null, link = 'parent_ip_id') => {
            return items.filter(item => item[link] === ip_id).map(item => ({
                ...item,
                children: nest(items, item.ip_id)
            }));
        };

        return nest(data);
    }

    onChangeItem(updateData, key) {
        let cloneUpdatedData = cloneDeep(updateData);
        
        const { data } = this.state;
        let obj = {};

        if (key) {
            obj[key] = cloneUpdatedData;
        } else {
            obj = cloneUpdatedData;
        }

        const updatedData = Object.assign({}, data, obj);
        const record = nullToString(Object.assign({}, { ...updatedData }));

        let clonedRecord = cloneDeep(record);

        this.state.data = clonedRecord;
        this.forceUpdate();

        this.setState({
            data: clonedRecord,
            esgGrouped: this.groupAttributes(clonedRecord)
        });
    }

    fetchIpsLevelsFunction(record) {
        const { xelacore } = this.props;

        const {
            auth: {
                token,
                companyData
            }
        } = xelacore;

        if(isAgent(companyData)) {
            fetchIpsLevelsv3(token, record.licensor_organisation_id).then(response => {

                this.setState({
                    ipLevels: !!response.data && map(response.data, item => parseV1Response(item)) || []
                });
            });
        } else {
            fetchIpsLevels(companyData.organisation_id, token, record.licensor_organisation_id).then(response => {
                this.setState({
                    ipLevels: !!response.data && response.data.ip_levels || []
                });
            });
        }
    }

    toggleOpaPanel(refetch = false, oldCode = null) {
        const { opaSearch } = this.state;
        if (refetch) {
            this.getProductData();
        }
        if (!opaSearch) window.scrollTo(0, 0);
        this.setState({ opaSearch: !opaSearch, oldCode });
    }

    switchTo(view) {
        const edit = { view: false, edit: true }[view];
        this.setState({ edit, switchTime: Date.now() });
    }

    groupAttributes(data) {
        let groupedAttrs = [];

        Object.keys(data.additional).map((el) => {
            let keyArr = el.split('_');
            let groups = keyArr.splice(0, 2);

            groupedAttrs.push({
                parent: groups[0],
                key: groups[1],
                label: keyArr.toString().split(',').join(' '),
                value: data.additional[el]
            });
        });

        return groupBy(groupedAttrs, 'key');
    }

    render() {
        const {
            data,
            opaSearch,
            originalData,
            opaDataMatch,
            oldCode,
            edit,
            fetching,
            categoryAttributes,
            ipLevels,
            staticBrandAttrs,
            switchTime,
            esgGrouped,
            cloneData,
            agenciesData
        } = this.state;
        const {
            dispatch,
            xelacore,
            params,
            params: { id },
            router,
            route
        } = this.props;
        if (!data) return null;

        const { auth: { companyData } = {} } = xelacore;
        const licensor = isLicensor(companyData);

        return (
            <div className="c-product__container">
                {/* <div className="c-my-records__button-container">
                    <div className="c-my-records__flex-header u-margin-top">
                        <div className="c-my-records__button-container">
                            <div className="u-flex">
                                <Button
                                    size={'small'}
                                    type={'secondary-2'}
                                    iconLeft={<ArrowLeft height="10" width="16"></ArrowLeft>}
                                    onClick={() => this.goBackFunction()}
                                >
                                    Back
                                </Button>
                                <h1>
                                    Product Details
                                    {data.is_quarantine && (
                                        <span> - Quarantine </span>
                                    )}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div> */}

                {fetching && (
                    <div className="c-product__editing-spinny">
                        <img src={imgPinkSpinner} />
                    </div>
                )}
                <ProductDetailTab
                    cloneData={cloneData}
                    onChangeItem={(updatedData, key) => this.onChangeItem(updatedData, key)}
                    editMode={licensor ? false : edit}
                    switchTime={switchTime}
                    switchTo={(view) => this.switchTo(view)}
                    dispatch={dispatch}
                    licensor={licensor}
                    agent={isAgent(companyData)}
                    toggleOpaPanel={(refetch, oldCode) =>
                        this.toggleOpaPanel(refetch, oldCode)
                    }
                    data={data}
                    originalData={originalData}
                    updateData={(data, key, callback) =>
                        this.updateData(data, key, callback)
                    }
                    type={params.productType}
                    opaSearch={opaSearch}
                    oldCode={oldCode}
                    getProductData={(
                        updateData,
                        recordId,
                        versionId,
                        validationVersionId
                    ) =>
                        this.getProductData(
                            updateData,
                            recordId,
                            versionId,
                            validationVersionId
                        )
                    }
                    opaDataMatch={opaDataMatch}
                    aprCode={map(data['concept_code_confirmed_info'], 'xelacore_concept_id')}
                    displayConfirmationModal={(data, key, text, showKey) =>
                        this.displayConfirmationModal(data, key, text, showKey)
                    }
                    router={router}
                    route={route}
                    id={id}
                    categoryAttributes={categoryAttributes}
                    ipLevels={ipLevels}
                    xelacore={xelacore}
                    esgData={esgGrouped}
                    agenciesData={agenciesData}
                    hasStaticAttributes={!!staticBrandAttrs && !!staticBrandAttrs.length && staticBrandAttrs.length > 0}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        xelacore,
        xelacore: {
            myRecords: {
                records: { data, totalItems, page, page_size } = {},
                notification
            } = {},
            categories: { list: categories } = {}
        } = {}
    } = state;

    return {
        xelacore,
        state,
        data,
        totalItems,
        page,
        page_size,
        categories,
        notification,
        messages: get(xelacore, 'socket.messages', [])
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductDetail));
