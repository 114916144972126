import React from 'react';
import { Icon } from 'src/js/components/static';
import { useTranslation } from 'react-i18next';

export default function Contact({}) {
    const { t } = useTranslation();

    return(
        <div className="help-panel__contact-block help-panel__contact-block-open">
            <section className="u-text-left">
                <h2>{t('static.side_panel.contact.contact_us')}</h2>
                <p>{t('static.side_panel.contact.get_in_touch_by_phone')}</p>
                <ul>
                    <li className="u-smaller u-flex-align u-margin-bottom">
                        <Icon
                            icon="ENVELOPE"
                            size="15"
                            className="u-margin-small-right"
                        />
                        <a href="mailto:support@fabacus.com">support@fabacus.com</a>
                    </li>
                    <li className="u-smaller u-flex-align u-margin-bottom">
                        <Icon
                            icon="phone"
                            size="15"
                            className="u-margin-small-right"
                        />
                        <a href="tel:00442034115000">+44 20 3411 5000</a>
                    </li>
                </ul>
            </section>
        </div>
    )
}