import get from 'lodash/get';
import set from 'lodash/set';

// Sort out the form fields by their priority
// 1) Mark fields by numbers by priority: error - 2, warning - 1, other fields - 0
// 2) Sort out fields by comparing its numeric weight
export const getSortedFormFields = (validationStatuses, inputHeaders) =>
    [...inputHeaders].sort((a, b) => {
        const aValue = get(validationStatuses, [a.dataName], '');
        const bValue = get(validationStatuses, [b.dataName], '');
        const aWeight =
            aValue.level === 'error' ? 2 : aValue.level === 'warn' ? 1 : 0;
        const bWeight =
            bValue.level === 'error' ? 2 : bValue.level === 'warn' ? 1 : 0;

        return bWeight - aWeight;
    });

export const getGroupedHeaders = (inputHeaders) =>
    inputHeaders.reduce(
        (acc, el) => {
            if (el.config && el.config.value) {
                set(acc, ['grouped', el.config.value, el.dataName], el);
            } else {
                set(acc, ['ungrouped', el.dataName], el);
            }
            return acc;
        },
        { grouped: {}, ungrouped: {} }
    );

export const getCollapsedHeaderGroups = (inputHeaders, validationStatuses) => {
    return inputHeaders.reduce((collapsedObj, el) => {
        if (el.config && el.config.group) {
            const key =
                Object.keys(validationStatuses).find((k) =>
                    k.startsWith(el.dataName === 'ip_paths' ? 'ips' : el.dataName) || k.startsWith(`static_attributes.${el.dataName}`)
                ) || '';

            const status = get(validationStatuses, [[key], 'level'], false);

            if (status === 'error' || status === 'warn') {
                return {
                    ...collapsedObj,
                    [el.config.group]: true
                };
            }
        }
        return collapsedObj;
    }, {});
}
