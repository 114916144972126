import React from 'react';
import Button from 'components/UiKit/Button';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
    const { t } = useTranslation();

    return (
        <div className="c-404">
            <div className="c-404__body">
                <h1 className="c-404__header">404</h1>
                <p>
                    <strong>{t('modules.single_static_page.page_doesnt_exist')}</strong>
                </p>
                <p>
                    <strong>
                        {t('modules.single_static_page.check_url')}
                    </strong>
                </p>
                <Button to={'/'}>{t('modules.single_static_page.take_me_home')}</Button>
            </div>
        </div>
    );
};

export default PageNotFound;
