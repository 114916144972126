import React, { useState, useEffect } from 'react';
import UiButton from 'modules/UiKit/components/Button/Button';
import NoteBox from './NoteBox';
import GenerateBox from './GenerateBox';
import RequestDetailsWrapper from './RequestDetailsWrapper';
import Breadcrumb from './Breadcrumb';
import { useTranslation } from 'react-i18next';
import {
    fetchAuthorizationRequest, generateDraftLetter,
    previewAuthorizationLetter
} from 'src/js/apicalls/other/authorization';

const DATA_STRUCTURE = {
    territories: {
        dataField: 'contracted_territories',
        fields: ['original', 'licensee_request', 'licensor_update', 'licensee_exception', 'licensor_exception'],
        copyFrom: {
            'licensor_update': 'licensee_request',
            'licensor_exception': 'licensee_exception'
        }
    },
    categories: {
        dataField: 'categories',
        fields: ['original', 'licensee_request', 'licensor_update', 'licensee_exception', 'licensor_exception'],
        copyFrom: {
            'licensor_update': 'licensee_request',
            'licensor_exception': 'licensee_exception'
        }
    },
    ips: {
        dataField: 'ips',
        fields: ['original', 'licensee_request', 'licensor_update', 'licensee_exception', 'licensor_exception'],
        copyFrom: {
            'licensor_update': 'licensee_request',
            'licensor_exception': 'licensee_exception'
        }
    }
};

const RequestDetails = ({ requestId, onGeneratePreview }) => {
    const { t } = useTranslation();
    const [selectedContract, setSelectedContract] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [previewAvailable, setPreviewAvailable] = useState(false);
    const [checkingPreview, setCheckingPreview] = useState(false);

    const processContractDetails = (details) => {
        if (!details || !Array.isArray(details)) return details;

        return details.map(contract => {
            Object.keys(DATA_STRUCTURE).forEach(sectionType => {
                const config = DATA_STRUCTURE[sectionType];
                if (!config) return;

                const dataField = config.dataField;

                if (!contract[dataField]) return;

                config.fields.forEach(field => {
                    contract[dataField][field] = contract[dataField][field] || [];
                });

                Object.entries(config.copyFrom).forEach(([targetField, sourceField]) => {
                    if (!contract[dataField][targetField] || contract[dataField][targetField].length === 0) {
                        contract[dataField][targetField] = [...(contract[dataField][sourceField] || [])];
                    }
                });
            });

            return contract;
        });
    };

    const loadRequestData = () => {
        setLoading(true);
        setError(null);

        fetchAuthorizationRequest(requestId)
            .then(response => {
                if (response && response.trademarkRequest) {
                    if (response.trademarkRequest.contract_details) {
                        response.trademarkRequest.contract_details = processContractDetails(
                            response.trademarkRequest.contract_details
                        );
                    }

                    setData(response);
                } else {
                    setError(t('toolkit.trademark_authorization.request.invalid_response'));
                }
            })
            .catch(err => {
                setError(err && err.response && err.response.data && err.response.data.message || t('toolkit.trademark_authorization.request.failed_fetch'));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!requestId) return;
        loadRequestData();
    }, [requestId]);

    useEffect(() => {
        let mounted = true;

        const checkPreviewAvailability = () => {
            if (!selectedContract || !data) {
                setPreviewAvailable(false);
                return;
            }

            const selectedContractData = (data.contracts.active || []).find(c => c.contractId === selectedContract);
            if (!selectedContractData) {
                setPreviewAvailable(false);
                return;
            }

            setCheckingPreview(true);
            previewAuthorizationLetter(selectedContractData.agreementId)
                .then(() => {
                    if (mounted) {
                        setPreviewAvailable(true);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setPreviewAvailable(false);
                    }
                })
                .finally(() => {
                    if (mounted) {
                        setCheckingPreview(false);
                    }
                });
        };

        checkPreviewAvailability();

        return () => {
            mounted = false;
        };
    }, [selectedContract, data]);

    const handleContractChange = (event) => {
        const contractId = event.target.value;
        setSelectedContract(contractId);
    };

    const parseTrademarkRequest = (trademarkRequest) => {
        if (!trademarkRequest) return null;

        const parsedRequest = { ...trademarkRequest };

        if (parsedRequest.requester_info) {
            parsedRequest.created_by = parsedRequest.requester_info;
            delete parsedRequest.requester_info;
        }
        delete parsedRequest.manufacturer_id;
        return parsedRequest;
    };

    const handleGeneratePreview = () => {
        setLoading(true);
        const updatedTrademarkRequest = parseTrademarkRequest(data.trademarkRequest);
        generateDraftLetter(updatedTrademarkRequest.id, updatedTrademarkRequest)
            .then(() => {
                if (onGeneratePreview) {
                    onGeneratePreview({
                        trademarkRequest: updatedTrademarkRequest,
                        contractId: selectedContract
                    });
                }
            })
            .catch(() => {
                setError(t('toolkit.trademark_authorization.request.error_update'));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleUpdateContractDetails = (updatedContracts) => {
        if (!data || !data.trademarkRequest) return;
        const updatedData = JSON.parse(JSON.stringify(data));
        updatedData.trademarkRequest.contract_details = updatedContracts;
        setData(updatedData);
    };

    const getBreadcrumbItems = () => {
        const items = [
            {
                label: t('toolkit.trademark_authorization.breadcrumb.requests'),
                link: '/authorization-request'
            }
        ];

        if (data && data.trademarkRequest) {
            const companyName = data.trademarkRequest.company_info.legal_company_name ||
                t('toolkit.trademark_authorization.details.unknown_company');

            items.push({
                label: `${t('toolkit.trademark_authorization.breadcrumb.request')} - ${companyName}`,
                link: null
            });
        }

        return items;
    };

    if (loading) return (
        <div className="toolkit-container">
            <p>{t('toolkit.please_wait')}</p>
        </div>
    );

    if (error) return (
        <div className="toolkit-container">
            <p>{error}</p>
            <UiButton type="secondary" onClick={loadRequestData}>
                {t('buttons.retry')}
            </UiButton>
        </div>
    );

    if (!data) return null;

    const trademarkRequest = data.trademarkRequest || {};
    const contracts = data.contracts || {};
    const activeContracts = contracts.active || [];
    const companyInfo = trademarkRequest.company_info || {};

    return (
        <div>
            <Breadcrumb items={getBreadcrumbItems()} />
            <div className="toolkit-container toolkit-container--request-details">

                <RequestDetailsWrapper
                    data={data}
                    onUpdateContractDetails={handleUpdateContractDetails}
                />

                <NoteBox
                    companyName={companyInfo.legal_company_name || t('toolkit.trademark_authorization.details.company')}
                    note={trademarkRequest.note || t('toolkit.trademark_authorization.note.no_notes')}
                    logoUrl={trademarkRequest.licensee_organisation_id}
                />

                <GenerateBox
                    contracts={activeContracts}
                    selectedContract={selectedContract}
                    onContractChange={handleContractChange}
                    onGeneratePreview={handleGeneratePreview}
                    isLoading={loading || checkingPreview}
                    previewAvailable={previewAvailable}
                />
            </div>
        </div>
    );
};

export default RequestDetails;
