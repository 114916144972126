import React, { Component } from 'react';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import size from 'lodash/size';
import { Modal, Auth, ModalCarousel } from 'src/js/components/static';
import { showModal, hideModal } from 'src/js/actions/xelacore';
import { bulkImagesUpload } from 'src/js/actions/imageUpload';
import { isURL, setImage, checkImageExists } from 'src/js/helpers/dataHelpers';
import ProductImagesUploader from './ProductImagesUploader';
import ProductImagesDisplay from './ProductImagesDisplay';
import { displayNotification } from 'src/js/actions/xelacore';
import { IMG_ERROR_MESSAGES } from 'src/js/constants/imagesConstants';
import Button from 'modules/UiKit/components/Button/Button';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';

const PRIMARY_TAG = 'primary';
const OTHERS_TAG = 'additional';

class ProductImages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeImage: {},
            newImageUrl: '',
            isPrimaryImage: false,
            images: [],
            fileUploaded: '',
            imageExists: null,
            imageCheckCount: 0,
            isImgZoomed: false,
            dragoverAreaClass: false
        };
    }

    componentDidMount() {
        const { imageExists } = this.state;
        const { data: { images = [] } = {} } = this.props;
        const imageSrc =
            images.length && (images[0].thumb_url_800 || images[0].local_url);
        let counter = 0;

        this.setPrimaryImageFirst(images);

        if (!imageExists) {
            let interval = setInterval(() => {
                checkImageExists(
                    imageSrc,
                    () => {
                        this.setState({
                            imageExists: true
                        });

                        clearInterval(interval);
                    },
                    () => {
                        if (counter > 15) {
                            clearInterval(interval);
                        }

                        counter++;

                        this.setState({
                            imageExists: false,
                            imageCheckCount: counter
                        });
                    }
                );
            }, 2000);
        }
    }

    componentDidUpdate() {
        if (!get(this, 'props.data.images')) return;
        if (
            get(this, 'props.data.images.length') !==
            get(this, 'state.images.length')
        ) {
            this.setPrimaryImageFirst(get(this, 'props.data.images'));
        }
    }

    setPrimaryImageFirst(images) {
        const imagesArr = [...images];
        const primaryImageIndex = findIndexPrimaryImage(imagesArr);
        const isPrimaryImage = primaryImageIndex !== -1;

        if (isPrimaryImage) {
            const primaryImg = { ...imagesArr[primaryImageIndex] };
            imagesArr.splice(primaryImageIndex, 1);
            imagesArr.unshift(primaryImg);
        }

        this.setState({
            images: imagesArr,
            activeImage: imagesArr && imagesArr[0],
            isPrimaryImage
        });
    }

    setActiveImage(i) {
        if (this.state.activeImage !== i) this.setState({ activeImage: i });
    }

    checkImage = (e) => {
        const { t } = this.props;
        e.preventDefault();
        window.scrollTo(0, 0);
        const uploadedImage = this.state.newImageUrl;

        const { updateData, data, dispatch } = this.props;
        if (!isURL(uploadedImage))
            return dispatch(
                showModal(
                    <Modal
                        alert
                        title={t('modules.product_detail.product_images.invalid_image')}
                        body={<div>{IMG_ERROR_MESSAGES['WRONG_URL']}</div>}
                    />
                )
            );
        const imageToAdd = setImage(uploadedImage, data);
        return updateData(imageToAdd, undefined, () => {
            this.setState({ newImageUrl: '' });
            return dispatch(
                displayNotification({
                    message: t('modules.product_detail.product_images.new_image_added'),
                    type: 'success'
                })
            );
        });
    };

    setMainImage() {
        const { updateData, dispatch, t } = this.props;
        const { images = [], activeImage, isPrimaryImage } = this.state;
        const primaryImageIndex = findIndexPrimaryImage(images);
        if (!!size(activeImage)) {
            const updImages = [...images];
            const activeImageIndex = findIndex(images, (image) => {
                return image.image_id === activeImage.image_id;
            });

            if (isPrimaryImage) {
                updImages[primaryImageIndex] = {
                    ...updImages[primaryImageIndex],
                    is_primary: false,
                    tag: OTHERS_TAG
                };
            }

            updImages[activeImageIndex] = {
                ...updImages[activeImageIndex],
                is_primary: true,
                tag: PRIMARY_TAG
            };

            this.setPrimaryImageFirst(updImages);

            return new Promise((resolve) => {
                updateData({ images: updImages }, null, resolve, false);
            }).then(() => {
                dispatch(
                    displayNotification({
                        message: t('modules.product_detail.product_images.primary_image_set'),
                        type: 'success'
                    })
                );
            });
        }
    }

    removeImage() {
        const { dispatch, updateData, t } = this.props;
        const { activeImage, images } = this.state;
        const primaryImageIndex = findIndexPrimaryImage(images);
        const activeImageIndex = findIndex(
            images,
            (image) => image.image_id === activeImage.image_id
        );

        const successfulDeletion = () =>
            dispatch(
                displayNotification({
                    message: t('modules.product_detail.product_images.image_deleted'),
                    type: 'success'
                })
            );

        if (primaryImageIndex === activeImageIndex) {
            const firstOtherImgIndex = images.findIndex(
                (img) => img && img.tag && img.tag === OTHERS_TAG
            );

            images[firstOtherImgIndex] = {
                ...images[firstOtherImgIndex],
                is_primary: true,
                tag: PRIMARY_TAG
            };
        }

        images.splice(activeImageIndex, 1);
        this.setActiveImage(images[0]);
        dispatch(hideModal());

        return new Promise((resolve) =>
            updateData({ images }, null, resolve)
        ).then(() => {
            successfulDeletion();
            this.setPrimaryImageFirst(images);
        });
    }

    confirmRemoveImage() {
        const { dispatch, t } = this.props;
        const content = (
            <div>
                <p>{t('modules.product_detail.product_images.are_you_sure')}</p>
                <div className="u-flex-gap u-margin-top u-flex-align--center">
                    <Button
                        type="secondary"
                        size="small"
                        onClick={() => this.removeImage()}>
                            {t('buttons.confirm')}
                    </Button>
                    <Button
                        type="secondary"
                        size="small"
                        onClick={() => dispatch(hideModal())}>
                        {t('buttons.cancel')}
                    </Button>
                </div>
            </div>
        );

        const modal = <Modal body={content} title={t('modules.product_detail.product_images.confirm_deletion')} />;
        return dispatch(showModal(modal));
    }

    showCarouselModal() {
        const {
            data: { images, product_name },
            dispatch,
            t
        } = this.props;
        const { activeImage } = this.state;

        const body = (
            <ModalCarousel
                isZoomApplied={this.state.isImgZoomed}
                images={images}
                currentIndex={activeImage}
                dispatch={dispatch}
            />
        );

        const modal = (
            <Modal
                title={`${t('modules.product_detail.product_images.viewing_images_for')} ${product_name}`}
                body={body}
                className="c-modal--large-box"
            />
        );

        return dispatch(showModal(modal));
    }

    onDragEnterImagearea() {
        return this.setState({
            dragoverAreaClass: true
        });
    }

    onDragLeaveImagearea() {
        return this.setState({
            dragoverAreaClass: false
        });
    }

    handleProductImagesCalback = (childData) => {
        if (childData === 'endDrag') {
            this.setState({
                dragoverAreaClass: false
            });
        }
    };


    render() {
        const {
            type,
            dispatch,
            data,
            recordId,
            filesLeftToUploadCount,
            filesTotalToUpload
        } = this.props;
        const {
            activeImage,
            newImageUrl,
            isPrimaryImage,
            images,
            imageExists,
            imageCheckCount,
            dragoverAreaClass
        } = this.state;

        return (
            <div>
                <Auth
                    restrictTo="licensee"
                >
                    <div
                        className={classnames('c-product__image-wrapper-upload-dropper', {
                            'dragoverAreaClass': dragoverAreaClass
                        })}
                        onDragOver={(e) => this.onDragEnterImagearea(e)}
                        onDragLeave={(e) => () => {
                            setTimeout(() => {
                                this.onDragLeaveImagearea(e);
                            }, 123);
                        }}
                        onDragExit={(e) => () => {
                            setTimeout(() => {
                            this.onDragLeaveImagearea(e)
                        }, 123);
                        }}
                    >

                        <ProductImagesDisplay
                            type={type}
                            dispatch={dispatch}
                            imageExists={imageExists}
                            imageCheckCount={imageCheckCount}
                            activeImage={activeImage}
                            images={images}
                            data={data}
                            isPrimaryImage={isPrimaryImage}
                            setMainImage={() => this.setMainImage()}
                            confirmRemoveImage={() => this.confirmRemoveImage()}
                            setActiveImage={(i) => this.setActiveImage(i)}
                            filesLeftToUploadCount={filesLeftToUploadCount}
                            filesTotalToUploadCount={filesTotalToUpload.length}
                            parentActionCallback={this.handleProductImagesCalback}
                            showCarouselModal={() => this.showCarouselModal()}
                        />

                        {type !== 'catalog' && (
                            <Auth
                                restrictTo="licensee"
                                permission="registration.manage"
                            >
                                <ProductImagesUploader
                                    newImageUrl={newImageUrl}
                                    handleChange={(e) =>
                                        this.setState({ newImageUrl: e.target.value })
                                    }
                                    bulkImagesUpload={(files) =>
                                        dispatch(
                                            bulkImagesUpload(files, recordId, data)
                                        )
                                    }
                                    checkImage={this.checkImage}
                                    dispatch={dispatch}
                                    data={data}
                                    filesTotalToUploadCount={filesTotalToUpload.length}
                                    parentActionCallback={this.handleProductImagesCalback}
                                />
                            </Auth>
                        )}
                    </div>
                </Auth>

                <Auth
                    restrictTo="licensor"
                >
                    <div
                        className={classnames('c-product__image-wrapper-upload-dropper', {
                            'dragoverAreaClass': dragoverAreaClass
                        })}
                    >
                        <ProductImagesDisplay
                            type={type}
                            dispatch={dispatch}
                            imageExists={imageExists}
                            imageCheckCount={imageCheckCount}
                            activeImage={activeImage}
                            images={images}
                            data={data}
                            isPrimaryImage={isPrimaryImage}
                            setMainImage={() => this.setMainImage()}
                            confirmRemoveImage={() => this.confirmRemoveImage()}
                            setActiveImage={(i) => this.setActiveImage(i)}
                            filesLeftToUploadCount={filesLeftToUploadCount}
                            filesTotalToUploadCount={filesTotalToUpload.length}
                            parentActionCallback={this.handleProductImagesCalback}
                            showCarouselModal={() => this.showCarouselModal()}
                        />
                    </div>
                </Auth>

                <Auth
                    restrictTo="agent"
                >
                    <div
                        className={classnames('c-product__image-wrapper-upload-dropper', {
                            'dragoverAreaClass': dragoverAreaClass
                        })}
                    >
                        <ProductImagesDisplay
                            type={type}
                            dispatch={dispatch}
                            imageExists={imageExists}
                            imageCheckCount={imageCheckCount}
                            activeImage={activeImage}
                            images={images}
                            data={data}
                            isPrimaryImage={isPrimaryImage}
                            setMainImage={() => this.setMainImage()}
                            confirmRemoveImage={() => this.confirmRemoveImage()}
                            setActiveImage={(i) => this.setActiveImage(i)}
                            filesLeftToUploadCount={filesLeftToUploadCount}
                            filesTotalToUploadCount={filesTotalToUpload.length}
                            parentActionCallback={this.handleProductImagesCalback}
                            showCarouselModal={() => this.showCarouselModal()}
                        />
                    </div>
                </Auth>

            </div>

        );
    }
}

function findIndexPrimaryImage(images) {
    return findIndex(
        images,
        (img) => img && img.is_primary && img.tag && img.tag === PRIMARY_TAG
    );
}

export default withTranslation()(ProductImages)