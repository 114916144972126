export const languagesArray = [
    { label: 'Afrikaans', name: 'af' },
    { label: 'Albanian', name: 'sq' },
    { label: 'Amharic', name: 'am' },
    { label: 'Arabic', name: 'ar' },
    { label: 'Armenian', name: 'hy' },
    { label: 'Azerbaijani', name: 'az' },
    { label: 'Bengali', name: 'bn' },
    { label: 'Bosnian', name: 'bs' },
    { label: 'Bulgarian', name: 'bg' },
    { label: 'Catalan', name: 'ca' },
    { label: 'Chinese (Simplified)', name: 'zh' },
    { label: 'Chinese (Traditional)', name: 'zh-TW' },
    { label: 'Croatian', name: 'hr' },
    { label: 'Czech', name: 'cs' },
    { label: 'Danish', name: 'da' },
    { label: 'Dari', name: 'fa-AF' },
    { label: 'Dutch', name: 'nl' },
    { label: 'English', name: 'en' },
    { label: 'Estonian', name: 'et' },
    { label: 'Farsi (Persian)', name: 'fa' },
    { label: 'Filipino, Tagalog', name: 'tl' },
    { label: 'Finnish', name: 'fi' },
    { label: 'French', name: 'fr' },
    { label: 'French (Canada)', name: 'fr-CA' },
    { label: 'Georgian', name: 'ka' },
    { label: 'German', name: 'de' },
    { label: 'Greek', name: 'el' },
    { label: 'Gujarati', name: 'gu' },
    { label: 'Haitian Creole', name: 'ht' },
    { label: 'Hausa', name: 'ha' },
    { label: 'Hebrew', name: 'he' },
    { label: 'Hindi', name: 'hi' },
    { label: 'Hungarian', name: 'hu' },
    { label: 'Icelandic', name: 'is' },
    { label: 'Indonesian', name: 'id' },
    { label: 'Irish', name: 'ga' },
    { label: 'Italian', name: 'it' },
    { label: 'Japanese', name: 'ja' },
    { label: 'Kannada', name: 'kn' },
    { label: 'Kazakh', name: 'kk' },
    { label: 'Korean', name: 'ko' },
    { label: 'Latvian', name: 'lv' },
    { label: 'Lithuanian', name: 'lt' },
    { label: 'Macedonian', name: 'mk' },
    { label: 'Malay', name: 'ms' },
    { label: 'Malayalam', name: 'ml' },
    { label: 'Maltese', name: 'mt' },
    { label: 'Marathi', name: 'mr' },
    { label: 'Mongolian', name: 'mn' },
    { label: 'Norwegian (Bokmål)', name: 'no' },
    { label: 'Pashto', name: 'ps' },
    { label: 'Polish', name: 'pl' },
    { label: 'Portuguese (Brazil)', name: 'pt' },
    { label: 'Portuguese (Portugal)', name: 'pt-PT' },
    { label: 'Punjabi', name: 'pa' },
    { label: 'Romanian', name: 'ro' },
    { label: 'Russian', name: 'ru' },
    { label: 'Serbian', name: 'sr' },
    { label: 'Sinhala', name: 'si' },
    { label: 'Slovak', name: 'sk' },
    { label: 'Slovenian', name: 'sl' },
    { label: 'Somali', name: 'so' },
    { label: 'Spanish', name: 'es' },
    { label: 'Spanish (Mexico)', name: 'es-MX' },
    { label: 'Swahili', name: 'sw' },
    { label: 'Swedish', name: 'sv' },
    { label: 'Tamil', name: 'ta' },
    { label: 'Telugu', name: 'te' },
    { label: 'Thai', name: 'th' },
    { label: 'Turkish', name: 'tr' },
    { label: 'Ukrainian', name: 'uk' },
    { label: 'Urdu', name: 'ur' },
    { label: 'Uzbek', name: 'uz' },
    { label: 'Vietnamese', name: 'vi' },
    { label: 'Welsh', name: 'cy' }
];

export const defaultContractDetails = [
    {
        contract_number: '201308107',
        contract_name: 'BTTF/VAR/Planeta',
        contract_status: 'Amendment Paid and Fully Executed',
        start_date: '1/9/2022',
        expiration_date: '12/31/2027',
        contracted_territories: {
            original: ['USA', 'Canada', 'UK', 'Spain', 'China', 'Moldova'],
            licensee_request: ['USA', 'Canada', 'UK', 'Spain', 'China', 'Moldova'],
            licensee_exception: [],
            licensor_update: [],
            licensor_exception: []
        },
        ips: {
            original: ['IP1', 'IP2'],
            licensee_request: ['IP1', 'IP2'],
            licensee_exception: [],
            licensor_update: [],
            licensor_exception: []
        },
        categories: {
            original: ['Category 1', 'Category 2'],
            licensee_request: ['Category 1', 'Category 2'],
            licensee_exception: [],
            licensor_update: [],
            licensor_exception: []
        }
    },
    {
        contract_number: '201308107',
        contract_name: 'BTTF/VAR/Planeta',
        contract_status: 'Amendment Paid and Fully Executed',
        start_date: '1/9/2022',
        expiration_date: '12/31/2027',
        contracted_territories: {
            original: ['USA', 'Canada', 'UK', 'Spain', 'China', 'Moldova'],
            licensee_request: ['USA', 'Canada', 'UK', 'Spain', 'China', 'Moldova'],
            licensee_exception: [],
            licensor_update: [],
            licensor_exception: []
        },
        ips: {
            original: ['IP1 1', 'IP2 2'],
            licensee_request: ['IP1 1', 'IP2 2'],
            licensee_exception: [],
            licensor_update: [],
            licensor_exception: []
        },
        categories: {
            original: ['Category1 1', 'Category1 2'],
            licensee_request: ['Category1 1', 'Category1 2'],
            licensee_exception: [],
            licensor_update: [],
            licensor_exception: []
        }
    }
];

export const RECIPIENT_TYPES = [
    {
        label: 'Border Agency',
        value: 'border_agency'
    },
    {
        label: 'Retailer',
        value: 'retailer'
    },
    {
        label: 'Online Marketplace',
        value: 'online_marketplace'
    },
    {
        label: 'Other',
        value: 'other'
    }
];
