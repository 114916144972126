import React from 'react';
import notFound from 'statics/imgs/desktop/concepts/not_found.svg';
import Button from 'modules/UiKit/components/Button/Button';
import { useTranslation } from 'react-i18next';

export default function ApprovalNoData({ toggleOpaPanel }) {
    const { t } = useTranslation();

    return (
        <div className="c-product__concept-block u-margin-bottom">
            <img
                src={notFound}
                className="u-padding"
                alt="No matching concepts"
            />
            <div>
                {t('modules.product_detail.approval_no_data.not_found_matching_concepts')}
            </div>
            <div className="u-text-center u-padding">
                <Button
                    type="secondary-2"
                    size="small"
                    onClick={toggleOpaPanel}>
                    {t('modules.product_detail.approval_no_data.search_for_concept')}
                </Button>
            </div>
        </div>
    );
}
