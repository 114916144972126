import React, { Fragment } from 'react';
import md5 from 'md5';
import moment from 'moment';
import Button from 'modules/UiKit/components/Button/Button';
import { useTranslation } from 'react-i18next';

export default function MetaComponent({ ...props }) {
    const { t } = useTranslation();

    const {
        data,
        fullLength,
        showUpload,
        profile_image
    } = props;
    const {
        record_id,
        conflict_with,
        meta: { ingest_id, upload_by_user, initial_upload_by_username } = {},
        uploaded_at
    } = data;

    const grav = `//www.gravatar.com/avatar/${md5(
        initial_upload_by_username || ''
    )}?s=50&d=identicon`;
    const image = profile_image ? profile_image : grav;

    return (
        <tr>
            <td
                className="c-rec-table__cell c-rec-table__meta-row"
                colSpan={fullLength}
            >
                <div className="u-flex-align">
                    <img
                         src={image}
                        alt="avatar"
                        width="30"
                        className="c-upload-history__uploader-image"
                    />

                    <div className="u-margin-right">
                        <span className="u-color-purple">
                            <b>{t('modules.my_records.meta_row.uploaded_by')} {upload_by_user}</b>
                        </span>{' '}
                        {/* TODO: Set this when auth service is ready */}
                        <br />
                        <span>
                            {moment(uploaded_at).format('MMM Do YYYY HH.mm')}
                        </span>
                    </div>
                    <div className="u-margin-right">
                        <Button
                            type="secondary-2"
                            size="small"
                            onClick={() => showUpload(ingest_id)}>
                            {t('buttons.track_file_upload')}
                        </Button>
                    </div>
                    {!conflict_with && (
                        <div className="u-margin-right">
                            <Button
                                type="secondary-2"
                                size="small"
                                to={`/product/records/${record_id}`}>
                                {t('buttons.view_product')}
                            </Button>
                        </div>
                    )}
                    {conflict_with && (
                        <Fragment>
                            <Button
                                type="secondary-2"
                                size="small"
                                disabled={conflict_with}>
                                {t('buttons.view_product')}
                            </Button>
                            <span className="u-margin-left">
                                {t('modules.my_records.meta_row.resolve_duplicates')}
                            </span>
                        </Fragment>
                    )}
                </div>
            </td>
        </tr>
    );
}
