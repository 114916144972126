export const navList = [
    {
        key: 'home',
        name: 'navigation.home',
        href: '/intro',
        restrictTo: 'licensee'
    },
    {
        key: 'dashboard',
        name: 'navigation.dashboard',
        href: '/',
        restrictTo: 'agent'
    },
    {
        key: 'dashboard',
        name: 'navigation.dashboard',
        href: '/',
        restrictTo: 'licensee'
    },
    {
        key: 'dashboard',
        name: 'navigation.dashboard',
        href: '/',
        restrictTo: 'licensor'
    },
    {
        key: 'my-records',
        href: '/my-records',
        name: 'navigation.my_records',
        restrictTo: 'licensee',
        permission: 'registration'
    },
    {
        key: 'product-catalogue',
        href: '/product-catalogue',
        name: 'navigation.product_catalogue'
    },
    {
        key: 'authorization-request',
        href: '/authorization-request',
        name: 'navigation.authorization_request',
        restrictTo: 'licensor',
        noParams:true,
        permission: ['trademark_authorisation_letter']
    },
    {
        key: 'royalty-reporting',
        href: '/royalty-reporting',
        name: 'navigation.royalty_reporting',
        restrictTo: 'licensee',
        permission: 'royalty_report'
    },
    {
        key: 'retailer-listings',
        href: '/retailer-listings',
        name: 'navigation.retailer_listings',
        restrictTo: 'licensor',
        permission: 'retailer_listings'
    },
    {
        key: 'toolkit',
        href: '/toolkit',
        name: 'navigation.toolkit',
        restrictTo: 'licensee',
        permission: ['trademark_authorisation_letter', 'licensee_royalty_assistant']
    }
]
