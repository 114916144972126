import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as TradeMarkIcon } from 'src/js/components/static/Icon/svg-icons/trademark.svg';
import { ReactComponent as ReportIcon } from 'src/js/components/static/Icon/svg-icons/report.svg';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

function Toolkit({ xelacore }) {
    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    const hasTrademarkPermission = () => {
        const posManage = get(
            xelacore,
            'auth.userPermissions.features.trademark_authorisation_letter.can',
            []
        );

        return (posManage || []).indexOf('view') !== -1;
    };

    const hasRoyaltyReportPermission = () => {
        const posManage = get(
            xelacore,
            'auth.userPermissions.features.licensee_royalty_assistant.can',
            []
        );

        return (posManage || []).indexOf('view') !== -1;
    };

    return (
        <div className='toolkit'>
            <div className='header'>
                <div className="u-flex-align">
                    <h2 className="u-margin-left">
                        {t('toolkit.toolkit_title')}
                    </h2>
                </div>
            </div>

            <div>
                <div className='body'>
                    <div className='group-header'>
                        <p className='group-subtitle'>{t('toolkit.toolkit_subtitle')}</p>
                        <h2>{t('toolkit.toolkit_title')}</h2>
                        <p>{t('toolkit.toolkit_description')}</p>
                    </div>

                    <div className='group-wrapper'>
                        { hasTrademarkPermission() && (
                            <a href='/toolkit/trademark' className='item-wrapper'>
                                <TradeMarkIcon></TradeMarkIcon>
                                <div className={'u-text-center'}>
                                    <p className='title'>{t('toolkit.trademark_letter_title')}</p>
                                    <p className='subtitle'>{t('toolkit.submit_trademark')}</p>
                                </div>
                            </a>
                        )}

                        { hasRoyaltyReportPermission() && (
                            <a href='/toolkit/royalty-report-assistant' className='item-wrapper'>
                                <ReportIcon></ReportIcon>
                                <div className={'u-text-center'}>
                                    <p className='title'>{t('toolkit.royalty_report_assistant')}</p>
                                    <p className='subtitle'>{t('toolkit.upload_sales_data')}</p>
                                </div>
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({
                             xelacore,
                             dispatch
 }) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(Toolkit);

