import React, { Fragment } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const AdvancedFilteringSubHeader = ({
    collapse,
    el,
    toggleCollapsedGroup,
    collapsedGroup,
    noChidren,
    subcategoryTitle,
    children,
    categoryLevels,
    subgroups,
    keyName
}) => {
    const { t } = useTranslation();
    const cxCollapsible = classnames('c-advanced-filtering__group-body', {
        'c-advanced-filtering__group-body--collapsed':
            collapse && collapsedGroup[el]
    });
    const showCollapser = !noChidren || subcategoryTitle;

    return (
        <Fragment key={`${el}-collapsed`}>
            {showCollapser && (
                <div
                    onClick={() => (collapse ? toggleCollapsedGroup(el) : {})}
                    className="c-advanced-filtering__group-header"
                >
                    {!!subcategoryTitle && (
                        <h4>
                            {
                                el === 'Category' && !!categoryLevels.length
                                    ? categoryLevels[0]
                                    : !!keyName ? t(`constants.filter_constants.${keyName}`) : el
                            }
                        </h4>
                    )}
                    
                    {((!subcategoryTitle && !subgroups) || el === 'Alerts') && (
                        <h4>{!!keyName ? t(`constants.filter_constants.${keyName}`) : el}</h4>
                    )}
                </div>
            )}
            <div className={cxCollapsible} key={`${el}-uncollapsed`}>
                {children}
            </div>
        </Fragment>
    );
};

export default AdvancedFilteringSubHeader;
