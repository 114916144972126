import React, { Component } from 'react';
import {
    getMainImage
} from 'helpers/dataHelpers';
import { connect } from 'react-redux';
import includes from 'lodash/includes';
import map from 'lodash/map';
import size from 'lodash/size';
import intersectionBy from 'lodash/intersectionBy';
import conceptPlaceholder from 'statics/imgs/no-photo.svg';
import conceptNoResultPlaceholder from 'statics/imgs/noresult-placeholder.png';
import { truncate } from 'lodash/string';
import ImageWrapped from 'components/BulkLinkConcepts/ImageWrapped';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import DataViewPagination from 'src/js/components/DataViewPagination/DataViewPagination';
import { withTranslation } from 'react-i18next';

class BulkLinkConceptsConceptList extends Component {
    constructor(props) {
        super(props);
    }

    arrayPaginate(array, pageSize, page) {
        return array.filter((item, index) => {
            return (index < pageSize * page) &&
                (index > (pageSize * (page - 1) - 1));
        });
    }

    getCurrentImageIndex(list, current) {
        current = current || list[0];
        return list.indexOf(current);
    }

    render() {
        const {
            filteredConceptItems,
            recommendedConceptIds,
            recommendedConceptItems,
            currentConceptPage,
            conceptPageSize,
            selectedConcepts,
            conceptChecked,
            openConceptDetails,
            updatePageHandler,
            currentConceptSearchTerm,
            totalConceptItems,
            viewSelectedConceptsOnly,
            selectedConceptsData,
            selectedProductGroup,
            t
        } = this.props;

        let spitedConceptItems = viewSelectedConceptsOnly ? selectedConceptsData : filteredConceptItems;
        let conceptConfirmedInfoMap = !!selectedProductGroup && !!size(selectedProductGroup.list) && map(selectedProductGroup.list, 'concept_code_confirmed_info');
        let confirmedInfoIntersection = map(intersectionBy(...conceptConfirmedInfoMap, 'xelacore_concept_id'), 'xelacore_concept_id');

        if (!viewSelectedConceptsOnly && (recommendedConceptIds.length > 0 && currentConceptSearchTerm.length === 0)) {
            spitedConceptItems = filteredConceptItems.filter(conceptItem => !recommendedConceptIds.includes(conceptItem.approval_code));
        }

        return (
            <React.Fragment>
                {(spitedConceptItems.length > 0 || !!(recommendedConceptItems.length > 0 && currentConceptSearchTerm.length === 0)) && (
                    <div className={'concept-list-wrapper'}>
                        <div className={'concept-list-display'}>
                            {!!(recommendedConceptItems.length > 0 && currentConceptSearchTerm.length === 0) && !viewSelectedConceptsOnly && (
                                <div className={'concept-list-box-wrapper'}>
                                    <div className={'concept-list-box-header'}>
                                        <h3>{t('concept_list.recommended_concepts')}</h3>
                                    </div>
                                    <div className={'concept-list-box'}>

                                        {recommendedConceptItems.map((conceptItem, index) => {
                                            if (!conceptItem) {
                                                return;
                                            }

                                            return (
                                                <div key={index} className={'concept-tile-item'}>
                                                    <div className={'concept-action'}>
                                                        <div className={'concept-check'}>
                                                            <UiCheckbox
                                                                type="checkbox"
                                                                id={conceptItem.xelacore_concept_id}
                                                                checked={includes(selectedConcepts, conceptItem.xelacore_concept_id)}
                                                                onChange={(e) => {
                                                                    conceptChecked(e, conceptItem);
                                                                }}>
                                                            </UiCheckbox>
                                                        </div>
                                                    </div>
                                                    <label
                                                        htmlFor={conceptItem.xelacore_concept_id}
                                                        className={'concept-image'}
                                                        onClick={() => {
                                                        }}
                                                    >
                                                        <span className={'concept-image-bg'}>
                                                              <ImageWrapped
                                                                  imageUrl={getMainImage(conceptItem.images, 200, conceptPlaceholder)}
                                                                  imageName={conceptItem.product_name}
                                                              ></ImageWrapped>
                                                        </span>

                                                        <ImageWrapped
                                                            imageUrl={getMainImage(conceptItem.images, 200, conceptPlaceholder)}
                                                            imageName={conceptItem.product_name}
                                                        ></ImageWrapped>
                                                    </label>
                                                    <div
                                                        className={'concept-title'}
                                                        onClick={() => {
                                                            openConceptDetails(conceptItem);
                                                        }}
                                                    >
                                                        <h4>
                                                            {
                                                                truncate(conceptItem.product_name, {
                                                                    'length': 40,
                                                                    'separator': ' '
                                                                })
                                                            }
                                                        </h4>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    </div>
                                </div>
                            )}

                            {spitedConceptItems.length > 0 && (
                                <div className={'concept-list-box-wrapper'}>
                                    { !!recommendedConceptItems && !!recommendedConceptItems.length && recommendedConceptItems.length > 0 && (
                                        <div className={'concept-list-box-header'}>
                                            <h3>{t('concept_list.all_other_concepts')}</h3>
                                        </div>
                                    )}

                                    <div className={'concept-list-box'}>
                                        {spitedConceptItems.map((conceptItem) => {
                                            return ( <div key={conceptItem.xelacore_concept_id} className={'concept-tile-item'}>
                                                <div className={'concept-action'}>
                                                    <div className={'concept-check'}>
                                                        <UiCheckbox
                                                            type="checkbox"
                                                            id={conceptItem.xelacore_concept_id}
                                                            checked={includes(selectedConcepts, conceptItem.xelacore_concept_id)}
                                                            onChange={(e) => {
                                                                conceptChecked(e, conceptItem);
                                                            }}>
                                                        </UiCheckbox>
                                                    </div>
                                                </div>
                                                <label
                                                    htmlFor={conceptItem.xelacore_concept_id}
                                                    className={'concept-image'}
                                                    onClick={() => {
                                                    }}
                                                >
                                                    <span className={'concept-image-bg'}>
                                                        <ImageWrapped
                                                            imageUrl={getMainImage(conceptItem.images, 200, conceptPlaceholder)}
                                                            imageName={conceptItem.product_name}
                                                        ></ImageWrapped>
                                                    </span>
                                                    <ImageWrapped
                                                        imageUrl={getMainImage(conceptItem.images, 200, conceptPlaceholder)}
                                                        imageName={conceptItem.product_name}
                                                    ></ImageWrapped>
                                                </label>
                                                
                                                <div
                                                    className={'concept-title'}
                                                    onClick={() => {
                                                        openConceptDetails(conceptItem);
                                                    }}
                                                >
                                                    <h4>
                                                        {
                                                            truncate(conceptItem.product_name, {
                                                                'length': 40,
                                                                'separator': ' '
                                                            })
                                                        }
                                                    </h4>
                                                    {!!confirmedInfoIntersection && confirmedInfoIntersection.includes(conceptItem.xelacore_concept_id)
                                                    && (
                                                        <span className={'concept-linked-to-group'}></span>
                                                    )
                                                    }
                                                    <span></span>
                                                </div>
                                            </div>
                                        )})}
                                    </div>
                                </div>
                            )}
                        </div>

                        {!viewSelectedConceptsOnly && (
                            <DataViewPagination
                                pageNum={parseInt(currentConceptPage)}
                                totalItems={parseInt(totalConceptItems)}
                                pageLimit={parseInt(conceptPageSize)}
                                itemsPerPageHidden={true}
                                fetchData={(e) => {
                                    updatePageHandler(parseInt(e.page));
                                }}
                                isModal={true}
                            />)}
                    </div>
                )}

                {!(spitedConceptItems.length > 0 || !!(recommendedConceptItems.length > 0 && currentConceptSearchTerm.length === 0)) && (
                    <div className={'concept-list-wrapper'}>
                        <div className={'no-concept-results'}>
                            <img
                                src={conceptNoResultPlaceholder}
                                alt={'no concept list'}
                            />

                            <p>{t('concept_list.we_couldnt_find_concepts')}</p>
                            <p>{t('concept_list.change_search')}</p>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default connect()(withTranslation()(BulkLinkConceptsConceptList));
