import React from 'react';
import ownProducts from 'statics/imgs/desktop/concepts/own_products.svg';
import { useTranslation } from 'react-i18next';

export default function ApprovalOwnProduct() {
    const { t } = useTranslation();

    return (
        <div>
            <h3 className="c-product__header u-margin-bottom">
                {t('modules.product_detail.approval_own_product.concept_approval_information')}
            </h3>

            <div className="c-product__concept-block">
                <div>
                    <div className="u-padding">
                        <img
                            src={ownProducts}
                            className="u-padding"
                            alt="Own Product"
                        />
                    </div>
                    <div className="u-color-green">
                        {t('modules.product_detail.approval_own_product.unlicensed_products')}
                    </div>
                </div>
            </div>
        </div>
    );
}