import React from 'react';
import { pluralise } from 'src/js/helpers/strings';
import { Modal } from 'src/js/components/static';

import Button from 'modules/UiKit/components/Button/Button';
import { useTranslation } from 'react-i18next';

export default function RegisterOutputModal({
    hideModal,
    selectedRows
}) {
    const totalCount = selectedRows.length;
    const selectedLicensor = !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {}
    const { t } = useTranslation();

    const modalBody = (
        <div className="u-full-width">
            <div className="u-color-black u-padding-bottom">
                {t('modals.register_output_modal.you_have_submitted.you_have_submitted')} {totalCount} {t('modals.register_output_modal.you_have_submitted.product')}{' '}
                {pluralise(t('modals.register_output_modal.you_have_submitted.record'), totalCount)} {t('modals.register_output_modal.you_have_submitted.for_registration')}
                <br />
                {t('modals.register_output_modal.you_have_submitted.with')}
                <span className="u-color-blue">
                    {' ' + selectedLicensor.licensor_organisation_name}.
                </span>
            </div>

            <div className="u-flex-align u-flex-align--right u-flex-gap">
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => hideModal()}>
                        {t('buttons.close')}
                </Button>

                <Button
                    type="primary"
                    size="small"
                    to="/product-catalogue" onClick={() => hideModal()}>
                    {t('buttons.go_to_product_catalogue')}
                </Button>
            </div>
        </div>
    );

    return <Modal centered title="Register Products" body={modalBody} isSmall/>;
}
