import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';
import { ReactComponent as UploadIcon } from 'src/js/components/static/Icon/svg-icons/icon-attach-file.svg';
import { withTranslation } from 'react-i18next';

const styles = {
    dropzone: {},
    dropzoneText: {}
};

class SmartUploader extends Component {
    static propTypes = {
        maxSize: PropTypes.number, // file size in megabytes
        onDrop: PropTypes.func,
        accept: PropTypes.string,
        className: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = { files: [] };
        this._instance = null;
    }

    ref(instance) {
        this._instance = instance;
        return instance;
    }

    openHelpModal(e) {
        const { openHelp } = this.props;

        e.preventDefault();
        openHelp();
    }

    onDrop(files) {
        const { maxSize, onDrop } = this.props;

        if (maxSize && files.some(file => file.size > maxSize * 1e6)) {
            // recalculate file size in bytes
            alert(`Maximum file size allowed for upload: ${maxSize} MB`);
            return;
        }

        this.setState({ files }, () => {
            if (!onDrop) return;
            onDrop(files);
        });
    }

    render() {
        const { accept, className, errorMessage, link, isOpaUploader, t } = this.props;

        return (
            <div>
                <Dropzone
                    style={styles.dropzone}
                    ref={instance => this.ref(instance)}
                    onDrop={files => this.onDrop(files)}
                    accept={accept}
                    className={className}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            {...getRootProps({ className })}
                            style={styles.dropzone}
                        >
                            <input {...getInputProps()} />
                            <div
                                className="c-file-upload__text"
                                style={styles.dropzoneText}
                            >
                                <UploadIcon className="attach-icon"></UploadIcon>

                                {t('static.smart_uploader.drag_and_drop')}
                            </div>

                            <div className="upload-error">
                                {errorMessage}
                                {link != null && (
                                    <span
                                        className="help-link"
                                        onClick={e => this.openHelpModal(e)}
                                    >
                                        {link}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                </Dropzone>

                { !isOpaUploader && (
                    <div className="upload-help-container">
                        <span className="help-link"
                              onClick={e => this.openHelpModal(e)}>
                            {t('static.smart_uploader.what_product_data')}
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(SmartUploader)
