import React from 'react';
import sortBy from 'lodash/sortBy';
import { SidePanel } from 'src/js/components/static';
import { mandatoryFields } from 'src/js/constants/productConstants/index';
import { headers } from 'src/js/constants/productConstants/headers';

import { Icon } from 'src/js/components/static';

import productUpload from 'statics/files/what-product-data-do-I-need-to-upload.pdf';
import Button from 'modules/UiKit/components/Button/Button';
import { useTranslation } from 'react-i18next';

const headerJsx = ({ description, dataName, label, descriptionLabel }, idx) => {
    const { t } = useTranslation();
    const key = `${dataName ? dataName : label}-${idx}`;

    if (!!dataName && dataName !== 'uploaded_by' && dataName !== 'updated_at' && dataName !== 'registered_by_user') {
        return (
            <div className="c-my-records__data-format--info" key={`${key}`}>
                <h4>{t(`table_headers.${dataName}`)}</h4>
                <div key={`${key}-description`}>{t(`table_header_constants.${dataName}.licensee`)}</div>
                {description.examples &&
                    description.examples.map((item, idx) => (
                        <div key={`${item}-${idx}`}>
                            <p>{t(`table_header_constants.${dataName}.examples.${idx}`)}</p>
                        </div>
                    ))}
            </div>
        );
    }
};

const DataFormat = ({ close }) => {
    const { t } = useTranslation();

    const extraAttributes = [
        {
            label: t('modules.my_records.data_format.recommend_retail_price'),
            description: {
                licensee:
                    t('modules.my_records.data_format.retail_price_description')
            },
            order: 34
        },
        {
            label: t('modules.my_records.data_format.additional_images'),
            description: {
                licensee: t('modules.my_records.data_format.additional_images_description')
            },
            order: 23
        }
    ];

    const filteredHeaders = headers.filter(
        header => header.config && header.config.group
    );

    const neededHeaders = [
        ...filteredHeaders.filter(header => !!header.order),
        ...extraAttributes
    ];
    const sortedHeaders = sortBy(neededHeaders, 'order');

    const mandatoryFieldList = mandatoryFields.map((field, idx) => {
        const { label } =
            filteredHeaders.find(header => header.dataName === field) || {};

        if(field !== 'licensor_brand' && field !== 'licensor_property') {
            return <li key={`${label}-${idx}`}>{t(`table_headers.${field}`)}</li>;
        }
    });

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;
    const isWindowsStuff =
        isIE || isEdge || navigator.userAgent.indexOf('Firefox') > 0;
    const isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;

    return (
        <SidePanel width="500px" left close={close} closeBtn>
            <div className="c-my-records__data-format">
                <div className="c-my-records__data-format-header">
                    <h3>{t('modules.my_records.data_format.what_product_data')}</h3>
                    <p>
                        {t('modules.my_records.data_format.when_preparing_data')}
                    </p>
                    <div>
                        <a
                            className="c-my-records__data-format-download-list"
                            href={productUpload}
                            target="_blank"
                        >
                            <small>
                                <Icon icon="download" /> {t('modules.my_records.data_format.save_or_print')}
                            </small>
                        </a>
                    </div>
                </div>

                <div className="c-my-records__data-format--info">
                    <h4> {t('modules.my_records.data_format.mandatory_attributes')} </h4>
                    <ul className=""> {mandatoryFieldList} </ul>
                </div>

                {sortedHeaders.map(header => headerJsx(header))}

                {!isSafari && (
                    <div
                        className={`u-margin-bottom ${isWindowsStuff &&
                            'u-margin-bottom-4X'}`}
                    >
                        &nbsp;
                    </div>
                )}
                <div className="c-my-records__data-format-footer">
                    <div className="c-my-records__data-format-footer-flexy">
                        <div>
                            {t('modules.my_records.data_format.you_can_download')}
                        </div>
                        <div>
                            <Button
                                type={'secondary-2'}
                                size={'small'}
                                to="/files/Xelacore-Product-Data-Upload-Template-Licensee.csv"
                                download
                            >
                                {t('buttons.download')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </SidePanel>
    );
};
export default DataFormat;
