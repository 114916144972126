import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import assign from 'lodash/assign';
import last from 'lodash/last';
import { Icon } from 'src/js/components/static';
import Dropzone from 'react-dropzone';
import { displayNotification, hideModal, showModal } from 'src/js/actions/xelacore';

import { Modal } from 'src/js/components/static';
import Button from 'modules/UiKit/components/Button/Button';
import { withTranslation } from 'react-i18next';

const MAX_IMAGES_PER_RECORD = 50;

class BulkImageUploadPanel extends Component {
    static getDerivedStateFromProps = props => {
        const recordImagesCount =
            get(props, 'data.images', []).length +
            props.filesTotalToUploadCount;

        return { recordImagesCount };
    };

    constructor(props) {
        super(props);

        this.state = {
            uploadsVisible: false,
            uploadComplete: false,
            uploaderVisible: true,
            uploading: false,
            confirm: false,
            files: props.filesData || [],
            fileName: '',
            recordImagesCount: 0,
            filesInfo: [],
            imagesWithErrors: [],
            processing: false
        };
    }

    onDrop(files) {
        const filesInfo = [];
        const errors = [];

        this.setState({processing: true});

        Array.from(files).forEach(file => {
            const img = new Image();
            const imageSize = (file.size / (1024 * 1024)).toFixed(2);

            img.onload = () => {
                let newObj = Object.assign({
                    preview: URL.createObjectURL(file),
                    imageSize,
                    status: null
                })

                assign(file, newObj);
                filesInfo.push(file)
                this.setState({filesInfo});
            };

            img.onerror = () => {
                errors.push(file.name);
                this.setState({ filesInfo: [], imagesWithErrors: errors });
            };

            img.src = URL.createObjectURL(file);
        });

        setTimeout(() => {
            this.setState({
                files: [...this.state.files, ...this.state.filesInfo],
                filesInfo: [],
                processing: false
            });
        }, 500)
    }

    batchUpload = files => {
        const { close, dispatch, bulkImagesUpload, t } = this.props;
        const { recordImagesCount } = this.state;

        bulkImagesUpload(
            files.slice(0, MAX_IMAGES_PER_RECORD - recordImagesCount)
        ).then(() => {
            dispatch(
                displayNotification({
                    message: t('bulk_images_upload.new_image_added'),
                    type: 'success'
                })
            )

        }).catch(() => {
            dispatch(
                displayNotification({
                    message: t('bulk_images_upload.failed_to_publish'),
                    type: 'error'
                })
            )
        });

        this.setState({ files });
        dispatch(hideModal());
        close();
    };

    removeImage(index) {
        let { files } = this.state;
        files.splice(index, 1);
        this.setState({ files });
    }

    processBatch(batchFiles) {
        const { dispatch, t } = this.props;
        const { recordImagesCount, files } = this.state;
        const maxImagesLeft =
            MAX_IMAGES_PER_RECORD - recordImagesCount - files.length;

        if (maxImagesLeft < 0) {
            const modal = (
                <Modal
                    body={
                        <div>
                            <p style={{ color: '#f05a28', fontWeight: 'bold' }}>
                                {t('bulk_images_upload.maximum_per_record.maximum')} {MAX_IMAGES_PER_RECORD} {t('bulk_images_upload.maximum_per_record.images_per_record')}
                            </p>
                            <p>
                                <span style={{ fontWeight: 'bold' }}>
                                    {t('bulk_images_upload.only_images.only')}
                                    {MAX_IMAGES_PER_RECORD - recordImagesCount}{' '}
                                    {t('bulk_images_upload.only_images.images_will_be_uploaded')}
                                </span>{' '}
                                {t('bulk_images_upload.only_images.to_upload')}
                            </p>
                            <p>
                                {t('bulk_images_upload.only_images.please_remove')}
                                <span style={{ fontWeight: 'bold' }}>
                                    {t('bulk_images_upload.only_images.product_details')}
                                </span>{' '}
                                {t('bulk_images_upload.only_images.page_first')}
                            </p>

                            <div style={{ marginTop: '30px' }}>
                                <Button
                                    size={'small'}
                                    type={'primary'}
                                    onClick={() => this.batchUpload(batchFiles)}
                                >
                                    &nbsp;{t('buttons.proceed')}&nbsp;
                                </Button>
                                <Button
                                    size={'small'}
                                    type={'secondary-danger-2'}
                                    onClick={() => dispatch(hideModal())}>
                                    &nbsp;{t('buttons.cancel')}&nbsp;
                                </Button>
                            </div>
                        </div>
                    }
                    title={t('bulk_images_upload.upload_images')}
                />
            );

            dispatch(showModal(modal));
        } else {
            this.batchUpload(batchFiles);
        }
    }

    renderModalBody() {
        const {
            data: { product_name },
            t
        } = this.props;
        const { files, recordImagesCount, imagesWithErrors, processing } = this.state;

        const thumbs = !!files && files.map((image, i) => (
            <div
                key={`uploaded-image-${i}`}
                className="images-modal__uploaded-list__item"
            >
                <div className="images-modal__uploaded-list__item__image">
                    <div
                        className="images-modal__uploaded-list__item__image__src"
                        style={{ backgroundImage: `url(${image.preview})` }}
                    />
                    <div className="overlay">
                        <div
                            className="images-modal__uploaded-list__item__image__remove"
                            onClick={() => this.removeImage(i)}
                        >
                            <Icon icon="delete_user" size="16" fill={'white'} />
                            {t('buttons.remove')}
                        </div>
                    </div>
                </div>
                <div className="images-modal__uploaded-list__item__title">
                    {image.name}
                </div>
            </div>
        ));

        return (
            <div className="images-modal">
                <div className="images-modal__header">
                    <h3>{t('bulk_images_upload.upload_images_for')}</h3>
                    <h4> {product_name} </h4>
                </div>

                {recordImagesCount < MAX_IMAGES_PER_RECORD ? (
                    <Dropzone
                        accept="image/*"
                        onDrop={files => this.onDrop(files)}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                {...getRootProps({
                                    className: 'images-modal__upload-block'
                                })}
                                style={{ padding: '0 20px' }}
                            >
                                <input {...getInputProps()} />
                                <br />
                                <Icon icon="add" size="30" center />
                                <div className="images-modal__dropzone">
                                    <h4>{t('bulk_images_upload.drag_and_drop')}</h4>
                                    <p>
                                        {t('bulk_images_upload.you_can_upload.you_can_upload')}
                                        {MAX_IMAGES_PER_RECORD} {t('bulk_images_upload.you_can_upload.images_remaining')}
                                        {MAX_IMAGES_PER_RECORD -
                                            recordImagesCount}
                                    </p>

                                    {!!imagesWithErrors && imagesWithErrors.length > 0 && (
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: '#f05a28', fontWeight: '' }}>
                                                {t('bulk_images_upload.failed_to_upload.failed_to_upload')} {!!imagesWithErrors && imagesWithErrors.map((image) => (<strong>{image + (imagesWithErrors.length > 1 && last(imagesWithErrors) !== image ? ', ' : '.')}</strong>))} {t('bulk_images_upload.failed_to_upload.please_ensure')}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ color: '#f05a28', fontWeight: 'bold' }}>
                                {t('bulk_images_upload.maximum_per_record.maximum')} {MAX_IMAGES_PER_RECORD} {t('bulk_images_upload.maximum_per_record.images_per_record')}
                        </p>
                        <p>
                            {t('bulk_images_upload.only_images.to_upload')} {t('bulk_images_upload.only_images.please_remove')}
                            <span style={{ fontWeight: 'bold' }}>
                                {t('bulk_images_upload.only_images.product_details')}
                            </span>{' '}
                            {t('bulk_images_upload.only_images.page_first')}
                        </p>
                    </div>
                )}

                {!processing && (<div className="u-text-center">
                    <Button
                        size={'small'}
                        type={'primary'}
                        disabled={files.length === 0}
                        onClick={() => this.processBatch(files)}>
                        {t('buttons.confirm_and_upload')}
                    </Button>
                </div>)}

                {!!processing && (<div className="u-text-center">
                    <Button
                        size={'small'}
                        type={'primary'}
                        disabled>
                        {t('buttons.processing')}
                    </Button>
                </div>)}

                <div className="images-modal__uploaded-list">{thumbs}</div>
            </div>
        );
    }

    render() {
        const { confirm } = this.state;
        const { close } = this.props;
        const panelCx = classnames(
            'c-panel c-panel--curved c-panel--full-height',
            {
                'c-panel--collapsed': confirm
            }
        );

        return (
            <div className={panelCx}>
                <div className="c-panel__body">
                    <div className="c-file-upload">
                        <div className="u-flex">
                            <span
                                className="c-file-upload__close-panel"
                                onClick={() => close()}
                            >
                                <Icon icon="close" fill="black" size="20" />
                            </span>
                        </div>
                        <div>{this.renderModalBody()}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({ ...state }),
    (dispatch) => ({ dispatch })
)((withTranslation())(BulkImageUploadPanel));
