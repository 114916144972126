import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Checkbox({
    name,
    onChange,
    value,
    values,
    id,
    defaultValue,
    placeholder
}) {
    const { t } = useTranslation();

    const onChangeBox = e => {
        return onChange(!!e.target.checked);
    };

    const label =
        values.find(val => typeof val.name === 'boolean' ? val.name ? t('product_form_elements.checkbox.yes') : t('product_form_elements.checkbox.no') : val.name.toString() === value.toString()) || {};
    return (
        <div className="c-form-element__checkbox">
            <input
                name={name}
                type="checkbox"
                className="c-input-checkbox__input"
                checked={!!value}
                onChange={e => onChangeBox(e)}
                id={id}
                defaultValue={defaultValue}
                placeholder={placeholder}
            />
            <label
                className="c-input-checkbox__label c-input-checkbox__label--none-transform c-input-checkbox__label-main u-full-visible"
                htmlFor={id}
            />
            <label>{label.label}</label>
        </div>
    );
}
