import React, {Component} from 'react';
import {pluralise} from 'src/js/helpers/strings';
import {Modal, Button} from 'src/js/components/static';
import {hideModal} from 'src/js/actions/xelacore';
import { withTranslation } from 'react-i18next';

class BulkEditModalOutput extends Component {
    constructor(props) {
        super(props);
    }

    modalBody = () => {
        const {all, dispatch, error, conflicted, t} = this.props;
        const totalCount = all.length;
        return (
            <div className="u-full-width">
                <div className="u-color-black u-padding-bottom">
                    <strong>
                        {t('bulk_edit.bulk_edit_modal.bulk_edit_modal_output.bulk_submitted.you_have_submitted')} {totalCount} {t('bulk_edit.bulk_edit_modal.bulk_edit_modal_output.bulk_submitted.product')}{' '}
                        {pluralise(t('bulk_edit.bulk_edit_modal.bulk_edit_modal_output.bulk_submitted.record'), totalCount)} {t('bulk_edit.bulk_edit_modal.bulk_edit_modal_output.bulk_submitted.for_update')}
                    </strong>
                </div>
                {error && <div className="u-color-black u-padding-bottom">
                    <div className="u-text-left u-padding-half-bottom">{t('bulk_edit.bulk_edit_modal.bulk_edit_modal_output.error_update')}</div>
                    <div className="o-box--registrered-error">
                        {error}
                    </div>
                </div>}
                {!error &&
                    <div>
                        <div className="o-box--registrered-success">
                            <span className="u-fw--bold">{totalCount}</span>
                            &nbsp;
                            {pluralise(t('bulk_edit.bulk_edit_modal.bulk_edit_modal_output.record'), totalCount)}{' '}
                            {pluralise(t('bulk_edit.bulk_edit_modal.bulk_edit_modal_output.has'), totalCount)} {t('bulk_edit.bulk_edit_modal.bulk_edit_modal_output.updated_successfully.successfully_updated')}
                        </div>
                        {!!(conflicted) &&
                            <div className="o-box--registrered-error">
                                <span className="u-fw--bold">{conflicted}</span>
                                &nbsp;
                                {t('bulk_edit.bulk_edit_modal.bulk_edit_modal_output.conflicted.of_which')}{pluralise('is', conflicted)}{' '}
                                {t('bulk_edit.bulk_edit_modal.bulk_edit_modal_output.conflicted.now_in_conflict')}{' '}
                                {pluralise(t('bulk_edit.bulk_edit_modal.bulk_edit_modal_output.conflicted.record'), totalCount)}
                            </div>
                        }
                    </div>
                }

                <span className="u-flex-align u-padding-top u-flex-align--center">
                   <Button purpleGrad onClick={() => dispatch(hideModal())}>{t('buttons.close')}</Button>
                </span>
            </div>
        )
    };

    render() {
        const { t } = this.props;
        return <Modal centered title={t('bulk_edit.bulk_edit_modal.bulk_edit_modal_output.bulk_edit_summary')} body={this.modalBody()}/>;
    }

}

export default withTranslation()(BulkEditModalOutput)
