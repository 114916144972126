import React, { Component } from 'react';
import Button from 'modules/UiKit/components/Button/Button';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class NoDataCatalogue extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uploadOpa: false
        };
    }

    render() {
        const { toggleUploader, companyData, t } = this.props;
        const licensor =
            companyData.information && companyData.information.licensor
                ? 'licensor'
                : 'licensee';
        return (
            <div className='no-data-catalogue'>
                {licensor === 'licensor' && (
                    <p className='c-my-records__no-data-text'>
                        {t('product_components.no_data_catalogue.no_products')}
                    </p>
                )}

                {licensor !== 'licensor' && (
                    <p className='c-my-records__no-data-text' dangerouslySetInnerHTML={{ __html: t('product_components.no_data_catalogue.havent_added_products') }}></p>
                )}

                {licensor === 'licensee' && (
                    <Button
                        type="secondary-2"
                        size="medium"
                        onClick={() => browserHistory.push('/my-records')}
                    >
                        {t('buttons.my_records')}
                    </Button>
                )}

                {this.state.uploadOpa && (
                    <Button
                        type="secondary-2"
                        size="medium"
                        onClick={toggleUploader}>
                        {t('buttons.upload_product_approval_records')}
                    </Button>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { xelacore: { auth: { companyData = {} } = {} } = {} } = state;

    return { companyData };
}

export default connect(mapStateToProps)(withTranslation()(NoDataCatalogue));
