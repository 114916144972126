// Toggles the expand/collapse state of a table row
export const toggleTable = (expandedTables, index) => ({
    ...expandedTables,
    [index]: !expandedTables[index]
});

// Toggles the exception visibility for a given contract and type
export const toggleException = (exceptionVisibility, index, type) => ({
    ...exceptionVisibility,
    [index]: { ...exceptionVisibility[index], [type]: !exceptionVisibility[index][type] }
});

// Toggles an item in the licensee_request array for a given contract property
export const toggleItemInContract = (contracts, contractIndex, property, item) => {
    const newContracts = [...contracts];
    const contract = { ...newContracts[contractIndex] };
    const propertyData = { ...contract[property] };
    const currentSelected = propertyData.licensee_request || [];
    if (currentSelected.includes(item)) {
        propertyData.licensee_request = currentSelected.filter(i => i !== item);
    } else {
        propertyData.licensee_request = [...currentSelected, item];
    }
    contract[property] = propertyData;
    newContracts[contractIndex] = contract;
    return newContracts;
};

// Returns initial exception visibility based on the contract details array
export const getInitialVisibilityForExceptions = (contractDetails) =>
    contractDetails.reduce((acc, _, idx) => {
        acc[idx] = { contracted: false, ips: false, categories: false };
        return acc;
    }, {});
