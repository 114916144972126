import React from 'react';
import { pluralise } from 'src/js/helpers/strings';
import { useTranslation } from 'react-i18next';

export default function MyRecordsPageNotificationn(props) {
    const { t } = useTranslation();
    const { notification: { message, count } = {}, toggleNotification } = props;

    return (
        <div
            onClick={toggleNotification}
            className="c-local-notification__clickable-box"
        >
            {count} {pluralise(message, count)} |{' '}
            <span>
                <b>{t('buttons.view')}</b>
            </span>
        </div>
    );
}
