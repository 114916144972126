import React, { useState } from 'react';
import { FloatingMenu } from 'src/js/components/static';
import Button from 'modules/UiKit/components/Button/Button';
import { helperSetUserStateForSaving } from 'src/js/actions/userSettings';
import { setUserState } from 'src/js/actions/userDetails';
import { useTranslation } from 'react-i18next';

export default function CustomiseBottomBlock({
    activeHeaders,
    updateActiveHeaders,
    dontShowRedirectModal,
    dispatchDisplayNotification,
    headerLayouts,
    toggleHeaderOptions,
    dispatch,
    headers: { list, isFromList, isHeadersChanged, isDefaultHeader } = {}
}) {
    const { t } = useTranslation();

    const [ createTableViewSetting, setCreateTableViewSetting ] = useState(false);
    const [ settingName, setSettingName ] = useState('');

    const isCreatableFromList =
        isFromList && isHeadersChanged && !createTableViewSetting;
    const isEditable =
        (!isFromList || !isHeadersChanged) && !createTableViewSetting;
    const isNewNameInput = isHeadersChanged && createTableViewSetting;

    const checkForDefault = (arr) => {
        const check = arr.filter(
            item => item.name === 'Default Headers' && item.isDefault
        );
        return !!(Array.isArray(check) && check.length);
    }

    const makeWarning = (title) => {
        setCreateTableViewSetting(false);
        setSettingName('');
        dispatchDisplayNotification(title);
    }

    const checkForAlreadyExisted = (arr, settingName) => {
        if (!settingName || settingName === '') {
            makeWarning(
                t('table_v3.customise_bottom_block.configuration_not_empty')
            );
            return false;
        }

        const check = arr.filter(item => item.name === settingName);

        if (Array.isArray(check) && check.length) {
            makeWarning(
                `${t('table_v3.customise_bottom_block.configuration_not_empty')} "${settingName}" ${t('table_v3.customise_bottom_block.already_used')}`
            );

            return false;
        }

        return true;
    }

    const makeAllPreviousDefaultsToFalse = () => {
        return headerLayouts.map(item => (item.byDefault = false));
    }

    const createHeaderSetting = () => {
        if (!checkForAlreadyExisted(headerLayouts, settingName)) return;
        const newSetting = { name: settingName, list, byDefault: true };
        makeAllPreviousDefaultsToFalse();
        headerLayouts.unshift(newSetting);
        if (!checkForDefault(headerLayouts)) {
            headerLayouts.push({
                name: 'Default Headers',
                isDefault: true,
                byDefault: false
            });
        }

        dispatchHeaderSetting(headerLayouts, dontShowRedirectModal);
        newSetting.isFromList = true;
        setCreateTableViewSetting(false);
        setSettingName('');
        updateActiveHeaders(newSetting, false);
    }

    const updateHeaderSetting = () => {
        const idx =
            activeHeaders &&
            headerLayouts.findIndex(item => item.name === activeHeaders);
        const newSetting = { name: activeHeaders, list, byDefault: true };

        makeAllPreviousDefaultsToFalse();
        headerLayouts[idx] = newSetting;

        dispatchHeaderSetting(headerLayouts, dontShowRedirectModal);
        newSetting.isFromList = isFromList;
        setCreateTableViewSetting(false);
        updateActiveHeaders(newSetting, false);
    }

    const dispatchHeaderSetting = (newArray, dontShowRedirectModal) => {
        const my_records_custom_table_views = helperSetUserStateForSaving(
            newArray
        );
        dispatch(
            setUserState('settings', {
                state: {
                    settings: {
                        my_records_custom_table_views,
                        dontShowRedirectModal
                    }
                }
            })
        );
    }

    const deleteHeaderSetting = () => {
        const idx = headerLayouts.findIndex(
            item => item.name === activeHeaders
        );
        headerLayouts.splice(idx, 1);

        dispatchHeaderSetting(headerLayouts, dontShowRedirectModal);
        setCreateTableViewSetting(false);
        setSettingName('');
        return updateActiveHeaders({}, true);
    }

    return (
        <div className="c-rec-panel__footer-content-outer">
            {(isHeadersChanged || !isDefaultHeader) && (
                <div className="c-rec-panel__footer-content-div">
                    {isCreatableFromList && (
                        <FloatingMenu
                            inline
                            customButton={
                                <Button
                                    type="secondary-2"
                                    size="small"
                                    iconRight={
                                        <span
                                            style={{
                                                fontSize: '30px',
                                                marginTop: '-28px'
                                            }}
                                        >
                                            ˬ
                                        </span>
                                    }
                                >
                                    {t('table_v3.customise_bottom_block.save_custom_configuration')}
                                </Button>
                            }
                            className="c-rec-panel__hovered-state"
                        >
                            <li onClick={() => updateHeaderSetting()}>
                                {t('table_v3.customise_bottom_block.update_current_configuration')}
                            </li>
                            <li onClick={() => setCreateTableViewSetting(true)}>
                                {t('table_v3.customise_bottom_block.create_custom_configuration')}
                            </li>
                        </FloatingMenu>
                    )}
                    {isEditable && (
                        <Button
                            type="secondary-2"
                            size="small"
                            onClick={() =>
                                isDefaultHeader
                                    ? setCreateTableViewSetting(true)
                                    : updateHeaderSetting()
                            }
                            disabled={!isHeadersChanged}
                        >
                            {t('table_v3.customise_bottom_block.save_custom_configuration')}
                        </Button>
                    )}
                    {isNewNameInput && (
                        <div className="c-rec-panel__name-input">
                            <input
                                maxLength="50"
                                type="text"
                                name="name"
                                placeholder={t('table_v3.customise_bottom_block.custom_view_name')}
                                value={settingName}
                                onChange={e => setSettingName(e.target.value)}
                            />
                            <Button
                                type="secondary-2"
                                size="small"
                                className="c-rec-panel__name-submit"
                                onClick={() => createHeaderSetting()}
                            >
                                {t('buttons.save')}
                            </Button>
                        </div>
                    )}
                    {!isDefaultHeader && (
                        <Button
                            type="secondary-danger"
                            size="small"
                            className="u-margin-left"
                            onClick={() => deleteHeaderSetting()}
                        >
                            {t('table_v3.customise_bottom_block.delete_custom_configuration')}
                        </Button>
                    )}
                    <Button
                        type="secondary-2"
                        size="small"
                        className="u-margin-left"
                        onClick={toggleHeaderOptions}
                    >
                        {t('buttons.close')}
                    </Button>
                </div>
            )}
        </div>
    )
}