import React from 'react';
import linkFromCatalogue from 'statics/imgs/desktop/concepts/link_from_catalogue.svg';
import { useTranslation } from 'react-i18next';

export default function AprovalNonRegistered() {
    const { t } = useTranslation();

    return (
        <div>
            <div className="c-product__concept-block u-fat-bottom">
                <div>
                    <div className="u-padding">
                        <img
                            src={linkFromCatalogue}
                            className="u-padding"
                            alt="Link from catalogue"
                        />
                    </div>
                    <h3 dangerouslySetInnerHTML={{ __html: t('modules.product_detail.licensor_no_code.concept_linking') }}></h3>
                    <div>{t('modules.product_detail.licensor_no_code.no_linked_concept')}</div>
                </div>
            </div>
        </div>
    );
}
