import { headers } from './headers';
import moment from 'moment';

export const getStatusInfo = (data, field) => {
    const status = data[field];

    const { form, tooltip, label } = headers.find(el => el.dataName === field);
    const list =
        field === 'product_status'
            ? getProductStatusList(data, [...form.values])
            : form.values;
    return { list: list, field, selectedLabel: status, tooltip, label };
};

export const getProductStatusList = (data, list) => {
    const DEFAULT_DATE_FORMATS = ['DD-MM-YYYY', 'DD/MM/YYYY'];
    const UI_DATE_FORMAT = 'DD MMM YYYY';

    const MAD = data['market_availability_date'];

    const formats = [...DEFAULT_DATE_FORMATS, UI_DATE_FORMAT];
    const date = moment.utc(MAD || null, formats, true);
    date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const today = moment.utc();
    today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const differenceBetweenToday = date.diff(today);

    return list.map(value => {
        value.isHide =
            (value.name === 'Live' && differenceBetweenToday > 0) ||
            (value.name === 'New Product Listing' &&
                differenceBetweenToday <= 0);
        return value;
    });
};

export const getStatusColor = (status, field) => {
    switch (field) {
        case 'product_status':
            return getProductStatusInfo(status);
        case 'manufacturing_status':
            return getManufacturingStatusInfo(status);
        default:
            return {};
    }
};

export const getProductStatusInfo = status => {
    switch (status) {
        case 'Live':
        case 'New Product Listing':
            return 'green';
        case 'On-Hold':
            return 'grey';
        case 'Sell-off':
        case 'Inactive':
            return 'red';
        default:
            return 'red';
    }
};

export const getManufacturingStatusInfo = status => {
    switch (status) {
        case 'Pre-Production':
            return 'amber';
        case 'In Production':
            return 'green';
        case 'On-Hold':
            return 'grey';
        case 'Discontinued':
            return 'red';
        default:
            return 'red';
    }
};

export const getMaxMinDate = (field, data) => {
    if (field !== 'market_availability_date') {
        return {
            maxDate: null,
            minDate: null
        };
    }
    const date = new Date();
    date.setDate(date.getDate());
    return {
        maxDate: data.product_status === 'Live' ? date : null,
        minDate:
            data.product_status === 'New Product Listing' ? new Date() : null
    };
};

export const keyFields = headers
    .filter(({ config }) => config && config.keyField)
    .map(({ dataName }) => dataName);

export const alwaysSelected = () => headers
    .filter(({ config }) => config && config.alwaysSelected)
    .map(({ dataName }) => dataName);

export const conditionalFields = ['product_name', 'product_description'];

export const mandatoryFields = [
    'product_name',
    'product_description',
    'gtin',
    'mpn',
    'target_market',
    'language',
    'licensee_category_path',
    'licensor',
    'licensor_brand',
    'market_availability_date',
    'images'
];

export const specialSectionsNameForLicensor = {
    'owned_ip': 'Licensee IP Properties'
};
