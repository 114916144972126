import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UiTable from 'modules/RetailerListings/components/Table';

import {
    RETAIL_LIST
} from 'src/js/constants/retailerListings';

import {
    fetchAggregatedRetailListings
} from 'src/js/apicalls/other/retailers';
import { useTranslation } from 'react-i18next';

function RetailerListings({dispatch, xelacore}) {
    const { t } = useTranslation();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalResults, setTotalResults] = useState(0);
    const [aggregations, setAggregations] = useState([]);

    const {
        auth: {
            companyData: { organisation_id }
        }
    } = xelacore;
    
    useEffect(() => {
        getAggregatedListings();
    }, [])

    const getAggregatedListings = (filter, search, pageParams) => {
        let params = {
            exact: filter || {},
            partial: search || {}
        }

        return fetchAggregatedRetailListings(organisation_id, params, pageParams).then(resp => {
            setPage(!!resp._metadata && resp._metadata.page || page)
            setPageSize(!!resp._metadata && resp._metadata.pageSize || pageSize)
            setTotalResults(!!resp._metadata && resp._metadata.totalResults || totalResults)
            setList(!!resp && !!resp.data && resp.data.listings || [])
            setAggregations(!!resp && !!resp.data && resp.data.aggregations || [])
        })
    }

    return (
        <div className="royalty-reporting">
            <div className='c-my-records__flex-header'>
                <div className='c-my-records__button-container'>
                    <div className='u-flex'>
                        <h1>{t('modules.retailer_listings.retail_listing')}</h1>
                        <div className='c-rec-panel__counter'>
                            <span>
                                {!!totalResults && totalResults || 0}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <UiTable
                dispatch={dispatch}
                headersArr={RETAIL_LIST}
                list={list}
                page={page}
                pageSize={pageSize}
                aggregations={aggregations}
                totalResults={totalResults}
                updateTable={(filter, search, pageParams) => getAggregatedListings(filter, search, pageParams || {page, page_size: pageSize})}>
            </UiTable>
        </div>
    );
}

const mapStateToProps = ({
    xelacore,
    dispatch
}) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(RetailerListings);