import React from 'react';
import LinkedNonCatalogue from './LinkedNonCatalogue';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import Button from 'modules/UiKit/components/Button/Button';
import { useTranslation } from 'react-i18next';

export default function AprovalNonRegistered({ ...props }) {
    const { t } = useTranslation();
    const {
        isCatalogue,
        registered,
        record_id,
        conceptCodeConfirmedInfo,
        dismissConceptInfoFunc,
        dontShow
    } = props;
    const specialCase =
        !isCatalogue && conceptCodeConfirmedInfo && !!conceptCodeConfirmedInfo.length;

    return (
        <div>
            {specialCase && <LinkedNonCatalogue record_id={record_id} />}
            {!specialCase && (
                <div>
                    {!registered && (
                        <div className="c-product__detail-listing-info">
                            <span dangerouslySetInnerHTML={{ __html: t('modules.product_detail.approval_non_registered.register_your_product') }}></span>

                            <div className='u-flex-gap u-flex-align--right u-margin-top'>
                                <UiCheckbox
                                    type="checkbox"
                                    id="dontShow"
                                    name="dontShow"
                                    onChange={() => dontShow('dismissConceptInfo')}
                                    label={t('buttons.dont_show_again')}>

                                </UiCheckbox>
                                <Button
                                    type="secondary-2"
                                    size="small"
                                    onClick={() => dismissConceptInfoFunc()}
                                >
                                    {t('buttons.dismiss')}
                                </Button>
                            </div>
                        </div>
                    )}
                    {registered && !isCatalogue && (
                        <div className="c-product__detail-listing-info">
                            {t('modules.product_detail.approval_non_registered.link_corresponding_concept')}

                            <div className='u-flex-gap u-flex-align--right u-margin-top'>
                                <UiCheckbox
                                    type="checkbox"
                                    id="dontShow"
                                    name="dontShow"
                                    onChange={() => dontShow('dismissConceptInfo')}
                                    label={t('buttons.dont_show_again')}>

                                </UiCheckbox>
                                <Button
                                    type="secondary-2"
                                    size="small"
                                    onClick={() => dismissConceptInfoFunc()}
                                >
                                    {t('buttons.dismiss')}
                                </Button>
                            </div>
                        </div>

                    )}
                </div>
            )}
        </div>
    );
}
