import React from 'react';
import linked from 'statics/imgs/desktop/concepts/linked.svg';
import { useTranslation } from 'react-i18next';

export default function LinkedNonCatalogue({ record_id }) {
    const { t } = useTranslation();

    return (
        <div className="c-product__concept-block">
            <div>
                <div className="u-padding">
                    <img src={linked} className="u-padding" alt="Linked" />
                </div>
                <h3 dangerouslySetInnerHTML={{ __html: t('modules.product_detail.linked_non_catalogue.there_are_no_concepts') }}></h3>
                <div>
                    {t('modules.product_detail.linked_non_catalogue.view_and_edit')}
                    <a href={`/product/catalog/${record_id}`}>
                        {t('modules.product_detail.linked_non_catalogue.product_catalogue')}
                    </a>
                    .
                </div>
            </div>
        </div>
    );
}
