import React, {Component} from 'react';
import {pluralise} from 'src/js/helpers/strings';
import {Modal, Button} from 'src/js/components/static';
import {hideModal} from 'src/js/actions/xelacore';
import { withTranslation } from 'react-i18next';

class BulkDeletionModalOutput extends Component {
    constructor(props) {
        super(props);
    }

    modalBody = () => {
        const {all, dispatch, error, t} = this.props;
        const totalCount = all.length;
        return (
            <div className="u-full-width">
                <div className="u-color-black u-padding-bottom">
                    <strong>
                        {t('bulk_delete.submitted.you_have_submitted')} {totalCount} {t('bulk_delete.submitted.product')}{' '}
                        {pluralise(t('bulk_delete.submitted.record'), totalCount)} {t('bulk_delete.submitted.for_deletion')}
                    </strong>
                </div>
                {error && <div className="u-color-black u-padding-bottom">
                    <div className="u-text-left u-padding-half-bottom">{t('bulk_delete.something_went_wrong')}</div>
                    <div className="o-box--registrered-error">
                        {error}
                    </div>
                </div>}
                {!error && <div className="o-box--registrered-success">
                    <span className="u-fw--bold">{totalCount}</span>
                    &nbsp;
                    {pluralise(t('bulk_delete.deleted_successfully.record'), totalCount)}{' '}
                    {pluralise(t('bulk_delete.deleted_successfully.has'), totalCount)} {t('bulk_delete.deleted_successfully.successfully_deleted')}
                </div>}

                <span className="u-flex-align u-padding-top u-flex-align--center">
                   <Button purpleGrad onClick={() => dispatch(hideModal())}>{t('buttons.close')}</Button>
                </span>
            </div>
        )
    };

    render() {
        const { t } = this.props;
        return <Modal centered title={t('bulk_delete.bulk_deletion_summary')} body={this.modalBody()}/>;
    }

}

export default withTranslation()(BulkDeletionModalOutput)
