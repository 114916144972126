import React from 'react';
import { Button, Modal } from 'src/js/components/static';
import { showModal } from 'src/js/actions/xelacore';
import { useTranslation } from 'react-i18next';

export default function SetRefreshModal(props) {
    const { dispatch, refetchAndClose, isModal } = props;
    const { t } = useTranslation();

    const bodyText = (
        <p>{t('set_refresh_modal.please_try_again')}</p>
    );
    const bodyTextModal = (
        <p>
            {t('set_refresh_modal.another_user_updating')}
        </p>
    );
    const body = (
        <div>
            {isModal ? bodyTextModal : bodyText}
            <div className="c-modal__sticky-footer">
                <Button purpleGrad onClick={refetchAndClose}>
                    {t('buttons.update_record')}{isModal && 's'}
                </Button>
            </div>
        </div>
    );
    const title = t('set_refresh_modal.another_user');
    const modal = <Modal title={title} body={body} close={refetchAndClose} />;
    return dispatch(showModal(modal));
}
