import React from 'react';
import {Icon} from 'src/js/components/static';
import { useTranslation } from 'react-i18next';

export default function ConflictsHeader({ length, quarantineMode }) {
    const { t } = useTranslation();

    return (
        <div className="c-conflicts-popover-header">
            {quarantineMode && (
                <div className="c-conflicts-popover-header-text">
                    <p>
                        <Icon className="u-margin-small-right" icon="DUPLICATES" fill="pink" size="12"/>
                        {t('modules.my_records.conflicts.record_is_sharing')} {length} {t('modules.my_records.conflicts.others')}
                    </p>
                    <p>{t('modules.my_records.conflicts.been_quarantined')}</p>
                </div>
            )}

            {!quarantineMode && (
                <div className="c-conflicts-popover-header-text">
                    <p>
                        <Icon className="u-margin-small-right" icon="DUPLICATES" fill="pink" size="12"/>
                        {t('modules.my_records.conflicts.record_is_conflicted')} {length} {t('modules.my_records.conflicts.others')}
                    </p>
                    <p>{t('modules.my_records.conflicts.check_the_information')}</p>
                </div>
            )}
        </div>
    );
}
