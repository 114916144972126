import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ContractDetailsItem from './ContractDetails/ContractDetailsItem';

const SECTION_TYPES = ['territories', 'ips', 'categories'];

const DATA_STRUCTURE = {
    territories: {
        dataField: 'contracted_territories',
        fields: ['original', 'licensee_request', 'licensor_update', 'licensee_exception', 'licensor_exception'],
        copyFrom: {
            'licensor_update': 'licensee_request',
            'licensor_exception': 'licensee_exception'
        }
    },
    categories: {
        dataField: 'categories',
        fields: ['original', 'licensee_request', 'licensor_update', 'licensee_exception', 'licensor_exception'],
        copyFrom: {
            'licensor_update': 'licensee_request',
            'licensor_exception': 'licensee_exception'
        }
    },
    ips: {
        dataField: 'ips',
        fields: ['original', 'licensee_request', 'licensor_update', 'licensee_exception', 'licensor_exception'],
        copyFrom: {
            'licensor_update': 'licensee_request',
            'licensor_exception': 'licensee_exception'
        }
    }
};

const ContractDetailsBlock = ({ contractDetails = [], onUpdateContractDetails }) => {
    const [editableContracts, setEditableContracts] = useState([]);
    const [exceptionRawInputs, setExceptionRawInputs] = useState({});

    useEffect(() => {
        if (contractDetails && contractDetails.length > 0) {
            const clonedContracts = JSON.parse(JSON.stringify(contractDetails));

            clonedContracts.forEach(contract => {
                SECTION_TYPES.forEach(sectionType => {
                    const config = DATA_STRUCTURE[sectionType];
                    if (!config) return;

                    const dataField = config.dataField;

                    if (!contract[dataField]) return;

                    config.fields.forEach(field => {
                        contract[dataField][field] = contract[dataField][field] || [];
                    });

                    Object.entries(config.copyFrom).forEach(([targetField, sourceField]) => {
                        if (!contract[dataField][targetField] || contract[dataField][targetField].length === 0) {
                            contract[dataField][targetField] = [...(contract[dataField][sourceField] || [])];
                        }
                    });

                    if ((!contract[dataField].licensor_update || contract[dataField].licensor_update.length === 0) &&
                        contract[dataField].licensee_request && contract[dataField].licensee_request.length > 0) {
                        contract[dataField].licensor_update = [...contract[dataField].licensee_request];
                    }

                    if (contract[dataField].licensee_exception && contract[dataField].licensee_exception.length > 0) {
                        setExceptionRawInputs(prev => ({
                            ...prev,
                            [`${contract.agreement_id}_${sectionType}`]: [...contract[dataField].licensee_exception]
                        }));
                    }
                });
            });

            setEditableContracts(clonedContracts);
        }
    }, [contractDetails]);

    if (!contractDetails || !contractDetails.length) {
        return null;
    }

    const formatDate = (dateString) => {
        if (!dateString) return '';
        return moment(dateString).format('DD/MM/YYYY');
    };

    const toggleTag = (contractNumber, section, tag) => {
        const contractIndex = editableContracts.findIndex(c => c.agreement_id === contractNumber);
        if (contractIndex === -1) return;

        const updatedContracts = [...editableContracts];
        const contract = { ...updatedContracts[contractIndex] };

        const sectionConfig = DATA_STRUCTURE[section];
        if (!sectionConfig) return;

        const dataField = sectionConfig.dataField;
        if (!contract[dataField]) return;

        const sectionData = { ...contract[dataField] };

        sectionData.original = sectionData.original || [];
        sectionData.licensee_request = sectionData.licensee_request || [];
        sectionData.licensor_update = sectionData.licensor_update || [];

        const licensorUpdateIndex = sectionData.licensor_update.indexOf(tag);
        const isInLicensorUpdate = licensorUpdateIndex !== -1;

        if (isInLicensorUpdate) {
            sectionData.licensor_update.splice(licensorUpdateIndex, 1);
        } else {
            sectionData.licensor_update.push(tag);
        }

        // if (section !== 'territories') {
            const licenseeRequestIndex = sectionData.licensee_request.indexOf(tag);
            const isInLicenseeRequest = licenseeRequestIndex !== -1;

            if (!isInLicenseeRequest && !isInLicensorUpdate) {
                sectionData.licensee_request.push(tag);
            } else if (isInLicenseeRequest && isInLicensorUpdate) {
                sectionData.licensee_request.splice(licenseeRequestIndex, 1);
            }
        // }

        contract[dataField] = sectionData;
        updatedContracts[contractIndex] = contract;
        setEditableContracts(updatedContracts);

        if (onUpdateContractDetails) {
            onUpdateContractDetails(updatedContracts);
        }
    };

    const getTagStatus = (contract, section, tag) => {
        if (!contract || !contract[section]) return 'unselected';

        const data = contract[section];

        const isInOriginal = Array.isArray(data.original) && data.original.includes(tag);
        const isInLicensorUpdate = Array.isArray(data.licensor_update) && data.licensor_update.includes(tag);

        if (isInLicensorUpdate) {
            return 'selected';
        }

        if (section === 'contracted_territories') {
            const isInLicenseeRequest = Array.isArray(data.licensee_request) && data.licensee_request.includes(tag);

            if (isInLicenseeRequest) {
                return 'rejected';
            } else if (isInOriginal) {
                return 'original';
            }
        } else {
            const isInLicenseeRequest = Array.isArray(data.licensee_request) && data.licensee_request.includes(tag);

            if (isInLicenseeRequest) {
                return 'rejected';
            } else if (isInOriginal) {
                return 'original';
            }
        }

        return 'unselected';
    };

    const handleExceptionTagsChange = (contractId, section, newTags) => {
        setExceptionRawInputs(prev => ({
            ...prev,
            [`${contractId}_${section}`]: newTags
        }));

        const contractIndex = editableContracts.findIndex(c => c.agreement_id === contractId);
        if (contractIndex === -1) return;

        const updatedContracts = [...editableContracts];
        const contract = { ...updatedContracts[contractIndex] };

        const sectionConfig = DATA_STRUCTURE[section];
        if (!sectionConfig) return;

        const dataField = sectionConfig.dataField;
        if (!contract[dataField]) {
            contract[dataField] = {};
            sectionConfig.fields.forEach(field => {
                contract[dataField][field] = [];
            });
        }

        contract[dataField].licensee_exception = [...newTags];
        contract[dataField].licensor_exception = [...newTags];

        updatedContracts[contractIndex] = contract;
        setEditableContracts(updatedContracts);

        if (onUpdateContractDetails) {
            onUpdateContractDetails(updatedContracts);
        }
    };

    const getAllTags = (contract, section) => {
        if (!contract) return [];

        const sectionConfig = DATA_STRUCTURE[section];
        if (!sectionConfig) return [];

        const dataField = sectionConfig.dataField;
        if (!contract[dataField]) return [];

        const data = contract[dataField];
        let allTags = [];

        if (Array.isArray(data.original)) {
            allTags = [...allTags, ...data.original];
        }

        if (Array.isArray(data.licensee_request)) {
            data.licensee_request.forEach(tag => {
                if (!allTags.includes(tag)) {
                    allTags.push(tag);
                }
            });
        }

        if (Array.isArray(data.licensor_update)) {
            data.licensor_update.forEach(tag => {
                if (!allTags.includes(tag)) {
                    allTags.push(tag);
                }
            });
        }

        return allTags;
    };

    return (
        <div className="contract-details-block">
            {editableContracts.map(contract => (
                <ContractDetailsItem
                    key={contract.agreement_id}
                    contract={contract}
                    getAllTags={getAllTags}
                    formatDate={formatDate}
                    exceptionRawInputs={exceptionRawInputs}
                    handleExceptionTagsChange={handleExceptionTagsChange}
                    getTagStatus={getTagStatus}
                    onToggleTag={toggleTag}
                    sectionTypes={SECTION_TYPES}
                />
            ))}
        </div>
    );
};

export default ContractDetailsBlock;
