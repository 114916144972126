import React from 'react';
import { ReactComponent as CloseIcon } from 'src/js/components/static/Icon/svg-icons/close-icon.svg';

const TagList = ({
                     contract,
                     section,
                     tags,
                     getTagStatus,
                     onToggleTag
                 }) => {
    if (!tags || !Array.isArray(tags) || tags.length === 0) {
        return null;
    }

    return (
        <div className="tag-list">
            {tags.map((tag, index) => {
                const sectionKey = section === 'territories' ? 'contracted_territories' : section;
                const status = getTagStatus(contract, sectionKey, tag);

                return (
                    <span
                        key={`${section}-${tag}-${index}`}
                        className={`tag-item ${section}-tag ${status}`}
                        onClick={() => {
                            onToggleTag(
                                contract.agreement_id || contract.contract_number,
                                section === 'territories' ? 'territories' : section,
                                tag
                            );
                        }}
                    >
            {tag}
                        {status === 'selected' ? (
                            <CloseIcon className="tag-icon" />
                        ) : (
                            <CloseIcon className="tag-icon plus-icon" />
                        )}
          </span>
                );
            })}
        </div>
    );
};

export default TagList;
