import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import filter from 'lodash/filter'
import cloneDeep from 'lodash/cloneDeep';
import { getUrlParam } from 'src/js/helpers/strings';
import { getEsData } from 'src/js/actions/esData';
import { PAGE_LIMIT, DEFAULT_SORT, NUMBER_OF_RECORDS_EXCEEDS, EXCEEDS_LIMIT } from 'src/js/constants/dataConstants';
import { setBulkFilters } from 'src/js/actions/filters';
import {
    getFiltersDataFromUrl,
    getSortDataFromUrl
} from 'src/js/helpers/dataHelpers';
import { isUpdated } from 'src/js/helpers/dataHelpers';

import DataViewPagination from 'src/js/components/DataViewPagination/DataViewPagination';
import HeaderSection from 'src/js/components/ProductComponents/HeaderSection/HeaderSection';
import TopHeaderSection from 'src/js/components/ProductComponents/TopHeaderSection/TopHeaderSection';
import ProductCatalogueTable from './ProductCatalogueTable/ProductCatalogueTable';
import esFilterObject from 'src/js/components/AdvancedFiltering/esFilterObject';
import Grid from './components/Grid/Grid';
import SelectLicensor from 'src/js/components/SelectLicensor/SelectLicensor';
import { fetchAgentLicensors, fetchLinkedLicensors } from 'src/js/apicalls/mixed/myRecords';
import { setUrlValues } from 'src/js/helpers/dataHelpers';
import { browserHistory } from 'react-router';

import {
    parseSearchItems,
    filterInitialization,
    formatFilterObject,
    addLevelstoQuery
} from 'src/js/components/AdvancedFiltering/AdvancedFilteringHelpers';

import { fetchProducts, resetConceptsRows, setData, selectAll } from 'src/js/actions/productsFetch';
import { getAgentRecords } from 'src/js/actions/productCatalogue';
import { getLinkedBrands, getBrands } from 'src/js/actions/categories';
import { isLicensor, isAgent } from 'src/js/helpers/permissions';
import {
    fetchCategories,
    fetchCategoriesV3
} from 'src/js/apicalls/other/categories';
import {
    fetchIpsLevels,
    fetchIpsLevelsv3
} from 'src/js/apicalls/other/ips';

import {
    parsedNewFilters,
    parsedNewQuery
} from 'src/js/components/AdvancedFiltering/AdvancedFilteringHelpers';
import { parseV1Response } from 'src/js/helpers/arrays';

import Tablev3 from 'src/js/components/v3Table/Tablev3';
import AdvancedFiltering from 'src/js/components/AdvancedFiltering/AdvancedFiltering';

class ProductCatalogue extends Component {
    constructor(props) {
        super(props);

        this.location = {
            queryObject: this.props.location.query,
            url: this.props.location.pathname
        };

        this.type = 'catalog';
        this.orgId = this.props.xelacore.auth.companyData.organisation_id;

        this.state = {
            headers: [],
            page: 1,
            page_size: PAGE_LIMIT,
            initialFetch: false,
            sortedBy: {
                name: 'last_registered_at',
                dir: true // So we can toggle
            },
            selectedRows: [],
            showHeaderOptions: false,
            layoutType: !!localStorage.getItem('layoutType') && localStorage.getItem('layoutType') || 'table',
            filteringData: {},
            viewing: '',
            showFilters: false,
            queryObject: esFilterObject,
            basicFilterObj: {},
            originalAggregations: {},
            aggregations: {},
            searchItems: [],
            ipLevels: [],
            categoryLevels: [],
            categories: [],
            agentRecords: [],
            agentRecordsCount: 0,
            filtersObj: {},
            searchObj: {},
            showFilters: false,
            selectedLicensor: !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {}
        };
    }


    componentDidMount() {
        this.handleMount();
    }

    handleMount() {
        const parsedUrlSortParam = getSortDataFromUrl();

        if (!isEmpty(parsedUrlSortParam)) {
            this.sortData(parsedUrlSortParam.name, parsedUrlSortParam.dir);
        }
        document.addEventListener('advancedFilterClear', () => {
            this.setState({ sortedBy: DEFAULT_SORT });
        });

        setTimeout(() => {
            this.updateFilters();
        }, 1111);
    }

    componentWillMount() {
        const { xelacore } = this.props;
        const {
            auth: {
                companyData
            }
        } = xelacore;
        const { selectedLicensor } = this.state;

        this.setState({ initialFetch: true });
        this.getLinkedBrands();


        if (!isAgent(companyData)) {
            this.getValuesFromURL();
            this.fetchIpLevels();
            this.getCategories();
        } else {
            if (!!size(selectedLicensor)) {
                this.fetchLicensors();
                this.fetchIpLevels();
                this.getCategories();
            }
        }
    }

    componentDidUpdate() {
        const { xelacore } = this.props;
        const {
            auth: {
                companyData
            }
        } = xelacore;
        const messages = get(this, 'props.xelacore.socket.messages', []);
        if (isUpdated(messages, ['elastic'], this.notifiedAt) && !isAgent(companyData)) {

            this.notifiedAt = Date.now();

            setTimeout(() => {
                this.fetchInfo();
                this.fetchData(null, getFiltersDataFromUrl());
            }, 0);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('advancedFilterClear', () => {
            this.setState({ sortedBy: DEFAULT_SORT });
        });
    }

    updateFilters() {
        const { xelacore } = this.props;
        const {
            auth: {
                companyData
            }
        } = xelacore;
        const { categoryLevels, queryObject, basicFilterObj } = this.state;

        const parsedUrlFilterParam = getFiltersDataFromUrl();

        let newFilters = { ...basicFilterObj };
        let newQueryObj = cloneDeep(queryObject);

        if (!isEmpty(parsedUrlFilterParam)) {
            filterInitialization(parsedUrlFilterParam, newFilters, newQueryObj, categoryLevels, isLicensor(companyData));
        }

        this.updateFiltersState(newQueryObj, newFilters, true, {});
    }

    getCategories(licensor) {
        const { xelacore } = this.props;
        const { categories, queryObject, selectedLicensor } = this.state;
        const {
            auth: {
                token,
                companyData
            }
        } = xelacore;

        if (isEmpty(categories)) {
            if(isAgent(companyData)) {
                fetchCategoriesV3(selectedLicensor.licensor_organisation_id || !!licensor && licensor.licensor_organisation_id || '', token).then((response) => {
                    this.setState({
                        categories: !!response.data && map(response.data, item => parseV1Response(item)),
                        categoryLevels: response.data && response.data.categoryLevels
                    })
                });
            }  else {
                fetchCategories(companyData.organisation_id, token).then((response) => {
                    this.setState({
                        categories: response.data && response.data.categories,
                        categoryLevels: response.data && response.data.category_levels,
                        queryObject: formatFilterObject(queryObject, response.data && response.data.category_levels, isLicensor(companyData))
                    });

                    this.updateFilters();
                });
            }
        }
    }

    setAgentFilters = (filters) => {
        return this.setState({
            filtersObj: !!filters && filters || {}
        });
    };

    setAgentSearch = (filters) => {
        return this.setState({
            searchObj: !!filters && filters || {}
        });
    };

    setAggregations = (updatedAggregations, group, newVal) => {
        this.setState({
            aggregations: { ...updatedAggregations, [group]: newVal }
        });
    };

    updateFiltersState(queryObject, basicFilterObj, hardUpdate = true, params) {
        this.setState(
            {
                page: !!params ? params.page : getUrlParam('page'),
                // page_size: !!params ? params.page_size : PAGE_LIMIT,
                queryObject,
                basicFilterObj,
                searchItems: parseSearchItems(basicFilterObj)
            },
            () => {
                this.fetchInfo();
                if (hardUpdate) this.fetchData(params, basicFilterObj);
            }
        );
    }

    getLinkedBrands() {
        const { dispatch, xelacore, linkedBrands, brands } = this.props;
        const { auth: { companyData } = {} } = xelacore;
        const filterBrands = isLicensor(companyData) ? brands : linkedBrands;

        if (isEmpty(filterBrands)) {
            isLicensor(companyData) ? dispatch(getBrands()) : dispatch(getLinkedBrands());
        }
    }

    checkPageParam() {
        const page = getUrlParam('page') || 1;
        const page_size = getUrlParam('page_size') || PAGE_LIMIT;
        this.setState({ page, page_size });
        return { page, page_size };
    }

    // Checks the URL and sets values pertaining to it
    getValuesFromURL() {
        const { dispatch } = this.props;
        const urlFilters = getFiltersDataFromUrl() || {};
        let sort = getSortDataFromUrl() || DEFAULT_SORT;

        dispatch(setBulkFilters(urlFilters, false));

        if (sort.name) {
            sort = {
                [sort.name]: sort.dir ? 'desc' : 'asc'
            };
        }

        this.setState(
            {
                showUploader: getUrlParam('upload') || false
            },
            () => this.fetchData({}, urlFilters, sort)
        );
    }

    fetchIpLevels(licensor) {
        const { selectedLicensor, queryObject } = this.state;
        const { xelacore } = this.props;
        const {
            auth: {
                token,
                companyData
            }
        } = xelacore;

        let orgId = isLicensor(companyData) ? companyData.organisation_id : licensor || selectedLicensor.licensor_organisation_id;

        if (isAgent(companyData)) {
            fetchIpsLevelsv3(token, orgId).then((response) => {
                this.setState({
                    ipLevels: response.data && response.data,
                    queryObject: addLevelstoQuery(queryObject, response.data && response.data)
                });
            });
        } else {
            fetchIpsLevels(companyData.organisation_id, token, orgId).then((response) => {
                this.setState({
                    ipLevels: response.data && response.data.ip_levels,
                    queryObject: addLevelstoQuery(queryObject, response.data && response.data.ip_levels)
                });
            });
        }
    }

    fetchInfo() {
        const { dispatch, xelacore } = this.props;
        const { queryObject, aggregations, originalAggregations } = this.state;

        const { auth: { companyData } = {} } = xelacore;

        if (!isAgent(companyData)) {
            return dispatch(getEsData(queryObject, this.type)).then((resp) => {
                if (aggregations !== resp.aggregations || originalAggregations !== resp.aggregations) {
                    this.setState({
                        aggregations: resp.aggregations || {},
                        originalAggregations: resp.aggregations || {}
                    });
                }
            });
        }
    }

    fetchData(params, urlFilters, sort) {
        const { selectedLicensor } = this.state;
        const { dispatch, initialCount, xelacore, selectedRows } = this.props;
        const paramsAll = isEmpty(params) ? this.checkPageParam() : params;

        let newFilters = parsedNewFilters('licensor_organisation_id', selectedLicensor.licensor_organisation_id, urlFilters);

        if (params && params.page && params.page_size) {
            this.setState({
                page: params.page,
                page_size: params.page_size
            });
        }

        sort = sort
            ? sort
            : {
                [this.state.sortedBy.name]: this.state.sortedBy.dir
                    ? 'desc'
                    : 'asc'
            };

        const { auth: { companyData } = {} } = xelacore;

        if (!isAgent(companyData)) {
            return dispatch(
                fetchProducts(
                    paramsAll,
                    newFilters,
                    initialCount,
                    false,
                    null,
                    null,
                    null,
                    selectedRows,
                    sort
                )
            ).then(() => {
                if (this.state.initialFetch) this.setState({ initialFetch: false });
            });
        } else {
            const filtersFromUrl = getFiltersDataFromUrl();

            if (!!this.state.initialFetch && !!filtersFromUrl && !!filtersFromUrl.exact) this.setAgentFilters(filtersFromUrl.exact);
            if (!!this.state.initialFetch && !!filtersFromUrl && !!filtersFromUrl.partial) this.setAgentSearch(filtersFromUrl.partial);

            if (!!selectedLicensor && !!selectedLicensor.licensor_organisation_id) {
                return getAgentRecords(!!this.state.initialFetch && !!filtersFromUrl ? filtersFromUrl : params, urlFilters, selectedLicensor.licensor_organisation_id).then(response => {
                    this.setState({
                        agentRecords: !!response && !!response.data && response.data.listings || [],
                        agentMetaData: !!response && !!response._metadata && response._metadata || {},
                        aggregations: !!response && !!response.data && response.data.aggregations || []
                    });

                    const locationString = setUrlValues(urlFilters, params);

                    if (!!size(params)) {
                        browserHistory.push(`/product-catalogue?${locationString}`);
                    }

                    if (this.state.initialFetch) this.setState({ initialFetch: false });
                });
            }
        }
    }

    sortData(key, forceDir) {
        const dir =
            key === this.state.sortedBy.name ? !this.state.sortedBy.dir : true;

        const sortedBy = {
            name: key,
            dir: forceDir === undefined ? dir : forceDir
        };

        this.setState({ sortedBy });

        this.fetchData(null, getFiltersDataFromUrl(), {
            [sortedBy.name]: sortedBy.dir ? 'desc' : 'asc'
        });
    }

    changeHeaders(names, headerLabel = '') {
        const { headers } = this.state;

        if (typeof names === 'string') {
            return this.setState({
                headers: headers.list.map((el) => {
                    if (el.name === names) el.active = !el.active;
                    return el;
                }),
                activeHeaders: headerLabel
            });
        }

        const filtered = headers.map((el) => {
            if (names.indexOf(el.name) > -1) return el;
            el.active = false;
            return el;
        });

        return this.setState({ headers: filtered, activeHeaders: headerLabel });
    }

    selectLicensor(licensor) {
        const { dispatch, xelacore } = this.props;
        const { auth: { companyData } = {} } = xelacore;

        localStorage.setItem('selectedLicensor', JSON.stringify(licensor));

        this.setState({
            selectedLicensor: licensor
        });

        this.forceUpdate();

        this.setLayoutType('table');

        setData(
            {},
            false,
            null,
            null,
            null,
            {},
            {},
            0,
            null,
            dispatch,
            []
        );

        if (!!licensor) {
            this.fetchIpLevels(licensor.licensor_organisation_id);
            window.location.reload();
        }

        if (!!isAgent(companyData)) {
            this.setAgentFilters({});
            this.setAgentSearch({});
            this.getCategories(licensor);
        }

        this.clearFilters(licensor);
    }

    clearFilters(licensor) {
        const { queryObject, basicFilterObj, categoryLevels } = this.state;

        let clonedQueryObject = cloneDeep(queryObject);
        clonedQueryObject.query.query.bool.must = filter(get(queryObject, 'query.query.bool.must', []), (item) => !!item && !!item.query_string && item.query_string.query && item.query_string.query === 'licensor_organisation_id');
        let newFilters = parsedNewFilters('licensor_organisation_id', licensor.licensor_organisation_id, basicFilterObj);
        let newQueryObj = parsedNewQuery('licensor_organisation_id', licensor.licensor_organisation_id, clonedQueryObject, categoryLevels);

        this.updateFiltersState(newQueryObj, newFilters, true, { page: 1 });
    }

    fetchLicensors() {
        const { xelacore } = this.props;
        const { selectedLicensor } = this.state;
        const { auth: { companyData } = {} } = xelacore;

        if (isAgent(companyData)) {
            return fetchAgentLicensors(this.orgId).then(response => {
                let mapLinkedLicensors = !!response.data && map(response.data, 'licensorOrganisationId') || [];

                if (!!selectedLicensor && !(includes(mapLinkedLicensors, selectedLicensor.licensor_organisation_id))) {
                    localStorage.removeItem('selectedLicensor');
                }
            });
        } else {
            return fetchLinkedLicensors(this.orgId).then(response => {
                let mapLinkedLicensors = !!response.data && map(response.data.links, 'licensor_organisation_id') || [];

                if (!!selectedLicensor && !(includes(mapLinkedLicensors, selectedLicensor.licensor_organisation_id))) {
                    localStorage.removeItem('selectedLicensor');
                }
            });
        }
    }

    setLayoutType(type) {
        localStorage.setItem('layoutType', type);
        this.setState({ layoutType: type });
    }

    showFiltersToggle(state) {
        this.setState({ showFilters: state !== undefined ? state : !this.state.showFilters });
    }

    render() {
        const {
            showHeaderOptions,
            initialFetch,
            sortedBy,
            layoutType,
            viewing,
            showFilters,
            originalAggregations,
            aggregations,
            basicFilterObj,
            queryObject,
            page_size,
            searchItems,
            page,
            categoryLevels,
            selectedLicensor,
            ipLevels,
            agentRecords,
            agentMetaData,
            filtersObj,
            searchObj
        } = this.state;

        const {
            data,
            totalItems,
            selectedRows,
            fetching = true,
            fetchErrors,
            headers,
            activeHeaders,
            initialCount,
            linkedBrands,
            brands,
            xelacore,
            dispatch,
            previousRow,
            headerLayouts,
            selectAll
        } = this.props;
        const { auth: { companyData } = {} } = xelacore;
        const noFilters = isEmpty(Object.keys(basicFilterObj).filter((f) => (f !== 'is_quarantine') && (f !== 'licensor_organisation_id')));
        const noCatalogData =
            Array.isArray(data) &&
            data.length === 0 &&
            noFilters &&
            (!initialCount || initialCount === 'undefined') &&
            !fetchErrors &&
            !fetching;

        const filterBrands = isLicensor(companyData) ? brands : linkedBrands && linkedBrands.linkedBrands.rows || [];
        const noData = noCatalogData;
        const dynamicSpecialValues = {
            'Product Information': {
                Brand: filterBrands
                    ? filterBrands.reduce(
                        (res, { brand_id, brand }) => ({
                            ...res,
                            [brand_id]: brand
                        }),
                        {}
                    )
                    : []
            }
        };
        const isFiltersReturnedNoData =
            Object.entries(aggregations)
                .filter(
                    ([k]) => !['Errors', 'Conflicts', 'Warnings'].includes(k)
                )
                .every(([, v]) => v.buckets && v.buckets.length === 0) &&
            basicFilterObj &&
            Object.keys(basicFilterObj).length > 0;

        return (
            <div className="c-my-records">
                {((!isLicensor(companyData) && !!selectedLicensor && !!selectedLicensor.licensor_organisation_id) || isLicensor(companyData) || (isAgent(companyData) && !!selectedLicensor && !!selectedLicensor.licensor_organisation_id)) && !initialFetch && (
                    <TopHeaderSection
                        headers={headers}
                        fetchData={(params, urlFilters) =>
                            this.fetchData(params, urlFilters)
                        }
                        isLicensor={isLicensor(companyData)}
                        selectLicensor={(licensor) => this.selectLicensor(licensor)}
                        noData={noCatalogData}
                        xelacore={xelacore}
                        count={isAgent(companyData) ? !!agentMetaData && !!agentMetaData.totalResults && agentMetaData.totalResults : totalItems}
                        totalItems={isAgent(companyData) ? !!agentRecords && agentRecords.length : totalItems}
                    />
                )}

                {((!isLicensor(companyData) && !!selectedLicensor && !!selectedLicensor.licensor_organisation_id) && !isAgent(companyData) || isLicensor(companyData)) && !isAgent(companyData) && !noData && !initialFetch && (
                    <Fragment>
                        <AdvancedFiltering
                            updateFiltersState={(
                                queryObject,
                                basicFilterObj,
                                hardUpdate,
                                params
                            ) =>
                                this.updateFiltersState(
                                    queryObject,
                                    basicFilterObj,
                                    hardUpdate,
                                    params
                                )
                            }
                            dynamicSpecialValues={dynamicSpecialValues}
                            queryObject={queryObject}
                            basicFilterObj={basicFilterObj}
                            aggregations={aggregations}
                            originalAggregations={originalAggregations}
                            isMyRecords={false}
                            showLists={showFilters}
                            setAggregations={this.setAggregations}
                            close={() => this.setState({ showFilters: false })}
                            isFiltersReturnedNoData={isFiltersReturnedNoData}
                            categoryLevels={categoryLevels}
                            ipLevels={ipLevels}
                            xelacore={xelacore}
                            selectedLicensor={selectedLicensor}
                        />

                        <HeaderSection
                            updateFiltersState={(
                                queryObject,
                                basicFilterObj,
                                hardUpdate,
                                params
                            ) =>
                                this.updateFiltersState(
                                    queryObject,
                                    basicFilterObj,
                                    hardUpdate,
                                    params
                                )
                            }
                            queryObject={queryObject}
                            basicFilterObj={basicFilterObj}
                            aggregations={aggregations}
                            originalAggregations={originalAggregations}
                            dynamicSpecialValues={dynamicSpecialValues}
                            changeHeaders={(name, label) =>
                                this.changeHeaders(name, label)
                            }
                            selectedRows={selectedRows}
                            activeHeaders={activeHeaders}
                            showHeaderOptions={showHeaderOptions}
                            fetchData={(params, urlFilters) =>
                                this.fetchData(params, urlFilters)
                            }
                            location={this.location}
                            setLayout={(type) =>
                                this.setLayoutType(type)
                            }
                            layoutType={layoutType}
                            viewing={viewing}
                            setViewing={(v) => this.setState({ viewing: v })}
                            noData={noCatalogData}
                            setAggregations={this.setAggregations}
                            isFiltersReturnedNoData={isFiltersReturnedNoData}
                            categoryLevels={categoryLevels}
                            resetConceptsRows={(data) => {
                                return resetConceptsRows(selectedRows, data, false, dispatch);
                            }}
                            ipLevels={ipLevels}
                            xelacore={xelacore}
                            companyData={companyData}
                            showFilters={showFilters}
                            showFiltersToggle={(state) => this.showFiltersToggle(state)}
                        />

                        {layoutType === 'grid' && viewing === '' && (
                            <Grid
                                data={data}
                                selectedRows={selectedRows}
                                dispatch={dispatch}
                                previousRow={previousRow} />
                        )}

                        {layoutType === 'table' && viewing === '' && (
                            <ProductCatalogueTable
                                updateFiltersState={(
                                    queryObject,
                                    basicFilterObj,
                                    hardUpdate,
                                    params
                                ) =>
                                    this.updateFiltersState(
                                        queryObject,
                                        basicFilterObj,
                                        hardUpdate,
                                        params
                                    )
                                }
                                queryObject={queryObject}
                                basicFilterObj={basicFilterObj}
                                searchItems={searchItems}
                                showFilters={showFilters}
                                fetchData={(params, urlFilters) =>
                                    this.fetchData(params, urlFilters)
                                }
                                showUpload={(id) =>
                                    this.showUploadInformation(id)
                                }
                                handleCheckedList={(e, p) => {
                                    this.handleCheckedList(e, p);
                                }}
                                handleCheckAllProduct={(e) => {
                                    this.handleCheckAllProduct(e);
                                }}
                                sortedBy={sortedBy}
                                sortData={(key, dir) => this.sortData(key, dir)}
                                fetching={fetching}
                                isFiltersReturnedNoData={
                                    isFiltersReturnedNoData
                                }
                                categoryLevels={categoryLevels}
                                selectedRows={selectedRows}
                                ipLevels={ipLevels}
                                xelacore={xelacore}
                                dispatch={dispatch}
                            />
                        )}
                        <div className="marg-b-40">
                            <DataViewPagination
                                totalItems={totalItems > EXCEEDS_LIMIT ? EXCEEDS_LIMIT : totalItems}
                                fetchData={(params, urlFilters) => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                    this.fetchData(params, urlFilters);
                                }}
                                pageLimit={page_size}
                                pageNum={page}
                            />
                        </div>

                        {totalItems > EXCEEDS_LIMIT && (
                            <span className="records-exceeds">
                                {NUMBER_OF_RECORDS_EXCEEDS}
                            </span>
                        )}
                    </Fragment>
                )}

                {isAgent(companyData) && !!selectedLicensor.licensor_organisation_id && (
                    <Tablev3
                        stickyHeader={140}
                        headers={headers}
                        headerLayouts={headerLayouts}
                        headersArr={headers.list}
                        list={!!agentRecords && agentRecords}
                        ipLevels={ipLevels}
                        aggregations={aggregations}
                        agentMetaData={agentMetaData}
                        filtersObj={filtersObj}
                        searchObj={searchObj}
                        setFiltersObj={(filters) => this.setAgentFilters(filters)}
                        setSearchObj={(filters) => this.setAgentSearch(filters)}
                        dispatch={dispatch}
                        previousRow={previousRow}
                        selectedRows={selectedRows}
                        categoryLevels={categoryLevels}
                        activeHeaders={activeHeaders}
                        selectAll={selectAll}
                        setLayout={(type) =>
                            this.setLayoutType(type)
                        }
                        layoutType={layoutType}
                        updateTable={(filters, search) => this.fetchData(filters, search)}>
                    </Tablev3>
                )}

                {((!selectedLicensor.licensor_organisation_id && !isLicensor(companyData)) || (!selectedLicensor.licensor_organisation_id && isAgent(companyData))) && (
                    <SelectLicensor
                        updateFiltersState={(
                            queryObject,
                            basicFilterObj,
                            hardUpdate,
                            params
                        ) =>
                            this.updateFiltersState(
                                queryObject,
                                basicFilterObj,
                                hardUpdate,
                                params
                            )
                        }
                        xelacore={xelacore}
                        dispatch={dispatch}
                        type={this.type}
                        queryObject={queryObject}
                        basicFilterObj={basicFilterObj}
                        categoryLevels={categoryLevels}
                        isMyRecords={false}
                        selectLicensor={(licensor => this.selectLicensor(licensor))}>
                    </SelectLicensor>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        xelacore,
        xelacore: {
            userSettings: {
                headers = {},
                activeHeaders = '',
                headerLayouts = [],
                selectAll
            } = {},
            productCatalogue: {
                records: { data, totalItems, page, page_size } = {},
                fetching: { fetching, fetchErrors } = {},
                selectedRows = [],
                initialCount,
                previousRow = null
            } = {},
            categories: { linkedBrands, brands } = {}
        } = {}
    } = state;

    return {
        xelacore,
        data,
        totalItems,
        page,
        page_size,
        fetching,
        fetchErrors,
        linkedBrands,
        brands,
        headers,
        activeHeaders,
        headerLayouts,
        selectedRows,
        initialCount,
        previousRow,
        selectAll
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCatalogue);
