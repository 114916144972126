import React, { useState, useEffect } from 'react';
import findIndex from 'lodash/findIndex';
import { Icon } from 'src/js/components/static';

import {
    parsedNewFilters,
    parsedNewQuery
} from 'src/js/components/AdvancedFiltering/AdvancedFilteringHelpers';

import { PAGE_LIMIT } from 'src/js/constants/dataConstants';
import { getUrlParam } from 'src/js/helpers/strings';
import { useTranslation } from 'react-i18next';

export default function HeaderSearchItem({
    openSearchBox,
    basicFilterObj,
    queryObject,
    updateFiltersState,
    categoryLevels,
    inputValue,
    searchItems,
    searchBox,
    el,
    elKey,
    popoverRef,
    clearSearch
}) {
    const { t } = useTranslation();
    const [inputVal, setInputValue] = useState(inputValue);
    const [searchI, setSearchItems] = useState(searchItems);
    const [searchB, setSearchBox] = useState(searchBox);

    useEffect(() => {
        setInputValue(inputValue)
    }, [inputValue])

    useEffect(() => {
        setSearchBox(searchBox)
    }, [searchBox])

    useEffect(() => {
        setInputValue(searchItems)
    }, [searchItems])

    const onSearch = (event, el) => {
        if (event.key === 'Enter') {
            searchItemsFunc(event, el);
        }
    }

    const searchItemsFunc = (event, el) => {
        let existingValueIndex = findIndex(searchI, (item) => {
            return item.label === el.label;
        });

        let newValue = {
            label: el.label,
            value: event.target.value
        };

        let newFilters = parsedNewFilters(el.dataName, event, basicFilterObj);
        let newQueryObj = parsedNewQuery(el.dataName, event, queryObject, categoryLevels);

        if (existingValueIndex > -1) {
            searchI[existingValueIndex].value =
                event.target.value;
        } else {
            searchI.push(newValue);
        }

        setSearchItems(searchI);

        openSearchBox(el);

        updateFiltersState(newQueryObj, newFilters, true, { page: 1, page_size: getUrlParam('page_size') || PAGE_LIMIT });
    }

    const handleChangeSearch = (event) => {
        setInputValue(event.target.value);
    };

    return (
        <div className="c-table-search">
            <Icon
                className="u-clickable"
                icon="search"
                fill="white"
                onClick={() => openSearchBox(el)}
            />
            {searchB === elKey && (
                <div
                    className="c-table-search__popover"
                    ref={popoverRef}
                >
                    <input
                        className="form-control"
                        autoFocus
                        value={inputVal && inputVal.value}
                        onChange={(e) => handleChangeSearch(e)}
                        onKeyPress={(event) => onSearch(event, el)}
                        placeholder={`${t('search_items.search_for')} ` + el.label}
                    />
                    <Icon
                        className="u-clickable clear-icon"
                        onClick={(event) => clearSearch(event, el)}
                        icon="BIN"
                        fill="gray"
                    />
                </div>
            )}
        </div>
    )
}
