import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import toArray from 'lodash/toArray';
import orderBy from 'lodash/orderBy';
import size from 'lodash/size';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import capitalize from 'lodash/capitalize';
import includes from 'lodash/includes';
import Button from 'modules/UiKit/components/Button/Button';
import { Icon } from 'src/js/components/static';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import {
    maxItemsInList
} from 'src/js/constants/advancedFilteringConstants';

import { listOfTargetMarketsByCode } from 'src/js/constants/countries';

import {
    AGENT_FILTERS
} from 'src/js/components/AdvancedFiltering/esFilterObjectDisplay.js';
import { toLower } from 'lodash';
import { withTranslation } from 'react-i18next';

class AggregatedFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expandedList: [],
            inputValue: {},
            filterAggregations: !!this.props.aggregations && this.props.aggregations || []
        };
    }

    rootRef = createRef();

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
        document.removeEventListener('advancedFilterClear', this.handleClickOutside, true);
    }

    handleClickOutside = (event) => {
        const domNode = this.rootRef.current;

        if (!domNode || !domNode.contains(event.target)) {
            this.props.closeFilters();
        }
    }

    clearFiltersFunc = () => {
        const { clearFilters } = this.props;
        const { inputValue } = this.state;

        if(!!inputValue) {
            mapKeys(inputValue, (val, key) => {
                this.handleSearch(null, key, true);
            })
        }

        this.forceUpdate();
        clearFilters();
    }

    toggleExpandedList(id) {
        this.setState({
            expandedList: {
                ...this.state.expandedList, [id]: !this.state.expandedList[id]
            }
        });
    }

    handleSearch(e, name, clear) {
        this.state.inputValue[name] = !!clear ? '' : e.target.value;
        this.forceUpdate();
    }

    filterTableFunc(filter, fil) {
        const { filterTable } = this.props;

        filterTable(filter, fil.key);
    }

    render() {
        const { expandedList, inputValue } = this.state;
        const {
            aggregations,
            filtersObj,
            showFilters,
            toggleFilters,
            t
        } = this.props;

        return (
            <div ref={this.rootRef}
                 className={`c-advanced-filtering-holder ui-filters ${showFilters ? '-open' : '-closed'}`}>
                <div className='ui-filters-header'>
                    <div>
                        <span>
                            {t('table_v3.aggregated_filters.filters')}
                        </span>

                        <Button
                            type="secondary"
                            size="small"
                            onClick={() => this.clearFiltersFunc()}
                        >
                            <div className="c-rec-panel__nav__content">
                                {t('buttons.clear')}
                            </div>
                        </Button>
                    </div>

                    <Icon
                        className="c-rec-panel__close-menu"
                        onClick={toggleFilters}
                        icon="close"
                        fill="black"
                    />
                </div>

                <div className='ui-filters-body'>
                    { toArray(AGENT_FILTERS).map((filter) => {
                        let filtersData = !!aggregations && filter === 'target_market' ? map(aggregations[filter], fil => {
                            return {
                                label: listOfTargetMarketsByCode()[fil.key],
                                key: fil.key,
                                count: fil.count
                            }
                        }) : aggregations[filter] ;
                        let filtersToDisplay =
                                !!filtersData && (filtersData.length > maxItemsInList) && !expandedList[filter]
                                ? orderBy(filtersData, 'key', 'asc').slice(0, maxItemsInList)
                                : orderBy(filtersData, 'key', 'asc');
                                

                        const filteredItems =
                            !!inputValue && !!inputValue[filter]
                            && !!filtersData
                            && orderBy(filtersData.filter((f) => (
                                filter === 'target_market'
                                    ? includes(toLower(f.label), toLower(inputValue[filter])) || includes(toLower(f.key), toLower(inputValue[filter]))
                                    : includes(toLower(f.key), toLower(inputValue[filter]))), 'key', 'asc')
                            );
                        const numbers = size(inputValue[filter]) > 0 ? size(filteredItems) : size(aggregations[filter]);
                        const limited = numbers > maxItemsInList && !expandedList[filter];
                        const expanded = numbers > maxItemsInList && expandedList[filter];
                        const label = !!filter && filter === 'is_concept' ? 'Concept' : filter === 'company' ? 'Licensee' : filter.replace('_', ' ');

                        if(filter === 'is_concept') {
                            filtersToDisplay = map(filtersToDisplay, fil => {
                                return {
                                    key: fil.key === 'true' ? 'Linked to Concept(s)' : 'Not linked to Concept(s)',
                                    count: fil.count
                                }
                            })
                        }

                        const noFranchiseData = filter === 'licensor_franchise' && !!filtersToDisplay && filtersToDisplay.length === 1 && !size(filtersToDisplay[0].key);
                        const noOtherIpsData = filter === 'licensor_other_ip' && !!filtersToDisplay && filtersToDisplay.length === 1 && !size(filtersToDisplay[0].key);
 
                        return (
                            <div>
                                { (!!filter && !!aggregations && aggregations[filter] && !!aggregations[filter].length && !noFranchiseData && !noOtherIpsData) && (
                                    <div className='ui-filter-item'>
                                        <div className='ui-filter-item-label'>{capitalize(label)}</div>

                                        {(numbers > maxItemsInList || inputValue[filter]) && (
                                            <div
                                                className="c-advanced-filtering__filter-bar"
                                                key={`${filter}-search`}
                                            >
                                                <div className="c-advanced-filtering__filter-input">
                                                    <input
                                                        id={this.state.inputValue[filter]}
                                                        name={this.state.inputValue[filter]}
                                                        type="text"
                                                        value={this.state.inputValue[filter]}
                                                        placeholder={t('placeholders.type_here')}
                                                        onChange={(e) =>
                                                            this.handleSearch(e, filter)
                                                        }
                                                    />
                                                    <div className="c-advanced-filtering__search">
                                                        <Icon fill="black" icon="search" size="14" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className='ui-filters-items-wrapper'>
                                            { (!!inputValue && inputValue[filter] && filteredItems || filtersToDisplay).map((fil) => {
                                                let key = filter === 'is_concept' ? !!includes(toLower(fil.key), 'not') ? 'false' : 'true' : fil.key;
                                                const filterChecked = !!filtersObj && !!filtersObj[filter] && !!includes(filtersObj[filter], key);
                                                
                                                return (
                                                    <UiCheckbox
                                                        onClick={() => this.filterTableFunc(filter, fil)}
                                                        id={`cust-select-all-headers-${fil.key}`}
                                                        type="checkbox"
                                                        checked={filterChecked}
                                                        label={`${filter === 'target_market' ? (!!fil.label && fil.label || fil.key) : fil.key} (${fil.count})`}
                                                        >
                                                    </UiCheckbox>
                                                )})
                                            }
                                        </div>

                                        { !!inputValue[filter] && numbers === 0 && (
                                            <span className='empty-items'>{t('table_v3.aggregated_filters.no_items_found')}</span>
                                        )}

                                        {(limited || expanded) && numbers > maxItemsInList && (
                                            <div
                                                key={`${filter}-see-all`}
                                                className="c-advanced-filtering__seeAll u_pointer"
                                                onClick={() => this.toggleExpandedList(filter)}
                                            >
                                                {limited ? t('table_v3.aggregated_filters.see_all') : t('table_v3.aggregated_filters.see_less')}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default connect()(withTranslation()(AggregatedFilters));