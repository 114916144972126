import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import MediaView from 'components/MediaView';
import find from 'lodash/find';
import { fetchLinkedLicensors } from 'apicalls/mixed/myRecords';
import recordPlaceholder from '../../../../../../statics/imgs/record-placeholder.png';
import Button from 'modules/UiKit/components/Button/Button';
import moment from 'moment/moment';
import { ReactComponent as ApprovedIcon } from 'src/js/components/static/Icon/svg-icons/approved.svg';
import { ReactComponent as PendingIcon } from 'src/js/components/static/Icon/svg-icons/pending.svg';
import { ReactComponent as RejectedIcon } from 'src/js/components/static/Icon/svg-icons/rejected-icon.svg';
import capitalize from 'lodash/capitalize';
import size from 'lodash/size';
import toArray from 'lodash/toArray';
import { useTranslation } from 'react-i18next';
import { hideModal, showModal } from 'src/js/actions/xelacore';
import { Modal } from 'components/static';
import { generateExportPdf } from 'apicalls/other/tradeMark';
import {
    languagesArray
} from 'modules/Toolkit/TrademarkAuthorisationLetter/Constants/TrademarkAuthorisationLetterFormConstants';

function TradeMarkTable({ xelacore, tradeMarksArray, dispatch }) {
    const { t } = useTranslation();
    const [, setTableWidth] = useState(0);
    const [licensorsArray, setLicensorsArray] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [dropdownOpenRows, setDropdownOpenRows] = useState([]);
    const orgId = xelacore.auth.companyData.organisation_id;
    const mainTable = useRef(null);
    const table = useRef(null);
    const scrollableheader = useRef(null);
    const scrollbar = useRef(null);
    const containerRef = useRef(null);

    const scrollHandler = () => {
        const distanceToBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
        const tableElement = table.current;
        const rect = tableElement.getBoundingClientRect();
        const tableDistanceToBottom = Math.max(0, document.documentElement.scrollHeight - (rect.bottom + window.pageYOffset));

        if (window.scrollY > 140) {
            document.getElementById('siteHeader').style.boxShadow = 'none';
        } else {
            document.getElementById('siteHeader').style.boxShadow = '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
        }

        if (distanceToBottom < tableDistanceToBottom + 40) {
            scrollbar.current.classList.add('bottom_fixed');
        } else {
            scrollbar.current.classList.remove('bottom_fixed');
        }
    };

    const handleScroll = (e, ref) => {
        if (ref === 'table') {
            scrollableheader.current.scrollTo(table.current.scrollLeft, 0);
            scrollbar.current.scrollTo(table.current.scrollLeft, 0);
        }
        if (ref === 'header') {
            table.current.scrollTo(scrollableheader.current.scrollLeft, 0);
            scrollbar.current.scrollTo(scrollableheader.current.scrollLeft, 0);
        }
        if (ref === 'scrollbar') {
            scrollableheader.current.scrollTo(scrollbar.current.scrollLeft, 0);
            table.current.scrollTo(scrollbar.current.scrollLeft, 0);
        }
    };

    const scrollHandlerBound = scrollHandler.bind();

    useEffect(() => {
        fetchLicensors();
    }, []);

    useEffect(() => {
        document.addEventListener('scroll', scrollHandlerBound);
        setTimeout(() => {
            setTableWidth(mainTable.current.offsetWidth);
        }, 1000);

        return () => {
            document.removeEventListener('scroll', scrollHandlerBound);
            document.getElementById('siteHeader').style.boxShadow = '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setDropdownOpenRows([]);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const fetchLicensors = () => {
        return fetchLinkedLicensors(orgId).then(response => {
            setLicensorsArray(response.data.links);
        });
    };

    const findLicensor = (licensorId) => {
        return find(licensorsArray, el => el.licensor_organisation_id === licensorId);
    };

    const exportPdf = (id, language) => {
        generateExportPdf(id, language).then(() => {});
        setDropdownOpenRows([]);
    };

    const findLanguage = (language) => {
        return !!find(languagesArray, lng => lng.name === language) && find(languagesArray, lng => lng.name === language).label || language;
    };

    const toggleExpand = (id) => {
        setExpandedRows(prev =>
            prev.includes(id)
                ? prev.filter(rowId => rowId !== id)
                : [...prev, id]
        );
    };

    const toggleDropdownRow = (id) => {
        setDropdownOpenRows(prev => prev.includes(id) ? [] : [id]);
    };

    const formatArray = (arr, title) => {
        if (!arr || !Array.isArray(arr)) return '';

        const getItemLabel = (item) => (typeof item === 'string' ? item : item.label || item.name || '');
        const visibleItems = arr.slice(0, 2).map(getItemLabel);
        const remainingCount = arr.length - 2;

        return (
            <span>
                {visibleItems.join(', ')}
                {remainingCount > 0 && (
                    <span>
                        , <a className={'view-more'} href="#" onClick={() => viewMore(arr, title)}>+{remainingCount}</a>
                    </span>
                )}
            </span>
        );
    };

    const viewMore = (data, title) => {
        if (!Array.isArray(data) || data.length === 0) return;

        const getItemLabel = (item) => (typeof item === 'string' ? item : item.label || item.name || '');
        const formattedData = data.map(getItemLabel);
        const body = (
            <div className='u-full-width'>
                <div className='u-color-black u-margin-bottom'>
                    {formattedData.map((el, index) => (
                        <div key={index}>
                            {el}{index !== formattedData.length - 1 ? ', ' : ''}
                        </div>
                    ))}
                </div>
                <div className='u-flex-gap u-flex-align--right'>
                    <Button
                        type='secondary'
                        size='small'
                        onClick={() => dispatch(hideModal())}>
                        Close
                    </Button>
                </div>
            </div>
        );

        const modal = <Modal centered isSmall title={title} body={body} />;
        return dispatch(showModal(modal));
    };

    const openRejectNote = (notes) => {
        const body = (
            <div className='u-full-width'>
                <div className='u-color-black u-margin-bottom'>
                    {notes}
                </div>
                <div className='u-flex-gap u-flex-align--right'>
                    <Button
                        type='secondary'
                        size='small'
                        onClick={() => dispatch(hideModal())}>
                        Close
                    </Button>
                </div>
            </div>
        );

        const modal = <Modal centered isSmall title={'Decline Reason'} body={body} />;
        return dispatch(showModal(modal));
    };

    return (
        <div className='royalty-report-wrapper trademark-table'>
            <div className="c-my-records">
                <div className={'ui-table table'}>
                    <div className="ui-table__wrapper table_ui">
                        <div className="ui-table__header-container">
                            <div className="c-rec-table__table-holder table_header_wrapper">
                                <div ref={scrollableheader} onScroll={(e) => handleScroll(e, 'header')} className="table_wrapper_header">
                                    <table className="ui-table__top-header -dark">
                                        <thead>
                                        <tr>
                                            <th className='ui-table__head-cell action-cell'>{t('toolkit.trademark_table_headers.document')}</th>
                                            <th className='ui-table__head-cell licensor-column'>{t('toolkit.trademark_table_headers.licensor')}</th>
                                            <th className='ui-table__head-cell'>{t('toolkit.trademark_table_headers.status')}</th>
                                            <th className='ui-table__head-cell'>{t('toolkit.trademark_table_headers.requested_date')}</th>
                                            <th className='ui-table__head-cell'>{t('toolkit.trademark_table_headers.action_on')}</th>
                                            <th className='ui-table__head-cell'>Contracts</th>
                                            <th className='ui-table__head-cell'>Contracted Territories</th>
                                            <th className='ui-table__head-cell'>{t('toolkit.trademark_table_headers.ips')}</th>
                                            <th className='ui-table__head-cell'>Categories</th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='c-rec-table'>
                            <div
                                className='c-rec-table__table-holder c-rec-table__main-table'
                                ref={table}
                                onScroll={(e) => handleScroll(e, 'table')}
                                style={{ minHeight: 100 }}
                            >
                                <table>
                                    <tbody ref={containerRef}>
                                    {tradeMarksArray.map((el) => {
                                        el.org_image = `https://assets.xelacore.io/org/${el.licensor_organisation_id}.png`;
                                        return (
                                            <React.Fragment key={el.id}>
                                                <tr className={`${expandedRows.includes(el.id) ? 'expanded' : ''}`}>
                                                    <td className='ui-table__body-cell action-cell'>
                                                        <div ref={containerRef} className={'select-dropdown select-single u-relative button-dropdown'}>
                                                            <Button
                                                                disabled={el.status !== 'approved'}
                                                                size={'small'}
                                                                type={'secondary'}
                                                                className={`c-pointer ${dropdownOpenRows.includes(el.id) ? 'expanded' : ''}`}
                                                                onClick={() =>
                                                                    !!el.request_languages && el.request_languages.length > 0
                                                                        ? toggleDropdownRow(el.id)
                                                                        : exportPdf(el.id, 'en')}
                                                            >
                                                                {t('buttons.view')}
                                                            </Button>

                                                            {!!el.request_languages && el.request_languages.length > 0 && dropdownOpenRows.includes(el.id) && (
                                                                <div
                                                                    className={'select-drop-down--multilist select-single-option-wrapper'}>
                                                                    <div className={'option-list'}>
                                                                        {['en', ...el.request_languages.filter(lang => lang !== 'en')].map(language => {
                                                                            return (
                                                                                <div className={'select-option-item'}
                                                                                     onClick={() => exportPdf(el.id, language)}
                                                                                     key={language}
                                                                                >
                                                                                    <span className={'option-text'}>{findLanguage(language)}</span>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className='ui-table__body-cell licensor-column c-pointer'
                                                        onClick={() => el.contract_details &&
                                                        el.contract_details.length > 1 ? toggleExpand(el.id) : null}>
                                                        <div className={'u-flex-align'}>
                                                            <div className='c-rec-table__image-cell'>
                                                                <div className='c-rec-table__image-icon'>
                                                                    <MediaView
                                                                        src={el.org_image}
                                                                        alt={'licensor_name'}
                                                                        fallbackSrc={recordPlaceholder}
                                                                        useImgTag
                                                                    />
                                                                </div>
                                                            </div>
                                                            <span>
                                                                    {(findLicensor(el.licensor_organisation_id) && findLicensor(el.licensor_organisation_id).licensor_organisation_name) || ''}
                                                                <span className={'ref-number'}>
                                                                        Ref ID: {el.trademark_request_id || el.id}
                                                                    </span>
                                                                {el.contract_details &&
                                                                    el.contract_details.length > 1 && (
                                                                        <div className='select-drop-down--control--arrow'>
                                                                            <span className='select-drop-down--control--arrow--triangle' />
                                                                        </div>
                                                                    )}
                                                                </span>
                                                        </div>
                                                    </td>
                                                    <td className='ui-table__body-cell'>
                                                        <div className={`u-flex-align ${el.status} status-cell`}>
                                                            <div className={'u-flex-align u-flex-gap'}>
                                                                {el.status === 'pending' && <PendingIcon />}
                                                                {el.status === 'approved' && <ApprovedIcon />}
                                                                {el.status === 'rejected' && <RejectedIcon />}

                                                                <div className="view-reason">
                                                                    {capitalize(el.status)}
                                                                    {el.status === 'rejected' && el.rejector_info && el.rejector_info.notes && (
                                                                        <span>
                                                                            <div className={'view-more c-pointer'} onClick={() => openRejectNote(el.rejector_info.notes)}>View Reason</div>
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='ui-table__body-cell'>
                                                        <span>{moment(el.created_at).format('DD MMM YYYY')}</span>
                                                    </td>
                                                    <td className='ui-table__body-cell'>
                                                            <span>
                                                                {el.status === 'rejected'
                                                                    ? moment(el.rejector_info.timestamp).format('DD MMM YYYY')
                                                                    : el.status === 'approved'
                                                                        ? moment(el.approver_info.timestamp).format('DD MMM YYYY')
                                                                        : 'N/A'
                                                                }
                                                            </span>
                                                    </td>
                                                    <td className='ui-table__body-cell'>
                                                        {el.contract_details &&
                                                        el.contract_details.length > 0 &&
                                                        el.contract_details.length === 1
                                                            ? el.contract_details[0].contract_number
                                                            : 'Multiple'
                                                        }
                                                    </td>
                                                    <td className='ui-table__body-cell'>
                                                            <span>
                                                                {el.contract_details &&
                                                                el.contract_details.length > 0 &&
                                                                el.contract_details.length === 1
                                                                    ? formatArray(el.contract_details[0].contracted_territories.licensee_request, 'Contracted Territories')
                                                                    : 'Multiple'
                                                                }
                                                            </span>
                                                    </td>
                                                    <td className='ui-table__body-cell'>
                                                            <span>
                                                                {el.contract_details &&
                                                                el.contract_details.length > 0 &&
                                                                el.contract_details.length === 1
                                                                    ? formatArray(el.contract_details[0].ips.licensee_request, 'Ips')
                                                                    : 'Multiple'
                                                                }
                                                            </span>
                                                    </td>
                                                    <td className='ui-table__body-cell'>
                                                            <span>
                                                                {el.contract_details &&
                                                                el.contract_details.length > 0 &&
                                                                el.contract_details.length === 1
                                                                    ? formatArray(el.contract_details[0].categories.licensee_request, 'Categories')
                                                                    : 'Multiple'
                                                                }
                                                            </span>
                                                    </td>
                                                </tr>

                                                {expandedRows.includes(el.id) &&
                                                    el.contract_details &&
                                                    el.contract_details.length > 1 &&
                                                    el.contract_details.map((detail, index) => (
                                                        <tr className='expanded-row' key={index}>
                                                            <td className='ui-table__body-cell' colSpan={5}></td>
                                                            <td className='ui-table__body-cell'>{detail.contract_number}</td>
                                                            <td className='ui-table__body-cell'>
                                                                {formatArray(detail.contracted_territories.licensee_request, 'Contracted Territories')}
                                                            </td>
                                                            <td className='ui-table__body-cell'>
                                                                {formatArray(detail.ips.licensee_request, 'Ips')}
                                                            </td>
                                                            <td className='ui-table__body-cell'>
                                                                {formatArray(detail.categories.licensee_request, 'Categories')}
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                {expandedRows.includes(el.id) && el.contract_details && el.contract_details.length > 0 && (
                                                    <tr className='spacer-row'><td colSpan="9" style={{ height: '10px' }}></td></tr>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div
                        ref={scrollbar}
                        className='c-rec-table__bottom-scrollbar'
                        onScroll={(e) => handleScroll(e, 'scrollbar')}
                    >
                        <div
                            style={{
                                height: 20, width: (table.current && table.current.scrollWidth) || 0
                            }}
                        />
                    </div>
                </div>
            </div>

            {!size(tradeMarksArray) && !toArray(tradeMarksArray).length && (
                <div className="u-text-center u-margin-top-4X">
                    <h3 className="c-my-records__no-data-header">
                        {t('modules.royalty_reporting.no_data_available')}
                    </h3>
                    <div className='no-data-catalogue'>
                        <p className='c-my-records__no-data-text'>
                            {t('modules.royalty_reporting.you_have_no_data_here')}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = ({ xelacore, dispatch }) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(TradeMarkTable);
