import React from 'react';
import size from 'lodash/size';
import filter from 'lodash/filter';
import each from 'lodash/each';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import moment from 'moment';
import { Base64 } from 'js-base64';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import dropWhile from 'lodash/dropWhile';
import uniq from 'lodash/uniq';
import map from 'lodash/map';

import { DEFAULT_ROWS } from 'src/js/constants/dataConstants';
import {
    IMG_EXTENSIONS,
    IMG_MAX_SIZE,
    IMG_ERROR_MESSAGES
} from 'src/js/constants/imagesConstants';

import {
    excludedLicensorData,
    excludedLicenseeCatalog,
    excludedLicenseeMyRecords,
    excludedAgentData
} from 'src/js/constants/productConstants/specialCases';
import imgPlaceholder from 'statics/imgs/placeholder.jpg';
import serialize from 'form-serialize';
import { registerProductsModal } from 'src/js/actions/registerModal';
import { pluralise } from 'src/js/helpers/strings';
import { isQuarantineRecord } from 'src/js/helpers/conflictsHelper';
import Button from 'modules/UiKit/components/Button/Button';
import { cloneDeep } from 'lodash';

export const checkData = (
    data,
    count = 50,
    showFullLabel = false,
    values = []
) => {
    if (isPlainObject(data)) return processObj(data);
    if (isArray(data)) return processArray(data, showFullLabel, values);
    if (typeof data === 'string' && data.length >= count)
        return data.substring(0, count) + '...';
    if (showFullLabel) {
        const val = values.find(v => v.value === data);
        if (val && val.label) {
            return `(${data.toUpperCase()})${val.label}`;
        }
    }
    return data;
};

export const checkImageExists = (imageSrc, success, error) => {
    let img = new Image();
    img.onload = success;
    img.onerror = error;
    img.src = imageSrc;
};

export const processArray = (array, showFullLabel = false, values = []) => {
    return (
        <ul>
            {array.map((data, i) => (
                <li key={`${data}-${i}`}>
                    {checkData(data, 50, showFullLabel, values)}
                </li>
            ))}
        </ul>
    );
};

export const processObj = obj => (
    <ul>
        {Object.keys(obj).map((key, i) => {
            return (
                <li key={`${key}-${i}`}>
                    <span className="u-nowrap u-margin-quart-right">
                        <b>{key}</b>:
                    </span>
                    <span className="u-word-break">{obj[key]}</span>
                </li>
            );
        })}
    </ul>
);

export const isURL = str => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?' + // port
        '(\\/[-a-z\\d%@$_.~+&:,]*)*' + // path
        '(\\?[;&a-z\\d%@$_.,~+&:=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // fragment locator
    return pattern.test(str);
};

// Update link for preventing strings like http: //http:
export const checkSetUrl = value => {
    return value
        .replace(/http.*https/g, 'https://')
        .replace(/http.*http/g, 'http://')
        .replace(/:.*:/g, ':')
        .replace(/(\/+)/g, '/')
        .replace(/(http:\/+)/g, 'http://')
        .replace(/(https:\/+)/g, 'https://');
};

export const isValidImageUrl = str => {
    const ext = str
        .split('.')
        .pop()
        .toLowerCase();
    return IMG_EXTENSIONS.includes(ext);
};

/* Check uploaded image by three parameters*/
export const checkUploadedImage = files => {
    if (files.length > 1) return IMG_ERROR_MESSAGES['ONE_FILE'];
    const file =
        Array.isArray(files) && files.length > 0
            ? files[0]
            : IMG_ERROR_MESSAGES['UNKNOWN'];
    if (!isValidImageUrl(file.name)) return IMG_ERROR_MESSAGES['WRONG_FILE'];
    if (file.size > IMG_MAX_SIZE) return IMG_ERROR_MESSAGES['TOO_BIG'];
    return false;
};

// Set the URL and sets values pertaining to it
export const setUrlValues = (params = {}, filters, sort) => {
    const { page = 1, page_size = DEFAULT_ROWS } = params;
    let locationString = `page=${page}&page_size=${page_size}`;

    if (sort) {
        const sortParam = sort.name
            ? sort
            : {
                name: Object.keys(sort)[0],
                dir: Object.values(sort)[0] === 'desc'
            };
        const encodedSort = encodeURIComponent(Base64.encode(JSON.stringify(sortParam)));
        locationString = `${locationString}&sort=${encodedSort}`;
    }

    if (!filters) return locationString;

    const encodedFilters = encodeURIComponent(Base64.encode(JSON.stringify(filters)));
    return `${locationString}&filters=${encodedFilters}`;
};

// Return URL param value by param name
export const getUrlParameter = (name, url) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(url);
    return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

// Get filters data encoded in URL
export const getFiltersDataFromUrl = () => {
    const urlFilterParam = getUrlParameter('filters', window.location.href);
    return urlFilterParam ? JSON.parse(Base64.decode(decodeURIComponent(urlFilterParam))) : null;
};

// Get sort data encoded in URL
export const getSortDataFromUrl = () => {
    const urlFilterParam = getUrlParameter('sort', window.location.href);
    return urlFilterParam ? JSON.parse(Base64.decode(decodeURIComponent(urlFilterParam))) : null;
};

// Helper for building image object for product update
// return or {primary_image_url: image}
// or {other_images: newImages}
export const setImage = (url, data) => {
    if (url && !isURL(url)) {
        return false;
    }

    const image = {
        tag: 'additional',
        is_primary: false,
        is_public: true,
        original_url: url
    };
    if (!(data.images || []).find(i => i.is_primary)) {
        image.is_primary = true;
        image.tag = 'primary';
    }

    data.images = (data.images || []).concat(image);
    return { images: data.images };
};

export const getFilteredFields = (
    licensor,
    list,
    secondExcluder = [],
    isMyRecords
) => {
    let agent = !!licensor && licensor === 'agent';

    const filteringList =
        licensor && licensor !== 'agent'
        ? excludedLicensorData
        : agent
        ? excludedAgentData
        : isMyRecords
            ? [...excludedLicenseeMyRecords, ...secondExcluder]
            : [...excludedLicenseeCatalog, ...secondExcluder];
    const filtered = isMyRecords
        ? list.filter(item => !item.catalogOnly)
        : list;

    return filtered.filter(
        item => !filteringList.includes(item.name) && item.name !== 'category_attributes' && item.showInList
    );
};

export const setMainImage = images => {
    if (!images || !Array.isArray(images) || images.length === 0) return images;
    if (!!images.find(item => item.is_primary)) return images;
    images.map((item, idx) => {
        item.is_primary = idx === 0;
        item.tag = idx === 0 ? 'primary' : 'additional';
    });
    return images;
};

export const checkDate = check =>
    moment(new Date()) < moment(check, 'DD MMM YYYY');

export const getMainImage = (images, size = 200, customPlaceholder = null) => {
    const tempImgPlaceholder = customPlaceholder || imgPlaceholder;
    const defaultImage = { tempImgPlaceholder };
    const formattedImages = images && images.find((el) => el && el.tag && el.tag === 'primary');
    const displayImage = formattedImages && (formattedImages[`thumb_url_${size}`] || formattedImages.local_url);
    return displayImage || defaultImage.tempImgPlaceholder;
};

export const jumpToPage = (e, updatePage) => {
    e.preventDefault();
    const payload = serialize(e.target, { hash: true });
    updatePage(parseInt(payload.page));
};

const buildFilters = (
    licensor_organisation_id,
    licensor_brand,
    product_category = null
) => {
    const terms = [
        { term: { licensor_organisation_id } },
        { term: { licensor_brand: licensor_brand } }
    ];

    if (product_category) {
        terms.push({ term: { product_category } });
    }

    return terms;
};

const prepareData = (record) => {
    if (!!record && (!record.licensor_organisation_id || !record.licensor_brand)) return false;
    return buildFilters(!!record && record.licensor_organisation_id, !!record  && record.licensor_brand);
};

export const buildQueryByCode = (record = {}) => {
    const filter = prepareData(record);
    if (!filter) return null;
    return { query: { query: { bool: { filter } } } };
};

export const buildMatchingDataQuery = (record) => {
    const exact = buildExactParams(record);
    if (!exact) return null;
    
    return {
        exact: removeEmpty(exact)
    };
};

export const getImageUrl = (element) => {
    let sourceExists = element.original_source && element.original_source === 'ingest' && element.original_url;

    return sourceExists
        ? element.original_url
        : element.local_url || element.original_url;
};

export const buildSpacedQuery = (search) => {
    const splitted = search.split(' ');
    if (!splitted || !splitted.length) return search;
    return `(${splitted.join(') AND (')})`;
};

export const removeEmpty = (obj) => {
    return Object.keys(obj)
            .filter((k) => size(obj[k]) > 0)
            .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
}

export const buildExactParams = (record, single, search) => {
    return {
        licensee_organisation_id: !!record && record.licensee_organisation_id || '',
        licensor_organisation_id: !!record && record.licensor_organisation_id || '',
        licensor_brand_id: !!record && record.licensor_brand_id || '',
        xelacore_concept_id: single ? search : typeof search === 'object' ? map(search, 'xelacore_concept_id') : ''
    }
}

export const buildSearchQuery = (search, record, single) => {
    const exact = buildExactParams(record, single, search);
    // const str = search.indexOf(' ') !== -1 ? buildSpacedQuery(search) : search;

    if (!filter) return null;

    const partial = typeof search === 'object' ?  {} : {
        product_name: search || '',
        product_description: search || '',
        product_category: search || '',
        licensee: search || '',
        licensor: search || '',
        licensor_brand: search || '',
        approval_code: search || '',
        mpn: search || ''
    };

    return {
        exact: removeEmpty(exact),
        partial: !single ? removeEmpty(partial) : {}
    };
};

export const readyToRegister = (data, selectedRows) => {
    let notRegistered =
        size(
            filter(data, record => {
                return (
                    map(selectedRows, 'record_id').includes(record.record_id) && !record.is_registered
                );
            })
        ) === size(selectedRows);

    let noErrors =
        size(
            filter(data, record => {
                return (
                    map(selectedRows, 'record_id').includes(record.record_id) &&
                    record.errors === 0
                );
            })
        ) === size(selectedRows);

    let noConflicts =
        size(
            filter(data, record => {
                return (
                    map(selectedRows, 'record_id').includes(record.record_id) &&
                    (record.conflicts_total_count === 0 ||
                        (record.conflicts_total_count > 0 &&
                            record.conflicts_resolved_local))
                );
            })
        ) === size(selectedRows);

    return selectedRows && notRegistered && noErrors && noConflicts;
};

export const filterItemProperForRegister = selectedRows => record =>
    map(selectedRows, 'record_id').includes(record.record_id) &&
    record.errors === 0 &&
    (record.conflicts_total_count === 0 ||
        (record.conflicts_total_count > 0 &&
            record.conflicts_resolved_local)) &&
    !record.registered_with;

export const filterReadyToRegister = (data, selectedRows) =>
    data
        .filter(filterItemProperForRegister(selectedRows))

export const registerButton = (
    t,
    recordErrors,
    dispatch,
    data,
    selectedRows,
    getProductData,
    isList = false,
    registrableIds
) => (
    <div className={`${isList ? '' : 'u-margin-half-right'}`}>
        <Button
            size={'small'}
            type={'secondary'}
            disabled={!!recordErrors}
            onClick={() =>
                dispatch(
                    registerProductsModal(
                        selectedRows,
                        getProductData,
                        true,
                        registrableIds
                    )
                )
            }
        >
            {t('buttons.register_product')}{" "}
        </Button>
    </div>
);

export const setRegisteredOutput = (data, registered, selected) => {
    const counts = new Map();
    const filteredData = data.filter(el => selected.indexOf(el.record_id) > -1);
    filteredData.forEach(row => {
        const { licensor, licensor_organisation_id, record_id } = row;
        const { successed = 0, errored = 0 } =
        counts.get(licensor_organisation_id) || {};
        const found = registered.find(item => item.record_id === record_id);
        const success = !isEmpty(found) && found.success ? 1 : 0;
        counts.set(licensor_organisation_id, {
            successed: success ? successed + success : successed,
            errored: !success ? errored + 1 : errored,
            licensor
        });
    });
    return counts;
};

export const buildRegisteredOutput = (data, registered, selected) => {
    const counts = setRegisteredOutput(data, registered, selected);
    const items = [];
    counts.forEach((item, key) => {
        const { successed, errored, licensor } = item;
        if (!successed && !errored) return;
        return items.push(
            <div
                className="o-box--registrered"
                key={`${key}-product-registration`}
            >
                <div className="o-box--registrered-name">{licensor}</div>
                {!!successed && (
                    <div className="o-box--registrered-success">
                        <span className="u-fw--bold">{successed}</span>
                        &nbsp;
                        {pluralise('Product', successed)}{" "}
                        {pluralise('has', successed)} been successfully
                        registered
                    </div>
                )}
                {!!errored && (
                    <div className="o-box--registrered-error">
                        <span className="u-fw--bold">{errored}</span>
                        &nbsp;
                        {pluralise('Product', errored)}{" "}
                        {pluralise('has', errored)} failed the registration.
                    </div>
                )}
            </div>
        );
    });

    return items;
};

export const buildConflictResolutionOutput = (data, registered) => {
    const counts = setRegisteredOutput(data, registered);
    const items = [];
    counts.forEach((item, key) => {
        const { successed, errored } = item;
        if (!successed && !errored) return;
        return items.push(
            <div
                className="o-box--registrered"
                key={`${key}-product-registration`}
            >
                <div className="o-box--registrered-name">{key}</div>
                {!!successed && (
                    <div className="o-box--registrered-success">
                        <span className="u-fw--bold">{successed}</span>
                        &nbsp;
                        {pluralise('Product', successed)}{" "}
                        {pluralise('has', successed)} been successfully
                        registered
                    </div>
                )}
                {!!errored && (
                    <div className="o-box--registrered-error">
                        <span className="u-fw--bold">{errored}</span>
                        &nbsp;
                        {pluralise('Product', errored)}{" "}
                        {pluralise('has', errored)} failed the registration.
                    </div>
                )}
            </div>
        );
    });

    return items;
};

export const filterQuarantineRows = (data, selectedRows) => {
    let cloneSelectedRows = cloneDeep(selectedRows);

    each(data, row => {
        if (includes(map(cloneSelectedRows, 'record_id'), row.record_id) && isQuarantineRecord(row)) {
            remove(cloneSelectedRows, item => {
                return item.record_id === row.record_id;
            });
        }
    });

    return cloneSelectedRows;
};

export const isUpdated = (messages, services, timestamp, ids) => {
    // This following fix will defer the ES notification to fetch latest updated record values from ES in
    // the end so that we wait for the validation counters to be ready and also refetch records only when everything is ready.
    const prioritizedMessages = dropWhile(messages, m => {
        return m.service === 'elastic';
    }).concat((messages || []).filter(m => m.service === 'elastic'));
    services = [].concat(services);
    const updated = (prioritizedMessages || []).filter(m => {
        return services.includes(m.service) && m.timestamp > (timestamp || 0);
    });

    if (!ids) return !!updated.length;

    ids = [].concat(ids);
    const updatedIds = updated.filter(m =>
        ids.includes(get(m, 'record.record_id'))
    );
    return uniq(updatedIds).length === uniq(ids).length;
};

export const getUpdated = (messages, service, timestamp, ids) => {
    const updated = (messages || []).filter(m => {
        return m.service === service && m.timestamp >= (timestamp || 0);
    });

    if (!ids) return !!updated.length;

    ids = [].concat(ids);
    const updatedFull = updated.filter(m =>
        ids.includes(get(m, 'record.record_id'))
    );
    return updatedFull.map(item => item.record);
};

export const getNukeParameter = (output, user_id) => {
    const org_id = localStorage.getItem('orgId');
    if (!org_id) return false;
    const employee = output.employees.find(
        employee =>
            employee.user_id === user_id && employee.organisation_id === org_id
    );
    return !!(employee && employee.is_nuking);
};
