import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
    buildMatchingDataQuery, buildSearchQuery
} from 'helpers/dataHelpers';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import difference from 'lodash/difference';
import get from 'lodash/get';
import compact from 'lodash/compact';
import includes from 'lodash/includes';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import intersection from 'lodash/intersection';
import { queryOpaData, removeConceptData } from 'src/js/actions/opa';
import { bulkConcepts } from 'apicalls/catalog/schemas';
import { displayNotification, hideModal } from 'src/js/actions/xelacore';
import { getGroupedHeaders } from 'helpers/headers';
import { headers as defaultHeaders } from 'constants/productConstants/headers';
import BulkLinkConceptsConceptSidebar from './ConceptSidebar/ConceptSidebar';
import BulkLinkConceptsProductSidebar from './ProductSidebar/ProductSidebar';
import { parseV1Response } from 'src/js/helpers/arrays';
import { withTranslation } from 'react-i18next';

class BulkLinkConcepts extends Component {
    constructor(props) {
        super(props);
        this.groupedHeaders = getGroupedHeaders(defaultHeaders);
        this.state = {
            data: null,
            selectedProductGroupIndex: 0,
            selectedProductGroup: null,
            searchItems: [],
            groupIntersectionConcepts: [],
            recommendedConceptIds: [],
            linkedConceptItems: [],
            conceptsIds: [],
            approvedGroupConceptIntersection: [],
            filteredConceptItems: [],
            from: 0,
            size: 15,
            selectedConcepts: [],
            selectedConceptsData: [],
            currentConceptForPreview: null,
            conceptActiveImage: null,
            productActiveImage: null,
            LinkingApprovedData: null,
            currentProductItemPreview: null,
            currentImageProductItemPreview: null,
            currentProductItemGroupPreview: null,
            viewSelectedConceptsOnly: false,
            currentConceptPage: 1,
            conceptPageSize: 12,
            totalConceptItems: 0,
            conceptSearchTerm: '',
            currentConceptSearchTerm: '',
            singleProductData: false,
            conceptInfoPreviewMode: false,
            pageInfo: {},
            selectedLicensor: !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {}
        };
    }

    componentWillMount() {
        this.fetchData();
    }

    setCurrentActiveImage(image) {
        this.setState({ conceptActiveImage: image });
    }

    linkConcepts() {
        this.fetchLinkedData();
    }

    linkSingleConcept(currentConceptForPreview) {
        this.fetchLinkedData([currentConceptForPreview.xelacore_concept_id]);
    }

    unLinkSingleConcept(currentConceptForPreview) {
        const { dispatch, getProductData, t } = this.props;
        return removeConceptData(this.state.currentProductItemPreview.record_id, currentConceptForPreview.xelacore_concept_id)
            .then(() => {
                dispatch(displayNotification({
                        timeOut: 5000,
                        message: t('bulk_link_concepts.concept_been_unlinked'),
                        type: 'success'
                    })
                );
                dispatch(hideModal());
                getProductData();
                return;
            });
    }

    fetchLinkedData(singleConcept = false) {
        const { dispatch, t } = this.props;
        const { data, selectedProductGroupIndex, selectedConcepts, singleProductData } = this.state;
        let selectedGroup = cloneDeep(data[selectedProductGroupIndex].list);
        const recordIdList = [];

        selectedGroup.forEach(product => {
            recordIdList.push({
                record_id: product.record_id,
                version_id: product.version_id
            });
        });

        const linkedConceptData = singleConcept || selectedConcepts;
        const dataToSend = {
            confirmations: linkedConceptData,
            records: recordIdList
        };

        bulkConcepts(dataToSend).then(resp => {
            const { getProductData } = this.props;
            this.setState({
                LinkingApprovedData: resp,
                currentProductItemPreview: null,
                currentImageProductItemPreview: null,
                currentProductItemGroupPreview: null
            });

            dispatch(displayNotification({
                    timeOut: 5000,
                    message: t('bulk_link_concepts.update_submitted'),
                    type: 'success'
                })
            );

            if (!!singleProductData) {
                dispatch(hideModal());
                getProductData();
                return;
            }

            if (!!singleConcept) {
                this.setState({
                    currentConceptForPreview: null,
                    conceptActiveImage: null
                });
                return;
            }
        });
    }

    fetchData() {
        const { rows, singleProduct, previewLinkedConcept } = this.props;

        if (!!rows && rows.length > 0) {
            let groupedArray = [];
            forEach(groupBy(rows, 'licensor_brand_id'), (value, key) => {
                groupedArray.push({
                    id: key,
                    name: value[0].licensor_brand,
                    list: value
                });
            });

            this.setState({
                data: groupedArray,
                selectedProductGroup: groupedArray[0]
            });
            this.checkProductGroup(groupedArray[this.state.selectedProductGroupIndex], this.state.selectedProductGroupIndex);
        }

        if (!!singleProduct) {
            this.productPreview(rows[0], null);
            let conceptInfoListDataFull = rows[0] && rows[0].concept_info ? rows[0].concept_info : null;
            let conceptInfoListData = !!conceptInfoListDataFull?conceptInfoListDataFull.map(item => item.xelacore_concept_id) : null;
            this.setState({
                    conceptInfoListFull: conceptInfoListDataFull,
                    conceptInfoList: conceptInfoListData,
                    singleProductData: singleProduct
                }
            );

        }

        if (!!previewLinkedConcept && singleProduct) {
            // this.getLinkedConceptList(rows[0]);
            this.openConceptDetails(previewLinkedConcept);
            this.setState({ conceptInfoPreviewMode: true });
        }
    }

    checkProductGroup(productGroup, groupIndex) {
        const { selectedProductGroup } = this.state;
        if ((selectedProductGroup && productGroup) &&
            selectedProductGroup.id === productGroup.id) {
            return;
        }
        this.setState({
            selectedProductGroupIndex: groupIndex,
            selectedProductGroup: productGroup,
            selectedConcepts: [],
            selectedConceptsData: [],
            currentConceptForPreview: null,
            conceptActiveImage: null
        });
        this.makeQuery('', false, productGroup);
    }

    makeQuery(search, matching, recordList) {
        if (!recordList) {
            return;
        }

        const { originalData } = this.props;
        const { currentConceptPage, conceptPageSize, selectedLicensor } = this.state;
        // const from = currentConceptPage === 1 ? 0 : (currentConceptPage - 1) * conceptPageSize;
        let record = recordList.list[0];
        const query = matching
            ? buildMatchingDataQuery(originalData)
            : buildSearchQuery(search.trim(), record);
        let pageParams = {
            page_size: !!conceptPageSize && conceptPageSize || 15,
            page: !!currentConceptPage && currentConceptPage || 1
        }

        queryOpaData(query, selectedLicensor.licensor_organisation_id, pageParams)
            .then((response) => {
                let data = response && response.data && map(response.data, item => {
                    item = {...item.properties, ...item};
                    return parseV1Response(item);
                });
                const {} = data;
                const searchItems = data || [];
                const totalItems = get(
                    response,
                    '_metadata.totalResults',
                    0
                );

                this.setState({
                    totalConceptItems: totalItems,
                    searchItems: searchItems,
                    filteredConceptItems: searchItems,
                    viewSelectedConceptsOnly: false
                });
                this.getRecommendedItems(recordList);
            })
            .catch((err) => {
                this.setState({ submitting: false, error: err });
            });
    }

    getRecomandedConcepts(conceptsIds) {
        const { selectedProductGroup, selectedLicensor } = this.state;
        let pageParams = {
            page_size: 15,
            page: 1
        }

        conceptsIds.forEach(conceptId => {
            let record = selectedProductGroup.list[0];
            const query = buildSearchQuery(conceptId.trim(), record);

            queryOpaData(query, selectedLicensor.licensor_organisation_id, pageParams)
                .then((response) => {
                    let data = response && response.data && map(response.data, item => {
                        item = {...item.properties, ...item};
                        return parseV1Response(item);
                    });
                    let newRecommendedConceptItems = cloneDeep(this.state.recommendedConceptItems);
                    newRecommendedConceptItems.push(data[0]);
                    this.setState({ recommendedConceptItems: compact(newRecommendedConceptItems) });
                })
                .catch((err) => {
                    this.setState({ submitting: false, error: err });
                });
        });
    }

    getLinkedConceptList(record) {
        const { selectedLicensor } = this.state;
        const conceptsIds = record.concept_code_confirmed_info.map(item => item.xelacore_concept_id);

        let pageParams = {
            page_size: 15,
            page: 1
        }

        conceptsIds.forEach(conceptId => {
            const query = buildSearchQuery(conceptId.trim(), record);

            queryOpaData(query, selectedLicensor.licensor_organisation_id, pageParams)
                .then((response) => {
                    let data = response && response.data && map(response.data, item => {
                        item = {...item.properties, ...item};
                        return parseV1Response(item);
                    });
                    let newLinkedConceptItems = cloneDeep(this.state.linkedConceptItems);
                    newLinkedConceptItems.push(data[0]);
                    this.setState({ linkedConceptItems: compact(newLinkedConceptItems) });
                })
                .catch((err) => {
                    this.setState({ submitting: false, error: err });
                });
        });
    }

    getSingleConceptById(conceptId) {
        const { rows } = this.props;
        const { selectedLicensor } = this.state;
        const query = buildSearchQuery(conceptId.trim(), rows[0], true);

        queryOpaData(query, selectedLicensor.licensor_organisation_id, {})
            .then((response) => {
                let data = response && response.data && map(response.data, item => {
                    item = {...item.properties, ...item};
                    return parseV1Response(item);
                });
                if (!!data[0]) {
                    this.openConceptDetails(data[0]);
                }
            })
            .catch((err) => {
                this.setState({ submitting: false, error: err });
            });
    }

    goToConceptPreview(conceptId) {
        this.getSingleConceptById(conceptId);
    }

    updatePageHandler(page) {
        const { currentConceptSearchTerm, conceptPageSize, selectedProductGroup } = this.state;
        const from = page === 1 ? 0 : (page - 1) * conceptPageSize;
        this.setState(
            {
                currentConceptPage: page,
                from
            }
        );
        setTimeout(() => {
            this.makeQuery(currentConceptSearchTerm, false, selectedProductGroup);
        }, 1);
    }

    conceptChecked(e, concept) {
        const { selectedConcepts, selectedConceptsData } = this.state;

        const clonedSelectedConcepts = cloneDeep(selectedConcepts);
        const clonedSelectedConceptsData = cloneDeep(selectedConceptsData);
        if (e.currentTarget.checked && !includes(selectedConcepts, concept.xelacore_concept_id)) {
            clonedSelectedConcepts.push(concept.xelacore_concept_id);
            clonedSelectedConceptsData.push(concept);
            this.setState(
                {
                    selectedConcepts: clonedSelectedConcepts,
                    selectedConceptsData: clonedSelectedConceptsData
                }
            );
        }
        if (!e.currentTarget.checked && !!includes(selectedConcepts, concept.xelacore_concept_id)) {
            this.setState(
                {
                    selectedConcepts: filter(selectedConcepts, item => item !== concept.xelacore_concept_id),
                    selectedConceptsData: filter(selectedConceptsData, item => item.xelacore_concept_id !== concept.xelacore_concept_id)
                }
            );
        }

        setTimeout(() => {
            if (this.state.selectedConcepts.length === 0) {
                this.viewSelectedItems(true);
            }
        }, 1);
    }

    openConceptDetails(concept) {
        this.setState({
            currentConceptForPreview: concept,
            conceptActiveImage: null
        });
    }

    backToConceptList() {
        this.setState({
            conceptInfoPreviewMode: false,
            currentConceptForPreview: null,
            conceptActiveImage: null
        });
    }

    removeProductFromList(data, group, product) {
        let clonedData = cloneDeep(data);
        const filteredList = filter(group.list, item => {
            return product.record_id !== item.record_id;
        });
        const groupIndex = clonedData.findIndex(item => item.id === group.id);
        clonedData[groupIndex].list = filteredList;
        this.setState({ currentProductItemGroupPreview: clonedData[groupIndex], data: clonedData });
        this.getRecommendedItems(clonedData[groupIndex]);
    }

    getRecommendedItems(group) {
        let listConceptCodesArray = [];
        let listApprovedConceptCodesArray = [];
        let listApprovedConceptIdsArr = [];
        this.setState({ recommendedConceptItems: [] });

        group.list.forEach(item => {
            if (!!item.concept_code_confirmed_info && item.concept_code_confirmed_info.length > 0) {
                let tempConceptInfo = item.concept_info.map(info => {
                    let conceptInfoIndex = findIndex(item.concept_code_confirmed_info, concept => concept.xelacore_concept_id === info.xelacore_concept_id);

                    if(conceptInfoIndex > -1) {
                        return info.approval_code;
                    }
                });
                if (!!item.concept_code_confirmed_info) {
                    listConceptCodesArray.push(tempConceptInfo);
                }
            }
            listApprovedConceptCodesArray.push(item.concept_code);
            listApprovedConceptIdsArr.push(map(item.concept_info, 'xelacore_concept_id'));
        });

        let intersectionListApprovedConceptCodesArray = listApprovedConceptCodesArray.length ? intersection(...listApprovedConceptCodesArray) : [];
        let intersectionListApprovedIdsArray = listApprovedConceptIdsArr.length ? intersection(...listApprovedConceptIdsArr) : [];
        let intersectionListConceptCodesArray = listConceptCodesArray.length ? intersection(...listConceptCodesArray) : [];
        let recommendedConceptIds = difference(intersectionListApprovedConceptCodesArray, intersectionListConceptCodesArray);

        this.setState({
            recommendedConceptIds: recommendedConceptIds,
            groupIntersectionConcepts: intersectionListApprovedIdsArray,
            approvedGroupConceptIntersection: intersectionListApprovedIdsArray
        });
        this.getRecomandedConcepts(recommendedConceptIds);

        return { recommendedConceptIds, intersectionListConceptCodesArray, intersectionListApprovedConceptCodesArray };
    }

    handleSearchConcepts(e) {
        this.setState({ conceptSearchTerm: e.target.value, currentConceptPage: 1 });
    }

    handleSearchConceptData(clear) {
        const { conceptSearchTerm, selectedProductGroup } = this.state;
        this.setState({ currentConceptSearchTerm: conceptSearchTerm });
        if (!!clear) {
            this.setState({ conceptSearchTerm: '', currentConceptSearchTerm: '' });
        }

        const searchTermData = !!clear ? '' : conceptSearchTerm;
        this.makeQuery(searchTermData, false, selectedProductGroup);
    }

    viewSelectedItems(clearFilter = false) {
        const { searchItems, selectedConcepts } = this.state;
        if (!clearFilter) {
            const filteredSelectedItem = searchItems.filter(item => selectedConcepts.includes(item.xelacore_concept_id));
            this.setState({ filteredConceptItems: filteredSelectedItem, viewSelectedConceptsOnly: true });
        } else {
            this.setState({ filteredConceptItems: searchItems, viewSelectedConceptsOnly: false });
        }
    }

    arrayPaginate(array, pageSize, page) {
        return array.filter((item, index) => {
            return (index < pageSize * page) &&
                (index > (pageSize * (page - 1) - 1));
        });
    }

    productPreview(productItem, groupItem) {
        this.setState({
            currentImageProductItemPreview: null,
            currentProductItemPreview: productItem,
            currentProductItemGroupPreview: groupItem
        });

    }

    filterProductList() {
        const {
            data,
            currentProductItemPreview,
            currentProductItemGroupPreview
        } = this.state;
        const groupIndex = data.findIndex(item => item.id === currentProductItemGroupPreview.id);
        if (data[groupIndex] && data[groupIndex].list) {
            return data[groupIndex].list.filter(item => item.record_id !== currentProductItemPreview.record_id);
        }

    }

    selectProductImage(productImage) {
        this.setState({ currentImageProductItemPreview: productImage });
    }

    goToImageArrow(dir, list, current, state) {
        state = state || 'currentImageProductItemPreview';
        const currentIndex = this.getCurrentImageIndex(list, current);
        if (dir === 'prev' && currentIndex > 0) {
            this.setState({ [state]: list[currentIndex - 1] });
        }
        if (dir === 'next' && currentIndex < list.length) {
            this.setState({ [state]: list[currentIndex + 1] });
        }
    }

    getCurrentImageIndex(list, current) {
        current = current || list[0];
        return list.indexOf(current);
    }

    goBackToProductList() {
        this.setState(
            {
                currentProductItemPreview: null,
                currentImageProductItemPreview: null
            }
        );
    }

    render() {
        const {
            data,
            selectedProductGroupIndex,
            selectedProductGroup,
            filteredConceptItems,
            selectedConcepts,
            selectedConceptsData,
            currentConceptForPreview,
            conceptActiveImage,
            groupIntersectionConcepts,
            recommendedConceptIds,
            recommendedConceptItems,
            approvedGroupConceptIntersection,
            currentProductItemPreview,
            currentImageProductItemPreview,
            currentProductItemGroupPreview,
            viewSelectedConceptsOnly,
            currentConceptPage,
            conceptPageSize,
            totalConceptItems,
            singleProductData,
            conceptSearchTerm,
            currentConceptSearchTerm,
            conceptInfoList,
            conceptInfoListFull,
            conceptInfoPreviewMode
        } = this.state;

        const {
            licensor,
            xelacore,
            dispatch,
            ipLevels,
            resetConceptsRows
        } = this.props;

        return (
            <div className="c-bulk-update">
                <div
                    className={classNames(
                        'bulk-link-concepts-wrapper',
                        `${!!singleProductData ? 'single-bulk-link-concepts-wrapper' : ''}`
                    )}
                >

                    <BulkLinkConceptsProductSidebar
                        currentProductItemPreview={currentProductItemPreview}
                        data={data}
                        currentImageProductItemPreview={currentImageProductItemPreview}
                        currentProductItemGroupPreview={currentProductItemGroupPreview}
                        selectedProductGroupIndex={selectedProductGroupIndex}
                        groupedHeaders={this.groupedHeaders}
                        checkProductGroup={(groupItem, groupIndex) => this.checkProductGroup(groupItem, groupIndex)}
                        productPreview={(productItem, groupItem) => this.productPreview(productItem, groupItem)}
                        removeProductFromList={(data, group, product) => this.removeProductFromList(data, group, product)}
                        getCurrentImageIndex={(dlist, current) => this.getCurrentImageIndex(dlist, current)}
                        goToImageArrow={(dir, list, current, state) => this.goToImageArrow(dir, list, current, state)}
                        selectProductImage={(productImage) => this.selectProductImage(productImage)}
                        filterProductList={() => this.filterProductList()}
                        goBackToProductList={() => this.goBackToProductList()}
                        singleProductData={singleProductData}
                        filteredConceptItems={filteredConceptItems}
                        openConceptDetails={(concept) => this.openConceptDetails(concept)}
                        licensor={licensor}
                        ipLevels={ipLevels}
                        xelacore={xelacore}
                        dispatch={dispatch}
                        resetConceptsRows={resetConceptsRows}
                    ></BulkLinkConceptsProductSidebar>


                    <BulkLinkConceptsConceptSidebar
                        currentConceptForPreview={currentConceptForPreview}
                        selectedProductGroup={selectedProductGroup}
                        selectedConcepts={selectedConcepts}
                        selectedConceptsData={selectedConceptsData}
                        filteredConceptItems={filteredConceptItems}
                        conceptActiveImage={conceptActiveImage}
                        groupIntersectionConcepts={groupIntersectionConcepts}
                        recommendedConceptIds={recommendedConceptIds}
                        approvedGroupConceptIntersection={approvedGroupConceptIntersection}
                        conceptInfoList={conceptInfoList}
                        conceptInfoListFull={conceptInfoListFull}
                        viewSelectedConceptsOnly={viewSelectedConceptsOnly}
                        currentConceptPage={currentConceptPage}
                        conceptPageSize={conceptPageSize}
                        viewSelectedItems={(item) => this.viewSelectedItems(item)}
                        backToConceptList={() => this.backToConceptList()}
                        linkConcepts={() => this.linkConcepts()}
                        linkSingleConcept={(currentConcept) => this.linkSingleConcept(currentConcept)}
                        unLinkSingleConcept={(currentConcept) => this.unLinkSingleConcept(currentConcept)}
                        handleSearchConceptData={(clear) => this.handleSearchConceptData(clear)}
                        handleSearchConcepts={(e) => this.handleSearchConcepts(e)}
                        handleClearConceptSearch={(e) => this.handleClearConceptSearch(e)}
                        openConceptDetails={(concept) => this.openConceptDetails(concept)}
                        conceptInfoPreviewMode={conceptInfoPreviewMode}
                        conceptChecked={(e, conceptItem) => this.conceptChecked(e, conceptItem)}
                        goToImageArrow={(dir, list, current, state) => this.goToImageArrow(dir, list, current, state)}
                        setCurrentActiveImage={(image) => this.setCurrentActiveImage(image)}
                        totalConceptItems={totalConceptItems}
                        singleProductData={singleProductData}
                        recommendedConceptItems={recommendedConceptItems}
                        conceptSearchTerm={conceptSearchTerm}
                        currentConceptSearchTerm={currentConceptSearchTerm}
                        goToConceptPreview={(concept) => this.goToConceptPreview(concept)}
                        updatePageHandler={(page) => this.updatePageHandler(page)}
                        licensor={licensor}
                        data={data}
                    >

                    </BulkLinkConceptsConceptSidebar>


                </div>
            </div>
        );
    }
}


export default connect()(withTranslation()(BulkLinkConcepts));
