import React, { Component } from 'react';
import get from 'lodash/get';

import {
    errorContent,
    warningContent,
    duplicateContent,
    duplicateErrorContent,
    confirmedDuplicateContent,
    onGtinWaiver
} from 'src/js/components/TableRow/common';

import Tooltip from 'rc-tooltip';
import TooltipSimpleData from 'src/js/modules/MyRecords/routes/MyRecords/components/TooltipsData/TooltipSimpleData';
import TooltipRegisteredData from 'src/js/modules/MyRecords/routes/MyRecords/components/TooltipsData/TooltipRegisteredData';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';

class TableRowTooltips extends Component {
    constructor(props) {
        super(props);
    }

    openModal = (modalActions) => {
        const { setConflicts } = this.props;
        setConflicts(modalActions);
    };

    render() {
        const {
            data,
            editRow,
            getRegisteredLink,
            errors,
            warnings,
            conflicts,
            conflicted,
            t
        } = this.props;

        const validations = (data && data.validations) || [];
        let duplicateTooltipFunc = !data.conflicts_resolved_local
            ? duplicateContent
            : confirmedDuplicateContent;
        let duplicateTooltipStyle = `${
            !data.conflicts_resolved_local
                ? 'c-rec-table__band-item-dupe'
                : 'c-rec-table__band-item-dupe-gray'
        }`;
        if (data.errors) {
            duplicateTooltipFunc = duplicateErrorContent;
            duplicateTooltipStyle = classnames(
                duplicateTooltipStyle,
                'u-auto-cursor'
            );
        }
        return (
            <div>
                <div className="c-rec-table__status-icons">
                    <TooltipRegisteredData
                        data={data}
                        getRegisteredLink={getRegisteredLink}
                    />

                    {data.gtin_waiver && (
                        <Tooltip
                            placement="right"
                            align={{ offset: [0, 12] }}
                            overlay={() => onGtinWaiver(t)}
                            trigger={['hover']}
                        >
                            <div>
                                <span className="gtin-icon"></span>
                            </div>
                        </Tooltip>
                    )}

                    {data.quarantine_total_count > 0 &&
                        !!get(data, 'registered_with') &&
                        !data.quarantine_resolved_local && (
                            <span
                                className="quarantine-icon"
                                onClick={() => this.openModal(false)}
                            >
                                Q
                            </span>
                        )}
                </div>
                <div className="c-rec-table__band">
                    <ul>
                        <TooltipSimpleData
                            icon="error2"
                            param={
                                conflicted
                                    ? validations.filter(
                                          (item) => item.level === 'error'
                                      ).length
                                    : errors
                            }
                            description="Error"
                            func={errorContent}
                            handleClick={editRow}
                            className="c-rec-table__band-item-error"
                            conflicted={conflicted}
                        />
                        <TooltipSimpleData
                            icon="duplicates"
                            param={conflicts}
                            description="Conflicts"
                            handleClick={() =>
                                data.errors ? null : this.openModal(true)
                            }
                            func={duplicateTooltipFunc}
                            className={duplicateTooltipStyle}
                        />
                        <TooltipSimpleData
                            icon="warning"
                            param={
                                conflicted
                                    ? validations.filter(
                                          (item) => item.level === 'warn'
                                      ).length
                                    : warnings
                            }
                            description="Warnings"
                            func={warningContent}
                            handleClick={editRow}
                            className="c-rec-table__band-item-warn"
                            conflicted={conflicted}
                        />
                    </ul>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TableRowTooltips)