import React, { Component } from 'react';
import { connect } from 'react-redux';
import isPlainObject from 'lodash/isPlainObject';
import DynamicIps from 'components/ProductFormElements/elements/DynamicIps';
import { returnUnique } from 'helpers/formValidationHelpers';
import { browserHistory } from 'react-router';
import { hideModal } from 'src/js/actions/xelacore';
import FormItemWrapper from 'modules/UiKit/components/FormElements/FormItem';
import { withTranslation } from 'react-i18next';

const mapStateToProps = ({ xelacore }) => ({ xelacore });
const mapDispatchToProps = (dispatch) => ({ dispatch });

@connect(mapStateToProps, mapDispatchToProps)
class LicenseeRightsGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    selectFields(
        elements,
        subGroup = false,
        isAssociatedIp = false,
        other = false
    ) {
        return elements.filter(
            (elem) =>
                elem.config.subGroup === subGroup &&
                elem.config.isAssociatedIp === isAssociatedIp &&
                elem.config.other === other
        );
    }

    renderValidations(errors, warnings) {
        if (!errors.length && !warnings.length) {
            return '';
        }
        return (
            <ul className="o-list">
                {returnUnique(errors).map((el) => (
                    <li
                        key={`${el}`}
                        className="c-product__detail-listing-item-level-error"
                    >
                        {el}
                    </li>
                ))}
                {returnUnique(warnings).map((el) => (
                    <li
                        key={`${el}`}
                        className="c-product__detail-listing-item-level-warn"
                    >
                        {el}
                    </li>
                ))}
            </ul>
        );
    }

    goToCatalogue(data) {
        const { dispatch, isModalView } = this.props;

        if (isModalView) dispatch(hideModal());
        return browserHistory.push(`/product/catalog/${data.record_id}`)
    }

    renderFields(fields) {
        const {
            data,
            validations,
            formatValidations,
            onChange,
            changeObject,
            xelacore,
            isAgent,
            agenciesData,
            originalData,
            t
        } = this.props;

        return (
            <div>
                {fields.map((el, i) => {
                    const {
                        form: {
                            type,
                            values = null,
                            isGrouped,
                            isInline = false,
                            isMulti,
                            fetchValues,
                            reliesOn,
                            valueDataName,
                            valueProp,
                            disabled
                        },
                        dataName
                    } = el;


                    const id = `${dataName}--product-edit-listing-${i}`;
                    const value = data[valueDataName || dataName];

                    const formatVals = isPlainObject(validations)
                        ? formatValidations(validations)
                        : validations;

                    return (
                        <div key={i+'lrg'}>
                            <FormItemWrapper
                                vertical={true}
                                key={id}
                                id={id}
                                data={data}
                                type={type}
                                name={dataName}
                                label={t(`table_headers.${dataName}`)}
                                value={value === null ? '' : value}
                                values={values}
                                tooltip={t(`table_header_constants.${dataName}.tooltip.licensee`)}
                                onChange={(e) => {
                                    onChange(e, valueDataName || dataName);
                                }}
                                updateObject={(val, key) =>
                                    changeObject(val, key)
                                }
                                validations={formatVals.filter(({ field }) =>
                                    type === 'array'
                                        ? field.startsWith(dataName)
                                        : dataName === 'agent_organisation' ? (field === 'agent_organisation_id' || field === 'agent_organisation') : field === dataName
                                )}
                                isGrouped={isGrouped}
                                isMulti={isMulti}
                                fetchValues={fetchValues}
                                reliesOn={reliesOn}
                                // TODO complete
                                localValidations={{}}
                                isInline={isInline}
                                valueProp={valueProp}
                                disabled={disabled}
                                xelacore={xelacore}
                                isAgent={isAgent}
                                agenciesData={agenciesData}
                                originalData={originalData}
                            />

                            {/* {isModalView &&
                                !!data.registered_with &&
                                !!find(
                                    validations,
                                    (item) =>
                                        item.code ===
                                        'catalog.conceptCodeNotFound'
                                ) &&
                                dataName === 'concept_code' && (
                                    <span
                                        className="code-match"
                                        onClick={() => this.goToCatalogue(data)}
                                    >
                                        Please update in Catalogue
                                    </span>
                                )} */}
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        const { elements, data, ipLevels, xelacore, ips, onChange } = this.props;
        const commonFields = this.selectFields(elements);
        const otherFields = this.selectFields(elements, false, false, true);

        return (
            <div>
                {this.renderFields(commonFields)}

                <DynamicIps
                    xelacore={xelacore}
                    data={data}
                    ips={ips}
                    onChange={onChange}
                    ipLevels={ipLevels}>
                </DynamicIps>

                {this.renderFields(otherFields)}
            </div>
        );
    }
}

export default withTranslation()(LicenseeRightsGroup)
