import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    previewAuthorizationLetter
} from 'src/js/apicalls/other/authorization';
import UiButton from 'modules/UiKit/components/Button/Button';
import LoadingSpinner from 'components/Charts/LoadingSpinner';


const PdfPreview = ({ requestId, contractId }) => {
    const { t } = useTranslation();
    const [pdfUrl, setPdfUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        loadPdfPreview();
    }, [requestId, contractId]);

    const loadPdfPreview = async () => {
        try {
            setLoading(true);
            setError(null);
            const url = await previewAuthorizationLetter(contractId);
            setPdfUrl(url);
        } catch (err) {
            setError(t('toolkit.trademark_authorization.request.failed_generate_pdf'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="toolkit-container">

            <div className="preview-container">
                {loading && (
                    <div className="loading-wrapper">
                        <LoadingSpinner />
                    </div>
                )}

                {error && (
                    <div className="error-state">
                        <p>{error}</p>
                        <UiButton
                            size="small"
                            type="secondary"
                            onClick={loadPdfPreview}
                        >
                            {t('toolkit.trademark_authorization.request.retry_pdf')}
                        </UiButton>
                    </div>
                )}

                <div className={'pdf-preview-wrapper'}>
                    {!loading && !error && pdfUrl && (
                        <iframe
                            src={pdfUrl}
                            className="pdf-viewer"
                            title={t('toolkit.trademark_authorization.request.pdf_preview_title')}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default connect()(PdfPreview);
