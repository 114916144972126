import React, { useEffect, useState } from 'react';
import size from 'lodash/size';
import { toArray } from 'lodash';
import { ReactComponent as CompletedMark } from 'src/js/components/static/Icon/svg-icons/check-mark.svg';
import { ReactComponent as GeneratingMark } from 'src/js/components/static/Icon/svg-icons/generating-mark.svg';
import Button from 'modules/UiKit/components/Button/Button';
import emptyReport from 'statics/imgs/report-empty.png';
import {
    getPosExport,
    getFileDownloadUrl,
    downloadFromUrl
} from 'src/js/apicalls/other/posExport';
import moment from 'moment';
import md5 from 'md5';
import classnames from 'classnames';
import DataViewPagination from 'src/js/components/DataViewPagination/DataViewPagination';
import { setUrlValues } from 'src/js/helpers/dataHelpers';
import { getUrlParam } from 'src/js/helpers/strings';
import { PAGE_LIMIT } from 'src/js/constants/dataConstants';
import { browserHistory } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

export default function PosExport({}) {
    const { t } = useTranslation();
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [posExports, setPosExports] = useState([]);
    const [meta, setMetaData] = useState([]);
    const [error, setError] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(1);

    useEffect(() => {
        const paramsAll = checkPageParam() || {};

        getExports(paramsAll);
    }, []);

    const getExports = (params) => {
        const paramsAll = isEmpty(params) ? checkPageParam() : params;

        getPosExport(paramsAll).then(response => {
            setError(false);
            setPosExports(!!response && !!response.data && response.data || []);
            setMetaData(!!response && !!response._metadata && response._metadata || {});

            const locationString = setUrlValues(paramsAll);

            if (!!size(paramsAll)) {
                browserHistory.push(`/pos-export?${locationString}`);
            }
        }, () => {
            setError(true);
        })
    }


    const checkPageParam = () => {
        const page = getUrlParam('page') || 1;
        const page_size = getUrlParam('page_size') || PAGE_LIMIT;
        setPage(page);
        setPageSize(page_size);
        return { page, page_size };
    }

    const displayItem = (type, item) => {
        switch (type) {
            case 'createdAt':
                return moment(item[type]).format('YYYY-MM-DD hh:mm A');
            case 'status':
                return (item[type] === 'completed'
                ? <div className="u-flex u-flex-align completed">
                    <CompletedMark className="u-margin-half-right"></CompletedMark>
                    {item[type]}
                </div>
                : <div className="u-flex u-flex-align generating">
                    <GeneratingMark className="u-margin-half-right"></GeneratingMark>
                    {item[type]}
                </div>);
            case 'userName':
                return (size(item[type]) > 0 ? item[type] : item['userEmail']) || '';
        }
    }

    const downloadFile = (filename) => {
        let params = {
            repositoryId: 'private',
            category: 'catalog/export',
            filename
        }

        getFileDownloadUrl(params).then(url => {
            downloadFromUrl(url);
        })
    }

    const filterByEmailSuffix = (exportsList, suffix) => {
        const userEmail = userData.email||'';
        const filteredExportsList = exportsList.filter(item => {
            const email = item.userEmail || '';
            return  !email.endsWith(suffix);
        });

       return  userEmail.endsWith(suffix)?exportsList:filteredExportsList
    };

    const headers = ['userName', 'createdAt', 'status'];

    return (
        <div>
            <div className='c-my-records__flex-header'>
                <div className='c-my-records__button-container'>
                    <div className='u-flex'>
                        <h1>{t('modules.pos_export.my_exports')}</h1>
                        <div className='c-rec-panel__counter'>
                            <span>
                                {!!size(posExports) && size(posExports) || 0}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ui-table table">
                <div className="ui-table__wrapper table_ui">
                    <div className="ui-table__header-container ">
                        <div
                            className="c-rec-table__table-holder table_header_wrapper"
                        >
                            <div className="table_wrapper_header">
                                <table className="ui-table__top-header -dark">
                                    <thead>
                                        <tr>
                                            <th className="ui-table__head-cell">
                                                <div className="">
                                                    {t('modules.pos_export.headers.user')}
                                                </div>
                                            </th>
                                            <th className="ui-table__head-cell">
                                                <div className="">
                                                    {t('modules.pos_export.headers.date')}
                                                </div>
                                            </th>
                                            <th className="ui-table__head-cell">
                                                <div className="">
                                                    {t('modules.pos_export.headers.status')}
                                                </div>
                                            </th>
                                            <th className="ui-table__head-cell">
                                                <div className="">
                                                    {t('modules.pos_export.headers.download')}
                                                </div>
                                            </th>
                                            <th className="ui-table__head-cell download-cell"></th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="c-rec-table">
                        <div
                            className="c-rec-table__table-holder c-rec-table__main-table"
                            style={{ minHeight: 100 }}
                        >
                            <table>
                                <tbody>
                                    {filterByEmailSuffix(posExports,'@fabacus.com').map((el) => {
                                        const hashedEmail = el.userEmail ? md5(el.userEmail) : null;
                                        const cx = classnames('u-rounded o-media-object__rounded-image-div', {
                                            'u-hide': !hashedEmail
                                        });
                                        const img = `//www.gravatar.com/avatar/${hashedEmail}?s=50&d=identicon`;

                                        return (
                                            <tr key="ui-table__body-1">
                                                {toArray(headers).map((item) => (
                                                    <td className="ui-table__body-cell">
                                                        <div className="u-flex u-flex-align">
                                                            { item === 'userName' && (<div style={{ backgroundImage: `url(${img})` }} className={cx}></div>)}
                                                            {displayItem(item, el)}
                                                        </div>
                                                    </td>
                                                ))}
                                                <td className="ui-table__body-cell">
                                                    <div className="u-flex u-flex-align">
                                                        {el.sourceInfo.filename || ''}
                                                    </div>
                                                </td>
                                                <td className="ui-table__body-cell download-cell">
                                                    <div className="u-flex u-flex-align--center">
                                                        <Button
                                                            size={'small'}
                                                            type={'secondary'}
                                                            onClick={() => downloadFile(el.sourceInfo.filename)}>
                                                            {t('buttons.download')}
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {(!!error || !exports) && (<div className='empty-report'>
                <img src={emptyReport} />
                <p>{!!error ? t('modules.pos_export.error') : t('modules.pos_export.no_data')}</p>
                {!!error && (<Button
                    type="primary"
                    size="small"
                    onClick={() => getExports()}
                >
                    {t('buttons.refresh')}
                </Button>)}
            </div>)}

            {!!posExports && size(posExports) > 0 && (<DataViewPagination
                extraclass={'marg-t-40'}
                totalItems={!!meta && !!meta.totalResults && meta.totalResults || 0}
                fetchData={(params) => getExports(params)}
                pageLimit={pageSize}
                pageNum={page}
            />)}
        </div>
    )
}
