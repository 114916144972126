import React from 'react';
import { connect } from 'react-redux';
import Input from 'modules/UiKit/components/FormElements/Input';
import { ReactComponent as RecipientDetailsIcon } from 'src/js/components/static/Icon/svg-icons/recipient-details.svg';
import { useTranslation } from 'react-i18next';
import CustomSelect from 'modules/UiKit/components/FormElements/CustomSelect';
import { RECIPIENT_TYPES } from '../../Constants/TrademarkAuthorisationLetterFormConstants';

function RecipientDetails({
    recipientDetails,
    setRecipientDetails,
    handleInputChange,
    handleSelectChange,
    setRecipientType,
    recipientType
 }) {
    const { t } = useTranslation();

    return (
        <div className='form-wrapper company-info'>
            <div className='form-group-title'>
                <RecipientDetailsIcon></RecipientDetailsIcon>
                {t('toolkit.trademark_request_form.recipient_details')}
            </div>

            <div className={'form-item'}>
                <label className={'main-label'}>
                    Recipient Type
                </label>

                <div className='field-item-wrapper'>
                    <div className='extra-wrapper input-wrapper'>
                        <CustomSelect
                            name={'recipientType'}
                            values={RECIPIENT_TYPES}
                            value={recipientType || ''}
                            onChange={(selectedValue) => handleSelectChange(selectedValue, setRecipientType)}
                            allowEmpty={false}
                            fullVal={true}
                            selectplaceholder={t('toolkit.trademark_request_form.select_placeholder')}
                        />
                    </div>
                </div>
            </div>

            <div className={'form-item'}>
                <label className={'main-label'}>
                    {t('toolkit.trademark_request_form.recipients_name')}
                </label>

                <div className='field-item-wrapper'>
                    <div className='extra-wrapper input-wrapper'>
                        <Input
                            name="recipientsName"
                            value={recipientDetails && recipientDetails.recipientsName || ''}
                            onChange={(e) => handleInputChange(e, setRecipientDetails)}
                        />
                    </div>
                </div>
            </div>

            <div className={'form-item'}>
                <label className={'main-label'}>
                    {t('toolkit.trademark_request_form.company_name')}
                </label>

                <div className='field-item-wrapper'>
                    <div className='extra-wrapper input-wrapper'>
                        <Input
                            name="companyName"
                            value={recipientDetails && recipientDetails.companyName || ''}
                            onChange={(e) => handleInputChange(e, setRecipientDetails)}
                        />
                    </div>
                </div>
            </div>

            <div className={'form-item'}>
                <label className={'main-label'}>
                    {t('toolkit.trademark_request_form.company_address')}
                </label>

                <div className='field-item-wrapper'>
                    <div className='extra-wrapper input-wrapper'>
                        <Input
                            name="companyAddress"
                            value={recipientDetails && recipientDetails.companyAddress || ''}
                            onChange={(e) => handleInputChange(e, setRecipientDetails)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ xelacore, dispatch }) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(RecipientDetails);
