import React from 'react';
import size from 'lodash/size';
import get from 'lodash/get';
import { Auth, Tooltip } from 'src/js/components/static';
import { registerButton } from 'src/js/helpers/dataHelpers';
import { useTranslation } from 'react-i18next';

export default function RegistrationSyndication({ ...props }) {
    const { t } = useTranslation();
    const { type, data, dispatch, getProductData, editMode } = props;
    const registered = data.shared_to || get(data, 'record.registered_with');
    const {
        record: { conflicts_gtin_local, conflicts_mpn_local }
    } = data;
    const recordConflicts = !!(
        size(conflicts_gtin_local) + size(conflicts_mpn_local)
    );
    const validation = get(data, 'record.validations', []);
    const recordErrors = Array.isArray(validation)
        ? validation.some((el) => el.level === 'error')
        : false;

    const button = registerButton(
        t,
        recordErrors ||
            (recordConflicts && !data.record.conflicts_resolved_local) ||
            registered || editMode || (data.record.validation_version_id !== data.record.version_id),
        dispatch,
        [data.record],
        [data.record.record_id],
        getProductData
    );

    return (
        <div className="">
            {type !== 'catalog' && (
                <Auth restrictTo="licensee">
                    {!registered && recordErrors && !recordConflicts && (
                        <Tooltip button={button}>
                            {t('modules.product_detail.registration_syndication.fix_all_errors_and_conflicts')}
                        </Tooltip>
                    )}
                    {!registered &&
                        recordConflicts &&
                        !data.record.conflicts_resolved_local && (
                            <Tooltip button={button}>
                                {t('modules.product_detail.registration_syndication.fix_all_errors_and_conflicts')}
                            </Tooltip>
                        )}
                    {!recordErrors &&
                        (!recordConflicts ||
                            (recordConflicts &&
                                data.record.conflicts_resolved_local)) &&
                        button}
                </Auth>
            )}
        </div>
    );
}
