import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal } from 'src/js/actions/xelacore';
import { setUserState } from 'src/js/actions/userDetails';
import { helperSetUserStateForSaving } from 'src/js/actions/userSettings';
import Button from 'modules/UiKit/components/Button/Button';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import { withTranslation } from 'react-i18next';

class CatalogueProductEditModal extends Component {
    constructor(props) {
        super(props);

        const { dontShowRedirectModal = false } = this.props;
        this.state = {
            dontShowCheckbox: dontShowRedirectModal
        };
    }

    setCheckedValue = () => {
        return this.setState({
            dontShowCheckbox: !this.state.dontShowCheckbox
        });
    };

    setDontShowOptions() {
        const { dispatch, headerLayouts } = this.props;
        const my_records_custom_table_views = helperSetUserStateForSaving(
            headerLayouts
        );
        return dispatch(
            setUserState('settings', {
                state: {
                    settings: {
                        my_records_custom_table_views,
                        dontShowRedirectModal: true
                    }
                }
            })
        );
    }

    removeModal = () => {
        const { dispatch } = this.props;
        dispatch(hideModal());
    };

    async handleOpening() {
        const { openRecordForEditing, record_id } = this.props;
        const { dontShowCheckbox } = this.state;

        if (dontShowCheckbox) {
            await this.setDontShowOptions();
        }

        this.removeModal();
        openRecordForEditing(record_id);
    }

    render() {
        const { t } = this.props;

        return (
            <div className="u-full-width">
                <div className="u-color-black u-margin-bottom">
                    <div>
                        {t('modals.product_edit_modal.you_are_being_redirected')}
                    </div>
                    <div className="marg-t-20 u-flex u-flex-align--left">
                        <UiCheckbox
                            type="checkbox"
                            id="dontShow"
                            name="dontShow"
                            onChange={() => this.setCheckedValue()}
                            label={t('buttons.dont_show_again')}>

                        </UiCheckbox>
                    </div>
                </div>
                <div className="u-flex-gap u-flex-align--right">
                    <Button
                        type="secondary"
                        size="small"
                        onClick={() => this.removeModal()}>
                        {t('buttons.cancel')}
                    </Button>
                    <Button
                        type="secondary"
                        size="small"
                        onClick={() => this.handleOpening()}>
                        {t('buttons.confirm')}
                    </Button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        xelacore: {
            userSettings: {
                lists = [],
                headers = {},
                activeHeaders = '',
                headerLayouts = [],
                selectAll,
                dontShowRedirectModal
            } = {}
        } = {}
    } = state;
    return {
        lists,
        headers,
        activeHeaders,
        headerLayouts,
        selectAll,
        dontShowRedirectModal
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(CatalogueProductEditModal));
