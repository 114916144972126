import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getMainImage
} from 'helpers/dataHelpers';
import { conceptShowFields } from 'src/js/constants/opaConstants';
import classNames from 'classnames';
import conceptPlaceholder from 'statics/imgs/no-photo.svg';
import angleLeftIcon from 'statics/imgs/icons/angle_left_icon.svg';
import angleRightIcon from 'statics/imgs/icons/angle_right_icon.svg';
import ZoomableImageItem from 'components/BulkLinkConcepts/ZoomableImage';
import { Auth } from 'components/static';
import ImageWrapped from 'components/BulkLinkConcepts/ImageWrapped';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import size from 'lodash/size';
import { withTranslation } from 'react-i18next';

class BulkLinkConceptsConceptItemPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conceptAttributes: [],
            productChecked:false
        }
    }

    componentDidUpdate(prevProps) {
        const {currentConceptForPreview}= this.props;
        if(!!this.state.productChecked && currentConceptForPreview.xelacore_concept_id !== prevProps.currentConceptForPreview.xelacore_concept_id){
            this.setState({
                productChecked: false
            });
        }
    }

    arrayPaginate(array, pageSize, page) {
        return array.filter((item, index) => {
            return (index < pageSize * page) &&
                (index > (pageSize * (page - 1) - 1));
        });
    }

    getCurrentImageIndex(list, current) {
        current = current || list[0];
        return list.indexOf(current);
    }

    parseString(string) {
        return string.replace(/_/g, ' ');
    }

    // getConceptFields() {
    //     const {
    //         currentConceptForPreview
    //     } = this.props;

    //     fetchConceptFields(currentConceptForPreview.licensor_organisation_id).then(response => {
    //         this.setState({
    //             conceptAttributes: response.data
    //         })
    //     })
    // }

    toggleCheckbox(){
        const {productChecked} = this.state;
        this.setState({
            productChecked: !productChecked
        });
    }

    render() {
        const {
            currentConceptForPreview,
            licensor = false,
            conceptActiveImage,
            conceptChecked,
            goToImageArrow,
            setCurrentActiveImage,
            t
        } = this.props;

        const filterField = licensor ? 'forLicensor' : 'forLicensee';
        const conceptFields = conceptShowFields.filter(
            (field) => !!field[filterField]
        );

        return (
            <div className={'concept-details-preview'}>

                <div className={'concept-details-info'}>
                    <Auth restrictTo="licensee">
                        <div className={'concept-details-info-check'}>
                            <div className={'concept-check'}>
                                <UiCheckbox
                                    type="checkbox"
                                    checked={this.state.productChecked}
                                    onClick={(e)=>{
                                        this.toggleCheckbox(e)
                                    }}
                                    onChange={(e) => {
                                        conceptChecked(e, currentConceptForPreview);
                                    }}>

                                </UiCheckbox>
                            </div>
                        </div>
                    </Auth>
                    <div className={'concept-details-info-image'}>
                        {!conceptActiveImage && (

                            <ZoomableImageItem
                                imageUrl={getMainImage(currentConceptForPreview.images, 800, conceptPlaceholder)}
                                placeholderImage={conceptPlaceholder}
                                imageName={currentConceptForPreview.product_name}
                            ></ZoomableImageItem>
                        )}

                        {conceptActiveImage && (
                            <ZoomableImageItem
                                imageUrl={conceptActiveImage.thumb_url_800}
                                imageName={currentConceptForPreview.product_name}
                            ></ZoomableImageItem>
                        )}

                        {
                            (<div className={'image-gallery-control'}>
                                {this.getCurrentImageIndex(currentConceptForPreview.images, conceptActiveImage) > 0 && (

                                    <div
                                        className={'icon-holder  arrow-left'}
                                        onClick={() => {
                                            goToImageArrow('prev', currentConceptForPreview.images, conceptActiveImage, 'conceptActiveImage');
                                        }}
                                    >
                                        <img
                                            src={angleLeftIcon}
                                            alt={'prev'}
                                        />
                                    </div>
                                )}
                                {this.getCurrentImageIndex(currentConceptForPreview.images, conceptActiveImage) < currentConceptForPreview.images.length - 1 && (
                                    <div
                                        className={'icon-holder  arrow-right'}
                                        onClick={() => {
                                            goToImageArrow('next', currentConceptForPreview.images, conceptActiveImage, 'conceptActiveImage');
                                        }}
                                    >
                                        <img
                                            src={angleRightIcon}
                                            alt={'next'}
                                        />
                                    </div>)}
                            </div>)
                        }

                    </div>
                    <div className={'concept-details-info-name'}>
                        <h3>
                            {currentConceptForPreview.product_name}
                        </h3>
                    </div>
                    <div className={'concept-details-info-description'}>
                        <p>{currentConceptForPreview.product_description}</p>
                    </div>
                    <div className={'concept-details-info-details'}>

                        <div className={'product-info-table-group'}>
                            <div className={'product-info-table-group-body'}>
                                {
                                    conceptFields.map((conceptItem) => {
                                        if (['images', 'concept_description', 'product_name', 'product_description'].includes(conceptItem.field)) {
                                            return;
                                        }

                                        const productCategory = conceptItem.field === 'product_category' && !!currentConceptForPreview[conceptItem.subfield] && currentConceptForPreview[conceptItem.subfield].length > 0
                                        ? !!currentConceptForPreview[conceptItem.subfield] && currentConceptForPreview[conceptItem.subfield][0]
                                        : conceptItem.field === 'product_category' && !!currentConceptForPreview[conceptItem.field] ? currentConceptForPreview[conceptItem.field] : '';

                                        const productSubcategory = conceptItem.field === 'product_subcategory' && !!currentConceptForPreview[conceptItem.subfield] && currentConceptForPreview[conceptItem.subfield].length > 1
                                        ? !!currentConceptForPreview[conceptItem.subfield] && !!currentConceptForPreview[conceptItem.subfield][1] && currentConceptForPreview[conceptItem.subfield][1] : ''

                                        if ((conceptItem.field === 'mpn' && size(currentConceptForPreview[conceptItem.field]) > 0) || conceptItem.field !== 'mpn') {
                                            return (
                                                <div className={'child-field-item'}>
                                                    <b className={'child-label u-capitalize'}>
                                                        {t(`concept_headers.${conceptItem.field}`)}:
                                                    </b>

                                                    <span className={'child-data'}>
                                                        {
                                                            conceptItem.field === 'product_category'
                                                                ? productCategory
                                                                : conceptItem.field === 'product_subcategory'
                                                                    ? productSubcategory
                                                                    : currentConceptForPreview[conceptItem.field]
                                                        }
                                                    </span>
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'concept-details-image-list'}>

                    {currentConceptForPreview.images.length > 1 &&
                    <div className={'thumb-image-list'}>
                        {currentConceptForPreview.images.map((imageItem, index) => {
                            return (
                                <div
                                    className={classNames(
                                        'thumb-image',
                                        `${((conceptActiveImage === imageItem) ||
                                            (!conceptActiveImage && index === 0)) ?
                                            'active' : ''}`
                                    )}
                                    onClick={() => {
                                        setCurrentActiveImage(imageItem);
                                    }}
                                >
                                    <ImageWrapped
                                        imageUrl={imageItem.thumb_url_200}
                                        imageName={'concept thumb'}
                                    ></ImageWrapped>
                                </div>
                            );
                        })}
                    </div>

                    }
                </div>
            </div>
        );
    }
}

export default connect()(withTranslation()(BulkLinkConceptsConceptItemPreview));
