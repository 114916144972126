import React from 'react';
import { pluralise } from 'src/js/helpers/strings';
import classnames from 'classnames';
import { Auth } from 'src/js/components/static';

export const errorContent = (t, errors) => {
    if (errors === 0) return null;
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--red">
            <div>
                {t('table_row.common.this_record_contains')} <b>{errors}</b>{' '}
                {pluralise(t('table_row.common.error'), errors)}
                <br />
            </div>
        </div>
    );
};

export const warningContent = (t, warnings) => {
    if (warnings === 0) return null;
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--orange">
            <div>
                {t('table_row.common.this_record_contains')} <b>{warnings}</b>{' '}
                {pluralise(t('table_row.common.warning'), warnings)}
                <br />
            </div>
        </div>
    );
};

export const duplicateErrorContent = (t, conflicts = 0) => {
    if (!conflicts) return null;
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--duplicate-confirmed">
            <div>
                {t('table_row.common.fix_errors_before')}
            </div>
        </div>
    );
};

export const duplicateContent = (t, conflicts = 0) => {
    if (!conflicts) return null;
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--duplicate">
            <div>
                {t('table_row.common.in_conflict_with.in_conflict_with')} <b>{conflicts}</b> {t('table_row.common.in_conflict_with.other_product')} {' '}
                {pluralise(t('table_row.common.in_conflict_with.record'), conflicts)}. {t('table_row.common.in_conflict_with.conflict_needs_resolved')}
                {t('table_row.common.in_conflict_with.can_be_registered')}
            </div>
        </div>
    );
};

export const confirmedDuplicateContent = (t, conflicts = 0) => {
    if (!conflicts) return null;
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--duplicate-confirmed">
            <div>{t('table_row.common.conflicting_confirmed')}</div>
        </div>
    );
};

export const inErrorContent = (t) => {
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--duplicate">
            <div>{t('table_row.common.click_to_correct')}</div>
        </div>
    );
};

export const onGtinWaiver = (t) => {
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--duplicate-confirmed">
            <div>{t('table_row.common.product_is_exempted')}</div>
        </div>
    );
};

export const registeredContent = (t, register) => {
    if (!register) return;
    const cx = classnames('c-rec-table__tooltip', {
        'c-rec-table__tooltip--green': register === 'registered',
        'c-rec-table__tooltip--orange': register === 'partly registered',
        'c-rec-table__tooltip--grey': register === 'not registered'
    });

    return (
        <div className={cx}>
            <div>{t(`constants.registered_message.${register}`)}</div>
        </div>
    );
};

export const approvedContent = (t, status) => {
    const cx = classnames('c-rec-table__tooltip', {
        'c-rec-table__tooltip--green': status === 'approved',
        'c-rec-table__tooltip--orange': status === 'linked',
        'c-rec-table__tooltip--grey': status === 'nodata'
    });

    return (
        <div className={cx}>
            <div>
                <Auth restrictTo="licensor">
                    {t(`constants.approved_message.${status}.licensor`)}
                </Auth>
                <Auth restrictTo="licensee">
                    {t(`constants.approved_message.${status}.licensee`)}
                </Auth>
                <Auth restrictTo="agent">
                    {t(`constants.approved_message.${status}.licensor`)}
                </Auth>
            </div>
        </div>
    );
};
export const customTableContent = (t) => {
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--grey">
            <div>
                {t('table_row.common.product_is_exempted')}{' '}
            </div>
        </div>
    );
};

export const errorContentMessage = errorMessage => {
    if (!errorMessage) return null;

    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--red">
            <div>
                {errorMessage}
            </div>
        </div>
    );
};
