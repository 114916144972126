import React from 'react';
import { connect } from 'react-redux';
import Input from 'modules/UiKit/components/FormElements/Input';
import { ReactComponent as MainOffice } from 'src/js/components/static/Icon/svg-icons/main-office.svg';
import { ReactComponent as PrimaryContact } from 'src/js/components/static/Icon/svg-icons/primary-contact.svg';
import { ReactComponent as BusinessInfo } from 'src/js/components/static/Icon/svg-icons/business-info.svg';
import CustomSelect from 'modules/UiKit/components/FormElements/CustomSelect';
import { useTranslation } from 'react-i18next';

function ContactInfo({
                         requestType,
                         setRequestType,
                         businessInfo,
                         setBusinessInfo,
                         handleInputChange,
                         primaryContact,
                         setPrimaryContact,
                         xelacore
                     }) {
    const { t } = useTranslation();
    const { auth: { companyData = {} } = {} } = xelacore;

    const REQUEST_TYPES = [
        {
            label: 'For ' + companyData.name,
            value: 'licensee'
        },
        {
            label: 'For an authorized 3rd party',
            value: 'third_party'
        }
    ];

    return (
        <div className="form-wrapper get-started">
            <div className="form-item">
                <label className="main-label">
                    {t('toolkit.trademark_request_form.request_type')}
                </label>
                <div className="field-item-wrapper">
                    <div className="extra-wrapper select-wrapper">
                        <CustomSelect
                            name="licensor"
                            values={REQUEST_TYPES}
                            value={requestType}
                            onChange={setRequestType}
                            allowEmpty={false}
                            selectplaceholder={t('toolkit.trademark_request_form.select_placeholder')}
                        />
                    </div>
                </div>
            </div>

            {requestType && (
                <div>
                    <div className="form-group-title">
                        <BusinessInfo />
                        {t('toolkit.trademark_request_form.business_info')}
                    </div>

                    <div className="form-item">
                        <label className="main-label">
                            {t('toolkit.trademark_request_form.legal_company_name')}
                        </label>
                        <div className="field-item-wrapper">
                            <div className="extra-wrapper input-wrapper">
                                <Input
                                    name="legalCompanyName"
                                    value={(businessInfo && businessInfo.legalCompanyName) || ''}
                                    onChange={(e) => handleInputChange(e, setBusinessInfo)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-item">
                        <label className="main-label">
                            {t('toolkit.trademark_request_form.company_registration_name')}
                        </label>
                        <div className="field-item-wrapper">
                            <div className="extra-wrapper input-wrapper">
                                <Input
                                    name="companyRegistrationNumber"
                                    value={(businessInfo && businessInfo.companyRegistrationNumber) || ''}
                                    onChange={(e) => handleInputChange(e, setBusinessInfo)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-item">
                        <label className="main-label">
                            {t('toolkit.trademark_request_form.vat')}
                        </label>
                        <div className="field-item-wrapper">
                            <div className="extra-wrapper input-wrapper">
                                <Input
                                    name="vat"
                                    value={(businessInfo && businessInfo.vat) || ''}
                                    onChange={(e) => handleInputChange(e, setBusinessInfo)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group-title">
                        <MainOffice />
                        {t('toolkit.trademark_request_form.main_office_address')}
                    </div>

                    <div className="form-item">
                        <label className="main-label">
                            {t('toolkit.trademark_request_form.street_address')}
                        </label>
                        <div className="field-item-wrapper">
                            <div className="extra-wrapper input-wrapper">
                                <Input
                                    name="streetAddress"
                                    value={(businessInfo && businessInfo.streetAddress) || ''}
                                    onChange={(e) => handleInputChange(e, setBusinessInfo)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-item">
                        <label className="main-label">
                            {t('toolkit.trademark_request_form.city')}
                        </label>
                        <div className="field-item-wrapper">
                            <div className="extra-wrapper input-wrapper">
                                <Input
                                    name="city"
                                    value={(businessInfo && businessInfo.city) || ''}
                                    onChange={(e) => handleInputChange(e, setBusinessInfo)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-item">
                        <label className="main-label">
                            {t('toolkit.trademark_request_form.region')}
                        </label>
                        <div className="field-item-wrapper">
                            <div className="extra-wrapper input-wrapper">
                                <Input
                                    name="region"
                                    value={(businessInfo && businessInfo.region) || ''}
                                    onChange={(e) => handleInputChange(e, setBusinessInfo)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-item">
                        <label className="main-label">
                            {t('toolkit.trademark_request_form.country')}
                        </label>
                        <div className="field-item-wrapper">
                            <div className="extra-wrapper input-wrapper">
                                <Input
                                    name="country"
                                    value={(businessInfo && businessInfo.country) || ''}
                                    onChange={(e) => handleInputChange(e, setBusinessInfo)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-item">
                        <label className="main-label">
                            {t('toolkit.trademark_request_form.postcode')}
                        </label>
                        <div className="field-item-wrapper">
                            <div className="extra-wrapper input-wrapper">
                                <Input
                                    name="postcode"
                                    value={(businessInfo && businessInfo.postcode) || ''}
                                    onChange={(e) => handleInputChange(e, setBusinessInfo)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group-title">
                        <PrimaryContact />
                        {t('toolkit.trademark_request_form.primary_contact')}
                    </div>

                    <div className="form-item">
                        <label className="main-label">
                            {t('toolkit.trademark_request_form.legal_name')}
                        </label>
                        <div className="field-item-wrapper">
                            <div className="extra-wrapper input-wrapper">
                                <Input
                                    name="legalName"
                                    value={(primaryContact && primaryContact.legalName) || ''}
                                    onChange={(e) => handleInputChange(e, setPrimaryContact)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-item">
                        <label className="main-label">
                            {t('toolkit.trademark_request_form.email')}
                        </label>
                        <div className="field-item-wrapper">
                            <div className="extra-wrapper input-wrapper">
                                <Input
                                    name="email"
                                    value={(primaryContact && primaryContact.email) || ''}
                                    onChange={(e) => handleInputChange(e, setPrimaryContact)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-item">
                        <label className="main-label">
                            {t('toolkit.trademark_request_form.phone_number')}
                        </label>
                        <div className="field-item-wrapper">
                            <div className="extra-wrapper input-wrapper">
                                <Input
                                    name="phoneNumber"
                                    value={(primaryContact && primaryContact.phoneNumber) || ''}
                                    onChange={(e) => handleInputChange(e, setPrimaryContact)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-item">
                        <label className="main-label">
                            {t('toolkit.trademark_request_form.job_title')}
                        </label>
                        <div className="field-item-wrapper">
                            <div className="extra-wrapper input-wrapper">
                                <Input
                                    name="jobTitle"
                                    value={(primaryContact && primaryContact.jobTitle) || ''}
                                    onChange={(e) => handleInputChange(e, setPrimaryContact)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = ({ xelacore, dispatch }) => ({
    xelacore,
    dispatch
});

export default connect(mapStateToProps)(ContactInfo);
