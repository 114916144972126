import React from 'react';
import UiButton from 'modules/UiKit/components/Button/Button';
import { useTranslation } from 'react-i18next';

const GenerateBox = ({
                         onGeneratePreview,
                         isLoading = false
                     }) => {
    const { t } = useTranslation();

    return (
        <div className="generate-box">
            <div className="generate-box__content">
                <h2 className="generate-box__title">
                    {t('toolkit.trademark_authorization.generate.title')}
                </h2>
                <p className="generate-box__description">
                    {t('toolkit.trademark_authorization.generate.description')}
                </p>
                <div className="generate-box__actions">
                    <UiButton
                        type="primary"
                        disabled={isLoading}
                        onClick={onGeneratePreview}
                        size="large"
                    >
                        {t('toolkit.trademark_authorization.generate.generate_draft')}
                    </UiButton>
                </div>
            </div>
        </div>
    );
};

export default GenerateBox;
