import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { numberCommas } from 'src/js/helpers/numbers';
import { Icon, Tooltip } from 'src/js/components/static';
import {
  displayNotification,
  removeIngestion
} from 'src/js/actions/xelacore';
import { withTranslation } from 'react-i18next';
class IngestBar extends Component {
  static tooltipTextBody = (t, key) => {
    if (!key) return;
    const tooltips = {
      saved:
        t('modules.my_records.ingest_bar.tooltips.saved'),
      accepted:
        t('modules.my_records.ingest_bar.tooltips.saved'),
      identical:
        t('modules.my_records.ingest_bar.tooltips.saved'),
      duplicate:
        t('modules.my_records.ingest_bar.tooltips.saved'),
      conflict:
        t('modules.my_records.ingest_bar.tooltips.saved'),
      rejected:
        t('modules.my_records.ingest_bar.tooltips.saved')
    };

    return <div className="c-tooltip__ingest">{tooltips[key]}</div>;
  };

  constructor(props) {
    super(props);

    this.state = {
      displayState: 'open',
      fileImportErrored: false,
      fileImportComplete: false,
      ingestId: '',
      recordsSubmitted: '-',
      recordsAccepted: '-',
      recordsRejected: '-',
      recordsConflicts: '-',
      errorMessage:
        'The ingest has errored. This is likely an issue with the GTIN, target market and language on your records. Please make sure these are valid and try again.'
    };
  }

  minimiseIngestBar(close) {
    if (close) {
      this.props.removeIngestion(this.props.ingestion.ingestionId)
    } else {
      this.setState({ displayState: 'minimised' })
    }
  }

  render() {
    const {
      errorMessage
    } = this.state;

    const { ingestion, t } = this.props
    const errored = ingestion.status === 'failed';
    const completed = ingestion.status === 'completed'
    const cx = classnames('c-ingest-bar', {
      'c-ingest-bar--errored': errored,
      'c-ingest-bar--complete': completed
    });

    const tooltipText = this.constructor.tooltipTextBody;
    if (this.state.displayState == 'closed' || this.state.displayState == 'minimised') return null;
    return (
      <div className={cx}>
        <div
          onClick={() => this.minimiseIngestBar(errored || completed)}
          className="c-ingest-bar__close-block"
        >
          <span className="c-ingest-bar__close-text">
            {(errored || completed) ? 'close' : 'minimise'}
          </span>
          <Icon
            icon={(errored || completed) ? 'close' : 'minimise'}
            size="15"
            fill="black"
          />
        </div>
        <div className="c-rec-panel__body">
          {ingestion.completedAt === null &&
            <p className={ingestion.status === 'failed' ? 'u-color-red' : ''}>
              {t('modules.my_records.ingest_bar.adding_records')} <b>{ingestion.label}</b>
            </p>
          }
          {ingestion.status === 'completed' &&
            <p className='u-color-green'>
              {t('modules.my_records.ingest_bar.completed')} <b>{ingestion.label}</b>
            </p>
          }
          {ingestion.status === 'failed' &&
            <p className='u-color-red'>
              {t('modules.my_records.ingest_bar.failed')} <b>{ingestion.label}</b>
            </p>
          }

          {// TODO: This needs to change
            ingestion.status === 'failed' && (
              <p className="u-color-red">{errorMessage}</p>
            )}
          <ul className="c-ingest-bar__results">
            <li>
              {t('modules.my_records.ingest_bar.file_status')}
            </li>
            <li>
              {t('modules.my_records.ingest_bar.submitted_records')}<b>{numberCommas(ingestion.recordsSubmitted)}</b>
            </li>
            <li className="u-color-green">
              {t('modules.my_records.ingest_bar.accepted_records')}
              <b>{numberCommas(Math.min(ingestion.recordsSavedPg, ingestion.recordsSavedEs))}</b>
              <Tooltip>{tooltipText(t, 'accepted')}</Tooltip>
            </li>
            <li className="u-color-red">
              <span>
                {t('modules.my_records.ingest_bar.conflict_records')}
                <b>{numberCommas(ingestion.recordsConflicted)}</b>
              </span>
              <Tooltip>{tooltipText(t, 'conflict')}</Tooltip>
            </li>
            <li className="u-color-red">
              <span>
                {t('modules.my_records.ingest_bar.rejected_records')}
                <b>{numberCommas(ingestion.recordsRejected)}</b>
              </span>
              <Tooltip>{tooltipText(t, 'rejected')}</Tooltip>
            </li>
          </ul>
          <div className="c-ingest-bar__blue-butt">
            {t('modules.my_records.ingest_bar.start_managing')}
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    displayNotification: (type, message, timeout) => dispatch(displayNotification({ type, message, timeout })),
    removeIngestion: (ingestionId) => dispatch(removeIngestion(ingestionId))
  };
}

export default connect(null, mapDispatchToProps)(withTranslation()(IngestBar));
