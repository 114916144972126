import React from 'react';
import classnames from 'classnames';
import ApprovalOverlayHeader from './ApprovalOverlayHeader';
import { Auth } from 'src/js/components/static';
import Button from 'components/UiKit/Button';
import { useTranslation } from 'react-i18next';

export default (props) => {
    const { t } = useTranslation();

    const {
        toggleSearchPanel,
        allConcepts,
        approvalData,
        opaDataMatch,
        productData,
        updateProductConcept,
        close,
        activeCode,
        search,
        searchItems
    } = props;

    const cCode = approvalData.xelacore_concept_id;
    const displayedConcepts = activeCode && !search
        ? allConcepts
        : search
            ? searchItems
            : opaDataMatch;

    const buttonText = () => {
        if ((displayedConcepts && displayedConcepts.length > 0 && !activeCode) || activeCode)
            return t('buttons.search');
        return t('buttons.back');
    };

    const linkConceptButton = (
        <Button
            onClick={() => {
                updateProductConcept();
                close();
            }}
        >
            {t('buttons.link_concept')}
        </Button>
    );

    const cx = classnames({
        'c-approval-overlay__pagination-confirm': !activeCode
    });

    const alreadyMatchedAndConfirmed =
        productData.concept_code_confirmed_info.some(
            (el) => el.xelacore_concept_id === cCode
        );

    return (
        <div className="c-approval-overlay__pagination">
            <Auth restrictTo="licensee">
                <div className="u-margin-right">
                    <Button onClick={() => toggleSearchPanel()}>
                        {buttonText()}
                    </Button>
                </div>
            </Auth>

            {alreadyMatchedAndConfirmed && !activeCode && (
                <div>
                    <span className="u-color-green u-bold">
                        {t('modules.product_detail.already_linked')}
                    </span>
                </div>
            )}

            {
                // We always show Link concept button if concept is not confirmed
                !alreadyMatchedAndConfirmed && (
                    <div className={cx}>{linkConceptButton}</div>
                )
            }

            <ApprovalOverlayHeader
                {...props}
                allConcepts={displayedConcepts}
            />
        </div>
    );
};
