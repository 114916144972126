import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from 'modules/UiKit/components/Button/Button';
import map from 'lodash/map';
import { getLicenseeLicensors, getTradeMarkLetters } from 'apicalls/other/tradeMark';
import TrademarkAuthorisationLetterForm from './Components/TrademarkAuthorisationLetterForm';
import TradeMarkTable from 'modules/Toolkit/TrademarkAuthorisationLetter/Components/TradeMarkTable';
import { useTranslation } from 'react-i18next';

function TrademarkAuthorisationLetter({
      xelacore
}) {
    const { t } = useTranslation();
    const [licensors, setLicensors] = useState([]);
    const orgId = xelacore.auth.companyData.organisation_id;
    const [tradeMarksArray, setTradeMarks] = useState([]);
    const [meta, setMeta] = useState({});
    const [trademarkFormView, setFormView] = useState(false);
    const [trademarkTableView, setTrademarkTableView] = useState(false);
    const [busy, setBusy] = useState(false);
    const [isCounting, setIsCounting] = useState(false);

    useEffect(() => {
        fetchLicensors();
    }, []);

    useEffect(() => {
        getTradeMarks({});
    }, []);

    const getTradeMarks = (params = {}) => {
        setBusy(true);
        getTradeMarkLetters({
            page: params.page || 1,
            limit: params.limit || 10000
        }).then((response) => {
            setTradeMarks((response && response.data) || []);
            setMeta((response && response.meta && response.meta.pagination) || {});
            setBusy(false);
        });
    };

    const fetchLicensors = () => {
        return getLicenseeLicensors(orgId).then(response => {
            setLicensors(map(response.data, el => {
                return {
                    label: el.name,
                    value: el.organisationId
                };
            }));

            localStorage.setItem('licensorsCount', response.data && response.data.length || 0);
        });
    };

    return (
        <div className='toolkit'>
            { ((!!trademarkFormView || (tradeMarksArray && tradeMarksArray.length > 0)) || busy || (tradeMarksArray.length === 0) ) && ( <div className='header'>
                <div className="u-flex-align">
                    <h2 className="u-margin-left">
                        {t('toolkit.toolkit_title')}
                    </h2>

                    {tradeMarksArray && tradeMarksArray.length > 0 && !trademarkFormView && (
                        <Button size={'small'}
                                type={'secondary'}
                                className='c-pointer u-margin-left'
                                onClick={() => {
                                    setFormView(true);
                                    setTrademarkTableView(false)
                                }}>
                            {t('toolkit.trademark_request_form.create_request')}
                        </Button>
                    )}
                </div>
            </div>)}

                { busy && !isCounting && (<div className='generate-letter'>
                    <div className='c-bulk-conflict-empty-placeholder u-margin-top-2x'>
                        <div className='loading'></div>
                        <div className='c-spinner'>
                            <div className='c-spinner-icon'>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <span className='c-spinner-label'></span>
                        </div>
                    </div>
                </div>)}

                    {!busy && (<div className='generate-letter'>
                {(!tradeMarksArray.length || trademarkFormView) && (
                    <TrademarkAuthorisationLetterForm
                        licensors={licensors}
                        trademarkTableView={trademarkTableView}
                        setFormView={setFormView}
                        getTradeMarks={(params) => getTradeMarks(params)}
                        setIsCounting={setIsCounting}
                        isCounting={isCounting}
                        trademarksExists={tradeMarksArray.length > 0}
                        setTrademarkTableView={setTrademarkTableView}>
                    </TrademarkAuthorisationLetterForm>
                )}

                {((tradeMarksArray && tradeMarksArray.length > 0 && !trademarkFormView) || trademarkTableView) && (
                    <TradeMarkTable
                        setFormView={() => setFormView(true)}
                        tradeMarksArray={tradeMarksArray}
                        licensors={licensors}
                        setTrademarkTableView={setTrademarkTableView}
                        getTradeMarks={(params) => getTradeMarks(params)}
                        meta={meta}
                    ></TradeMarkTable>
                )}
            </div>)}
        </div>
    )
}

const mapStateToProps = ({
                             xelacore,
                             dispatch
                         }) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(TrademarkAuthorisationLetter);

