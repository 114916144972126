import React from 'react';
import moment from 'moment';
import includes from 'lodash/includes';
import DatePicker from 'react-datepicker';
import { staticAttributesDates } from 'src/js/constants/productConstants/specialCases';
import { useTranslation } from 'react-i18next';

export default function Date({
    name,
    onChange,
    onFocus,
    onBlur,
    value,
    id,
    dates = {},
    isMultiple
}) {
    const { t } = useTranslation();
    const dateFormat = !!includes(staticAttributesDates, name) ? 'YYYY-MM-DD' : 'DD MMM YYYY'
    const handleChange = e => {
        const newDate = moment(e).format(dateFormat);
        return onChange(newDate, name);
    };

    const handleChangeRaw = e => e.preventDefault();
    const newValue = moment(value, dateFormat);

    return (
        <DatePicker
            name={name}
            id={id}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={e => handleChange(e)}
            onChangeRaw={e => handleChangeRaw(e)}
            selected={newValue.isValid() ? newValue.toDate() : null}
            placeholderText={isMultiple ? t('placeholders.multiple_values') : t('product_form_elements.date.please_select_date')}
            dateFormat={!!includes(staticAttributesDates, name) ? 'yyyy-MM-dd' : 'dd MMM yyyy'}
            minDate={dates.minDate}
            maxDate={dates.maxDate}
            showYearDropdown
            scrollableYearDropdown
            autoComplete={'off'}
        />
    );
}
