import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Auth, Icon } from 'components/static';
import BulkLinkConceptsConceptList from './components/ConceptList';
import BulkLinkConceptsConceptItemPreview from './components/ConceptItemPreview';
import classnames from 'classnames';
import UiButton from 'modules/UiKit/components/Button/Button';
import map from 'lodash/map';
import size from 'lodash/size';
import intersectionBy from 'lodash/intersectionBy';
import { withTranslation } from 'react-i18next';

class BulkLinkConceptsConceptSidebar extends Component {
    constructor(props) {
        super(props);
    }

    arrayPaginate(array, pageSize, page) {
        return array.filter((item, index) => {
            return (index < pageSize * page) &&
                (index > (pageSize * (page - 1) - 1));
        });
    }

    getCurrentImageIndex(list, current) {
        current = current || list[0];
        return list.indexOf(current);
    }

    render() {
        const {
            selectedProductGroup,
            filteredConceptItems,
            currentConceptForPreview,
            conceptActiveImage,
            groupIntersectionConcepts,
            approvedGroupConceptIntersection,
            recommendedConceptIds,
            recommendedConceptItems,
            viewSelectedConceptsOnly,
            selectedConceptsData,
            currentConceptPage,
            conceptPageSize,
            viewSelectedItems,
            linkConcepts,
            handleSearchConceptData,
            backToConceptList,
            handleSearchConcepts,
            selectedConcepts,
            conceptChecked,
            linkSingleConcept,
            unLinkSingleConcept,
            goToImageArrow,
            setCurrentActiveImage,
            openConceptDetails,
            singleProductData,
            totalConceptItems,
            currentConceptSearchTerm,
            conceptSearchTerm,
            updatePageHandler,
            goToConceptPreview,
            conceptInfoList,
            conceptInfoListFull,
            conceptInfoPreviewMode,
            licensor,
            data,
            t
        } = this.props;
        let fluidConceptInfoList = conceptInfoList;

        if (!!licensor) {
            fluidConceptInfoList = conceptInfoListFull.filter(item => groupIntersectionConcepts.includes(item.xelacore_concept_id)).map(item => item.xelacore_concept_id);
        }

        let conceptConfirmedInfoMap = !!selectedProductGroup && !!size(selectedProductGroup.list) && map(selectedProductGroup.list, 'concept_code_confirmed_info') || [];
        let confirmedInfoIntersection = !!conceptConfirmedInfoMap && map(intersectionBy(...conceptConfirmedInfoMap, 'xelacore_concept_id'), 'xelacore_concept_id') || [];

        return (
            <div
                className={classNames(
                    'bulk-concept-list-wrapper',
                    `${currentConceptForPreview ?
                        'preview-concept' : ''}`
                )}
            >

                <div
                    className={classNames(
                        'sidebar-header sidebar-header-concept'
                    )}
                >
                    <div className={'title'}>
                        {!currentConceptForPreview && (
                            <h2>
                                <b>{selectedProductGroup.name}</b> {t('concept_sidebar.concepts')}
                                {selectedConcepts.length > 0 && (
                                    <span>
                                        {!viewSelectedConceptsOnly && (
                                            <span
                                                className={'checked-concept-counter c-pointer'}
                                                onClick={() => {
                                                    viewSelectedItems();
                                                }}
                                            >
                                                {selectedConcepts.length} {t('concept_sidebar.concepts_selected')}
                                            </span>
                                        )}
                                        {viewSelectedConceptsOnly && (
                                            <span
                                                className={'checked-concept-counter c-pointer'}
                                                onClick={() => {
                                                    viewSelectedItems(true);
                                                }}
                                            >
                                                {t('buttons.view_all_concepts')}
                                            </span>
                                        )}
                                    </span>
                                )}
                            </h2>
                        )}

                        {!!currentConceptForPreview &&
                            !conceptInfoPreviewMode && (
                                <h2
                                    className={'return-to-list-button c-pointer'}
                                    onClick={() => {
                                        backToConceptList();
                                    }}
                                >
                                    <Icon
                                        fill="#000000"
                                        className={'marg-r-5'}
                                        icon="arrow_left_style_2"
                                        size="14"
                                    />
                                <span>{conceptInfoPreviewMode ? t('concept_sidebar.search') : t('concept_sidebar.return_to_concepts')}</span>
                                </h2>


                            )}
                        <Auth restrictTo="licensee">
                            {!!currentConceptForPreview &&
                                !!conceptInfoPreviewMode && (
                                    <UiButton
                                        type="secondary"
                                        size="small"
                                        onClick={() => {
                                            backToConceptList();
                                        }}
                                    >
                                        {t('buttons.search')}
                                    </UiButton>
                                )}
                        </Auth>

                        <Auth restrictTo="agent">
                            {!!currentConceptForPreview && !!currentConceptForPreview.approval_status && (
                                <div className="status-field">
                                    <label>{t('concept_sidebar.status')}:</label>
                                    <b
                                        className={classnames(
                                            {
                                                'green': currentConceptForPreview.approval_status === 'Fully Approved',
                                                'orange': currentConceptForPreview.approval_status !== 'Fully Approved'
                                            }
                                        )}
                                    >{currentConceptForPreview.approval_status}</b>
                                </div>
                            )}
                            {!!currentConceptForPreview && !!currentConceptForPreview.approval_stage && (
                                <div className="stage-field">
                                    <label>{t('concept_sidebar.stage')}:</label>
                                    <b>{currentConceptForPreview.approval_stage}</b>
                                </div>
                            )}
                        </Auth>


                        <Auth restrictTo="licensor">
                            {!!currentConceptForPreview && !!currentConceptForPreview.approval_status && (
                                <div className="status-field">
                                    <label>{t('concept_sidebar.status')}:</label>
                                    <b
                                        className={classnames(
                                            {
                                                'green': currentConceptForPreview.approval_status === 'Fully Approved',
                                                'orange': currentConceptForPreview.approval_status !== 'Fully Approved'
                                            }
                                        )}
                                    >{currentConceptForPreview.approval_status}</b>
                                </div>
                            )}
                            {!!currentConceptForPreview && !!currentConceptForPreview.approval_stage && (
                                <div className="stage-field">
                                    <label>{t('concept_sidebar.stage')}:</label>
                                    <b>{currentConceptForPreview.approval_stage}</b>
                                </div>
                            )}
                        </Auth>
                    </div>
                    <div className={'actions'}>

                        {(!!currentConceptForPreview && !!singleProductData) &&
                            (fluidConceptInfoList && fluidConceptInfoList.length > 1 && !!fluidConceptInfoList.includes(currentConceptForPreview.xelacore_concept_id)) &&
                            conceptInfoPreviewMode && (
                                <div className={'linked-concept-carousel'}>

                                    <UiButton
                                        type="secondary"
                                        size="small"
                                        disabled={fluidConceptInfoList.indexOf(currentConceptForPreview.xelacore_concept_id) < 1}
                                        onClick={() => {
                                            goToConceptPreview(fluidConceptInfoList[fluidConceptInfoList.indexOf(currentConceptForPreview.xelacore_concept_id) - 1]);
                                        }}
                                    >
                                    {t('buttons.prev')}
                                    </UiButton>
                                    <UiButton
                                        type="secondary"
                                        size="small"
                                        disabled={fluidConceptInfoList.indexOf(currentConceptForPreview.xelacore_concept_id) > fluidConceptInfoList.length - 2}
                                        onClick={() => {
                                            goToConceptPreview(fluidConceptInfoList[fluidConceptInfoList.indexOf(currentConceptForPreview.xelacore_concept_id) + 1]);
                                        }
                                        }
                                    >
                                    {t('buttons.next')}
                                    </UiButton>
                                </div>)}


                        <Auth restrictTo="licensee">
                            {!currentConceptForPreview && (
                                <UiButton
                                    type="secondary"
                                    size="small"
                                    disabled={selectedConcepts.length < 1}
                                    onClick={() => {
                                        linkConcepts();
                                    }}
                                    iconLeft={<Icon fill="black" icon="link" size="14" />}
                                >
                                    {t('buttons.link_concept')}
                                </UiButton>
                            )}

                            {!!currentConceptForPreview &&
                                !confirmedInfoIntersection.includes(currentConceptForPreview.xelacore_concept_id) && (
                                    <UiButton
                                        type="secondary"
                                        size="small"
                                        onClick={() => {
                                            linkSingleConcept(currentConceptForPreview);
                                        }}
                                        iconLeft={<Icon fill="black" icon="link" size="14" />}
                                    >
                                    {t('buttons.link_concept')}
                                    </UiButton>
                                )}
                            {(!!currentConceptForPreview && !!singleProductData) && !!confirmedInfoIntersection.includes(currentConceptForPreview.xelacore_concept_id) && (
                                    <UiButton
                                        type="secondary"
                                        size="small"
                                        onClick={() => {
                                            unLinkSingleConcept(currentConceptForPreview);
                                        }}
                                        iconLeft={<Icon fill="black" icon="link" size="14" />}
                                    >
                                    {t('buttons.unlink_concept')}
                                    </UiButton>
                                )}
                        </Auth>
                    </div>
                    {(!currentConceptForPreview && !viewSelectedConceptsOnly) && (
                        <div className={'search-wrapper'}>

                            <form
                                className={'input-wrapper'}
                                onReset={() => {
                                    handleSearchConceptData(true);
                                }}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSearchConceptData();
                                }}
                            >
                                <a className={'input-icon'}>
                                    <Icon
                                        onClick={() => {
                                            handleSearchConceptData();
                                        }}
                                        fill="black" icon="search" size="14"
                                    />
                                </a>
                                <input
                                    className={'search-input'}
                                    type="text"
                                    defaultValue={conceptSearchTerm}
                                    onChange={(e) => {
                                        handleSearchConcepts(e);
                                    }}
                                    placeholder={t('placeholders.search')}
                                />
                                {conceptSearchTerm.length > 0 && (
                                    <input
                                        className={'search-reset-action'}
                                        type="reset"
                                        value={t('buttons.clear_search')}
                                    />
                                )}
                            </form>

                        </div>
                    )}
                </div>

                <div className={'sidebar-body sidebar-body-concept'}>


                    {!currentConceptForPreview && (
                        <BulkLinkConceptsConceptList
                            selectedConcepts={selectedConcepts}
                            filteredConceptItems={filteredConceptItems}
                            groupIntersectionConcepts={groupIntersectionConcepts}
                            recommendedConceptIds={recommendedConceptIds}
                            recommendedConceptItems={recommendedConceptItems || []}
                            approvedGroupConceptIntersection={approvedGroupConceptIntersection}
                            currentConceptPage={currentConceptPage}
                            conceptPageSize={conceptPageSize}
                            openConceptDetails={openConceptDetails}
                            conceptChecked={conceptChecked}
                            updatePageHandler={updatePageHandler}
                            totalConceptItems={totalConceptItems}
                            currentConceptSearchTerm={currentConceptSearchTerm}
                            viewSelectedConceptsOnly={viewSelectedConceptsOnly}
                            selectedConceptsData={selectedConceptsData}
                            singleProductData={singleProductData}
                            data={data}
                            selectedProductGroup={selectedProductGroup}
                        >
                        </BulkLinkConceptsConceptList>
                    )}
                    {!!currentConceptForPreview && (
                        <BulkLinkConceptsConceptItemPreview
                            recommendedConceptIds={recommendedConceptIds}
                            currentConceptForPreview={currentConceptForPreview}
                            conceptActiveImage={conceptActiveImage}
                            conceptChecked={conceptChecked}
                            goToImageArrow={goToImageArrow}
                            setCurrentActiveImage={setCurrentActiveImage}
                            singleProductData={singleProductData}
                        ></BulkLinkConceptsConceptItemPreview>
                    )}
                </div>
            </div>);
    }
}

export default connect()(withTranslation()(BulkLinkConceptsConceptSidebar));
