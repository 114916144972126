import React from 'react';
import classnames from 'classnames';
import findIndex from 'lodash/findIndex';
import { Icon, Auth } from 'src/js/components/static';
import MediaView from 'src/js/components/MediaView';
import ProductSmallImages from './ProductSmallImages';
import fallbackImageBig from 'statics/imgs/fallback_img_big.jpg';
import imgPinkSpinner from 'statics/imgs/desktop/pink-spinner.gif';
import Button from 'modules/UiKit/components/Button/Button';
import ProductImageGalleryDropper
    from 'src/js/modules/Product/ProductDetail/Components/ProductDetailTab/ProductImages/ProductImageGalleryDropper';
import { useTranslation } from 'react-i18next';

const ProductImagesDisplay = ({ ...props }) => {
    const { t } = useTranslation();

    const {
        type,
        activeImage,
        images,
        setMainImage,
        confirmRemoveImage,
        setActiveImage,
        isPrimaryImage,
        filesTotalToUploadCount,
        filesLeftToUploadCount,
        showCarouselModal,
        dispatch,
        data,
        bulkImagesUpload,
        parentActionCallback
    } = props;

    const activeIndex = findIndex(
        images,
        image => image.image_id === activeImage.image_id
    );
    const imageSrc = images.length && images[activeIndex]
        ? images[activeIndex].thumb_url_800 || images[activeIndex].local_url || images[activeIndex].original_url
        : null;
    const jsxSetPrimaryImageButton = (
        <Button
            type="secondary"
            size="small"
            submit
            onClick={() => setMainImage()}
            iconLeft={<Icon icon="tick" />}
        >
            {t('buttons.set_as_main')}
        </Button>
    );

    return (
        <div className="c-product__image-section">
            <h3 className="c-product__header c-product__header--block">
                {t('modules.product_detail.product_images.product_images')}
            </h3>
            <div className="c-product__selected-image">
                {type !== 'catalog' && (
                    <Auth
                        restrictTo="licensee"
                        permission="registration.manage"
                    >
                        {images.length > 0 && (
                            <div className="c-product__change-image u-flex-gap">
                                {isPrimaryImage
                                    ? activeIndex !== 0 &&
                                    jsxSetPrimaryImageButton
                                    : jsxSetPrimaryImageButton}
                                {
                                    <Button
                                        type="danger"
                                        size="small"
                                        onClick={() => confirmRemoveImage()}
                                    >
                                        <Icon icon="bin" fill="white" /> {t('buttons.remove')}
                                    </Button>
                                }
                            </div>
                        )}
                    </Auth>
                )}

                <div className="c-product__selected-image-div">
                    {filesLeftToUploadCount > 0 && filesTotalToUploadCount > 0 && (
                        <div className="c-product__selected-image__uploading">
                            <p>{t('modules.product_detail.product_images.uploading_images')}</p>
                            <h5>
                                <img
                                    height="20"
                                    src={imgPinkSpinner}
                                    alt="Uploading images..."
                                />{' '}
                                {filesLeftToUploadCount} of{' '}
                                {filesTotalToUploadCount}
                            </h5>
                            <p className="c-product__selected-image__uploading--info">
                                {t('modules.product_detail.product_images.uploading_images')}
                            </p>
                        </div>
                    )}

                    {imageSrc && (
                        <MediaView
                            src={imageSrc}
                            className="c-product__dived-img"
                            fallbackSrc={fallbackImageBig}
                            useImgTag
                            onClick={() => showCarouselModal()}
                        />
                    )}

                    {!imageSrc && (
                        <MediaView
                            src={fallbackImageBig}
                            className="c-product__dived-img"
                            useImgTag
                        />
                    )}
                </div>
            </div>
            <div className="c-product__image-list">
                <ul>
                    {images
                        .filter(f => f !== null)
                        .map((e, i) => {
                            const smallImageSrc =
                                e && (e.thumb_url_200 || e.local_url);
                            const originalImageSrc = e.original_url;
                            const cx = classnames(
                                'c-product__image-list-item',
                                {
                                    'c-product__image-list-item--active':
                                        activeIndex === i
                                }
                            );

                            return (
                                <li
                                    key={`${e}-${i}`}
                                    className={cx}
                                    onClick={() => setActiveImage(e)}
                                >
                                    <ProductSmallImages
                                        smallImageSrc={smallImageSrc || originalImageSrc}
                                    />
                                </li>
                            );
                        })}
                </ul>
            </div>

            <ProductImageGalleryDropper
                filesTotalToUploadCount={filesTotalToUploadCount}
                dispatch={dispatch}
                bulkImagesUpload={bulkImagesUpload}
                parentActionCallback={parentActionCallback}
                data={data}
            ></ProductImageGalleryDropper>

        </div>
    );
};

export default ProductImagesDisplay;
