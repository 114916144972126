import config from 'config';
export const USER_TOKEN_COOKIE = 'user_token';
export const USER_REFRESH_TOKEN_COOKIE = 'user_refresh_token';
export const XELACORE_DOMAIN = config.cookieDomain;
export const ORG_ID_COOKIE = 'organisation_id';
export const APP_INIT = 'XELACORE_CORE::APP_INIT';
export const USER_DATA = 'XELACORE_CORE::USER_DATA';
export const USER_PERMISSIONS = 'XELACORE_CORE::USER_PERMISSIONS';
export const USER_ORGANISATIONS = 'XELACORE_CORE::USER_ORGANISATIONS';
export const CUSTOM_REPORTS = 'XELACORE_CORE::CUSTOM_REPORTS';
export const POS_EXPORT_CONFIG = 'XELACORE_CORE::POS_EXPORT_CONFIG';
export const USER_REFRESH_TOKEN = 'XELACORE_CORE::USER_REFRESH_TOKEN';
export const SET_USER_SETTINGS = 'XELACORE_CORE::SET_USER_SETTINGS';
export const REMOVE_USER_SETTINGS = 'XELACORE_CORE::REMOVE_USER_SETTINGS';
export const SET_MY_RECORDS = 'XELACORE_CORE::SET_MY_RECORDS';
export const SET_SOCKET_NOTIFICATION = 'XELACORE_CORE::SET_SOCKET_NOTIFICATION';
export const REMOVE_MY_RECORDS = 'XELACORE_CORE::REMOVE_MY_RECORDS';
export const SET_PRODUCT_CATALOGUE = 'XELACORE_CORE::SET_PRODUCT_CATALOGUE';
export const SET_QUARANTINE = 'XELACORE_CORE::SET_QUARANTINE';
export const REMOVE_PRODUCT_CATALOGUE =
    'XELACORE_CORE::REMOVE_PRODUCT_CATALOGUE';
export const SET_INGESTIONS = 'XELACORE_CORE::SET_INGESTIONS';
export const UPDATE_INGESTIONS = 'XELACORE_CORE::UPDATE_INGESTIONS';
export const REMOVE_INGESTION = 'XELACORE_CORE::REMOVE_INGESTION';
export const REMOVE_INGESTIONS = 'XELACORE_CORE::REMOVE_INGESTIONS';
export const LOGIN_REQUEST = 'XELACORE_CORE::LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'XELACORE_CORE::LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'XELACORE_CORE::LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'XELACORE_CORE::LOGOUT_SUCCESS';
export const LOGOUT = 'XELACORE_CORE::LOGOUT';
export const COMPANY_DATA = 'XELACORE_CORE::COMPANY_DATA';
export const CLIENT_CONF = 'XELACORE_CORE::CLIENT_CONF';
export const MODAL_SHOW = 'XELACORE_CORE::MODAL_SHOW';
export const MODAL_HIDE = 'XELACORE_CORE::MODAL_HIDE';
export const DISPLAY_NOTIFICATION = 'XELACORE_CORE::DISPLAY_NOTIFICATION';
export const DESTROY_NOTIFICATION = 'XELACORE_CORE::DESTROY_NOTIFICATION';
export const SET_PAGE_NOTIFICATION = 'XELACORE_CORE::SET_PAGE_NOTIFICATION';
export const DESTROY_PAGE_NOTIFICATION =
    'XELACORE_CORE::DESTROY_PAGE_NOTIFICATION';
export const NOTIFICATION_SHOW = 'XELACORE_CORE::NOTIFICATION_SHOW';
export const NOTIFICATION_HIDE = 'XELACORE_CORE::NOTIFICATION_HIDE';
export const SET_SCHEMAS = 'XELACORE_CORE::SET_SCHEMAS';
export const SET_USERS_LIST = 'XELACORE_CORE::SET_USERS_LIST';
export const REMOVE_USERS_LIST = 'XELACORE_CORE::REMOVE_USERS_LIST';
export const SET_USER_PERMISSIONS = 'XELACORE_CORE::SET_USER_PERMISSIONS';
export const SET_CATEGORIES = 'XELACORE_CORE::SET_CATEGORIES';
export const SET_LINKED_BRANDS = 'XELACORE_CORE::SET_LINKED_BRANDS';
export const SET_BRANDS = 'XELACORE_CORE::SET_BRANDS';
export const REMOVE_CATEGORIES = 'XELACORE_CORE::REMOVE_CATEGORIES';
export const LOCK_BODY = 'XELACORE_CORE::LOCK_BODY';
export const UNLOCK_BODY = 'XELACORE_CORE::UNLOCK_BODY';
