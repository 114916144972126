import React, { Component } from 'react';
import { Tooltip } from 'src/js/components/static';
import { checkSetUrl } from 'src/js/helpers/dataHelpers';
import BulkImageUploadPanel from 'src/js/components/BulkImageUploadPanel/BulkImageUploadPanel';
import Button from 'modules/UiKit/components/Button/Button';
import { withTranslation } from 'react-i18next';
import { MAX_IMAGES_PER_RECORD } from 'src/js/constants/imagesConstants';

class ProductImagesUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUploader: false
        };
    }

    ref(instance) {
        this._instance = instance;
        return instance;
    }

    setValue() {
        const { newImageUrl = '' } = this.props;
        const defaultUrlVal = 'https://';
        const len = newImageUrl.length;
        if (len > 0) {
            const uxTry = len < 15 && newImageUrl.indexOf('.') === -1;
            if (
                newImageUrl.indexOf('http://') > -1 ||
                newImageUrl.indexOf('https://') > -1 ||
                uxTry
            )
                return newImageUrl;
            return checkSetUrl(defaultUrlVal + newImageUrl);
        }
        return newImageUrl;
    }

    disableScrolling() {
        this.scrollX = window.scrollX;
        this.scrollY = window.scrollY;
        window.onscroll = () => {
            window.scrollTo(this.scrollX, this.scrollY);
        };
    }

    enableScrolling() {
        window.onscroll = null;
    }

    componentWillUnmount() {
        this.enableScrolling();
    }

    toggleUploader(val = null) {
        this.props.parentActionCallback('endDrag');
        return this.setState({
            showUploader: val !== null ? val : !this.state.showUploader
        });
    }

    uploadButton() {
        const {
            data,
            t
        } = this.props;

        return (<div>
            <Button
                size={'small'}
                type={'secondary'}
                disabled={data.images && data.images.length >= MAX_IMAGES_PER_RECORD}
                onClick={() => this.toggleUploader()}>
                {t('buttons.upload_images')}
            </Button>
        </div>);
    }

    submitButton() {
        const {
            data,
            t
        } = this.props;

        return (
            <div>
                <Button
                    size={'small'}
                    type={'secondary'}
                    disabled={data.images && data.images.length >= MAX_IMAGES_PER_RECORD}
                    onClick={() => {
                    }}>
                    {t('buttons.submit')}
                </Button>
            </div>
        );
    }

    render() {
        const {
            checkImage,
            handleChange,
            dispatch,
            data,
            bulkImagesUpload,
            filesTotalToUploadCount,
            t
        } = this.props;
        const { showUploader } = this.state;

        showUploader ? this.disableScrolling() : this.enableScrolling();
        const uploadButton = this.uploadButton();
        const submitButton = this.submitButton();

        return (
            <div className="c-product__image-upload">
                <div className="u-flex-align u-flex-1">
                    <div className="c-product__image-upload-button">
                        {data.images && data.images.length >= MAX_IMAGES_PER_RECORD && (
                            <Tooltip
                                button={uploadButton}>
                                {t('bulk_images_upload.you_can_upload.images_limit')}
                            </Tooltip>
                        )}

                        {data.images && data.images.length < MAX_IMAGES_PER_RECORD && (
                            <div>
                                <Button
                                    size={'small'}
                                    type={'secondary'}
                                    disabled={data.images && data.images.length >= MAX_IMAGES_PER_RECORD}
                                    onClick={() => this.toggleUploader()}>
                                    {t('buttons.upload_images')}
                                </Button>
                            </div>
                        )}
                    </div>
                    <span className="c-product__upload-spacer">{t('modules.product_detail.product_images.or')}</span>
                    <div className="c-product__image-upload-box">
                        <input
                            id="image-upload"
                            value={this.setValue()}
                            type="text"
                            disabled={data.images && data.images.length >= MAX_IMAGES_PER_RECORD}
                            onChange={e => handleChange(e)}
                            placeholder={t('modules.product_detail.product_images.paste_image_link')}
                        />

                        {data.images && data.images.length >= MAX_IMAGES_PER_RECORD && (
                            <Tooltip
                                button={submitButton}>
                                {t('bulk_images_upload.you_can_upload.images_limit')}
                            </Tooltip>
                        )}

                        {data.images && data.images.length < MAX_IMAGES_PER_RECORD && (
                            <Button
                                size={'small'}
                                type={'secondary'}
                                disabled={data.images && data.images.length >= MAX_IMAGES_PER_RECORD}
                                onClick={e => checkImage(e)}>{t('buttons.submit')}
                            </Button>
                        )}
                    </div>

                    {data.images && data.images.length < MAX_IMAGES_PER_RECORD && (
                        <Tooltip>
                            {t('modules.product_detail.product_images.upload_images_from_local')}
                        </Tooltip>
                    )}
                </div>

                {showUploader && (
                    <div className="images-modal__bulkimagebox">
                        <BulkImageUploadPanel
                            toggleDataFormat={() => this.toggleDataFormat()}
                            close={() => this.toggleUploader()}
                            dispatch={dispatch}
                            data={data}
                            bulkImagesUpload={bulkImagesUpload}
                            filesTotalToUploadCount={filesTotalToUploadCount}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(ProductImagesUploader);
