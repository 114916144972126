import React, { Component } from 'react';
import { Tooltip } from 'src/js/components/static';
import { checkSetUrl } from 'src/js/helpers/dataHelpers';
import BulkImageUploadPanel from 'src/js/components/BulkImageUploadPanel/BulkImageUploadPanel';
import Button from 'modules/UiKit/components/Button/Button';
import { withTranslation } from 'react-i18next';

class ProductImagesUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUploader: false
        };
    }

    ref(instance) {
        this._instance = instance;
        return instance;
    }

    setValue() {
        const { newImageUrl = '' } = this.props;
        const defaultUrlVal = 'https://';
        const len = newImageUrl.length;
        if (len > 0) {
            const uxTry = len < 15 && newImageUrl.indexOf('.') === -1;
            if (
                newImageUrl.indexOf('http://') > -1 ||
                newImageUrl.indexOf('https://') > -1 ||
                uxTry
            )
                return newImageUrl;
            return checkSetUrl(defaultUrlVal + newImageUrl);
        }
        return newImageUrl;
    }

    disableScrolling() {
        var x = window.scrollX;
        var y = window.scrollY;
        window.onscroll = function() {
            window.scrollTo(x, y);
        };
    }

    enableScrolling() {
        window.onscroll = function() {};
    }
    toggleUploader(val = null) {
        this.props.parentActionCallback('endDrag');
        return this.setState({
            showUploader: val !== null ? val : !this.state.showUploader
        });
    }

    render() {
        const {
            checkImage,
            handleChange,
            dispatch,
            data,
            bulkImagesUpload,
            filesTotalToUploadCount,
            t
        } = this.props;
        const { showUploader } = this.state;

        showUploader ? this.disableScrolling(): this.enableScrolling();

        return (
            <div className="c-product__image-upload">
                <div className="u-flex-align u-flex-1">
                    <div className="c-product__image-upload-button">
                        <Button
                            size={'small'}
                            type={'secondary'}
                            onClick={() => this.toggleUploader()}>
                            {t('buttons.upload_images')}
                        </Button>
                    </div>
                    <span className="c-product__upload-spacer">{t('modules.product_detail.product_images.or')}</span>
                    <div className="c-product__image-upload-box">
                        <input
                            id="image-upload"
                            value={this.setValue()}
                            type="text"
                            onChange={e => handleChange(e)}
                            placeholder={t('modules.product_detail.product_images.paste_image_link')}
                        />
                        <Button
                            size={'small'}
                            type={'secondary'}
                            onClick={e => checkImage(e)}>{t('buttons.submit')}</Button>
                    </div>

                    <Tooltip>
                        {t('modules.product_detail.product_images.upload_images_from_local')}
                    </Tooltip>
                </div>

                {showUploader && (
                    <div className="images-modal__bulkimagebox">
                        <BulkImageUploadPanel
                            toggleDataFormat={() => this.toggleDataFormat()}
                            close={() => this.toggleUploader()}
                            dispatch={dispatch}
                            data={data}
                            bulkImagesUpload={bulkImagesUpload}
                            filesTotalToUploadCount={filesTotalToUploadCount}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(ProductImagesUploader)