import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import size from 'lodash/size';

import { Modal, Auth, Icon } from 'src/js/components/static';
import {
    searchableFileds,
    searchHeaders
} from 'src/js/constants/advancedFilteringConstants';
import ProductCatalogueTableRow from './ProductCatalogueTableRow';

import { isLicensor } from 'src/js/helpers/permissions';
import { headers as defaultHeaders } from 'src/js/constants/productConstants/headers';
import TableRowSortingIcons from 'src/js/components/TableRow/TableRowSortingIcons';
import SearchItems from 'src/js/components/AdvancedFiltering/TableSearchItems/SearchItems';
import HeaderSearchItem from 'src/js/components/AdvancedFiltering/TableSearchItems/HeaderSearchItem';
import { getFilteredFields } from 'src/js/helpers/dataHelpers';
import { ReactComponent as BandAidIcon } from 'src/js/components/static/Icon/svg-icons/band-aid.svg';
import { map } from 'lodash';

import { selectAll } from 'src/js/actions/productsFetch';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import Conflicts from 'modules/MyRecords/routes/MyRecords/MyRecordsTable/Conflicts/Conflicts.jsx';
import { showModal } from 'src/js/actions/xelacore';
import { isQuarantineRecord } from 'src/js/helpers/conflictsHelper';
import CustomSelect from 'modules/UiKit/components/FormElements/CustomSelect';
import uniq from 'lodash/uniq';
import differenceBy from 'lodash/differenceBy';
import { withTranslation } from 'react-i18next';

class ProductCatalogueTable extends Component {
    constructor(props) {
        super(props);

        this.type = 'catalog';
        this.table = React.createRef();
        this.header = React.createRef();
        this.scrollbar = React.createRef();

        // Need for more accurate scrolling
        // Creates a bit of a lag on initial load but is a necessary evil
        this.handleScroll = debounce(this.handleScroll, 1);

        this.state = {
            highlightRow: null,
            quarantineOpen: null,
            conflictsOpen: null,
            searchBox: false,
            tableWidth: 0,
            inputValue: ''
        };

        this.searchTable = React.createRef();
        this.mainTable = React.createRef();
        this.popoverRef = React.createRef();
        this.tableHeaderWrap = React.createRef();

        this.handleChangeSearch = this.handleChangeSearch.bind(this);
    }

    scrollHandler() {
        const distanceToBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
        const table = this.table.current;
        const rect = table.getBoundingClientRect();
        const tableDistanceToBottom = Math.max(0, document.documentElement.scrollHeight - (rect.bottom + window.pageYOffset));

        if (window.scrollY > 80) {
            document.getElementById('siteHeader').style.boxShadow = 'none';
            this.tableHeaderWrap.current.classList.add('sticky');
        } else {
            document.getElementById('siteHeader').style.boxShadow =
                '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
            this.tableHeaderWrap.current.classList.remove('sticky');
        }

        if (distanceToBottom < tableDistanceToBottom + 40) {
            this.scrollbar.current.classList.add('bottom_fixed');
        } else {
            this.scrollbar.current.classList.remove('bottom_fixed');
        }
    }

    scrollHandlerBound = this.scrollHandler.bind(this);

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        document.addEventListener('scroll', this.scrollHandlerBound);

        setTimeout(() => {
            this.setState({
                tableWidth: this.mainTable.current.offsetWidth
            });
        }, 1000);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
        document.removeEventListener('scroll', this.scrollHandlerBound);
        document.getElementById('siteHeader').style.boxShadow =
            '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
    }

    handleChangeSearch(event) {
        this.setState({ inputValue: event.target.value });
    }

    handleClickOutside = (event) => {
        if (this.popoverRef.current && !this.popoverRef.current.contains(event.target)) {
            this.setState({
                searchBox: null
            });
        }
    };

    openSearchBox(el) {
        const { searchBox } = this.state;
        const { searchItems } = this.props;

        let searchText = searchBox;

        if (
            (el && el.dataName === searchBox) ||
            (el && el.label === searchBox)
        ) {
            searchText = '';
        } else {
            searchText = el.dataName || el.label;
        }

        this.setState({
            searchBox: searchText,
            inputValue: find(searchItems, (item) => {
                return item && item.label === (el.dataName || el.label);
            })
        });
    }

    resolveConflicts(id, modalActions) {
        const { data = [], dispatch } = this.props;
        const rowData = data.find((el) => el.record_id === id);
        const body = (
            <Conflicts
                data={rowData}
                quarantineMode={isQuarantineRecord(rowData)}
                modalActions={modalActions}
                self={true}
                id={id}
            />
        );

        const contentOfTheModal = (
            <Modal
                scrolling
                isWide
                className="c-conflicts-record__modal" title="Resolve Conflicts" body={body}
            />
        );

        return dispatch(showModal(contentOfTheModal));
    }

    handleScroll(e, ref) {
        if (ref === 'table') {
            this.header.current.scrollTo(this.table.current.scrollLeft, 0);
            this.scrollbar.current.scrollTo(this.table.current.scrollLeft, 0);
        }

        if (ref === 'header') {
            this.table.current.scrollTo(this.header.current.scrollLeft, 0);
            this.scrollbar.current.scrollTo(this.header.current.scrollLeft, 0);
        }

        if (ref === 'scrollbar') {
            this.header.current.scrollTo(this.scrollbar.current.scrollLeft, 0);
            this.table.current.scrollTo(this.scrollbar.current.scrollLeft, 0);
        }
    }

    handleFormSubmit(e) {
        e.preventDefault();
    }

    handleCheckProduct(e, product) {
        this.props.handleCheckedList(e, product);
    }

    handleCheckAllProduct(e) {
        this.props.handleCheckAllProduct(e);
    }

    fetchCheckboxSelector(optionSelected) {
        const {
            selectedRows,
            dispatch,
            data
        } = this.props;
        const excludeFromThisPage = differenceBy(selectedRows, data, 'record_id');
        switch (optionSelected) {
            case 'all_on_page':
                return dispatch(
                    selectAll(
                        uniq([
                            ...selectedRows,
                            ...data
                        ]),
                        false
                    )
                );

            case 'clear_on_page':
                return dispatch(
                    selectAll(
                        uniq(excludeFromThisPage),
                        false,
                        uniq(excludeFromThisPage)
                    )
                );
            case 'clear_all':
                return dispatch(
                    selectAll(
                        [],
                        false
                    )
                );
        }
    }

    checkAllAction(selectedRows, allChecked, allIds) {
        const { dispatch, data } = this.props;

        const filteredCurrent =
            selectedRows.filter(
                (item) =>
                    !allIds.includes(
                        item.record_id
                    )
            );

        const selected =
            allChecked || selectedRows.filter((item) => allIds.includes(item.record_id)).length > 0
                ? filteredCurrent
                : [
                    ...filteredCurrent,
                    ...data
                ];

        dispatch(
            selectAll(
                uniq(selected),
                false
            )
        );
    }

    render() {
        const {
            data = [],
            headers,
            showUpload,
            selectedRows,
            sortData,
            users,
            sortedBy,
            dispatch,
            fetchData,
            fetching,
            companyData,
            searchItems,
            updateFiltersState,
            queryObject,
            basicFilterObj,
            isFiltersReturnedNoData,
            categoryLevels,
            previousRow,
            selected,
            ipLevels,
            xelacore,
            t
        } = this.props;

        const {
            highlightRow,
            quarantineOpen,
            isQuarantine,
            searchBox,
            inputValue,
            tableWidth
        } = this.state;
        const { list = [] } = headers;
        const licensor = isLicensor(companyData);
        const activeHeaders = getFilteredFields(licensor, list).filter(
            (f) => f.active
        );

        let ipIndex = findIndex(activeHeaders, item => item.name === 'ip_paths');
        let categoryIndex = findIndex(activeHeaders, item => item.name === 'licensee_category_path');

        const sortedHeaders = activeHeaders
            .map((e) => defaultHeaders.find((f) => f.dataName === e.name))
            .filter((f) => !!f && f.dataName !== 'additional' && f.dataName !== 'category_attributes');
        const allIds = map(data, 'record_id');
        const filteredSelectedData = selectedRows.filter((item) => allIds.includes(item.record_id));
        const allChecked = size(data) === size(filteredSelectedData);

        const checkSelectorOptionList = [
            {
                value: 'all_on_page',
                label: t('constants.product_constants.all_on_page')
            },
            {
                value: 'clear_on_page',
                label: t('constants.product_constants.clear_on_page')
            },
            {
                value: 'clear_all',
                label: t('constants.product_constants.clear_all')
            }
        ];

        let mapLevels = map(ipLevels, (ip) => {
            return {
                dataName: `ips_${ip.ip_level_id}`,
                label: ip.level_name,
                ip_level_id: ip.ip_level_id,
                active: ipIndex > -1,
                order: ipIndex + 2,
                showInList: ipIndex > -1,
                form: { type: 'dynamicIp' }
            };
        });

        let mapCategories = map(categoryLevels, (cat, i) => {
            return {
                dataName: `${licensor ? 'licensor_category_path' : 'licensee_category_path'}.${i}`,
                label: cat,
                index: i,
                active: categoryIndex > -1,
                order: categoryIndex + 2,
                showInList: categoryIndex > -1,
                form: { type: 'dynamicCategory' }
            };
        });

        if (ipIndex > -1) sortedHeaders.splice(ipIndex, 0, ...mapLevels);
        if (categoryIndex > -1) sortedHeaders.splice(categoryIndex, 0, ...mapCategories);
        remove(sortedHeaders, header => header.dataName === 'ip_paths');
        remove(sortedHeaders, header => header.dataName === 'licensee_category_path');

        return (
            <div>
                <div className={`c-rec-table__wrapper ${fetching?'fetching-data':''}`}>

                    <div ref={this.tableHeaderWrap}
                         className="c-rec-table c-rec-table__header-container catalogue-table-head">
                        <Auth restrictTo="licensor">

                            <div className={`checkbox-selector-wrapper-fixed dark-table-head ${allChecked ? 'full_selected' : (filteredSelectedData.length > 0 || selectedRows.length > 0) ? 'partial_selected' : 'unselected'}`}>
                                <UiCheckbox
                                    extraClass={
                                        allChecked ? 'full_selected' : filteredSelectedData.length > 0 ? 'partial_selected' : ''}
                                    type="checkbox"
                                    checked={allChecked}
                                    onClick={() => {
                                        this.checkAllAction(selectedRows, allChecked, allIds);
                                    }}>

                                </UiCheckbox>

                                <div className="checkbox-action-counter-indicator">
                                    {selectedRows.length > 0 && (<span
                                        className="checkbox-counter">{selectedRows.length}</span>)}
                                    <CustomSelect
                                        onChange={(e) => {
                                            this.fetchCheckboxSelector(e, allIds);

                                        }}
                                        values={checkSelectorOptionList}
                                        allowEmpty={true}
                                    />
                                </div>
                            </div>

                        </Auth>
                        <Auth restrictTo="licensee">
                            <div className={`checkbox-selector-wrapper-fixed dark-table-head ${allChecked ? 'full_selected' : (filteredSelectedData.length > 0 || selectedRows.length > 0) ? 'partial_selected' : 'unselected'}`}>
                                <UiCheckbox
                                    extraClass={
                                        allChecked ? 'full_selected' : filteredSelectedData.length > 0 ? 'partial_selected' : ''}
                                    type="checkbox"
                                    checked={allChecked}
                                    onClick={() => {
                                        this.checkAllAction(selectedRows, allChecked, allIds);
                                    }}>

                                </UiCheckbox>

                                <div className="checkbox-action-counter-indicator">
                                    {selectedRows.length > 0 && (<span
                                        className="checkbox-counter">{selectedRows.length}</span>)}
                                    <CustomSelect
                                        onChange={(e) => {
                                            this.fetchCheckboxSelector(e, allIds);

                                        }}
                                        values={checkSelectorOptionList}
                                        allowEmpty={true}
                                    />
                                </div>
                            </div>
                        </Auth>

                        <div
                            className="c-rec-table__table-holder c-rec-table__table-header"
                            ref={this.header}
                            onScroll={(e) => this.handleScroll(e, 'header')}
                        >
                            <table className="c-rec-table__top-header c-rec-table--green-head">
                                <thead>
                                <tr ref={this.mainTable}>
                                    <th className="c-rec-table__cell c-rec-table__checkbox checkbox-holder">
                                        {/*cell forcheckbox and dropdown*/}

                                    </th>
                                    <th className="c-rec-table__head-cell c-rec-table__head-cell-cat c-rec-table__approval">
                                        <div className="c-rec-table__icon-holder">
                                            <Icon
                                                icon="LOCK_FULL"
                                                fill="white"
                                                width="12"
                                                height="10"
                                                right="2"
                                                top="2"
                                            />
                                            {t('table_headers.concept')}
                                        </div>
                                    </th>
                                    <th className="c-rec-table__head-cell c-rec-table__head-cell-cat c-rec-table__reg-status">
                                        <div className="c-rec-table__icon-holder">
                                            <Icon
                                                icon="LOCK_FULL"
                                                fill="white"
                                                width="10"
                                                height="10"
                                                right="2"
                                                top="2"
                                            />
                                            <Auth restrictTo="licensee">
                                                {t('table_headers.registered_to')}
                                            </Auth>
                                            <Auth restrictTo="licensor">
                                                {t('table_headers.registered_by')}
                                            </Auth>
                                        </div>
                                    </th>
                                    <th className="c-rec-table__head-cell c-rec-table__head-cell-cat c-rec-table__image-cell">
                                        <div className="c-rec-table__icon-holder">
                                            <Icon
                                                icon="LOCK_FULL"
                                                fill="white"
                                                width="10"
                                                height="10"
                                                right="2"
                                                top="2"
                                            />
                                            {t('table_headers.image')}
                                        </div>
                                    </th>

                                    {sortedHeaders.map((el) => {
                                        const key = el.name || el.dataName;
                                        return (
                                            <th
                                                className="c-rec-table__head-cell"
                                                key={`${key}-mrhead`}
                                            >
                                                <div className="u-flex-align">
                                                    {includes(
                                                        searchableFileds,
                                                        el.dataName
                                                    ) && (
                                                        <HeaderSearchItem
                                                            el={el}
                                                            searchBox={
                                                                searchBox
                                                            }
                                                            elKey={key}
                                                            handleChangeSearch={
                                                                this
                                                                    .handleChangeSearch
                                                            }
                                                            inputValue={
                                                                inputValue
                                                            }
                                                            openSearchBox={() =>
                                                                this.openSearchBox(
                                                                    el
                                                                )
                                                            }
                                                            searchItems={
                                                                searchItems
                                                            }
                                                            basicFilterObj={
                                                                basicFilterObj
                                                            }
                                                            queryObject={
                                                                queryObject
                                                            }
                                                            updateFiltersState={(
                                                                newQuery,
                                                                newFilters,
                                                                updateHard,
                                                                params
                                                            ) =>
                                                                updateFiltersState(
                                                                    newQuery,
                                                                    newFilters,
                                                                    updateHard,
                                                                    params
                                                                )
                                                            }
                                                            popoverRef={
                                                                this
                                                                    .popoverRef
                                                            }
                                                            clearSearch={(event) => { event.preventDefault(); this.setState({ inputValue: '' })}}
                                                        />
                                                    )}

                                                    <TableRowSortingIcons
                                                        colour={'white'}
                                                        label={(!includes(el.dataName, 'ips') && !includes(el.dataName, 'licensor_category_path')) ? t(`table_headers.${el.dataName}`) : el.label}
                                                        sortData={sortData}
                                                        dataName={
                                                            el.dataName
                                                        }
                                                        isActiveSorting={
                                                            sortedBy.name ===
                                                            el.dataName
                                                        }
                                                        isUp={
                                                            !!(
                                                                sortedBy.name ===
                                                                el.dataName &&
                                                                !sortedBy.dir
                                                            )
                                                        }
                                                        defaultSort="last_registered_at"
                                                        categoryLevels={categoryLevels}
                                                        selectedRows={selectedRows}
                                                    />
                                                </div>
                                            </th>
                                        );
                                    })}
                                </tr>
                                </thead>
                            </table>

                            <SearchItems
                                searchItems={searchItems}
                                tableWidth={tableWidth}
                                searchTable={this.searchTable}
                                sortedHeaders={sortedHeaders}
                                searchHeaders={searchHeaders}
                                queryObject={queryObject}
                                basicFilterObj={basicFilterObj}
                                openSearchBox={(el) => this.openSearchBox(el)}
                                updateFiltersState={(
                                    newQuery,
                                    newFilters,
                                    updateHard,
                                    params
                                ) =>
                                    updateFiltersState(
                                        newQuery,
                                        newFilters,
                                        updateHard,
                                        params
                                    )
                                }
                            />
                        </div>
                    </div>

                    <div className="c-rec-table">
                        <div
                            className="c-rec-table__table-holder c-rec-table__main-table"
                            ref={this.table}
                            onScroll={(e) => this.handleScroll(e, 'table')}
                            style={{ minHeight: 100 }}
                        >
                            {!isFiltersReturnedNoData ? (
                                <table>
                                    {data.map((el) => {
                                        const key = `${el.record_id}-${el.version_id}`;
                                        return (
                                            <ProductCatalogueTableRow
                                                headers={list}
                                                dispatch={dispatch}
                                                data={el}
                                                checkProduct={(e, product) => {
                                                    this.handleCheckProduct(e, product);
                                                }}
                                                licensor={licensor}
                                                fetchData={fetchData}
                                                isQuarantine={isQuarantine}
                                                quarantineOpen={quarantineOpen}
                                                key={key}
                                                showUpload={(id) =>
                                                    showUpload(id)
                                                }
                                                selectedRows={selectedRows}
                                                users={users}
                                                isMyRecords={false}
                                                handleClick={() => {
                                                    this.setState({
                                                        highlightRow:
                                                            highlightRow === key
                                                                ? null
                                                                : key
                                                    });
                                                }}
                                                highlighted={
                                                    highlightRow === key
                                                }
                                                setQuarantine={(modalActions) =>
                                                    this.resolveConflicts(
                                                        el.record_id,
                                                        modalActions
                                                    )
                                                }
                                                previousRow={previousRow}
                                                selected={selected}
                                                allProducts={data}
                                                sortedHeaders={sortedHeaders}
                                                ipLevels={ipLevels}
                                                xelacore={xelacore}
                                            />
                                        );
                                    })}
                                </table>
                            ) : (
                                <div className="u-full-width u-flex-column u-flex-align">
                                    <BandAidIcon
                                        className="empty-table-icon u-margin-bottom u-margin-top"
                                    ></BandAidIcon>
                                    <h3 className="u-text-center u-margin-bottom" dangerouslySetInnerHTML={{ __html: t('filters.no_result') }}></h3>
                                    <p className="u-text-center u-margin-bottom">
                                            {t('filters.clear_any_applied_filter')}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        ref={this.scrollbar}
                        className="c-rec-table__bottom-scrollbar"
                        onScroll={(e) => this.handleScroll(e, 'scrollbar')}
                    >
                        <div
                            style={{
                                height: 20,
                                width:
                                    (this.table.current &&
                                        this.table.current.scrollWidth) ||
                                    0
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    const { isMyRecords } = props;
    const key = isMyRecords ? 'myRecords' : 'productCatalogue';
    const {
        xelacore: {
            auth: { companyData } = {},
            userSettings: {
                lists = [],
                headers = {},
                activeHeaders = '',
                headerLayouts = []
            } = {},
            [key]: {
                records: { data = [], totalItems, page, page_size } = {},
                fetching: { fetching, fetchErrors } = {},
                filters: { activeList } = {},
                initialCount,
                selectedRows = [],
                selected = null,
                previousRow = null
            } = {}
        } = {}
    } = state;

    return {
        lists,
        headers,
        activeHeaders,
        headerLayouts,
        data,
        totalItems,
        page,
        page_size,
        initialCount,
        selectedRows,
        fetching,
        fetchErrors,
        activeList,
        companyData,
        selected,
        previousRow
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(ProductCatalogueTable));
