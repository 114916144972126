export const conflictTypes = [
    'exact_duplicate',
    'local_repeat',
    'product_iteration',
    'data_error',
    'global_conflict'
];

export const conflictDescriptions = {
    local_repeat: {
        title: 'Repeat',
        description:
            'A potential product record conflict occurs when identical core product attributes are identified across multiple records but with different GTIN\'s assigned to them. To resolve this conflict you have to first confirm the validity and status of the conflicting product records and then if valid, assign a "Variant attribute" across all the respective records to differentiate the products accordingly.',
        button: 'Manage Repeats'
    },
    exact_duplicate: {
        title: 'Duplicate',
        description:
            'A potential product record conflict occurs when all product attributes are identified to be exactly matching across multiple records. To resolve this conflict you have to first confirm the validity and status of the conflicting product records and then if valid, assign a "Variant attribute" across all the respective records to differentiate the products accordingly.',
        button: 'Manage Duplicates'
    },
    product_iteration: {
        title: 'Iteration',
        description:
            "A potential product record conflict occurs when matching GTIN's and core product attributes are identified across multiple records but with different MPN's assigned. To resolve this conflict you have to first confirm the validity and status of the conflicting product records and then if valid, either assign different GTIN's to uniquely identify the products or consolidate the MPN's under a single product record.",
        button: 'Manage Iterations'
    },
    data_error: {
        title: 'GTIN Exception',
        description:
            "A potential product record conflict occurs when matching GTIN's are identified across multiple records that appear to be different products. To resolve this conflict you have to first confirm the validity and status of the conflicting product records and then if valid, either assign different GTIN's to uniquely identify the products or register the product records to the Quarantine section of the catalogue.",
        button: 'Register Into Quarantine'
    },
    global_conflict: {
        title: 'Global',
        description:
            'A potential product conflict occurs when ALL product core attributes are identical. We have highlighted the identical attributes in the table below:',
        button: 'Delete records'
    }
};

export const variantsList = [
    'size',
    'colour',
    'flavour',
    'scent' /*,
    'CPV',
    'Other'*/
];

export const quarantineHeader = [
    {
        key: 'view',
        label: ''
    },
    {
        key: 'image',
        label: 'Image'
    },
    {
        key: 'product_name',
        label: 'Name'
    },
    {
        key: 'gtin',
        label: 'GTIN'
    },
    {
        key: 'mpn',
        label: 'MPN'
    },
    {
        key: 'licensor_brand',
        label: 'Licensed Brand'
    },
    {
        key: 'size',
        label: 'Size'
    },
    {
        key: 'colour',
        label: 'Colour'
    },
    {
        key: 'flavour',
        label: 'Flavour'
    },
    {
        key: 'scent',
        label: 'Scent'
    },
    {
        key: 'height',
        label: 'Height'
    },
    {
        key: 'width',
        label: 'Width'
    },
    {
        key: 'length',
        label: 'Length'
    },
    {
        key: 'weight',
        label: 'Weight'
    }
];

export const conflictsList = [
    {
        key: 'checkbox',
        label: ''
    },
    {
        key: 'registered',
        label: ''
    },
    {
        key: 'image',
        label: 'Image'
    },
    {
        key: 'product_name',
        label: 'Name'
    },
    {
        key: 'gtin',
        label: 'GTIN'
    },
    {
        key: 'mpn',
        label: 'MPN'
    },
    {
        key: 'licensor_brand_id',
        label: 'Licensed Brand'
    },
    {
        key: 'ips',
        label: 'Licensed Brand'
    },
    {
        key: 'size',
        label: 'Size'
    },
    {
        key: 'colour',
        label: 'Colour'
    },
    {
        key: 'flavour',
        label: 'Flavour'
    },
    {
        key: 'scent',
        label: 'Scent'
    },
    {
        key: 'height',
        label: 'Height'
    },
    {
        key: 'width',
        label: 'Width'
    },
    {
        key: 'length',
        label: 'Length'
    },
    {
        key: 'weight',
        label: 'Weight'
    }
];

export const extendedAttrs = [
    'size',
    'colour',
    'flavour',
    'scent',
    'height',
    'width',
    'length',
    'weight'
];

export const coreFields = [
    'gtin',
    'mpn',
    'product_name',
    'licensor',
    'licensor_brand'
];
