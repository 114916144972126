import React from 'react';
import moment from 'moment';
import { Auth, Badge, Button, Tooltip } from 'src/js/components/static';
import { useTranslation } from 'react-i18next';

export default function ApprovalCodeBlock({ ...props }) {
    const { t } = useTranslation();

    const {
        approvalCode,
        aprConfirmed,
        aprConfirmedOn,
        confirmCode,
        removeCode,
        updateCode,
        record_id
    } = props;
    return (
        <div>
            <div
                className={`u-margin-top-2x u-margin-bottom ${aprConfirmed ? 'c-product__approved' : 'c-product__provided'
                    }`}
            >
                <div className="c-product__approval-code">
                    <Badge
                        green={!!aprConfirmed}
                        orange={!aprConfirmed}
                        text={t('modules.product_detail.approval_code_block.concept_code')}
                        value={approvalCode}
                        className="u-margin-bottom"
                    />
                    {aprConfirmed && (
                        <div>
                            <Badge
                                transparent
                                text={t('modules.product_detail.approval_code_block.confirmed_on')}
                                value={moment(aprConfirmedOn).format(
                                    'DD MMM YYYY HH.mm'
                                )}
                                className="u-margin-bottom"
                            />
                        </div>
                    )}
                    {!aprConfirmed && (
                        <Auth restrictTo="licensee">
                            <div>
                                <div className="u-margin-left u-flex-align">
                                    <Button
                                        onClick={() =>
                                            confirmCode(record_id, approvalCode)
                                        }
                                    >
                                        {t('modules.product_detail.approval_code_block.confirm_concept')}
                                    </Button>
                                    <Tooltip>
                                        {t('modules.product_detail.approval_code_block.processing_button')}
                                    </Tooltip>
                                </div>
                            </div>
                        </Auth>
                    )}
                </div>
            </div>
            <Auth restrictTo="licensee">
                <div>
                    {!aprConfirmed && (
                        <div className="u-block u-bold u-bottom">
                            {t('modules.product_detail.approval_code_block.concept_information_incorrect')}
                        </div>
                    )}
                    <div className="u-flex">
                        <div className="u-margin-right u-flex-align">
                            <Button
                                blueText
                                noBorder
                                onClick={() =>
                                    updateCode(record_id, approvalCode)
                                }
                            >
                                {t('modules.product_detail.approval_code_block.change_concept')}
                            </Button>
                            <Tooltip>
                                {t('modules.product_detail.approval_code_block.change_associated_concept')}
                            </Tooltip>
                        </div>
                        <div className="u-margin-left u-flex-align">
                            <Button redText noBorder onClick={() => removeCode(record_id, approvalCode)}>
                                {t('modules.product_detail.approval_code_block.remove_concept')}
                                <Tooltip>
                                    {t('modules.product_detail.approval_code_block.remove_by_selecting')}
                                </Tooltip>
                            </Button>
                        </div>
                    </div>
                </div>
            </Auth>
        </div>
    );
}
