import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';
import size from 'lodash/size';
import remove from 'lodash/remove';
import each from 'lodash/each';
import findIndex from 'lodash/findIndex';
import classnames from 'classnames';
import levenshtein from 'js-levenshtein';

import { Icon } from 'src/js/components/static';
import { headers as defaultHeaders } from 'src/js/constants/productConstants/headers';
import { getMaxMinDate } from 'src/js/constants/productConstants/index';
import { buildSearchQuery } from 'src/js/helpers/dataHelpers';
import { queryOpaData } from 'src/js/actions/opa';

import {
    fetchBrandAgencies
} from 'src/js/apicalls/other/categories';

import {
    keyFields,
    conditionalFields,
    getProductStatusList
} from 'src/js/constants/productConstants/index';

import {
    hideModal,
    showModal,
    displayNotification
} from 'src/js/actions/xelacore';
import {
    bulkEditRecordImages,
    getProductInformation
} from 'src/js/actions/records';
import { bulkEditRecord } from 'src/js/actions/records';
import BulkEditLoading from './BulkEditLoading';
import { PAGE_LIMIT } from 'src/js/constants/dataConstants';
import {
    isUpdated,
    getFiltersDataFromUrl,
    getMainImage
} from 'src/js/helpers/dataHelpers';
import { valueToString } from 'src/js/helpers/objects';

import BulkEditModalOutput from './BulkEditModalOutput';
import MediaView from 'src/js/components/MediaView';
import fallbackImageSmall from 'statics/imgs/fallback_img_small.png';
import Button from 'modules/UiKit/components/Button/Button';
import { toArray } from 'lodash';

import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import FormItemWrapper from 'modules/UiKit/components/FormElements/FormItem';
import { withTranslation } from 'react-i18next';

class BulkEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            similarEditData: {},
            uploadedImages: [],
            editedFields: {},
            showingSimilar: true,
            submitting: false,
            loading: true,
            timestamp: null,
            selectedUpdate: false,
            confirmedReregistration: false,
            showConfirmingReRegMessage: false,
            confirmedCodeModal: false,
            showConfirmingCodeUpdate: false,
            agenciesData: [],
            originalData: {},
            countChangedCodes: 0,
            selectedAttrs: [
                'action_type',
                'conflicts_resolved_global',
                'conflicts_resolved_local',
                'quarantine_resolved_global',
                'quarantine_resolved_local',
                'organisation_id',
                'gtin_waiver'
            ]
        };
    }

    componentWillMount() {
        this.fetchData();
    }

    setNotification(type = 'success', recordsCount) {
        const { dispatch, t } = this.props;
        const message = {
            error: t('bulk_edit.bulk_edit_modal.updating_error'),
            success: `${recordsCount} ${t('bulk_edit.bulk_edit_modal.successfully_updated.record')}${
                recordsCount > 1 ? 's' : ''
                } ${t('bulk_edit.bulk_edit_modal.successfully_updated.successfully_updated')}`
        };

        return dispatch(
            displayNotification({
                type,
                message: message[type]
            })
        );
    }s

    componentDidUpdate() {
        const { dispatch, messages, rows, resetRows, fetchData } = this.props;
        const { submitting, timestamp, selectedUpdate, ids } = this.state;
        if (
            !submitting ||
            !timestamp ||
            !messages ||
            !messages.length ||
            !rows ||
            !rows.length ||
            selectedUpdate
        )
            return;

        if (isUpdated(messages, ['elastic'], timestamp, ids)) {
            this.setState({ selectedUpdate: true });
            dispatch(hideModal());
            this.setNotification('success', ids.length);
            resetRows();
            setTimeout(() => {
                fetchData(null, getFiltersDataFromUrl());
            }, 1000);
        }
    }


    fetchAgencies(data) {
        if(!!data && data.licensor_brand_id) {
            return fetchBrandAgencies(data.licensor_brand_id).then(response => {
                this.setState({
                    agenciesData: response && response.data && map(response.data, item => {
                        return {
                            label: item.agentOrganisationName,
                            value: item.agentOrganisationId
                        }
                    })
                })
            })
        }
    }

    setOutputModal = (updated, error, conflicted) => (
        <BulkEditModalOutput
            all={updated}
            conflicted={conflicted}
            dispatch={this.props.dispatch}
            error={error}
        />
    );

    getConflicted = (updated, rows) =>
        updated
            .filter(
                (record) =>
                    map(rows, 'record_id').includes(record.record_id) && record.conflict_with
            )
            .map((row) => row.record_id);

    getNewSelected = (updated, rows) =>
        updated
            .filter(
                (record) =>
                    map(rows, 'record_id').includes(record.record_id) && !record.conflict_with
            );

    fetchData() {
        const { rows, dispatch } = this.props;
        const obj = {
            record_id: map(rows, 'record_id')
        };
        const pagination = {
            page: 1,
            page_size: rows.length || PAGE_LIMIT
        };
        return dispatch(getProductInformation('records', obj, pagination, true))
            .then((resp) => {
                each(resp.records, record => {
                    const staticAttrs = !!record.static_attributes ? Object.keys(record.static_attributes) : [];
                    record.licensee_category_path = toArray(record.licensee_category_path);

                    each(staticAttrs, attr => {
                        record[attr] = record.static_attributes[attr];
                    });
                });

                this.setState({ data: resp.records, loading: false }, () =>
                    this.groupSimilar()
                );
            })
            .catch(() => this.setState({ data: null, loading: false }));
    }

    // Change function for all normal form elements such as input / select etc
    onChange(e, key, path) {
        const { similarEditData } = this.state;
        const formEl = !!e ? e.preventDefault : null;
        const value = formEl ? e.target.value : !!e ? e : similarEditData[key];
        const newData = { ...cloneDeep(similarEditData), [key]: value };

        if (key === 'licensor_brand_id') {
            const brandInfo = this.props.linkedBrands.find(
                ({ brand_id }) => brand_id === value
            );
            newData.licensor = !!brandInfo && brandInfo.licensor_organisation_name || '';
            newData.licensor_organisation_id = !!brandInfo && brandInfo.organisation_id || '';
            newData.licensor_property = null;
        }

        if (key === 'licensee_category_id' || key === 'licensee_category_root_id') {
            newData.licensee_category_path = !!path && path || newData.licensee_category_path;
        }

        if (key === 'ips') {
            newData.ips = value;
            newData.licensor_brand_id = !!value && !!value[0] && !!value[0][0] && value[0][0].ip_id;
            newData.ip_paths = !!path && path || newData.ip_paths;
            newData.agent_organisation_id = null;
            newData.agent_organisation = null;
        }

        if (key === 'agent_organisation') {
            newData.agent_organisation_id = e;
            newData.agent_organisation = e;
        }

        return this.setState({
            similarEditData: newData
        });
    }

    fetchConfirmedInfoConcepts(obj) {
        const { similarEditData } = this.state;
        let clonedData = cloneDeep(obj);

        let query = buildSearchQuery(!!obj && obj.concept_code_confirmed_info, obj);

        return queryOpaData(query, similarEditData.licensor_organisation_id, {}).then((response) => {
            each(!!response && response.data, res => {
                let index = !!clonedData && findIndex(clonedData.concept_code_confirmed_info, code => code.xelacore_concept_id === res.xelacoreConceptId);

                if(index > -1) {
                    clonedData.concept_code_confirmed_info[index].concept_code = res.approvalCode;
                }
            });

            this.setState({similarEditData: clonedData, originalData: clonedData});
        });
    }

    countChangedCodes(editedData, defaultData) {
        const key = 'concept_code';
        const confirmedKey = 'concept_code_confirmed_info';

        return (
            !!editedData[key] &&
            !!defaultData && !!defaultData[key] && defaultData[key].filter(
                (code) =>
                    !!defaultData[confirmedKey].find(
                        (confCode) => confCode.concept_code === code
                    ) && !editedData[key].find((updCode) => updCode === code)
            ).length
        );
    }

    groupSimilar() {
        // For each data object
        // We want to loop through each of their keys
        // If this key is the exact same across all other data objects
        // We put it into another array called 'Similar Data' along with it's record id
        const { data } = this.state;
        const base = (data && data.length && data[0]) || [];
        const obj = {};

        Object.keys(base).forEach((el) => {
            // In all of the objects, we need to find this key and check if the value is the same
            const additionalObj = {};

            if (el !== 'additional') {
                const check = data.find((f) => {
                    if (
                        el === 'images' ||
                        el === 'language' ||
                        el === 'licensor_talent' ||
                        el === 'licensor_character' ||
                        el === 'licensor_other' ||
                        el === 'target_market' ||
                        el === 'licensee_category_path' ||
                        el === 'ip_paths' ||
                        el === 'ips'
                    ) {
                        let initialArray = [...base[el] || ''];
                        let comparedArray = [...f[el] || ''];

                        obj[`${el}_multiple`] =
                            !isEqual(
                                comparedArray.sort(),
                                initialArray.sort()
                            ) &&
                            (size(f[el]) > 0 || size(base[el]) > 0);

                        return !isEqual(
                            comparedArray.sort(),
                            initialArray.sort()
                        );
                    }

                    obj[`${el}_multiple`] =
                        f[el] !== base[el] &&
                        (size(f[el]) > 0 || size(base[el]) > 0);

                    return f[el] !== base[el];
                });

                return check === undefined ? (obj[el] = base[el]) : null;
            }

            if (!base.additional) return null;
            const additionalFields = Object.keys(base.additional);

            additionalFields.forEach((adF) => {
                const check = data.find((f) => {
                    return (
                        f.additional &&
                        f.additional[adF] !== base.additional[adF]
                    );
                });
                if (!check) {
                    additionalObj[adF] = base.additional[adF];
                }
            });

            return Object.keys(additionalObj).length
                ? (obj.additional = additionalObj)
                : null;
        });

        Object.keys(obj).forEach((el) => {
            if (obj[el] === null) delete obj[el];
        });

        if (obj.additional) {
            obj.additional = obj.additional ? obj.additional : {};
        }

        if (obj.product_category || obj.product_sub_category) {
            obj.product_category = obj.product_category
                ? obj.product_category
                : '';
            obj.product_sub_category = obj.product_sub_category
                ? obj.product_sub_category
                : '';
        }

        if (
            obj.product_status ||
            obj.market_availability_date ||
            obj.manufacturing_status
        ) {
            obj.product_status = obj.product_status ? obj.product_status : '';
            obj.market_availability_date = obj.market_availability_date
                ? obj.market_availability_date
                : '';
            obj.manufacturing_status = obj.manufacturing_status
                ? obj.manufacturing_status
                : '';
        }

        this.setState({
            similarEditData: obj,
            originalData: obj
        });

        this.fetchAgencies(obj);
        this.fetchConfirmedInfoConcepts(obj);
    }

    removeProductFromBulkEdit(id) {
        const { data } = this.state;
        const newData = data.filter((f) => f.record_id !== id);
        this.setState(
            {
                data: newData,
                confirmedReregistration: false,
                showConfirmingReRegMessage: false
            },
            () => this.groupSimilar()
        );
    }

    removeGroupFromBulkEdit(isRegistered) {
        const { data } = this.state;
        const { dispatch } = this.props;
        const newData = data.filter((f) =>
            isRegistered ? !f.registered_version_id : !!f.registered_version_id
        );
        if (!newData.length) {
            return dispatch(hideModal());
        }
        this.setState(
            {
                data: newData,
                confirmedReregistration: false,
                showConfirmingReRegMessage: false
            },
            () => this.groupSimilar()
        );
    }

    resubmit(e, modalConfirmField, showConfirmingReRegMessage) {
        e.persist();
        this.setState(
            {
                [modalConfirmField]: true,
                [showConfirmingReRegMessage]: false
            },
            () => this.submitData()
        );
    }

    isReregistrationConfirmation(records, editedData) {
        const registeredRecords = records.filter(
            (record) => !!record.registered_version_id
        );
        if (!registeredRecords.length) {
            return false;
        }
        for (let i = 0; i < registeredRecords.length; i++) {
            const rec = registeredRecords[i];
            const changedKeyFields = keyFields.find(
                (field) => {
                    let checkField = field === 'ip_paths' ? 'ips' : field === 'licensee_category_path' ? 'licensee_category_id' : field;
                    let isSimilar = checkField === 'ips'
                        ? isEqual(rec[checkField], editedData[checkField])
                        : valueToString(rec[checkField]) === valueToString(editedData[checkField]);

                    return editedData.hasOwnProperty(checkField) && !isSimilar;
                }
            );
            if (changedKeyFields) return true;
            const changedConditionalFields = conditionalFields.find(
                (field) =>
                    editedData.hasOwnProperty(field) &&
                    (levenshtein(rec[field], editedData[field]) * 100) /
                    rec[field].length >
                    50
            );
            if (changedConditionalFields) return true;
        }
        return false;
    }

    deformatArrayFields(data) {
        const arrayHeaders = defaultHeaders.filter(
            (elem) =>
                elem.form && elem.form.type === 'array' && !!data[elem.dataName]
        );
        const arrayFields = {};
        arrayHeaders.forEach((header) => {
            const { dataName } = header;
            arrayFields[dataName] = this.deFormatArrayField(data, dataName);
        });
        return arrayFields;
    }

    deFormatArrayField(data, dataName) {
        const values = data[dataName] || [];
        return values
            .map((val) => val.value)
            .filter((val) => val && val.length > 0);
    }

    translateStatusesList = (field, list) => {
        const { t } = this.props;
        let newList = [];

        newList = map(list, item => {
            return {
                name: item.name,
                label: t(`constants.${field}.${item.label}`)
            }
        })

        return newList;
    }

    getValuesList(fieldName, values, data) {
        return fieldName === 'product_status'
            ? getProductStatusList(data, this.translateStatusesList(fieldName, values))
            : fieldName === 'manufacturing_status'
                ? this.translateStatusesList(fieldName, values)
                : values
    }

    submitData(e) {
        if (e) e.preventDefault();

        const { dispatch } = this.props;
        const {
            similarEditData,
            data,
            confirmedReregistration,
            selectedAttrs,
            confirmedCodeModal,
            originalData
        } = this.state;
        const ids = data.map((el) => el.record_id);
        const selectedAttributes = {};
        const staticAttrs = !!data[0].static_attributes ? Object.keys(data[0].static_attributes) : [];

        each(selectedAttrs, (att) => {
            if (
                att === 'licensor_character' ||
                att === 'licensor_talent' ||
                att === 'licensor_other'
            ) {
                selectedAttributes[att] = map(similarEditData[att], 'value');
            } else if (att === 'licensee_category_path') {
                selectedAttributes['licensee_category_id'] = similarEditData['licensee_category_id'];
                selectedAttributes['licensee_category_root_id'] = similarEditData['licensee_category_root_id'];
            } else if (att === 'ip_paths') {
                selectedAttributes['ip_paths'] = similarEditData['ip_paths'];
                selectedAttributes['ips'] = similarEditData['ips'];
            } else if (att === 'agent_organisation') {
                selectedAttributes['agent_organisation'] = similarEditData['agent_organisation'];
                selectedAttributes['agent_organisation_id'] = similarEditData['agent_organisation_id'];
            } else if (includes(staticAttrs, att)) {
                selectedAttributes.static_attributes = !!selectedAttributes.static_attributes && selectedAttributes.static_attributes || {};
                selectedAttributes.static_attributes[att] = similarEditData[att];
            } else if (att === 'concept_code') {
                if (similarEditData[att].length === 1 && (!!similarEditData[att][0] && size(similarEditData[att][0].value) === 0)) {
                    selectedAttributes[att] = [];
                    selectedAttributes['concept_code_confirmed_info'] = similarEditData['concept_code_confirmed_info'];
                } else {
                    selectedAttributes[att] = size(similarEditData[att]) > 0 && !!similarEditData[att][0].value ? map(similarEditData[att], 'value') : similarEditData[att];
                    selectedAttributes['concept_code_confirmed_info'] = similarEditData['concept_code_confirmed_info'];
                }
            } else {
                selectedAttributes[att] = similarEditData[att];
            }
        });

        const record = {
            ...selectedAttributes
        };

        this.setState({
            countChangedCodes: this.countChangedCodes(
                selectedAttributes,
                originalData
            )}
        );

        if (
            similarEditData.registered_with &&
            this.countChangedCodes(
                selectedAttributes,
                originalData
            ) > 0 &&
            !confirmedCodeModal
        ) {
            return this.setState({ showConfirmingCodeUpdate: true });
        }

        if (
            this.isReregistrationConfirmation(data, record) &&
            !confirmedReregistration
        ) {
            return this.setState({ showConfirmingReRegMessage: true });
        }

        this.setState({
            submitting: true,
            addAttribute: false,
            ids
        });

        try {
            const imagePromise =
                this.state.uploadedImages.length > 0
                    ? dispatch(
                        bulkEditRecordImages(ids, this.state.uploadedImages)
                    )
                    : Promise.resolve();
            imagePromise.then(() => {
                if (this.state.uploadedImages.length > 0) {
                    delete record.images;
                }
                dispatch(bulkEditRecord(ids, record)).then(() => {
                    this.setState({
                        timestamp: Date.now()
                    });
                });
            });
        } catch (e) {
            const message = get(e, 'response.data.message');
            return dispatch(
                showModal(this.setOutputModal(ids, message || e.message))
            );
        }
    }

    updateModalSubmitting = () => {
        const { t } = this.props;
        return (<div className="marg-t-20 marg-b-20">{t('bulk_edit.bulk_edit_modal.updating_products')}</div>)
    };

    selectAttributes(el) {
        const { selectedAttrs } = this.state;
        const key = el.form.valueDataName || el.dataName;
        if (selectedAttrs.includes(key)) {
            remove(selectedAttrs, (attr) => {
                return attr === key;
            });
        } else {
            selectedAttrs.push(key);
        }

        this.setState({
            selectedAttrs: selectedAttrs
        });
    }

    sortRecords(data = []) {
        const registeredRecords = [];
        const nonRegisteredRecords = [];
        if (!data) {
            return { registeredRecords, nonRegisteredRecords };
        }
        data.forEach((record) => {
            if (record.registered_version_id) {
                registeredRecords.push(record);
            } else {
                nonRegisteredRecords.push(record);
            }
        });
        return { registeredRecords, nonRegisteredRecords };
    }

    renderRecordsList(
        records,
        isRegistered,
        dataLength,
        showConfirmingReRegMessage,
        otherTypeExists = false
    ) {
        const { t } = this.props;

        return (
            <div className="c-bulk-update__products-list-container">
                <span dangerouslySetInnerHTML={{ __html: t('bulk_edit.bulk_edit_modal.important_note') }}></span>

                <div className="c-bulk-update__products-wrapper">
                    <div className="c-bulk-update__products-list-title">
                        <span>
                            {isRegistered ? (
                                <h4>
                                    {t('bulk_edit.bulk_edit_modal.products.products')} <span className="u-color-green">{t('bulk_edit.bulk_edit_modal.products.registered')}</span>
                                </h4>
                            ) : (
                                    <h4>{t('bulk_edit.bulk_edit_modal.products.products')} <span className="u-color-red">{t('bulk_edit.bulk_edit_modal.products.not_registered')}</span></h4>
                            )}
                        </span>
                        {otherTypeExists && (
                            <span
                                className="u-color-red remove_products"
                                onClick={() =>
                                    this.removeGroupFromBulkEdit(isRegistered)
                                }
                            >
                                {t('buttons.remove_all')}
                            </span>
                        )}
                    </div>

                    <ul className="c-bulk-update__products-list">
                        {records.map((el) => (
                            <li
                                key={`bulk_edit-${el.record_id}`}
                                className={classnames('c-bulk-update__product', {
                                    'u-border-bright-red':
                                        isRegistered && showConfirmingReRegMessage
                                })}
                            >
                                <div className="c-bulk-update__product-left">
                                    <MediaView
                                        src={getMainImage(el.images)}
                                        alt={el.name}
                                        fallbackSrc={fallbackImageSmall}
                                        useImgTag
                                        className="c-bulk-update__image-thumb"
                                    />
                                    <div>{el.product_name}</div>
                                </div>
                                <div className="c-bulk-update__product-right">
                                    <div></div>
                                    {dataLength > 1 && (
                                        <Icon
                                            className="u-clickable"
                                            icon="close"
                                            fill="grey16"
                                            size="16"
                                            onClick={() =>
                                                this.removeProductFromBulkEdit(
                                                    el.record_id
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    render() {
        const {
            data,
            similarEditData,
            showingSimilar,
            submitting,
            loading,
            showConfirmingReRegMessage,
            selectedAttrs,
            agenciesData,
            showConfirmingCodeUpdate,
            countChangedCodes
        } = this.state;

        const { dispatch, xelacore, ipLevels, ips, t } = this.props;

        const similiarKeys = Object.keys(similarEditData).map((el) => el);
        const filteredHeaders = defaultHeaders.filter(
            (header) =>
                header.config &&
                header.config.group &&
                !header.config.hideOnBulkEdit
        );
        const displayedHeaders = !showingSimilar
            ? filteredHeaders.filter(
                (f) => similiarKeys.indexOf(f.dataName) > -1
            )
            : filteredHeaders;

        if (submitting) return this.updateModalSubmitting();
        const { registeredRecords = [], nonRegisteredRecords = [] } =
            this.sortRecords(data);
        const noData = loading || !data || !data.length;

        return (
            <div className="c-bulk-update">
                <form className="c-bulk-update__container" onSubmit={(e) => this.submitData(e)}>
                    <div className="c-bulk-update__products">
                        <div className="c-bulk-update__header">
                            {noData ? (
                                <BulkEditLoading
                                    onRetry={() => this.fetchData()}
                                />
                            ) : (
                                <b>
                                        {t('bulk_edit.selected_products')}{' '}
                                    <span className="c-bulk-update__count">
                                        ({data.length})
                                    </span>
                                </b>
                            )}
                        </div>
                        {!!registeredRecords.length &&
                            this.renderRecordsList(
                                registeredRecords,
                                true,
                                data.length,
                                showConfirmingReRegMessage,
                                !!nonRegisteredRecords.length
                            )}
                        {!!nonRegisteredRecords.length &&
                            this.renderRecordsList(
                                nonRegisteredRecords,
                                false,
                                data.length,
                                false,
                                !!registeredRecords.length
                            )}
                    </div>
                    <div
                        className={`c-bulk-update__data ${
                            noData ? 'c-bulk-update__inactive' : ''
                        }`}
                    >
                        <div className="c-bulk-update__header u-margin-bottom">
                            <b>{t('bulk_edit.change_attributes')}</b>
                        </div>
                        <fieldset
                            className="c-product__editing-form__fieldset u-no-padding"
                            disabled={showConfirmingReRegMessage}
                        >
                            {
                                // Display either:
                                // Data that is similar to all fields with all the data in tact
                                // All fields based off the headers
                                displayedHeaders.map((el, i) => {
                                    const {
                                        form: {
                                            type,
                                            values = null,
                                            isGrouped,
                                            isMulti,
                                            fetchValues,
                                            valueProp,
                                            valueDataName,
                                            reliesOn,
                                            showFullLabel,
                                            disabled
                                        },
                                        label,
                                        dataName
                                    } = el;
                                    const id = `${dataName}--product-edit-listing-${i}`;
                                    const key = valueDataName || dataName;
                                    const value = similarEditData[key];

                                    if (['dynamic_attributes', 'category_attributes', 'asin'].includes(dataName)) return null;

                                    return (
                                        <div
                                            className="u-margin-bottom c-bulk-edit-form"
                                            key={`${el.dataName}-bulk-edit-form-${i}`}
                                        >
                                            <div className="c-input-checkbox">
                                                {!disabled && (
                                                    <React.Fragment>
                                                        <UiCheckbox
                                                            id={`${i}-bulk-edit`}
                                                            key={`${i}-bulk-edit`}
                                                            type="checkbox"
                                                            checked={selectedAttrs.includes(
                                                                key
                                                            )}
                                                            onChange={() =>
                                                                this.selectAttributes(
                                                                    el
                                                                )
                                                            }>

                                                        </UiCheckbox>
                                                    </React.Fragment>
                                                )}
                                            </div>

                                            <label
                                                className="c-bulk-edit-form-label"
                                                onClick={() => {
                                                    if (!disabled) {
                                                        this.selectAttributes(
                                                            el
                                                        );
                                                    }
                                                }}
                                            >
                                                {type === 'dynamicCategory' ? 'Categories' : t(`table_headers.${dataName}`)}
                                            </label>

                                            <FormItemWrapper
                                                hideLabel={!['categories'].includes(label.toLowerCase()) || dataName === 'licensee_category_path' || dataName === 'licensor_category_path'}
                                                key={id}
                                                data={similarEditData}
                                                id={id}
                                                label={label}
                                                type={type}
                                                name={dataName}
                                                isMultipleValues={
                                                    similarEditData[
                                                        `${dataName}_multiple`
                                                        ]
                                                }
                                                placeholder={
                                                    !!similarEditData[
                                                        `${dataName}_multiple`
                                                    ]
                                                        ? t('placeholders.multiple_values')
                                                        : ''
                                                }
                                                value={
                                                    value === null ? '' : value
                                                }
                                                values={this.getValuesList(
                                                    dataName,
                                                    values,
                                                    similarEditData
                                                )}
                                                valueProp={valueProp}
                                                valueDataName={valueDataName}
                                                tooltip={t(`table_header_constants.${dataName}.tooltip.licensee`)}
                                                onChange={(e, dynamicKey, path) =>
                                                    this.onChange(e, dynamicKey || key, path)
                                                }
                                                onSelect={(e, dynamicKey, path) =>
                                                    this.onChange(e, dynamicKey || key, path)
                                                }
                                                onImageUploadChange={(
                                                    uploadedImages
                                                ) =>
                                                    this.setState({
                                                        uploadedImages
                                                    })
                                                }
                                                updateObject={(val, key) =>
                                                    this.changeObject(val, key)
                                                }
                                                // Images comes in as images.01 etc so we use indexOf instead of array.find/includes etc
                                                validations={[]}
                                                isGrouped={isGrouped}
                                                isMulti={isMulti}
                                                showFullLabel={showFullLabel}
                                                fetchValues={fetchValues}
                                                reliesOn={reliesOn}
                                                disabledElement={
                                                    !selectedAttrs.includes(key)
                                                }
                                                localValidations={[]}
                                                dates={getMaxMinDate(
                                                    dataName,
                                                    similarEditData
                                                )}
                                                ipLevels={ipLevels}
                                                ips={ips}
                                                categories={xelacore.categories}
                                                agenciesData={agenciesData}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </fieldset>

                        <div className="c-bulk-update__sticky-button">
                            {!showConfirmingReRegMessage && !showConfirmingCodeUpdate && (
                                <div>
                                    <Button
                                        type="secondary"
                                        size="small"
                                        onClick={() => dispatch(hideModal())}>
                                        {t('buttons.cancel')}
                                    </Button>

                                    <Button
                                        type="primary"
                                        size="small"
                                        disabled={size(selectedAttrs) === 7}
                                        submit
                                    >
                                        {t('buttons.confirm_changes')}
                                    </Button>
                                </div>
                            )}

                            {showConfirmingReRegMessage &&
                                (
                                    <div className="c-product__deregistration-confirmation bulk-edit-modal">
                                        <div className="c-product__deregistration-confirmation__message">
                                        {t('bulk_edit.bulk_edit_modal.changed_core_attributes')}
                                        </div>
                                        <div className="c-product__deregistration-confirmation__buttons">
                                            <Button
                                                type="secondary"
                                                size="small"
                                                onClick={() =>
                                                    this.setState({
                                                        showConfirmingReRegMessage: false
                                                    })
                                                }
                                            >
                                                {t('buttons.cancel')}
                                            </Button>
                                            <Button
                                                type="secondary"
                                                size="small"
                                                onClick={(e) => this.resubmit(e, 'confirmedReregistration', 'showConfirmingReRegMessage')}
                                            >
                                                {t('buttons.confirm')}
                                            </Button>
                                        </div>
                                    </div>
                                )
                            }

                            {showConfirmingCodeUpdate &&
                                (
                                    <div className="c-product__deregistration-confirmation bulk-edit-modal">
                                        <div className="c-product__deregistration-confirmation__message">
                                        {t('bulk_edit.bulk_edit_modal.modify_concept_code.you_are_going')} {countChangedCodes} {t('bulk_edit.bulk_edit_modal.modify_concept_code.confirmed_concept_code')}
                                        </div>
                                        <div className="c-product__deregistration-confirmation__buttons">
                                            <Button
                                                type="secondary"
                                                size="small"
                                                onClick={() =>
                                                    this.setState({
                                                        showConfirmingCodeUpdate: false
                                                    })
                                                }
                                            >
                                                {t('buttons.cancel')}
                                            </Button>
                                            <Button
                                                type="secondary"
                                                size="small"
                                                onClick={(e) => this.resubmit(e, 'confirmedCodeModal', 'showConfirmingCodeUpdate')}
                                            >
                                                {t('buttons.confirm')}
                                            </Button>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        xelacore,
        xelacore: {
            categories: {
                list: categories,
                linkedBrands: { rows: linkedBrands } = {}
            } = {},
            socket: { messages = [] } = {}
        } = {}
    } = state;
    return { xelacore, categories, linkedBrands, messages };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BulkEdit));
