import React, { Component } from 'react';
import classNames from 'classnames';
import { Icon } from 'src/js/components/static';
import ProductInfo from './ProductInfo';
import ConceptInfo from './ConceptInfo';
import OpaSearchPanel from '../OpaSearchPanel/OpaSearchPanel';
import ApprovalOverlayHeader from './ApprovalOverlayHeader';
import { withTranslation } from 'react-i18next';
// import ApprovalOverlayHeader from './ApprovalOverlayHeader';

class ProductApprovalOverlay extends Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();
        const { data } = this.props;
        this.state = {
            search: false,
            searchItems: [],
            showOpaSearchPanel: props.search,
            approvalData: props.approvalData,
            allConcepts: props.search ? [] : data.concept_info,
            opaDataMatch: !data.concept_info.length ? props.opaDataMatch : []
        };
    }

    showSearchPanel() {
        this.myRef.current.scrollTo({ top: 0 });
        const { showOpaSearchPanel } = this.state;
        const { data, opaDataMatch } = this.props;

        this.setState({
            allConcepts: [],
            opaDataMatch: data.concept_info.length === 0 ? opaDataMatch : [],
            showOpaSearchPanel: !showOpaSearchPanel
        });
    }

    displayNewApprovalData(data, search, searchItems) {
        this.myRef.current.scrollTo({ top: 0 });

        this.setState({
            search: search,
            searchItems: searchItems,
            approvalData: data,
            showOpaSearchPanel: false
        });
    }

    setConceptInfo(index, allConcepts) {
        let i = index;
        if (index < 0) i = 0;
        if (index >= allConcepts.length) i = allConcepts.length - 1;

        this.setState({
            approvalData: allConcepts[i]
        });
    }

    render() {
        const {
            data,
            type,
            originalData,
            close,
            updateProductConcept,
            removeConcept,
            opaDataMatch,
            xelacore,
            t
        } = this.props;

        const {
            showOpaSearchPanel,
            approvalData,
            allConcepts,
            searchItems,
            search
        } = this.state;

        // For the search panel
        const {
            dispatch,
            toggleOpaPanel,
            updateData,
            confirmArn,
            record_id,
            displayConfirmationModal,
            aprCode,
            licensor
        } = this.props;

        const conceptCodes = originalData.concept_info.map(
            ({ approval_code }) => approval_code
        );
        const activeCode = allConcepts.some(
            (f) => f.approval_code === approvalData.approval_code
        );

        const currentConceptIndex = allConcepts.findIndex(
            (el) => el.approval_code === approvalData.approval_code
        );

        const overlayCx = classNames(
            'c-approval-overlay__main-body-right',
            'c-approval-overlay__main-body-right--opa-panel',
            {
                'u-hidden': !showOpaSearchPanel
            }
        );

        return (
            <div className="c-approval-overlay">
                <div className="c-approval-overlay__container" ref={this.myRef}>
                    <div>
                        <h1>{data.product_name} {t('modules.product_detail.approval_overlay.concepts')}</h1>
                        <p>
                            {t('modules.product_detail.approval_overlay.side_by_side')}
                        </p>
                    </div>
                    <div
                        className="c-approval-overlay__close-block"
                        onClick={close}
                    >
                        {t('buttons.close')} <Icon icon="close" size={17} />
                    </div>
                    <div className="c-approval-overlay__main-body">
                        <ProductInfo
                            data={data}
                            type={type}
                            originalData={originalData}
                            dispatch={dispatch}
                            xelacore={xelacore}
                        />
                        {!showOpaSearchPanel && (
                            <div className="c-approval-overlay__main-body-right">
                                <div className="c-approval-overlay__main-body-right-child">
                                    <ApprovalOverlayHeader
                                        allConcepts={allConcepts}
                                        approvalData={approvalData}
                                        activeCode={activeCode}
                                        licensor={licensor}
                                        updateProductConcept={() => {
                                            updateProductConcept(
                                                record_id,
                                                approvalData.approval_code
                                            );
                                        }}
                                        conceptCodes={conceptCodes}
                                        currentConceptIndex={
                                            currentConceptIndex
                                        }
                                        toggleSearchPanel={() =>
                                            this.showSearchPanel()
                                        }
                                        close={close}
                                        setConceptInfo={(i, useOpa) =>
                                            this.setConceptInfo(i, useOpa)
                                        }
                                        searchItems={searchItems}
                                        search={search}
                                        opaDataMatch={opaDataMatch}
                                        productData={data}
                                    />

                                    <ConceptInfo
                                        productData={data}
                                        approvalData={approvalData}
                                        record_id={data.record_id}
                                        close={close}
                                        licensor={licensor}
                                        allConcepts={allConcepts}
                                        updateProductConcept={(id, code) =>
                                            updateProductConcept(id, code)
                                        }
                                        removeConcept={(id, code) =>
                                            removeConcept(id, code)
                                        }
                                        showOpaSearchPanel={() =>
                                            this.showSearchPanel()
                                        }
                                        xelacore={xelacore}
                                    />
                                </div>
                            </div>
                        )}
                        <div className={overlayCx}>
                            <OpaSearchPanel
                                dispatch={dispatch}
                                originalData={originalData}
                                toggleOpaPanel={(refetch) =>
                                    toggleOpaPanel(refetch)
                                }
                                updateData={(data, key, callback) =>
                                    updateData(data, key, callback)
                                }
                                confirmArn={confirmArn}
                                record_id={record_id}
                                displayConfirmationModal={(data, key, text) =>
                                    displayConfirmationModal(data, key, text)
                                }
                                confirmCode={() =>
                                    this.confirmCode(record_id, aprCode)
                                }
                                updateProductConcept={this.confirmChangeConcept}
                                showProductOverlay={(productInfo, search, searchItems) =>
                                    this.displayNewApprovalData(productInfo, search, searchItems)
                                }
                                xelacore={xelacore}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ProductApprovalOverlay)
