import React from 'react';
import { Route, browserHistory, IndexRoute } from 'react-router';
import reduxAuth from 'src/js/lib/reduxAuth';
import AppLayout from 'src/js/layouts/AppLayout';
import OrphanLayout from 'src/js/layouts/OrphanLayout';
import AppHelmet from './AppHelmet';
import OrganisationSelect from 'src/js/modules/OrganisationSelect';
import Home from 'src/js/modules/Home/Home';
import Dashboard from 'src/js/modules/Dashboard/Dashboard';
import PageNotFound from 'src/js/modules/SingleStaticPages/PageNotFound';
import Maintenance from 'src/js/components/Maintenance';
import TechnicalDifficulties from 'src/js/modules/SingleStaticPages/TechnicalDifficulties';
import ProductCatalogue from 'src/js/modules/ProductCatalogue/routes/ProductCatalogue/';
import ProductDetail from 'src/js/modules/Product/ProductDetail/ProductDetail';
import RoyaltyReportAssistant from 'modules/Toolkit/RoyaltyReportAssistant/RoyaltyReportAssistant';
import Toolkit from 'src/js/modules/Toolkit/Toolkit';
import TrademarkAuthorisationLetter from 'src/js/modules/Toolkit/TrademarkAuthorisationLetter/TrademarkAuthorisationLetter';
import CustomReports from 'src/js/modules/CustomReports/CustomReports';
import RetailerListings from 'src/js/modules/RetailerListings/RetailerListings';
import UiKit from 'src/js/modules/UiKit/UiKit';
import MyRecords from 'src/js/modules/MyRecords/routes/MyRecords/';
import BulkConflict from 'src/js/modules/BulkConflict/';
import PosExport from 'src/js/modules/PosExport/';
import ReportAssistantTable from 'modules/Toolkit/RoyaltyReportAssistant/Components/Report';
import AuthorizationRequest from 'modules/AuthorizationRequest/AuthorizationRequest';

export function buildRoutes(state, store) {
    const requireAuth = reduxAuth(store);

    return Promise.resolve(
        <Route component={AppHelmet} history={browserHistory}>
            <Route component={OrphanLayout}>
                <Route
                    name="Maintenance"
                    path="/maintenance"
                    component={Maintenance}
                />

                <Route
                    name="Technical Difficulties"
                    path="/technical-difficulties"
                    component={TechnicalDifficulties}
                />
            </Route>

            <Route
                name="Select Organisation"
                path="/select-organisation"
                component={OrganisationSelect}
                onEnter={requireAuth()}
            />

            <Route component={AppLayout}>
                <Route
                    name="Dashboard"
                    path="/"
                    component={Dashboard}
                    onEnter={requireAuth()}
                />

                <Route
                    name="Home"
                    path="/intro"
                    component={Home}
                    onEnter={requireAuth()}
                />

                <Route
                    name="Product Catalogue"
                    path="/product-catalogue"
                    onEnter={requireAuth()}
                >
                    <IndexRoute
                        name="Product Catalogue"
                        component={ProductCatalogue}
                    />
                </Route>
                <Route
                    name="Authorization Request"
                    path="/authorization-request"
                    onEnter={requireAuth()}
                >
                    <IndexRoute
                        name="Authorization Request"
                        component={AuthorizationRequest}
                    />
                </Route>

                <Route
                    name="Retailer Listings"
                    path="/retailer-listings"
                    onEnter={requireAuth()}
                >
                    <IndexRoute
                        name="Retailer Listings"
                        component={RetailerListings}
                    />
                </Route>

                <Route
                    name="Analytics"
                    path="/analytics"
                    onEnter={requireAuth()}
                >
                    <IndexRoute
                        name="Analytics"
                        component={CustomReports}
                    />
                </Route>

                <Route
                    name="Toolkit"
                    path="/toolkit"
                    params
                    onEnter={requireAuth()}
                >
                    <IndexRoute
                        name="Toolkit"
                        component={Toolkit}
                    />
                </Route>

                <Route
                    name="Trademark Authorization Letter"
                    path="/toolkit/trademark"
                    params
                    onEnter={requireAuth()}
                >
                    <IndexRoute
                        name="Trademark Authorization Letter"
                        component={TrademarkAuthorisationLetter}
                    />
                </Route>

                <Route
                    name="Royalty Report Co-Pilot"
                    path="/toolkit/royalty-report-assistant"
                    params
                    onEnter={requireAuth()}
                >
                    <IndexRoute
                        name="Royalty Report Co-Pilot"
                        component={RoyaltyReportAssistant}
                    />
                </Route>

                <Route
                    name="Royalty Report Co-Pilot"
                    path="/toolkit/royalty-report-assistant/:id"
                    params
                    onEnter={requireAuth()}
                >
                    <IndexRoute
                        name="Royalty Report Co-Pilot"
                        component={ReportAssistantTable}
                    />
                </Route>

                <Route
                    name="Product Detail Page"
                    path="/product/:productType/:id"
                    onEnter={requireAuth()}
                >
                    <IndexRoute component={ProductDetail} />
                </Route>

                <Route
                    name="My Records"
                    path="/my-records"
                    onEnter={requireAuth()}
                >
                    <IndexRoute name="My Records" component={MyRecords} />
                </Route>

                <Route
                    name="Bulk Conflict"
                    path="/bulk-conflict"
                    onEnter={requireAuth()}
                >
                    <IndexRoute name="Bulk Conflict" component={BulkConflict} />
                </Route>

                <Route
                    name="POS Export"
                    path="/pos-export"
                    onEnter={requireAuth()}
                >
                    <IndexRoute name="POS Export" component={PosExport} />
                </Route>

                <Route
                    name="Ui Kit"
                    path="/uikit"
                    // onEnter={requireAuth()}
                >
                    <IndexRoute
                        name="Ui Kit"
                        component={UiKit}
                    />
                </Route>
            </Route>

            <Route component={AppLayout}>
                <Route name="notfound" path="*" component={PageNotFound} />
            </Route>
        </Route>
    );
}
