import React, { Component, Fragment } from 'react';
import { checkSetUrl } from 'src/js/helpers/dataHelpers';
import Dropzone from 'react-dropzone';
import BulkImageUploadPanel from 'src/js/components/BulkImageUploadPanel/BulkImageUploadPanel';
import { bulkImagesUpload } from 'src/js/actions/imageUpload';
import classnames from 'classnames';
import { Auth } from 'components/static';
import { withTranslation } from 'react-i18next';
import { MAX_IMAGES_PER_RECORD } from 'src/js/constants/imagesConstants';

class ProductImageGalleryDropper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUploader: false,
            filesData: [],
            files: [],
            dragoverClass: false
        };
    }

    ref(instance) {
        this._instance = instance;
        return instance;
    }

    setValue() {
        const { newImageUrl = '' } = this.props;
        const defaultUrlVal = 'https://';
        const len = newImageUrl.length;
        if (len > 0) {
            const uxTry = len < 15 && newImageUrl.indexOf('.') === -1;
            if (
                newImageUrl.indexOf('http://') > -1 ||
                newImageUrl.indexOf('https://') > -1 ||
                uxTry
            )
                return newImageUrl;
            return checkSetUrl(defaultUrlVal + newImageUrl);
        }
        return newImageUrl;
    }


    toggleUploader(val = null) {
        this.props.parentActionCallback('endDrag');
        return this.setState({
            showUploader: val !== null ? val : !this.state.showUploader,
            files: [],
            filesData: []
        });
    }

    onDropN(files) {
        this.toggleUploader(true);
        this.filesData = files;
    }

    onDrop(files) {
        const validatedFiles = this.state.filesData.concat(
            files.map(file => {
                const imageSize = (file.size / (1024 * 1024)).toFixed(2);
                return Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    imageSize,
                    status: null
                });
            })
        );
        this.setState({
            filesData: validatedFiles,
            showUploader: true
        });
    }

    render() {
        const {
            dispatch,
            data,
            filesTotalToUploadCount,
            t
        } = this.props;
        const { showUploader, filesData, dragoverClass } = this.state;

        const dragCx = classnames('images-modal__upload-block-dropper');

        return (
            <Fragment>
                <div
                    className={classnames('c-product__image-upload-dropper', {
                        'dragoverClass': dragoverClass
                    })}
                >


                    <Dropzone
                        accept="image/*"
                        noClick={true}
                        onDrop={files => this.onDrop(files)}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                {...getRootProps({
                                    className: dragCx
                                })
                                }
                            >

                                <Auth
                                    restrictTo="licensee"
                                >
                                    <input {...getInputProps()} />
                                    <div className="images-modal__dropzone">
                                        <h4>{t('modules.product_detail.product_images.drag_and_drop')}</h4>
                                        <h4 className='subtitle'>
                                            {t('bulk_images_upload.you_can_upload.you_can_upload')}
                                            {MAX_IMAGES_PER_RECORD}{' '}
                                            {t('bulk_images_upload.you_can_upload.images')}
                                        </h4>
                                    </div>
                                </Auth>
                            </div>
                        )}
                    </Dropzone>


                </div>
                {showUploader && (
                    <div className='images-modal__bulkimagebox'>
                        <BulkImageUploadPanel
                            toggleDataFormat={() => this.toggleDataFormat()}
                            close={() => this.toggleUploader()}
                            dispatch={dispatch}
                            data={data}
                            filesData={filesData}
                            bulkImagesUpload={(files) =>
                                dispatch(
                                    bulkImagesUpload(files, data.record_id, data)
                                )
                            }
                            filesTotalToUploadCount={filesTotalToUploadCount}
                        />
                    </div>
                )}
            </Fragment>
        );
    }
}

export default withTranslation()(ProductImageGalleryDropper)
