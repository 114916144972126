import React, { useState, useEffect, useRef } from 'react';
import Button from 'modules/UiKit/components/Button/Button';
import moment from 'moment';
import MediaView from 'src/js/components/MediaView';
import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ApprovedIcon } from 'src/js/components/static/Icon/svg-icons/approved.svg';
import { ReactComponent as PendingIcon } from 'src/js/components/static/Icon/svg-icons/pending.svg';
import { ReactComponent as RejectedIcon } from 'src/js/components/static/Icon/svg-icons/rejected-icon.svg';
import { Icon, Tooltip } from 'src/js/components/static';
import { ReactComponent as CloseIcon } from 'src/js/components/static/Icon/svg-icons/close-icon.svg';
import recordPlaceholder from 'statics/imgs/record-placeholder.png';
import EmptyTablePlaceholder from './EmptyTablePlaceholder';
import {
    languagesArray
} from 'modules/Toolkit/TrademarkAuthorisationLetter/Constants/TrademarkAuthorisationLetterFormConstants';
import { generateExportPdf } from 'apicalls/other/tradeMark';

const AuthorizationsTable = ({ requests = [], loading, onViewRequest }) => {
    const { t } = useTranslation();
    const [filteredRequests, setFilteredRequests] = useState(requests);
    const [sortConfig, setSortConfig] = useState({
        key: 'created_at', direction: 'desc'
    });
    const [searchConfig, setSearchConfig] = useState({});
    const [searchInputs, setSearchInputs] = useState({});
    const [activeSearchField, setActiveSearchField] = useState(null);
    const [dropdownOpenRows, setDropdownOpenRows] = useState([]);

    const tableRef = useRef(null);
    const headerRef = useRef(null);
    const scrollbarRef = useRef(null);
    const mainTableRef = useRef(null);
    const tableHeaderWrapRef = useRef(null);
    const popoverRef = useRef(null);

    const headers = [{
        key: 'company_info.legal_company_name',
        label: t('toolkit.trademark_authorization.table.headers.licensee'),
        searchable: true
    }, {
        key: 'trademark_request_id',
        label: t('toolkit.trademark_authorization.table.headers.trademark_request_id'),
        searchable: true
    }, {
        key: 'created_at',
        label: t('toolkit.trademark_authorization.table.headers.request_date'),
        searchable: true
    }, {
        key: 'status',
        label: t('toolkit.trademark_authorization.table.headers.status'),
        searchable: true
    }, { key: 'action', label: t('toolkit.trademark_authorization.table.headers.action'), searchable: false }];

    const handleScroll = debounce((e, ref) => {
        if (ref === 'table' && tableRef.current && headerRef.current && scrollbarRef.current) {
            headerRef.current.scrollLeft = tableRef.current.scrollLeft;
            scrollbarRef.current.scrollLeft = tableRef.current.scrollLeft;
        }

        if (ref === 'header' && tableRef.current && headerRef.current && scrollbarRef.current) {
            tableRef.current.scrollLeft = headerRef.current.scrollLeft;
            scrollbarRef.current.scrollLeft = headerRef.current.scrollLeft;
        }

        if (ref === 'scrollbar' && tableRef.current && headerRef.current && scrollbarRef.current) {
            headerRef.current.scrollLeft = scrollbarRef.current.scrollLeft;
            tableRef.current.scrollLeft = scrollbarRef.current.scrollLeft;
        }
    }, 10);

    const scrollHandler = () => {
        if (!tableHeaderWrapRef.current) return;

        const siteHeader = document.getElementById('siteHeader');

        if (window.scrollY > 120) {
            if (siteHeader) siteHeader.style.boxShadow = 'none';
            tableHeaderWrapRef.current.classList.add('sticky');
        } else {
            if (siteHeader) siteHeader.style.boxShadow = '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
            tableHeaderWrapRef.current.classList.remove('sticky');
        }

        if (tableRef.current && scrollbarRef.current) {
            const distanceToBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
            const rect = tableRef.current.getBoundingClientRect();
            const tableDistanceToBottom = Math.max(0, document.documentElement.scrollHeight - (rect.bottom + window.pageYOffset));

            if (distanceToBottom < tableDistanceToBottom + 40) {
                scrollbarRef.current.classList.add('bottom_fixed');
            } else {
                scrollbarRef.current.classList.remove('bottom_fixed');
            }
        }
    };

    const handleClickOutside = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            setActiveSearchField(null);
        }

        const isClickInsideSelectOption = event.target.closest('.select-option-item');
        const isClickInsideDropdown = event.target.closest('.select-drop-down--multilist');
        const isClickOnViewButton = event.target.closest('.c-pointer') || event.target.closest('button') && event.target.textContent === t('buttons.view');

        if (!isClickInsideSelectOption && !isClickInsideDropdown && !isClickOnViewButton) {
            setDropdownOpenRows([]);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        document.addEventListener('scroll', scrollHandler);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('scroll', scrollHandler);
            const siteHeader = document.getElementById('siteHeader');
            if (siteHeader) {
                siteHeader.style.boxShadow = '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
            }
        };
    }, []);

    useEffect(() => {
        applyFiltersAndSort();
    }, [requests, sortConfig, searchConfig]);

    const handleSort = (key) => {
        let direction;
        if (sortConfig.key !== key) {
            direction = 'asc';
        } else if (sortConfig.direction === 'asc') {
            direction = 'desc';
        } else if (sortConfig.direction === 'desc') {
            direction = null;
            key = null;
        }
        setSortConfig({ key, direction });
    };

    const toggleSearchInput = (key, e) => {
        if (e) {
            e.stopPropagation();
        }

        setActiveSearchField(activeSearchField === key ? null : key);

        if (activeSearchField === key) {
            clearSearch(key);
        }
    };

    const handleSearchChange = (e, key) => {
        const value = e.target.value;
        setSearchInputs({
            ...searchInputs, [key]: value
        });

        debounce(() => {
            setSearchConfig({
                ...searchConfig, [key]: value
            });
        }, 300)();
    };

    const handleSearchKeyDown = (e, key) => {
        if (e.key === 'Escape') {
            toggleSearchInput(key);
        }
    };

    const clearSearch = (key) => {
        setSearchInputs(prev => ({
            ...prev, [key]: ''
        }));

        setSearchConfig(prev => {
            const newConfig = { ...prev };
            delete newConfig[key];
            return newConfig;
        });
    };

    const getNestedValue = (obj, path) => {
        if (!path) return obj;
        const parts = path.split('.');
        let current = obj;
        for (const part of parts) {
            if (current === null || current === undefined) return '';
            current = current[part];
        }
        return current || '';
    };

    const applyFiltersAndSort = () => {
        let result = [...requests];

        Object.keys(searchConfig).forEach(key => {
            if (searchConfig[key]) {
                const searchTerm = searchConfig[key].toLowerCase();
                result = result.filter(item => {
                    const value = getNestedValue(item, key);
                    if (typeof value === 'string') {
                        return value.toLowerCase().includes(searchTerm);
                    } else if (typeof value === 'number') {
                        return value.toString().includes(searchTerm);
                    } else if (value instanceof Date) {
                        return value.toDateString().toLowerCase().includes(searchTerm);
                    }
                    return false;
                });
            }
        });

        if (sortConfig.key) {
            result.sort((a, b) => {
                const aValue = getNestedValue(a, sortConfig.key);
                const bValue = getNestedValue(b, sortConfig.key);

                if (sortConfig.key === 'created_at') {
                    const dateA = new Date(aValue || 0);
                    const dateB = new Date(bValue || 0);
                    return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }

        setFilteredRequests(result);
    };

    const formatDate = (date) => {
        return moment(date).format('DD MMM YYYY');
    };

    const handleView = (request) => {
        if (request.draft_letter) {
            onViewRequest(request.id, true);
        } else {
            onViewRequest(request.id, false);
        }
    };

    const toggleDropdownRow = (id) => {
        setDropdownOpenRows(prev => {
            if (prev.includes(id)) {
                return [];
            }
            return [id];
        });
    };

    const findLanguage = (language) => {
        const foundLanguage = find(languagesArray, lng => lng.name === language);
        return foundLanguage ? foundLanguage.label : language;
    };

    const handleExportPdf = (id, language) => {
        generateExportPdf(id, language);
        setDropdownOpenRows([]);
    };

    const LanguageDropdown = ({ request }) => {
        const dropdownRef = useRef(null);

        const availableLanguages = Array.isArray(request.request_languages) && request.request_languages.length > 0 ? request.request_languages : ['en'];

        const isOpen = dropdownOpenRows.includes(request.id);

        const handleButtonClick = (e) => {
            e.stopPropagation();

            if (availableLanguages.length > 1) {
                toggleDropdownRow(request.id);
            } else {
                handleExportPdf(request.id, availableLanguages[0]);
            }
        };

        const handleLanguageSelect = (e, language) => {
            e.stopPropagation();
            e.preventDefault();
            handleExportPdf(request.id, language);
        };

        return (<div ref={dropdownRef} className="select-dropdown select-single u-relative button-dropdown">
                <Button
                    size="small"
                    type="secondary"
                    className="c-pointer"
                    onClick={handleButtonClick}
                >
                    {t('buttons.view')}
                </Button>

                {availableLanguages.length > 1 && isOpen && (
                    <div className="select-drop-down--multilist select-single-option-wrapper">
                        <div className="option-list">
                            {availableLanguages.map(language => (<div
                                    className="select-option-item"
                                    onClick={(e) => handleLanguageSelect(e, language)}
                                    key={language}
                                >
                                    <span className="option-text">{findLanguage(language)}</span>
                                </div>))}
                        </div>
                    </div>)}
            </div>);
    };

    const renderActionCell = (request) => {
        if (request.status === 'rejected' && request.rejector_info && request.rejector_info.timestamp) {
            const rejectionDate = formatDate(request.rejector_info.timestamp);
            const rejectionNotes = request.rejector_info.notes || t('toolkit.trademark_authorization.request.no_rejection_notes');

            return (<div className="rejection-info">
                    <Tooltip
                        position="top"
                        trigger="hover"
                        overlay={() => (<div className="rejection-notes-tooltip">
                                <span>{rejectionNotes}</span>
                            </div>)}
                        button={<span className="rejection-date">{rejectionDate}</span>}
                    />
                </div>);
        }

        if (request.status === 'approved' && request.draft_letter) {
            return <LanguageDropdown request={request} />;
        }

        return (<Button
                type="secondary"
                size="small"
                onClick={() => handleView(request)}
            >
                {request.draft_letter && request.status !== 'approved' ? t('toolkit.trademark_authorization.table.view_draft') : t('toolkit.trademark_authorization.table.view_request')}
            </Button>);
    };

    const renderHeaderCell = (header) => {
        if (header.key === 'action') {
            return (<th key={header.key} className="c-rec-table__head-cell action-column">
                    {header.label}
                </th>);
        }

        return (<th
                key={header.key}
                className={`c-rec-table__head-cell ${header.key === 'company_info.legal_company_name' ? 'licensee-column' : ''}`}
            >
                {activeSearchField === header.key ? (<div className="header-search-active" ref={popoverRef}>
                        <input
                            type="text"
                            value={searchInputs[header.key] || ''}
                            onChange={(e) => handleSearchChange(e, header.key)}
                            onKeyDown={(e) => handleSearchKeyDown(e, header.key)}
                            placeholder={`Search ${header.label}`}
                            className="search-input"
                            autoFocus
                        />
                        <button
                            className="close-button"
                            onClick={(e) => toggleSearchInput(header.key, e)}
                        >
                            <CloseIcon className="close-icon" />
                        </button>
                    </div>) : (<div className="u-flex-align">
                        {header.searchable && (<Icon
                                className="u-clickable"
                                icon="SEARCH"
                                fill="white"
                                onClick={(e) => toggleSearchInput(header.key, e)}
                            />)}
                        <div className="header-label" onClick={() => handleSort(header.key)}>
                            {header.label}
                            <Icon
                                icon={sortConfig.key === header.key && sortConfig.direction === 'asc' ? 'TRIANGLE_UP' : sortConfig.key === header.key && sortConfig.direction === 'desc' ? 'TRIANGLE_UP' : 'TRIANGLE_BOTH'}
                                size="15"
                                fill="white"
                                tooltip
                                className={sortConfig.key === header.key && sortConfig.direction === 'desc' ? 'is-down' : sortConfig.key === header.key && sortConfig.direction === 'asc' ? 'is-up' : ''}
                            />
                        </div>
                    </div>)}
            </th>);
    };

    if (loading) {
        return (<div className="loading-state">
                {t('toolkit.trademark_authorization.request.loading')}
            </div>);
    }

    if (!requests.length) {
        return <EmptyTablePlaceholder />;
    }

    return (<div className="authorization-request-table">
            <div className="table-header">
                <h2>{t('toolkit.trademark_authorization.request.authorization_requests')}</h2>
            </div>

            <div className="c-rec-table__wrapper">
                <div
                    ref={tableHeaderWrapRef}
                    className="c-rec-table c-rec-table__header-container catalogue-table-head"
                >
                    <div
                        className="c-rec-table__table-header"
                        ref={headerRef}
                        onScroll={(e) => handleScroll(e, 'header')}
                    >
                        <table className="c-rec-table__top-header c-rec-table--green-head">
                            <thead>
                            <tr ref={mainTableRef}>
                                {headers.map(renderHeaderCell)}
                            </tr>
                            </thead>
                        </table>
                    </div>
                </div>

                <div className="c-rec-table">
                    <div
                        className="c-rec-table__main-table"
                        ref={tableRef}
                        onScroll={(e) => handleScroll(e, 'table')}
                        style={{ minHeight: 100 }}
                    >
                        <table>
                            <tbody>
                            {filteredRequests.length === 0 ? (<tr>
                                    <td colSpan={headers.length} className="c-rec-table__cell no-data">
                                        <div className="u-full-width u-flex-column u-flex-align">
                                            <h3 className="u-text-center u-margin-bottom">{t('toolkit.trademark_authorization.table.no_matching_results')}</h3>
                                            <p className="u-text-center u-margin-bottom">
                                                {t('filters.clear_any_applied_filter')}
                                            </p>
                                        </div>
                                    </td>
                                </tr>) : (filteredRequests.map(request => {
                                    request.org_image = `https://assets.xelacore.io/org/${request.licensee_organisation_id}.png`;

                                    return (<tr key={request.id}>
                                            <td className="c-rec-table__cell licensee-column">
                                                <div className="u-flex-align">
                                                    <div className="c-rec-table__image-cell">
                                                        <div className="c-rec-table__image-icon">
                                                            <MediaView
                                                                src={request.org_image}
                                                                alt={t('toolkit.trademark_authorization.details.company_logo_alt', {
                                                                    company: (request.company_info && request.company_info.legal_company_name) || t('toolkit.trademark_authorization.details.unknown_company')
                                                                })}
                                                                fallbackSrc={recordPlaceholder}
                                                                useImgTag
                                                            />
                                                        </div>
                                                    </div>
                                                    <span>
                                                            {(request.company_info && request.company_info.legal_company_name) || t('toolkit.trademark_authorization.details.unknown_company')}
                                                        </span>
                                                </div>
                                            </td>
                                            <td className="c-rec-table__cell reference-column">
                                                {request.trademark_request_id}
                                            </td>
                                            <td className="c-rec-table__cell date-column">
                                                {formatDate(request.created_at)}
                                            </td>
                                            <td className="c-rec-table__cell status-column">
                                                    <span
                                                        className={`c-rec-table__icon-holder ${request.status} status-cell`}>
                                                        {request.status === 'pending' && <PendingIcon />}
                                                        {request.status === 'approved' && <ApprovedIcon />}
                                                        {request.status === 'rejected' && <RejectedIcon />}
                                                        {capitalize(request.status)}
                                                    </span>
                                            </td>
                                            <td className="c-rec-table__cell action-column">
                                                {renderActionCell(request)}
                                            </td>
                                        </tr>);
                                }))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div
                    ref={scrollbarRef}
                    className="c-rec-table__bottom-scrollbar"
                    onScroll={(e) => handleScroll(e, 'scrollbar')}
                >
                    <div
                        style={{
                            height: 20, width: (tableRef.current && tableRef.current.scrollWidth) || 0
                        }}
                    />
                </div>
            </div>
        </div>);
};

export default AuthorizationsTable;
