import React from 'react';

import bulkLoading from 'statics/imgs/desktop/records/bulk_loading.png';
import { Button } from '../static';
import { useTranslation } from 'react-i18next';

const BulkEditLoading = ({onRetry}) => {
    const { t } = useTranslation();
    
    return (
        <div className='c-bulk-update__loading'>
            <div><img src={bulkLoading} alt="Bulk Edit Loading" height={80} /></div>
            <div><h3>{t('bulk_edit.bulk_edit_modal.bulk_edit_loading.bulk_loading')}</h3></div>
            <div><small>{t('bulk_edit.bulk_edit_modal.bulk_edit_loading.may_take_time')}</small></div>
            <div>
                <Button
                    onClick={onRetry}
                >
                    {t('buttons.retry')}
                </Button>
            </div>
        </div>
    );
};

export default BulkEditLoading;
