import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    fetchQuicksightDashboard
} from 'src/js/apicalls/other/quicksightDashboard';
import emptyReport from 'statics/imgs/report-empty.png';
import Button from 'modules/UiKit/components/Button/Button';
import { useTranslation } from 'react-i18next';

function CustomReports({xelacore}) {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState({});
    const {
        auth: {
            customReports
        }
    } = xelacore; const [dashboardUrl, setDashboardUrl] = useState(null);
    const [errorDashboard, setDashboardError] = useState(false);
    const [busyReport, setBusyReport] = useState(false);

    useEffect(() => {
        if (!!customReports && customReports.length > 0) {
            setActiveTab(customReports && customReports[0] || {});
        }
    }, [customReports])

    useEffect(() => {
        if(!!activeTab && !!activeTab.view && activeTab.view !== 'dashboard') {
            fetchDashboard()
        }
    }, [activeTab]);

    const fetchDashboard = () => {
        setBusyReport(true);

        fetchQuicksightDashboard(!!activeTab && activeTab.view || '').then(response => {
            setDashboardUrl(response.view_url_init);
            setDashboardError(false);
            setBusyReport(false);
        }, () => {
            setDashboardUrl(null)
            setDashboardError(true);
            setBusyReport(false);
        })
    }

    return (
        <div className="">
            <div className="c-site-header c-site-header-second">
                <div className="c-site-header__container">
                    <div className="c-site-header__nav">
                        {!!customReports && customReports.length > 0 && (
                            <div>
                                {customReports.map(
                                    (report) => {
                                        return <div className={`c-site-header__nav-item ${report.view === (activeTab && activeTab.view) ? 'c-site-header__nav-item--active' : ''}`}>
                                            <a onClick={() => { setActiveTab(report) }}>{report.name}</a>
                                        </div>
                                    }
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="custom-reports-container">
                <div>
                    {!errorDashboard && (<iframe src={dashboardUrl} style={{ height: 'calc(100vh - 120px)', overflowY: 'auto', width: '100%', boxShadow: 'none', border: '0' }}></iframe>)}

                    {errorDashboard && (
                        <div className='empty-report'>
                            <img src={emptyReport} />
                            <p>{t('modules.quick_sight.something_went_wrong')}</p>
                            <Button
                                type="primary"
                                size="small"
                                disabled={busyReport}
                                onClick={() => fetchDashboard()}
                            >
                                {t('buttons.refresh')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({
    xelacore,
    dispatch
}) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(CustomReports);

