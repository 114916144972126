import React from 'react';

import imgLogo from 'statics/imgs/app/xelacore-logo.svg';
import { useTranslation } from 'react-i18next';

const TechnicalDifficulties = () => {
    const { t } = useTranslation();

    return (
        <div className="c-maintenance">
            <img
                src={imgLogo}
                alt="Xelacore"
                className="c-maintenance__logo"
                height="50"
            />
            <div className="c-maintenance__body">
                <h1 className="c-maintenance__header" dangerouslySetInnerHTML={{ __html: t('modules.single_static_page.technical_difficulties') }}></h1>
                <p>
                    <strong>
                        {t('modules.single_static_page.return_to_normal')}
                    </strong>
                </p>
                <p>
                    <strong>
                        {t('modules.single_static_page.urgent_enquiries')}{' '}
                        <a href="mailto:info@fabacus.com">info@fabacus.com</a>
                    </strong>
                </p>
            </div>
        </div>
    )
};

export default TechnicalDifficulties;
