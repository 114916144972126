import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import {
    fetchAuthorizationRequest,
    updateDraftLetter,
    updateAuthorizationStatus
} from 'src/js/apicalls/other/authorization';
import UiButton from 'modules/UiKit/components/Button/Button';
import LoadingSpinner from 'components/Charts/LoadingSpinner';
import { Modal } from 'src/js/components/static';
import { showModal, hideModal } from 'src/js/actions/xelacore';
import {
    languagesArray
} from 'src/js/modules/Toolkit/TrademarkAuthorisationLetter/Constants/TrademarkAuthorisationLetterFormConstants';
import Breadcrumb from './Breadcrumb';
import successHandler from 'src/js/actions/successHandler';
import errorHandler from 'src/js/actions/errorHandler';

const DraftLetterPreview = ({ dispatch }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [draftLetters, setDraftLetters] = useState([]);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [editedBodies, setEditedBodies] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [companyName, setCompanyName] = useState('');

    const dataRef = useRef(null);
    const saveTimerRef = useRef(null);
    const hasPendingChangesRef = useRef(false);
    const lastEditTimestampRef = useRef(0);
    const inactivityTimeoutRef = useRef(null);
    const textareaRef = useRef(null);
    const lineIndicatorRef = useRef(null);

    const INACTIVITY_THRESHOLD_MS = 2000;

    const getDraftPreviewId = () => {
        const params = new URLSearchParams(window.location.search);
        return params.get('draftPreview');
    };

    const requestId = getDraftPreviewId();

    const updateLineIndicator = () => {
        if (!textareaRef.current) return;

        const textarea = textareaRef.current;
        const caretPosition = textarea.selectionStart;

        const mirror = document.createElement('div');
        mirror.style.cssText = `
            position: absolute;
            top: -9999px;
            left: -9999px;
            width: ${textarea.clientWidth}px;
            padding: ${window.getComputedStyle(textarea).padding};
            font: ${window.getComputedStyle(textarea).font};
            line-height: ${window.getComputedStyle(textarea).lineHeight};
            white-space: pre-wrap;
            word-wrap: break-word;
            overflow-wrap: break-word;
            overflow: hidden;
        `;

        const textBeforeCaret = textarea.value.substring(0, caretPosition);

        mirror.textContent = textBeforeCaret;
        const caretSpan = document.createElement('span');
        caretSpan.textContent = '|';
        mirror.appendChild(caretSpan);

        document.body.appendChild(mirror);

        const caretRect = caretSpan.getBoundingClientRect();
        const mirrorRect = mirror.getBoundingClientRect();

        const caretTop = caretRect.top - mirrorRect.top;

        document.body.removeChild(mirror);

        const lineHeight = parseFloat(window.getComputedStyle(textarea).lineHeight);

        const textareaScrollTop = textarea.scrollTop;
        const relativeCaretTop = caretTop - textareaScrollTop;

        if (lineIndicatorRef.current) {
            lineIndicatorRef.current.style.top = `${relativeCaretTop - 3}px`;
            lineIndicatorRef.current.style.height = `${lineHeight}px`;

            const textareaRect = textarea.getBoundingClientRect();
            const textareaPaddingTop = parseFloat(window.getComputedStyle(textarea).paddingTop);
            const textareaPaddingBottom = parseFloat(window.getComputedStyle(textarea).paddingBottom);
            const visibleAreaTop = textareaPaddingTop;
            const visibleAreaBottom = textareaRect.height - textareaPaddingBottom;

            if (relativeCaretTop >= visibleAreaTop && relativeCaretTop <= visibleAreaBottom) {
                lineIndicatorRef.current.style.display = 'block';
            } else {
                lineIndicatorRef.current.style.display = 'none';
            }
        }
    };

    const loadRequestData = () => {
        if (!requestId) return;

        setLoading(true);
        setError(null);

        fetchAuthorizationRequest(requestId)
            .then(response => {
                if (response && response.trademarkRequest) {
                    dataRef.current = response;

                    const companyInfo = response.trademarkRequest.company_info || {};
                    setCompanyName(companyInfo.legal_company_name || t('toolkit.trademark_authorization.details.unknown_company'));

                    const draftLetter = response.trademarkRequest.draft_letter || [];
                    setDraftLetters(draftLetter);

                    const initialBodies = draftLetter.reduce((acc, letter, index) => {
                        acc[index] = letter.body;
                        return acc;
                    }, {});
                    setEditedBodies(initialBodies);
                } else {
                    setError(t('toolkit.trademark_authorization.request.invalid_response'));
                }
            })
            .catch(err => {
                setError(err && err.response && err.response.data && err.response.data.message || t('toolkit.trademark_authorization.request.failed_fetch'));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadRequestData();

        return () => {
            if (saveTimerRef.current) {
                clearTimeout(saveTimerRef.current);
            }
            if (inactivityTimeoutRef.current) {
                clearTimeout(inactivityTimeoutRef.current);
            }
        };
    }, [requestId]);

    useEffect(() => {
        if (!loading && !error && draftLetters.length > 0 && textareaRef.current) {
            textareaRef.current.focus();
            textareaRef.current.setSelectionRange(0, 0);
            updateLineIndicator();
        }
    }, [loading, error, draftLetters.length]);

    useEffect(() => {
        const textarea = textareaRef.current;
        if (!textarea) return;

        const handleCaretMove = () => {
            window.requestAnimationFrame(() => {
                updateLineIndicator();
            });
        };

        const handleSelectionChange = () => {
            if (document.activeElement === textarea) {
                handleCaretMove();
            }
        };

        textarea.addEventListener('click', handleCaretMove);
        textarea.addEventListener('keyup', handleCaretMove);
        textarea.addEventListener('keydown', handleCaretMove);
        textarea.addEventListener('mouseup', handleCaretMove);
        textarea.addEventListener('mousedown', handleCaretMove);
        textarea.addEventListener('input', handleCaretMove);
        textarea.addEventListener('focus', handleCaretMove);

        textarea.addEventListener('scroll', handleCaretMove);

        document.addEventListener('selectionchange', handleSelectionChange);

        textarea.addEventListener('select', handleCaretMove);
        textarea.addEventListener('change', handleCaretMove);

        textarea.addEventListener('keypress', handleCaretMove);

        window.addEventListener('resize', handleCaretMove);

        handleCaretMove();

        const intervalCheck = setInterval(() => {
            if (document.activeElement === textarea) {
                handleCaretMove();
            }
        }, 100);

        return () => {
            textarea.removeEventListener('click', handleCaretMove);
            textarea.removeEventListener('keyup', handleCaretMove);
            textarea.removeEventListener('keydown', handleCaretMove);
            textarea.removeEventListener('mouseup', handleCaretMove);
            textarea.removeEventListener('mousedown', handleCaretMove);
            textarea.removeEventListener('input', handleCaretMove);
            textarea.removeEventListener('focus', handleCaretMove);

            textarea.removeEventListener('scroll', handleCaretMove);

            document.removeEventListener('selectionchange', handleSelectionChange);

            textarea.removeEventListener('select', handleCaretMove);
            textarea.removeEventListener('change', handleCaretMove);
            textarea.removeEventListener('keypress', handleCaretMove);

            window.removeEventListener('resize', handleCaretMove);

            clearInterval(intervalCheck);
        };
    }, [activeTabIndex, draftLetters.length]);

    const getBreadcrumbItems = () => {
        const items = [
            {
                label: t('toolkit.trademark_authorization.breadcrumb.requests'),
                link: '/authorization-request'
            }
        ];

        if (companyName) {
            items.push({
                label: `${t('toolkit.trademark_authorization.breadcrumb.draft')} - ${companyName}`,
                link: null
            });
        }

        return items;
    };

    const saveLetterContent = () => {
        if (!dataRef.current || !dataRef.current.trademarkRequest || saving || isSubmitting) return;

        setSaving(true);

        try {
            const dataToUpdate = JSON.parse(JSON.stringify(dataRef.current.trademarkRequest));

            if (dataToUpdate.draft_letter) {
                Object.keys(editedBodies).forEach(indexKey => {
                    const index = parseInt(indexKey, 10);
                    if (dataToUpdate.draft_letter[index]) {
                        dataToUpdate.draft_letter[index].body = editedBodies[index];
                        dataToUpdate.draft_letter[index].updated_at = new Date().toISOString();
                    }
                });
            }

            if (dataToUpdate.manufacturer_id !== undefined) {
                delete dataToUpdate.manufacturer_id;
            }

            updateDraftLetter(requestId, {
                draft_letter: dataToUpdate.draft_letter
            })
                .then(response => {
                    if (response && response.draft_letter) {
                        setDraftLetters(response.draft_letter);

                        if (dataRef.current) {
                            const updatedData = { ...dataRef.current };
                            updatedData.trademarkRequest = response;
                            dataRef.current = updatedData;
                        }
                    }
                    hasPendingChangesRef.current = false;
                })
                .catch(err => {
                    errorHandler(dispatch, {
                        message: t('toolkit.trademark_authorization.draft_letter.save_error')
                    });
                    setError(err && err.response && err.response.data && err.response.data.message ||
                        t('toolkit.trademark_authorization.draft_letter.save_error'));
                })
                .finally(() => {
                    setSaving(false);
                });
        } catch (err) {
            setSaving(false);
            errorHandler(dispatch, {
                message: t('toolkit.trademark_authorization.draft_letter.save_error')
            });
        }
    };

    const handleBodyChange = (index, newBody) => {
        setEditedBodies(prev => ({
            ...prev,
            [index]: newBody
        }));

        updateLineIndicator();

        hasPendingChangesRef.current = true;
        lastEditTimestampRef.current = Date.now();

        if (inactivityTimeoutRef.current) {
            clearTimeout(inactivityTimeoutRef.current);
        }

        inactivityTimeoutRef.current = setTimeout(() => {
            if (hasPendingChangesRef.current && !saving && !isSubmitting) {
                saveLetterContent();
            }
        }, INACTIVITY_THRESHOLD_MS);
    };

    const forceSave = () => {
        if (inactivityTimeoutRef.current) {
            clearTimeout(inactivityTimeoutRef.current);
            inactivityTimeoutRef.current = null;
        }

        if (hasPendingChangesRef.current && !saving && !isSubmitting) {
            saveLetterContent();
        }
    };

    const redirectToTable = () => {
        browserHistory.push({
            pathname: '/authorization-request'
        });
    };

    const handleApprove = () => {
        forceSave();

        setIsSubmitting(true);
        updateAuthorizationStatus(requestId, {
            status: 'approved',
            notes: t('toolkit.trademark_authorization.request.approve_notes')
        })
            .then(response => {
                setError(null);
                if (response && response.status === 'approved') {
                    successHandler(
                        dispatch,
                        t('toolkit.trademark_authorization.request.success_approve')
                    );
                    redirectToTable();
                }
            })
            .catch(() => {
                errorHandler(
                    dispatch,
                    {
                        message: t('toolkit.trademark_authorization.request.error_approve')
                    }
                );
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const openDeclineModal = () => {
        forceSave();

        const DeclineModalContent = () => {
            const [notes, setNotes] = useState('');

            const handleConfirm = () => {
                setIsSubmitting(true);
                updateAuthorizationStatus(requestId, {
                    status: 'rejected',
                    notes: notes || t('toolkit.trademark_authorization.request.decline_notes')
                })
                    .then(response => {
                        setError(null);
                        if (response && response.status === 'rejected') {
                            successHandler(
                                dispatch,
                                t('toolkit.trademark_authorization.request.success_decline')
                            );
                            redirectToTable();
                        }
                    })
                    .catch(() => {
                        errorHandler(
                            dispatch,
                            {
                                message: t('toolkit.trademark_authorization.request.error_decline')
                            }
                        );
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                        dispatch(hideModal());
                    });
            };

            return (
                <div className="decline-modal-content">
                    <p>{t('toolkit.trademark_authorization.request.decline_confirmation_text')}</p>
                    <div className="form-group">

                        <textarea
                            id="declineNotes"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            placeholder={t('toolkit.trademark_authorization.request.decline_notes_placeholder')}
                            className="decline-notes-textarea"
                            rows={4}
                            required={true}
                            disabled={isSubmitting}
                        />
                    </div>
                    <div className="modal-footer">
                        <UiButton
                            type="secondary-danger"
                            size="small"
                            onClick={() => dispatch(hideModal())}
                            disabled={isSubmitting}
                        >
                            {t('buttons.cancel')}
                        </UiButton>
                        <UiButton
                            type="primary"
                            size="small"
                            onClick={handleConfirm}
                            disabled={isSubmitting}
                        >
                            {t('buttons.confirm')}
                        </UiButton>
                    </div>
                </div>
            );
        };

        const modal = (
            <Modal
                title={t('toolkit.trademark_authorization.request.decline_confirmation_title')}
                className="decline-confirmation-modal"
                isSmall
                body={<DeclineModalContent />}
            />
        );

        dispatch(showModal(modal));
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (hasPendingChangesRef.current) {
                try {
                    const xhr = new XMLHttpRequest();
                    xhr.open('PUT', `${window.location.origin}/trademark-letter/api/requests/${requestId}/draft`, false);
                    xhr.setRequestHeader('Content-Type', 'application/json');

                    const dataToUpdate = JSON.parse(JSON.stringify(dataRef.current.trademarkRequest));

                    if (dataToUpdate.draft_letter) {
                        Object.keys(editedBodies).forEach(indexKey => {
                            const index = parseInt(indexKey, 10);
                            if (dataToUpdate.draft_letter[index]) {
                                dataToUpdate.draft_letter[index].body = editedBodies[index];
                                dataToUpdate.draft_letter[index].updated_at = new Date().toISOString();
                            }
                        });
                    }

                    if (dataToUpdate.manufacturer_id !== undefined) {
                        delete dataToUpdate.manufacturer_id;
                    }

                    xhr.send(JSON.stringify({
                        draft_letter: dataToUpdate.draft_letter
                    }));
                } catch (e) {
                }
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [requestId, editedBodies]);

    const getLanguageLabel = (languageCode) => {
        const language = languagesArray.find(lang => lang.name === languageCode);
        return language ? language.label : languageCode.toUpperCase();
    };

    return (
        <div>
            <Breadcrumb items={getBreadcrumbItems()} />
            <div className="toolkit-container">

                {loading && (
                    <div className="loading-container">
                        <LoadingSpinner />
                        <p>{t('toolkit.please_wait')}</p>
                    </div>
                )}

                {error && (
                    <div className="error-state">
                        <p className="error-message">{error}</p>
                        <UiButton
                            type="secondary"
                            size="small"
                            onClick={loadRequestData}
                        >
                            {t('buttons.retry')}
                        </UiButton>
                    </div>
                )}

                {!loading && !error && draftLetters.length > 0 && (
                    <div className="action-buttons-container">
                        <UiButton
                            type="secondary-danger"
                            size="small"
                            onClick={openDeclineModal}
                            disabled={isSubmitting || saving}
                        >
                            {t('toolkit.trademark_authorization.request.decline_request')}
                        </UiButton>

                        <UiButton
                            type="primary"
                            size="small"
                            onClick={handleApprove}
                            disabled={isSubmitting || saving}
                        >
                            {t('toolkit.trademark_authorization.request.approve_request')}
                        </UiButton>
                    </div>)}


                {!loading && !error && draftLetters.length > 0 && (
                    <div className="draft-letter-preview">

                        {draftLetters && draftLetters.length > 1 && (
                            <div className="draft-letter-tabs">
                                {draftLetters.map((letter, index) => (
                                    <button
                                        key={index}
                                        className={`draft-letter-tab ${activeTabIndex === index ? 'active' : ''}`}
                                        onClick={() => {
                                            forceSave();
                                            setActiveTabIndex(index);
                                        }}
                                    >
                                        {getLanguageLabel(letter.language)}
                                    </button>
                                ))}
                            </div>
                        )}

                        <div className={`draft-letter-content ${saving ? 'saving' : ''}`}>
                            <div className="textarea-container">
                                <div className="line-indicator-container">
                                    <div
                                        ref={lineIndicatorRef}
                                        className="current-line-indicator"
                                        aria-hidden="true"
                                    ></div>
                                </div>
                                <textarea
                                    ref={textareaRef}
                                    className="letter-body-textarea"
                                    value={editedBodies[activeTabIndex] || ''}
                                    onChange={(e) => handleBodyChange(activeTabIndex, e.target.value)}
                                    onBlur={forceSave}
                                    onClick={updateLineIndicator}
                                    onKeyDown={updateLineIndicator}
                                    onSelect={updateLineIndicator}
                                    placeholder={t('toolkit.trademark_authorization.draft_letter.placeholder')}
                                    rows={24}
                                    spellCheck="true"
                                />
                            </div>
                        </div>

                        {(saving || isSubmitting) && (
                            <div className="saving-indicator">
                                <LoadingSpinner />
                                <span>
                            </span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default connect()(DraftLetterPreview);
