import React, { Component } from 'react';
import { Icon } from 'src/js/components/static';

import map from 'lodash/map';
import capitalize from 'lodash/capitalize'
import includes from 'lodash/includes';
import size from 'lodash/size';
import last from 'lodash/last';
import each from 'lodash/each';
import FormItemWrapper from 'modules/UiKit/components/FormElements/FormItem';
import { withTranslation } from 'react-i18next';

const EMPTY_ITEM = { label: '', value: '', u_id: 1000 };

// Component to handle the state change of an object element
// We update the entire object here before passing that entire object back up
// Which can then be integrated into the overall state object
class ObjectField extends Component {
    state = {
        data: {},
        newData: []
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                data: this.props.value || {}
            });
        }, 1000);
    }

    onChange(e, key) {
        e.preventDefault();

        const { onChange, value } = this.props;
        const { data } = this.state;
        const newObj = { [key]: e.target.value };

        return this.setState(
            {
                data: Object.assign({}, data, newObj)
            },
            () => onChange(Object.assign({}, value, newObj))
        );
    }

    onChangeNew(e, index, key) {
        e.preventDefault();

        const { onChange } = this.props;
        const { data, newData } = this.state;

        newData[index][key] = e.target.value;

        return this.setState(
            {
                newData
            },
            () => onChange(Object.assign({}, data, this.parseData(newData)))
        );
    }

    parseData(data) {
        let newArray = [];

        each(data, (el) => {
            newArray[el.label] = el.value;
        });

        return newArray;
    }

    deleteElement(i) {
        const { data, newData } = this.state;
        const { onChange } = this.props;

        newData.splice(i, 1);

        return this.setState(
            {
                newData
            },
            () => onChange(Object.assign({}, data, this.parseData(newData)))
        );
    }

    removeAttr(el) {
        const { data } = this.state;
        const { onChange } = this.props;

        const attrsMap = map(data, (i, key) => key);

        if (!!data && !!includes(attrsMap, el)) {
            delete data[el];

            this.setState(
                {
                    data
                },
                () => onChange(Object.assign({}, data, data))
            );
        }
    }

    addElement() {
        const { newData } = this.state;

        const lastId =
            !!newData && size(newData) > 0 ? last(newData).u_id : null;

        newData.push({
            u_id: (!!lastId && lastId + 1) || EMPTY_ITEM.u_id + 1,
            label: EMPTY_ITEM.label,
            value: EMPTY_ITEM.value
        });

        return this.setState({
            newData
        });
    }

    render() {
        const { data, newData } = this.state;
        const { t } = this.props;
        return (
            <div className="c-product__detail-block">
                {Object.keys(data).map((el, i) => {
                    const id = `${el}-${i}`;

                    if(!!el && el.split('_')[0] !== 'dpp' && el.split('_')[0] !== 'esg') {
                        return (
                            <div className="u-flex-align" key={id}>
                                <FormItemWrapper
                                    htmlFor={id}
                                    label={!!el && capitalize(el.replaceAll('_', ' '))}
                                    vertical={false}
                                    name={el}
                                    type="text"
                                    onChange={(e) => this.onChange(e, el)}
                                    value={data[el]}
                                    id={id} />
    
                                <div className="remove-element existing-attr u-margin-half-left">
                                    <Icon
                                        className="u-clickable"
                                        icon="close2"
                                        size={16}
                                        description={t('buttons.remove_elements')}
                                        onClick={() => this.removeAttr(el)}
                                    />
                                </div>
                            </div>
                        );
                    }
                })}

                {!!newData && newData.length > 0 && (
                    <div className="new-attributes">
                        <h4 className="u-margin-top u-margin-bottom">
                            {t('product_form_elements.object_field.new_attributes')}
                        </h4>

                        {newData.map((el, i) => {
                            const id = `form-element-${el.u_id}`;

                            return (
                                <div
                                    key={`child-${id}`}
                                    className="u-flex-align u-flex-between"
                                >

                                    <FormItemWrapper
                                        label={t('product_form_elements.object_field.label')}
                                        vertical={true}
                                        name="label"
                                        type="text"
                                        onChange={(e) =>
                                            this.onChangeNew(
                                                e,
                                                i,
                                                'label'
                                            )
                                        }
                                        value={el.label}
                                        id="label"
                                    />
                                    <FormItemWrapper
                                        label={t('product_form_elements.object_field.value')}
                                        vertical={true}
                                        name="value"
                                        type="text"
                                        onChange={(e) =>
                                            this.onChangeNew(
                                                e,
                                                i,
                                                'value'
                                            )
                                        }
                                        value={el.value}
                                        id="value"
                                    />

                                    <div className="remove-element">
                                        <Icon
                                            className="u-clickable"
                                            icon="close2"
                                            size={16}
                                            description={t('buttons.remove_elements')}
                                            onClick={() =>
                                                this.deleteElement(i)
                                            }
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}

                <div
                    className="u-flex-align u-clickable u-margin-half-top"
                    onClick={() => this.addElement()}
                >
                    <small className="u-padding-half-right">
                        {t('product_form_elements.object_field.add_new_attribute')}
                    </small>

                    <Icon
                        icon="add"
                        size={15}
                        title="Add a new item"
                        description="Add a new item"
                    />
                </div>
            </div>
    );
    }
}

export default withTranslation()(ObjectField)
