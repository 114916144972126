import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as CloseIcon } from 'src/js/components/static/Icon/svg-icons/close-icon.svg';
import CustomSelect from 'modules/UiKit/components/FormElements/CustomSelect';
import Textarea from 'modules/UiKit/components/FormElements/Textarea';
import Button from 'modules/UiKit/components/Button/Button';
import { hideModal, showModal } from 'src/js/actions/xelacore';
import { Modal } from 'components/static';
import map from 'lodash/map';
import { getLicensorContracts, sendRequest } from 'apicalls/other/tradeMark';
import { useTranslation } from 'react-i18next';
import ContractDetails from 'modules/Toolkit/TrademarkAuthorisationLetter/Components/FormElements/ContractDetails';
import ContactInfo from 'modules/Toolkit/TrademarkAuthorisationLetter/Components/FormElements/ContactInfo';
import InformationReview from 'modules/Toolkit/TrademarkAuthorisationLetter/Components/FormElements/InformationReview';
import RecipientDetails from 'modules/Toolkit/TrademarkAuthorisationLetter/Components/FormElements/RecipientDetails';
import CountingPlaceholder from 'modules/Toolkit/TrademarkAuthorisationLetter/Components/FormElements/CountingPlaceholder';
import { ReactComponent as ArrowLeft } from 'src/js/components/static/Icon/svg-icons/arrow-left.svg';
import { languagesArray, defaultContractDetails } from '../Constants/TrademarkAuthorisationLetterFormConstants';
import { toggleTable, toggleException, toggleItemInContract, getInitialVisibilityForExceptions } from '../Utils/TrademarkAuthorisationLetterUtils';
import find from 'lodash/find';
import filter from 'lodash/filter';
import TagsInput from 'modules/Toolkit/TrademarkAuthorisationLetter/Components/FormElements/TagsInput';
import MultipleDropdown from 'modules/UiKit/components/FormElements/MultipleDropdown';

function TrademarkAuthorisationLetterForm({
  dispatch,
  licensors,
  xelacore,
  setTrademarkTableView,
  setFormView,
  isCounting,
  setIsCounting,
  trademarksExists,
  getTradeMarks
}) {
    const { t } = useTranslation();
    const [requestType, setRequestType] = useState('');
    const [count, setCount] = useState(5);
    const [currentStep, setCurrentStep] = useState(0);
    const { auth: { companyData = {} } = {} } = xelacore;

    const [licensor, setLicensor] = useState('');
    const [note, setNote] = useState('');
    const [recipientType, setRecipientType] = useState('');
    const [recipientDetails, setRecipientDetails] = useState({
        recipientsName: '',
        companyName: '',
        companyAddress: ''
    });
    const [languages, setLanguages] = useState(['en']);
    const [businessInfo, setBusinessInfo] = useState({
        legalCompanyName:
            (companyData.information && companyData.information.legal_company_name) || '',
        companyRegistrationNumber:
            (companyData.information && companyData.information.company_registration_number) || '',
        vat: (companyData.information && companyData.information.uk_vat) || '',
        streetAddress:
            (companyData.information &&
                companyData.information.address &&
                companyData.information.address.street) ||
            '',
        city:
            (companyData.information &&
                companyData.information.address &&
                companyData.information.address.town) ||
            '',
        region:
            (companyData.information &&
                companyData.information.address &&
                companyData.information.address.region) ||
            '',
        country:
            (companyData.information &&
                companyData.information.address &&
                companyData.information.address.country) ||
            '',
        postcode:
            (companyData.information &&
                companyData.information.address &&
                companyData.information.address.postcode) ||
            ''
    });
    const [primaryContact, setPrimaryContact] = useState({
        legalName:
            ((companyData.information &&
                    companyData.information.contacts &&
                    companyData.information.contacts.primary_ambassador &&
                    companyData.information.contacts.primary_ambassador.first_name) ||
                '') +
            ' ' +
            ((companyData.information &&
                    companyData.information.contacts &&
                    companyData.information.contacts.primary_ambassador &&
                    companyData.information.contacts.primary_ambassador.last_name) ||
                ''),
        email:
            (companyData.information &&
                companyData.information.contacts &&
                companyData.information.contacts.primary_ambassador &&
                companyData.information.contacts.primary_ambassador.email) ||
            '',
        phoneNumber:
            (companyData.information &&
                companyData.information.contacts &&
                companyData.information.contacts.primary_ambassador &&
                companyData.information.contacts.primary_ambassador.biz_phone) ||
            '',
        jobTitle: ''
    });
    const [notifyContacts, setNotifyContacts] = useState([]);

    const steps = [
        { index: 0, value: 1, label: t('toolkit.trademark_form_steps.get_started') },
        { index: 1, value: 2, label: t('toolkit.trademark_form_steps.contract_details') },
        { index: 2, value: 3, label: t('toolkit.trademark_form_steps.recipient_details') },
        { index: 3, value: 4, label: 'Review the information' },
        { index: 4, value: 5, label: t('toolkit.trademark_form_steps.licensor_note') }
    ];

    const [expandedTables, setExpandedTables] = useState({});
    const [exceptionVisibility, setExceptionVisibility] = useState(getInitialVisibilityForExceptions(defaultContractDetails));
    const [exceptionRawInputs, setExceptionRawInputs] = useState({});
    const defaultContractsRef = useRef([]);
    const [contractDetails, setContractDetails] = useState(defaultContractsRef.current);

    const handleToggleTable = (index) => {
        setExpandedTables(prev => toggleTable(prev, index));
    };

    const handleToggleException = (index, type) => {
        setExceptionVisibility(prev => toggleException(prev, index, type));
    };

    const handleToggleItem = (contractIndex, property, item) => {
        setContractDetails(prevContracts => toggleItemInContract(prevContracts, contractIndex, property, item));
    };

    const handleExceptionInputChange = (contractIndex, property, e) => {
        const value = e && e.target ? e.target.value : e;
        const key = `${contractIndex}_${property}`;
        setExceptionRawInputs(prev => ({ ...prev, [key]: value }));
    };

    const handleExceptionInputBlur = (contractIndex, property) => {
        const key = `${contractIndex}_${property}`;
        const inputValue = exceptionRawInputs[key] || '';
        const newExceptionArray = inputValue
            .split(',')
            .map(item => item.trim())
            .filter(item => item !== '');
        setContractDetails(prevContracts => {
            const newContracts = [...prevContracts];
            const contract = { ...newContracts[contractIndex] };
            contract[property] = {
                ...contract[property],
                licensee_exception: newExceptionArray
            };
            newContracts[contractIndex] = contract;
            return newContracts;
        });
    };

    useEffect(() => {
        setLicensor('');
        setContractDetails(defaultContractsRef.current);
        setNote('');
        setLanguages(['en']);
        setNotifyContacts('');
        setExpandedTables({});

        if (requestType === 'licensee') {
            setBusinessInfo({
                legalCompanyName:
                    (companyData.information && companyData.information.legal_company_name) || '',
                companyRegistrationNumber:
                    (companyData.information && companyData.information.company_registration_number) || '',
                vat: (companyData.information && companyData.information.uk_vat) || '',
                streetAddress:
                    (companyData.information &&
                        companyData.information.address &&
                        companyData.information.address.street) ||
                    '',
                city:
                    (companyData.information &&
                        companyData.information.address &&
                        companyData.information.address.town) ||
                    '',
                region:
                    (companyData.information &&
                        companyData.information.address &&
                        companyData.information.address.region) ||
                    '',
                country:
                    (companyData.information &&
                        companyData.information.address &&
                        companyData.information.address.country) ||
                    '',
                postcode:
                    (companyData.information &&
                        companyData.information.address &&
                        companyData.information.address.postcode) ||
                    ''
            });
            setPrimaryContact({
                legalName:
                    ((companyData.information &&
                            companyData.information.contacts &&
                            companyData.information.contacts.primary_ambassador &&
                            companyData.information.contacts.primary_ambassador.first_name) ||
                        '') +
                    ' ' +
                    ((companyData.information &&
                            companyData.information.contacts &&
                            companyData.information.contacts.primary_ambassador &&
                            companyData.information.contacts.primary_ambassador.last_name) ||
                        ''),
                email:
                    (companyData.information &&
                        companyData.information.contacts &&
                        companyData.information.contacts.primary_ambassador &&
                        companyData.information.contacts.primary_ambassador.email) ||
                    '',
                phoneNumber:
                    (companyData.information &&
                        companyData.information.contacts &&
                        companyData.information.contacts.primary_ambassador &&
                        companyData.information.contacts.primary_ambassador.biz_phone) ||
                    '',
                jobTitle: ''
            });
            setContractDetails(defaultContractsRef.current);
        } else if (requestType === 'third_party') {
            setBusinessInfo({
                legalCompanyName: '',
                companyRegistrationNumber: '',
                vat: '',
                streetAddress: '',
                city: '',
                region: '',
                country: '',
                postcode: ''
            });
            setPrimaryContact({
                legalName: '',
                email: '',
                phoneNumber: '',
                jobTitle: ''
            });
            setContractDetails(prevContracts =>
                prevContracts.map(contract => ({
                    ...contract,
                    contracted_territories: {
                        ...contract.contracted_territories,
                        licensee_request: []
                    },
                    ips: {
                        ...contract.ips,
                        licensee_request: []
                    },
                    categories: {
                        ...contract.categories,
                        licensee_request: []
                    }
                }))
            );
        }
    }, [requestType, companyData]);

    useEffect(() => {
        let timer;
        if (isCounting && count > 0) {
            timer = setInterval(() => setCount(prev => prev - 1), 1000);
        } else if (count === 0) {
            setFormView(false);
            setTrademarkTableView(true);
            setIsCounting(false);
        }
        return () => clearInterval(timer);
    }, [isCounting, count]);

    useEffect(() => {
        if(!!licensor) {
            getLicensorContractsFunc();
            setExpandedTables({});
        }
    }, [licensor]);

    useEffect(() => {
        setContractDetails((prevContracts) => {
            return prevContracts.map((contract) => {
                return Object.assign({}, contract, { selected: requestType === 'licensee' });
            });
        });
    }, [requestType]);

    const handleSelectAll = (e) => {
        let isChecked = e.target.checked;
        setContractDetails((prevContracts) => {
            return prevContracts.map((contract) => {
                return Object.assign({}, contract, { selected: isChecked });
            });
        });
    };

    const handleRowCheckboxChange = (index, e) => {
        let isChecked = e.target.checked;
        setContractDetails((prevContracts) => {
            let newContracts = prevContracts.slice();
            let contract = Object.assign({}, newContracts[index]);
            contract.selected = isChecked;
            newContracts[index] = contract;
            return newContracts;
        });
    };

    const handleInputChange = (e, setter) => {
        const { name, value } = e.target;
        setter(prev => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (selectedValue, setter) => setter(selectedValue);

    const startCountdown = () => {
        setCount(5);
        setIsCounting(true);
    };

    const findLicensor = (licensor) => {
        return find(licensors, el => el.value === licensor);
    };

    const sendRequestFunc = () => {
        const {
            auth: {
                userData
            }
        } = xelacore;
        dispatch(hideModal());

        const params = {
            licensor_organisation_id: licensor,
            licensor_organisation_name: !!findLicensor(licensor) && findLicensor(licensor).label || '',
            request_type: requestType,
            company_info: {
                legal_company_name: businessInfo.legalCompanyName,
                company_registration_number: businessInfo.companyRegistrationNumber,
                company_tax_number: businessInfo.vat,
                address: {
                    street_address: businessInfo.streetAddress,
                    city: businessInfo.city,
                    region: businessInfo.region,
                    country: businessInfo.country,
                    postcode: businessInfo.postcode
                },
                primary_contact: {
                    legal_name: primaryContact.legalName,
                    job_title: primaryContact.jobTitle,
                    email_address: primaryContact.email,
                    phone_number: primaryContact.phoneNumber
                }
            },
            contract_details: contractDetails.filter((contract) => {
                return contract.selected;
            }),
            recipient_info: {
                recipient_type: recipientType,
                recipient_name: recipientDetails.recipientsName,
                company_name: recipientDetails.companyName,
                company_address: recipientDetails.companyAddress
            },
            created_by: {
                user_id : userData.user_id,
                email: userData.email,
                first_name: userData && userData.information.first_name || '',
                last_name: userData && userData.information.last_name || ''
            },
            notify_contacts: notifyContacts || [],
            note: note,
            manufacturer_id: null,
            request_languages: filter(languages, lng => lng !== 'en')
        };

        sendRequest(params).then(() => {
            getTradeMarks({});
            startCountdown();
        });
    };

    const findCurrentStep = () => {
        return steps.find(step => step.index === currentStep);
    };

    const goNextStep = () =>
        currentStep === steps.length - 1 ? confirmSubmit() : setCurrentStep(currentStep + 1);

    const goPrevStep = () => setCurrentStep(currentStep - 1);

    const confirmSubmit = () => {
        const body = (
            <div className="u-full-width">
                <div className="u-color-black u-margin-bottom">
                    <div>{t('toolkit.trademark_request_form.confirm_modal_body')}</div>
                    <div className="marg-t-20 u-flex u-flex-align--left u-bold">
                        {t('toolkit.trademark_request_form.are_you_sure')}
                    </div>
                </div>
                <div className="u-flex-gap u-flex-align--right">
                    <Button type="secondary" size="small" onClick={() => dispatch(hideModal())}>
                        {t('buttons.no')}
                    </Button>
                    <Button size="small" type="primary" onClick={sendRequestFunc}>
                        {t('buttons.yes')}
                    </Button>
                </div>
            </div>
        );
        const modal = (
            <Modal centered isSmall title={t('toolkit.trademark_request_form.confirm_modal_title')} body={body} />
        );
        return dispatch(showModal(modal));
    };

    const getLicensorContractsFunc = () => {
        getLicensorContracts(licensor).then((response) => {
            let data = response && response.data ? response.data : [];
            let contracts = map(data, (item) => {
                return {
                    agreement_id: item.agreementId,
                    agreement_name: item.agreementName,
                    contract_status: item.contractStatus,
                    start_date: item.startDate,
                    expiration_date: item.expirationDate,
                    contracted_territories: {
                        original: item.sourceContractedTerritories || [],
                        licensee_request: requestType === 'licensee' ? item.sourceContractedTerritories || [] : [],
                        licensee_exception: [],
                        licensor_update: [],
                        licensor_exception: []
                    },
                    ips: {
                        original: item.sourceIps || [],
                        licensee_request: requestType === 'licensee' ? item.sourceIps || [] : [],
                        licensee_exception: [],
                        licensor_update: [],
                        licensor_exception: []
                    },
                    categories: {
                        original: item.sourceProductCategories || [],
                        licensee_request: requestType === 'licensee' ? item.sourceProductCategories || [] : [],
                        licensee_exception: [],
                        licensor_update: [],
                        licensor_exception: []
                    },
                    selected: requestType === 'licensee'
                };
            });
            setContractDetails(contracts);
            setExceptionVisibility(getInitialVisibilityForExceptions(contracts));
        });
    };

    const handleExceptionTagsChange = (contractIndex, property, newTags) => {
        setExceptionRawInputs(prev => ({
            ...prev,
            [`${contractIndex}_${property}`]: newTags
        }));

        setContractDetails(prevContracts => {
            const newContracts = [...prevContracts];
            const contract = { ...newContracts[contractIndex] };
            contract[property] = {
                ...contract[property],
                licensee_exception: newTags
            };
            newContracts[contractIndex] = contract;
            return newContracts;
        });
    };

    const handleTagsInput = (newTags, setter) => {
        setter(newTags);
    };

    return (
        <div>
            {!isCounting && (
                <div className="widget">
                    {trademarksExists && (
                        <CloseIcon className="c-modal__close-icon" onClick={() => setFormView(false)} />
                    )}
                    <div className="widget-header">
                        <div>
                            {currentStep > 0 && (
                                <span className="back-icon c-pointer back-button" onClick={goPrevStep}>
                                  <ArrowLeft className="u-margin-small-right" height="10" width="16" />
                                  <span className="u-bold">{t('buttons.back')}</span>
                                </span>
                            )}
                            <div className="multi-step">
                                <span className="counter">{`${findCurrentStep().value}/${steps.length}`}</span>
                                <div className="multi-step-wrapper">
                                    {steps.map((step, i) => (
                                        <li key={i} className={`multi-step-wrapper__item ${currentStep === step.index ? 'active' : ''}`} />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <b>{t('toolkit.trademark_letter_title')}</b>
                        <h2>{findCurrentStep().label}</h2>
                    </div>
                    {currentStep === 0 && (
                        <ContactInfo
                            requestType={requestType}
                            setRequestType={setRequestType}
                            handleInputChange={handleInputChange}
                            setBusinessInfo={setBusinessInfo}
                            setPrimaryContact={setPrimaryContact}
                            primaryContact={primaryContact}
                            businessInfo={businessInfo}
                        />
                    )}
                    {currentStep === 1 && (
                        <div>
                            <div className="form-wrapper">
                                <div className="form-item">
                                    <label className="main-label">
                                        {t('toolkit.trademark_request_form.licensor')}
                                    </label>
                                    <div className="field-item-wrapper">
                                        <div className="extra-wrapper select-wrapper">
                                            <CustomSelect
                                                name="licensor"
                                                values={licensors}
                                                value={licensor}
                                                onChange={setLicensor}
                                                allowEmpty={false}
                                                selectplaceholder={t('toolkit.trademark_request_form.select_placeholder')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {licensor && (
                                <ContractDetails
                                    contractDetails={contractDetails}
                                    expandedTables={expandedTables}
                                    exceptionVisibility={exceptionVisibility}
                                    toggleTable={handleToggleTable}
                                    toggleException={handleToggleException}
                                    toggleItem={handleToggleItem}
                                    handleExceptionInputChange={handleExceptionInputChange}
                                    handleExceptionInputBlur={handleExceptionInputBlur}
                                    exceptionRawInputs={exceptionRawInputs}
                                    handleExceptionTagsChange={handleExceptionTagsChange}
                                    handleSelectAll={handleSelectAll}
                                    handleRowCheckboxChange={handleRowCheckboxChange}
                                />
                            )}
                        </div>
                    )}
                    {currentStep === 2 && (
                        <RecipientDetails
                            recipientDetails={recipientDetails}
                            setRecipientDetails={setRecipientDetails}
                            handleSelectChange={(val) => setRecipientType(val)}
                            handleInputChange={handleInputChange}
                            recipientType={recipientType}
                            setRecipientType={setRequestType}
                        />
                    )}
                    {currentStep === 3 && (
                        <InformationReview
                            requestType={requestType}
                            businessInfo={businessInfo}
                            contractDetails={contractDetails}
                            recipientDetails={recipientDetails}
                            recipientType={recipientType}
                            licensor={licensor}
                            onEditStep={setCurrentStep}
                            licensorsArray={licensors}
                        />
                    )}
                    {currentStep === 4 && (
                        <div className="form-wrapper licensor-notes">
                            <div className="form-item">
                                <label className="main-label">Is anyone you want to keep notified? e.g: your licensor contact (optional)</label>
                                <div className="field-item-wrapper">
                                    <div className="extra-wrapper input-wrapper">
                                        <TagsInput
                                            tags={notifyContacts || []}
                                            onChange={(newTags) =>
                                                handleTagsInput(newTags, setNotifyContacts)
                                            }
                                            placeholder="Add email address"
                                        />
                                    </div>
                                </div>
                                <label className="main-label">
                                    {t('toolkit.trademark_request_form.note_to_licensor')}
                                </label>
                                <div className="field-item-wrapper">
                                    <div className="extra-wrapper input-wrapper">
                                        <Textarea
                                            name="note"
                                            value={note}
                                            onChange={(e) => setNote(e.target.value)}
                                            placeholder={t('toolkit.trademark_request_form.note_placeholder')}
                                        />
                                    </div>
                                </div>
                                <label className="main-label">
                                    In which languages do you need the trademark authorization letters?
                                </label>
                                <div className="field-item-wrapper">
                                    <div className="extra-wrapper select-wrapper">
                                        <MultipleDropdown
                                            name="language"
                                            entityName="Language"
                                            values={languagesArray}
                                            value={languages}
                                            onChange={(selectedValue) => handleSelectChange(selectedValue, setLanguages)}
                                            isMulti={true}
                                            enableSearch={true}
                                            placeholder="Please select from the list"
                                            selectedLimit={7}
                                            disabledOptions={['en']}
                                        >
                                        </MultipleDropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="form-wrapper buttons">
                        <Button
                            disabled={!requestType || (currentStep === 1 && (!licensor || (contractDetails.filter((contract) => {
                                return contract.selected;
                            }).length === 0)))}
                            type="primary"
                            size="medium"
                            onClick={goNextStep}
                        >
                            {currentStep === 3
                                ? 'The information is correct'
                                : currentStep === steps.length - 1
                                    ? t('buttons.finalise_request')
                                    : t('buttons.next')}
                        </Button>
                    </div>
                </div>
            )}
            {isCounting && <CountingPlaceholder count={count} />}
        </div>
    );
}

const mapStateToProps = ({ xelacore, dispatch }) => ({
    xelacore,
    dispatch
});

export default connect(mapStateToProps)(TrademarkAuthorisationLetterForm);
