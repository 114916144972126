import React from 'react';
import classnames from 'classnames';
import { Icon } from 'src/js/components/static';
import Tooltip from 'rc-tooltip';
import { useTranslation } from 'react-i18next';

export default function TooltipSimpleData({
    param,
    icon,
    func,
    handleClick,
    className,
    conflicted
}) {
    const { t } = useTranslation();
    const isNoValue = !param || param === 0 || param === '0';

    const cx = classnames('c-rec-table__band-item', className, {
        'c-rec-table__band-item--active': param > 0
    });

    return (
        <li className={cx}>
            {isNoValue && (
                <div className="c-rec-table__icon-box">
                    <Icon icon={icon} size={12} tooltip />
                    <span>{param > 99 ? '99+' : param}</span>
                </div>
            )}
            {!isNoValue && (
                <Tooltip
                    align={{ offset: [-10, 20] }}
                    placement="right"
                    trigger={['hover']}
                    overlay={() => func(t, param)}
                    onClick={() => conflicted ? null : handleClick()}
                >
                    <div className="c-rec-table__icon-box">
                        <Icon icon={icon} size={12} tooltip />
                        <span>{param > 99 ? '99+' : param}</span>
                    </div>
                </Tooltip>
            )}
        </li>
    );
}
