import React from 'react';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronRight } from 'src/js/components/static/Icon/svg-icons/arrow-right-small.svg';

const Breadcrumb = ({ items }) => {
    const { t } = useTranslation();

    const renderBreadcrumbItem = (item, index) => {
        const label = typeof item.label === 'string' ? t(item.label) : item.label;

        return (<React.Fragment key={index}>
                {index > 0 && (<ChevronRight
                        className="breadcrumb-separator"
                        aria-label={t('toolkit.trademark_authorization.breadcrumb.separator')}
                    />)}
                {item.link ? (<Link
                        to={item.link}
                        className="breadcrumb-link"
                        aria-current={index === items.length - 1 ? 'page' : undefined}
                    >
                        {label}
                    </Link>) : (<span
                        className="breadcrumb-text"
                        aria-current={index === items.length - 1 ? 'page' : undefined}
                    >
                        {label}
                    </span>)}
            </React.Fragment>);
    };

    return (<div className="page-header">
            <div className="header-left">
                <nav aria-label={t('toolkit.trademark_authorization.breadcrumb.nav_label')}>
                    <div className="u-flex-align breadcrumb-wrapper">
                        {items.map((item, index) => renderBreadcrumbItem(item, index))}
                    </div>
                </nav>
            </div>
        </div>);
};

export default Breadcrumb;
