import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from 'src/js/components/static/Icon/svg-icons/close-icon.svg';

const TagsInput = ({ tags, onChange, placeholder }) => {
    const [inputValue, setInputValue] = useState('');

    const addTag = () => {
        const trimmedValue = inputValue.trim();
        if (trimmedValue && !tags.includes(trimmedValue)) {
            onChange([...tags, trimmedValue]);
        }
        setInputValue('');
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addTag();
        }
    };

    const handleBlur = () => {
        addTag();
    };

    const removeTag = (indexToRemove) => {
        onChange(tags.filter((_, index) => index !== indexToRemove));
    };

    return (
        <div className="tags-input-wrapper">
            <div className="contract-details-wrapper_tags-wrapper tags-input">
                {tags.map((tag, index) => (
                    <div key={index} className="contract-details-wrapper_tags-wrapper__item">
                        <div>{tag}</div>
                        <CloseIcon onClick={() => removeTag(index)} />
                    </div>
                ))}
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
};

export default TagsInput;
