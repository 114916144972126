import React from 'react';
import { useTranslation } from 'react-i18next';
import TagList from './TagList';
import TagsInput from './TagsInput';

const ContractSection = ({
                             contract,
                             section,
                             tags,
                             title,
                             exceptionRawInputs = {},
                             handleExceptionTagsChange,
                             toggleExceptionAcceptance,
                             onToggleSection,
                             getTagStatus,
                             onToggleTag
                         }) => {
    const { t } = useTranslation();

    const sectionToDataField = {
        'territories': 'contracted_territories',
        'ips': 'ips',
        'categories': 'categories'
    };

    const dataField = sectionToDataField[section] || section;

    const getDisplayExceptions = () => {
        const key = `${contract.agreement_id}_${section}`;
        if (exceptionRawInputs[key] !== undefined) {
            return exceptionRawInputs[key];
        }

        if (!contract || !contract[dataField]) return [];
        return [...(contract[dataField].licensee_exception || [])];
    };

    const getRejectedExceptions = () => {
        if (!contract || !contract[dataField]) return [];

        const licenseeExceptions = contract[dataField].licensee_exception || [];
        const licensorExceptions = contract[dataField].licensor_exception || [];

        return licenseeExceptions.filter(tag => !licensorExceptions.includes(tag));
    };

    const handleExceptionTagClick = (tag) => {
        toggleExceptionAcceptance(contract.agreement_id, section, tag);
    };

    // if (!tags || tags.length === 0) return null;

    return (
        <div className="contract-section">
            <div
                className="section-title clickable"
                onClick={() => onToggleSection(contract.agreement_id, section)}
            >
                <div className="title-with-button">
                    <span>{title}</span>
                    {/*<button*/}
                    {/*    className="add-exception-button"*/}
                    {/*>*/}
                    {/*    {t('toolkit.contract_details.manage_exceptions')}*/}
                    {/*</button>*/}
                </div>
            </div>
            <div className="section-value">
                <TagList
                    contract={contract}
                    section={section}
                    tags={tags}
                    getTagStatus={getTagStatus}
                    onToggleTag={onToggleTag}
                />

                <div className="exceptions-section">
                    <h4 className="exceptions-title">
                        {t(`toolkit.contract_details.${section}_exception`, {
                            defaultValue: t('toolkit.contract_details.exceptions')
                        })}
                    </h4>
                    <TagsInput
                        tags={getDisplayExceptions()}
                        onChange={(newTags) => handleExceptionTagsChange(contract.agreement_id, section, newTags)}
                        placeholder={t('toolkit.contract_details.type_exception_and_press_enter')}
                        rejectedTags={getRejectedExceptions()}
                        onTagClick={handleExceptionTagClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default ContractSection;
