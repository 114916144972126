import React, { Component, Fragment } from 'react';
import moment from 'moment';
import orderBy from 'lodash/orderBy';

import { isNum } from 'src/js/helpers/numbers';
import { Gravatar, Button, Modal, Tooltip } from 'src/js/components/static';
import { showModal } from 'src/js/actions/xelacore';
import CodesModal from './CodesModal';

import {
    getPreviousBatches,
    downloadBatch,
    checkBatch
} from 'src/js/actions/redemption';
import { displayNotification } from 'src/js/actions/xelacore';
import { withTranslation } from 'react-i18next';

class Codes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numberOfCodes: '',
            previousDownloads: [],
            unusedCodes: false,
            submitted: false
        };
    }

    componentWillMount() {
        this.fetchPreviousCodes();
    }

    fetchPreviousCodes() {
        const { dispatch, data } = this.props;
        const { gtin, target_market, language } = data;

        return dispatch(getPreviousBatches(gtin, target_market, language)).then(
            (resp) => {
                this.setState({ previousDownloads: resp.data.batches });
            }
        );
    }

    checkCsvDownload(batchId) {
        const { dispatch, t } = this.props;

        return dispatch(checkBatch(batchId)).then((resp) => {
            if (resp.status) return this.downloadCsv(batchId);

            return dispatch(
                displayNotification({
                    type: 'info',
                    message: t('modules.product_detail.codes.being_processed'),
                    timeOut: 5000
                })
            );
        });
    }

    downloadCsv(batchId) {
        const { dispatch, data, t } = this.props;

        dispatch(
            displayNotification({
                type: 'info',
                message: t('modules.product_detail.codes.fetching_records'),
                timeOut: 5000
            })
        );

        return dispatch(downloadBatch(batchId, data.product_name)).then(() => {
            return dispatch(
                displayNotification({
                    type: 'success',
                    message: t('modules.product_detail.codes.successfully_downloaded'),
                    timeOut: 5000
                })
            );
        });
    }

    handleChange(e) {
        this.setState({ numberOfCodes: e.target.value });
    }

    setSubmitted() {
        return this.setState({
            submitted: true,
            numberOfCodes: '',
            unusedCodes: true
        });
    }

    showModal() {
        const { dispatch, data, t } = this.props;
        const { numberOfCodes } = this.state;
        const modalBody = (
            <CodesModal
                fetchCodes={() => this.fetchPreviousCodes()}
                setSubmitted={() => this.setSubmitted()}
                data={data}
                dispatch={dispatch}
                numberOfCodes={numberOfCodes}
            />
        );

        const modal = (
            <Modal
                className="c-modal--large-box"
                title={t('modules.product_detail.codes.generate_serialised_codes')}
                body={modalBody}
            />
        );
        return dispatch(showModal(modal));
    }

    confirmCodes(el) {
        const { previousDownloads } = this.state;

        previousDownloads.map((item) => {
            if (item.id === el.id) item.downloadConfirmed = true;
            return item;
        });

        this.setState({
            previousDownloads,
            unusedCodes: false
        });
    }

    render() {
        const { t } = this.props;
        const { numberOfCodes, previousDownloads, unusedCodes } = this.state;
        const disabled = unusedCodes || !isNum(numberOfCodes, true);

        return (
            <Fragment>
                <div className="c-product__codes">
                    <h4 className="u-color-purple">
                        {t('modules.product_detail.codes.generate_codes')}
                        <Tooltip>
                            {t('modules.product_detail.codes.preview_look')}
                        </Tooltip>
                    </h4>

                    <div className="u-margin-bottom">
                        <div className="c-product__code-form">
                            <input
                                value={numberOfCodes}
                                min="0"
                                placeholder="Specify the number of codes you wish to generate"
                                type="text"
                                onChange={(e) => this.handleChange(e)}
                            />
                            <button
                                disabled={disabled}
                                onClick={() => this.showModal()}
                            >
                                {t('buttons.generate')}
                            </button>
                        </div>
                        {!unusedCodes && (
                            <small className="u-color-purple">
                                {t('modules.product_detail.codes.confirmation_screen')}
                            </small>
                        )}
                        {unusedCodes && (
                            <small className="u-color-purple">
                                {t('modules.product_detail.codes.confirm_all_codes')}
                            </small>
                        )}
                    </div>
                    {previousDownloads.length > 0 && (
                        <div className="c-product__codes-table">
                            <table className="c-table c-table--fixed c-table--purple-rows">
                                <thead>
                                    <tr className="c-table--purple">
                                        <th>{t('modules.product_detail.codes.table_headers.user')}</th>
                                        <th>{t('modules.product_detail.codes.table_headers.no_of_codes')}</th>
                                        <th>{t('modules.product_detail.codes.table_headers.date')}</th>
                                        <th>{t('modules.product_detail.codes.table_headers.download')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderBy(
                                        previousDownloads,
                                        'created_at',
                                        'desc'
                                    ).map((el) => (
                                        <tr key={el.batch_id}>
                                            <td>
                                                <div className="o-media-object">
                                                    <Gravatar
                                                        className="o-media-object__rounded-image"
                                                        email={
                                                            el._meta.requestedBy
                                                        }
                                                        title={''}
                                                    />
                                                    <div className="o-media-object__text">
                                                        {el._meta.requestedBy}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {el.requested_code_count.toLocaleString()}
                                            </td>
                                            <td>
                                                {moment(el.created_at).format(
                                                    'DD MMM YYYY'
                                                )}
                                            </td>
                                            <td>
                                                <Button
                                                    onClick={() =>
                                                        this.checkCsvDownload(
                                                            el.batch_id
                                                        )
                                                    }
                                                >
                                                    {t('buttons.download')}
                                                </Button>
                                                {el.has_been_used && (
                                                    <small className="u-bold c-product__tiny-red">
                                                        {t('modules.product_detail.codes.already_downloaded')}
                                                    </small>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default withTranslation()(Codes)