import React, { Component } from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Triangle } from 'src/js/components/static';
import { getStatusColor } from 'src/js/constants/productConstants/index';

export default class Badge extends Component {
    static getDerivedStateFromProps = (props, state) => {
        const { prevState, ...thisState } = state; // thisState - state without prevState to prevent cyclic nesting
        if (prevState.value === state.value && props.value !== state.value) {
            return {
                value: props.value,
                prevState: thisState
            };
        } else if (prevState.value !== state.value) {
            return {
                value: state.value,
                prevState: thisState
            };
        }
        return null;
    };

    constructor(props) {
        super(props);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.state = {
            showValues: false,
            value: props.value,
            prevState: {}
        };
    }

    showMenuList() {
        if (!this.state.showValues) {
            document.addEventListener('click', this.handleOutsideClick);
        } else {
            document.removeEventListener('click', this.handleOutsideClick);
        }
        this.setState({
            showValues: !this.state.showValues
        });
    }

    handleOutsideClick(e) {
        if (this.node && !this.node.contains(e.target)) {
            this.showMenuList();
        }
    }

    changeStatus(value) {
        this.setState({
            value,
            showValues: false
        });
        this.props.updateData(value, this.props.dataName);
    }

    render() {
        const {
            text,
            editable,
            tooltip,
            className,
            dataName,
            values,
            transparent,
            orange,
            red,
            green,
            greener,
            grey,
            withoutColon,
            label
        } = this.props;

        const { showValues, value } = this.state;
        const statusColor = getStatusColor(label || value, dataName);

        const renderTheTip = () => {
            return (
                <span
                    data-tip={tooltip}
                    data-for={text}
                    className="c-tooltip u-flex-align c-tooltip__badge"
                >
                    <ReactTooltip id={text} offset={{ top: -5 }} />
                </span>
            );
        };

        const cx = classnames('c-badge', className);
        const isGreen = statusColor === 'green' || green;

        const classesButton = classnames('c-badge__block-button', {
            'c-badge--red': statusColor === 'red' || red,
            'c-badge--green': isGreen,
            'c-badge--dark-green': greener,
            'c-badge--orange': statusColor === 'amber' || orange,
            'c-badge--grey': statusColor === 'grey' || grey,
            'c-badge--transparent': transparent
        });

        const classesCircle = classnames('c-badge__circle', {
            'c-badge--green': isGreen
        });

        return (
            <div className={cx}>
                {tooltip && renderTheTip()}
                <div className="c-badge__block">
                    <div className="c-badge__block-text">
                        <b>
                            {text}
                            {!withoutColon && ':'}
                        </b>
                    </div>
                    {editable ? (
                        <div className="c-badge__data">
                            <div
                                className={classesButton}
                                onClick={e => this.showMenuList(e)}
                            >
                                <span>
                                    {isGreen && (
                                        <span className={classesCircle} />
                                    )}
                                    {value}
                                    <Triangle />
                                </span>
                            </div>
                            {showValues && (
                                <div
                                    className="c-badge__data__list"
                                    ref={node => (this.node = node)}
                                >
                                    {values.map(val =>
                                        val.name === value || val.isHide ? (
                                            <div
                                                className={classnames('c-badge__data__list__item c-badge__data__list__item--inactive',{
                                                    'disabled':val.isHide
                                                })}
                                                key={`${dataName}-${val.name}`}
                                            >
                                                {val.label}
                                            </div>
                                        ) : (
                                            <div
                                                className="c-badge__data__list__item c-badge__data__list__item--active"
                                                key={`${dataName}-${val.name}`}
                                                onClick={() =>
                                                    this.changeStatus(val.name)
                                                }
                                            >
                                                {val.label}
                                            </div>
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={classesButton}>
                            <span>
                                {isGreen && <span className={classesCircle} />}
                                {value}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
