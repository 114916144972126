import React, { Component } from 'react';
import ImagesBlock from '../OpaSearchPanel/ImagesBlock';
import { Button, Icon } from 'src/js/components/static';
import { withTranslation } from 'react-i18next';

class ApprovalWithNumberAndData extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { productPreview, toggleProductInfo, close, t } = this.props;
        return (
            <div>
                <div>
                    <Button onClick={() => close()} square>
                        <span>
                            <Icon
                                icon="arrow_left"
                                left
                                width="13"
                                height="13"
                                top={2}
                            />{' '}
                            {t('buttons.back')}
                        </span>
                    </Button>
                </div>
                <div className="u-margin-bottom">
                    <ImagesBlock data={productPreview} />
                    <Button
                        blueText
                        onClick={() => toggleProductInfo(productPreview)}
                    >
                        {t('buttons.view_confept_info')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ApprovalWithNumberAndData)