import React, { Component } from 'react';
import { esgConstants } from 'src/js/constants/productConstants/specialCases';
import { groupBy, toArray, cloneDeep, isEqual, findIndex, orderBy } from 'lodash';
import FormItemWrapper from 'modules/UiKit/components/FormElements/FormItem';
import fallbackImageBig from 'statics/imgs/fallback_img_big.jpg';
import MediaView from 'src/js/components/MediaView';
import {
    showModal
} from 'src/js/actions/xelacore';
import Button from 'modules/UiKit/components/Button/Button';
import { Modal, ModalCarousel } from 'src/js/components/static';
import { withTranslation } from 'react-i18next';
// import { ReactComponent as InfoIcon } from 'src/js/components/static/Icon/svg-icons/info.svg';

class EsgView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expandedPanel: {
                pd: true
            },
            originalAttributes: {},
            cloneEsg: {},
            esgViewData: {}
        };
    }

    componentDidMount() {
        this.setState({
            esgViewData: !!this.props.esgData && cloneDeep(this.props.esgData)
        });
    }

    componentDidUpdate(prevProps) {
        if(!isEqual(prevProps.esgData, this.props.esgData)) {
            this.setState({
                esgViewData: !!this.props.esgData && cloneDeep(this.props.esgData)
            })
        }
    }

    cancelData(e) {
        const { cancelEditMode, cloneData } = this.props;
        
        let cloneDataObj = cloneDeep(cloneData);

        cancelEditMode(e, cloneDataObj);
    }

    togglePanel(key) {
        const { expandedPanel } = this.state;

        expandedPanel[key] = !expandedPanel[key];

        this.setState({
            expandedPanel
        });
    }

    showCarouselModal(image) {
        const {
            data: { images, product_name },
            dispatch,
            t
        } = this.props;

        this.setState({
            activeImage: image
        });

        const body = (
            <ModalCarousel
                isZoomApplied={this.state.isImgZoomed}
                images={images}
                currentIndex={image}
                dispatch={dispatch}
            />
        );

        const modal = (
            <Modal
                title={`${t('modules.product_detail.esg_view.viewing_images_for')} ${product_name}`}
                body={body}
                className="c-modal--large-box"
            />
        );

        return dispatch(showModal(modal));
    }

    handleChange(e, type, key, label) {
        const { esgViewData } = this.state;
        const { data } = this.props;

        let index = findIndex(esgViewData[key], item => {
            return item.key === key && item.parent === type && item.label === label
        });

        data.additional[`${type}_${key}_${label.split(' ').join('_')}`] = e.target.value;

        if(index > -1) {
            esgViewData[key][index].value = e.target.value;
        }

        this.setState({
            esgViewData
        })
    }
    
    openImage(image) {
        return window.open(image, '_blank', 'noreferrer');
    }

    render() {
        const { expandedPanel, esgViewData } = this.state;
        const { data, updateData, editMode, switchTo, onChangeItem, t } = this.props;

        const dppImages = !!data && !!data.additional && !!data.additional.dpp_pd_cropped_images && data.additional.dpp_pd_cropped_images.split(';') || [];

        return (
            <div className="c-esg-view">
                <div className="c-esg-view-images-wrapper">
                    {!!dppImages && dppImages.map((image, index) => {
                        return (<div key={index + 'esc-image'} className="c-esg-view-images-wrapper__image">
                            {image && (
                                <MediaView
                                    src={image}
                                    className=""
                                    fallbackSrc={fallbackImageBig}
                                    useImgTag
                                    onClick={() => this.openImage(image)}
                                />
                            )}

                            {!image && (
                                <MediaView
                                    src={fallbackImageBig}
                                    className=""
                                    useImgTag
                                />
                            )}
                        </div>);
                    })}
                </div>

                <div className="c-product__group-content esg-view-panel-wrapper c-product__group-content--collapsed">
                    {!!data && !!esgViewData && toArray(esgViewData).length > 0 && toArray(esgConstants).map((item, index) => {
                        let panelData = !!toArray(groupBy(esgViewData[item.key], 'parent')['esg']).length && !!toArray(groupBy(esgViewData[item.key], 'parent')['dpp']).length;

                        if (!!panelData) {
                            return (
                                <div key={index + 'esg-product-item'} className="c-product__group esg-group">
                                    <div
                                        className={`c-product__group-header esg-header ${!!expandedPanel[item.key] ? 'c-product__group-header--collapsed' : ''}`}
                                        onClick={() => this.togglePanel(item.key)}>
                                        <h5>{item.label}</h5>
                                        <span><i /></span>
                                    </div>

                                    <div
                                        className={`c-product__group-content esg-content ${!!expandedPanel[item.key] ? 'c-product__group-content--collapsed' : ''}`}>
                                        <div className="esg-panel-wrapper">
                                            <div className="esg-panel-wrapper__product">
                                                    <span className="esg-panel-wrapper__label">
                                                    {t('modules.product_detail.esg_view.product_data')}
                                                        {/* <InfoIcon className="info-icon"></InfoIcon> */}
                                                    </span>

                                                <div className="esg-panel-wrapper__form">
                                                    {orderBy(toArray(groupBy(esgViewData[item.key], 'parent')['esg']), ['label', 'asc']).map((esg, index) => {
                                                        return (
                                                            <div key={index + 'esg-item'}
                                                                 className="esg-panel-wrapper__form-box">
                                                                <FormItemWrapper
                                                                    disabled={!editMode}
                                                                    label={esg.label === 'buttons coo' ? 'Buttons COO' : esg.label === 'zips coo' ? 'Zips COO' : esg.label}
                                                                    type="text"
                                                                    onChange={(e) => this.handleChange(e, 'esg', item.key, esg.label)}
                                                                    value={esg.value}
                                                                    onInputBlur={() => onChangeItem(data.additional, 'additional')}
                                                                /></div>
                                                        );
                                                    })}
                                                </div>
                                            </div>

                                            <div className="esg-panel-wrapper__dpp">
                                                    <span className="esg-panel-wrapper__label">
                                                    {t('modules.product_detail.esg_view.digital_product_passport')}
                                                        {/* <InfoIcon className="info-icon"></InfoIcon> */}
                                                    </span>
                                                <div className="esg-panel-wrapper__form">
                                                    {orderBy(toArray(groupBy(esgViewData[item.key], 'parent')['dpp']), ['label', 'asc']).map((dpp, index) => {
                                                        return (
                                                            <div key={index + 'dpp-esg'}
                                                                 className="esg-panel-wrapper__form-box">
                                                                <FormItemWrapper
                                                                    disabled={!editMode}
                                                                    label={''}
                                                                    type="text"
                                                                    onChange={(e) => this.handleChange(e, 'dpp', item.key, dpp.label)}
                                                                    value={dpp.value}
                                                                    onInputBlur={() => onChangeItem(data.additional, 'additional')}
                                                                />

                                                                {/* <InfoIcon className="info-icon"></InfoIcon> */}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );

                        }
                    })}

                    {toArray(esgViewData).length === 0 && (
                        <div>{t('modules.product_detail.esg_view.no_data')}</div>
                    )}
                </div>

                {!!editMode && (
                    <div className="u-flex u-flex-align sticky-footer">
                        <Button
                            type="secondary"
                            size="small"
                            onClick={(e) => this.cancelData(e)}
                        >
                            {t('buttons.cancel')}
                        </Button>

                        <Button
                            size={'small'}
                            type={'primary'}
                            onClick={() => {
                                updateData();
                                switchTo('view');
                            }}
                        >
                            {t('buttons.update_record')}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(EsgView)
