import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

class AsinArray extends Component {
    state = {
        data: []
    };

    componentDidMount() {
        this.setState({
            data: this.props.value || []
        });
    }

    render() {
        const { data } = this.state;
        const { t } = this.props;

        return (
            <div className="asin-element">
                {
                    data.map((el, i) => {
                        return (
                            <div className="u-flex c-product__detail-listing-item-main-asin"
                                 key={`element-${i}`}>
                                <div className="c-product__detail-listing-item-main-asin-asin-box">
                                    <div className="c-product__detail-listing-item-main-asin-asin-box-item">{t('product_list_elements.asin_array.source_provider')}</div>
                                    <div className="c-product__detail-listing-item-main-asin-asin-box-item">{t('product_list_elements.asin_array.parent_asin')}</div>
                                    <div className="c-product__detail-listing-item-main-asin-asin-box-item">{t('product_list_elements.asin_array.child_asin')}</div>
                                </div>
                                <div className="c-product__detail-listing-item-main-asin-asin-box u-bold">
                                    <div className="c-product__detail-listing-item-main-asin-asin-box-item">{el.source || '- N/A -'}</div>
                                    <div className="c-product__detail-listing-item-main-asin-asin-box-item">{el.parent || '- N/A -'}</div>
                                    <div className="c-product__detail-listing-item-main-asin-asin-box-item">{el.child || '- N/A -'}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default withTranslation()(AsinArray)