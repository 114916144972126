import React from 'react';
import { useTranslation } from 'react-i18next';

export default function NoDataMyRecods({}) {
    const { t } = useTranslation();

    return (
        <div className="u-text-center">
            <p className="c-my-records__no-data-text" dangerouslySetInnerHTML={{ __html: t('product_components.no_data_my_records.account_correctly_set_up') }}></p>
        </div>
    );
}
