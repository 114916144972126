import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from 'src/js/components/static/Icon/svg-icons/close-icon.svg';

const TagsInput = ({ tags = [], onChange, placeholder, disabled = false, rejectedTags = [], onTagClick }) => {
    const [inputValue, setInputValue] = useState('');

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const trimmedValue = inputValue.trim();
            if (trimmedValue && !tags.includes(trimmedValue) && !disabled) {
                onChange([...tags, trimmedValue]);
            }
            setInputValue('');
        }
    };

    const removeTag = (indexToRemove) => {
        if (!disabled) {
            onChange(tags.filter((_, index) => index !== indexToRemove));
        }
    };

    const handleTagClick = (tag) => {
        if (onTagClick && !disabled) {
            onTagClick(tag);
        }
    };

    const isRejected = (tag) => {
        return rejectedTags.includes(tag);
    };

    return (
        <div className="contract-details-wrapper_tags-wrapper tags-input">
            {tags && tags.map((tag, index) => (
                <span
                    key={index}
                    className={`contract-details-wrapper_tags-wrapper__item ${
                        disabled ? 'disabled' : isRejected(tag) ? 'rejected' : 'enabled'
                    }`}
                    onClick={() => handleTagClick(tag)}
                >
                    {tag}
                    {!disabled && (
                        <CloseIcon
                            className="tag-icon"
                            onClick={(e) => {
                                e.stopPropagation();
                                removeTag(index);
                            }}
                        />
                    )}
                </span>
            ))}
            {!disabled && (
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder={placeholder}
                />
            )}
        </div>
    );
};

export default TagsInput;
