import React, { Fragment, useEffect, useRef, useState } from 'react';
import toArray from 'lodash/toArray';
import size from 'lodash/size';
import remove from 'lodash/remove';
import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import toLower from 'lodash/toLower';
import recordPlaceholder from 'statics/imgs/record-placeholder.png';
import DataViewPagination from 'src/js/components/DataViewPagination/DataViewPagination';
import { Icon } from 'src/js/components/static';
import { cloneDeep } from 'lodash';
import { headers as defaultHeaders } from 'src/js/constants/productConstants/headers';
import { getMainImage } from 'src/js/helpers/dataHelpers';
import MediaView from 'src/js/components/MediaView';
import { getFilteredFields } from 'src/js/helpers/dataHelpers';
import { renderElement } from 'src/js/components/ProductListElements';
import { Auth } from 'src/js/components/static';
import TooltipApprovedData from 'src/js/modules/ProductCatalogue/routes/ProductCatalogue/ProductCatalogueTable/TooltipApprovedData';
import AggregatedSearch from './components/SearchItems';
import HeaderSection from './components/HeaderSection';
import { selectAll as selectAllFunction } from 'src/js/actions/productsFetch';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import { selectRow } from 'src/js/actions/productsFetch';

import {
    searchableFileds
} from 'src/js/constants/advancedFilteringConstants';
import CustomSelect from 'modules/UiKit/components/FormElements/CustomSelect';
import differenceBy from 'lodash/differenceBy';
import uniqBy from 'lodash/uniqBy';
import Grid from 'src/js/modules/ProductCatalogue/routes/ProductCatalogue/components/Grid/Grid';
import { useTranslation } from 'react-i18next';

export default function Tablev3({
    headersArr,
    aggregations,
    list,
    updateTable,
    ipLevels,
    agentMetaData,
    filtersObj = {},
    searchObj = {},
    setFiltersObj,
    setSearchObj,
    dispatch,
    selectedRows,
    previousRow,
    stickyHeader,
    categoryLevels,
    headers,
    headerLayouts,
    activeHeaders,
    selectAll,
    setLayout,
    layoutType
}) {
    const { t } = useTranslation();
    const [searchBox, setSearchBox] = useState(null);
    const [tableWidth, setTableWidth] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(1);
    const [inputValue, setInputValue] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const data = list;

    const mainTable = useRef(null);
    const refPopover = useRef(null);
    const table = useRef(null);
    const header = useRef(null);
    const scrollableheader = useRef(null);
    const tableWrapper = useRef(null);
    const scrollbar = useRef(null);

    const activeHeadersArr = !!headersArr && getFilteredFields('agent', headersArr).filter((f) => f.active);
    const categoryPathName = 'licensee_category_path';

    const checkSelectorOptionList = [
        {
            value: 'all_on_page',
            label: t('constants.product_constants.all_on_page')
        },
        {
            value: 'clear_on_page',
            label: t('constants.product_constants.clear_on_page')
        },
        {
            value: 'clear_all',
            label: t('constants.product_constants.clear_all')
        }
    ];

    let ipIndex = findIndex(activeHeadersArr, item => item.name === 'ip_paths');
    let categoryIndex = findIndex(activeHeadersArr, item => item.name === categoryPathName);

    const sortedHeaders = !!activeHeadersArr && activeHeadersArr
        .map((e) => defaultHeaders.find((f) => f.dataName === e.name))
        .filter((f) => !!f && f.dataName !== 'additional' && f.dataName !== 'category_attributes');

    let mapLevels = map(ipLevels, (ip) => {
        return {
            dataName: `ips_${ip.ipLevelId}`,
            label: ip.levelName,
            ip_level_id: ip.ipLevelId,
            active: ipIndex > -1,
            order: ipIndex + 2,
            showInList: ipIndex > -1,
            form: { type: 'dynamicIp' }
        };
    });

    let mapCategories = map(categoryLevels, (cat, i) => {
        return {
            dataName: `licensor_category_path.${i}`,
            label: cat,
            index: i,
            active: categoryIndex > -1,
            order: categoryIndex + 2,
            showInList: categoryIndex > -1,
            form: { type: 'dynamicCategory' }
        };
    });

    if (ipIndex > -1) sortedHeaders.splice(ipIndex, 0, ...mapLevels);
    if (categoryIndex > -1) sortedHeaders.splice(categoryIndex, 0, ...mapCategories);
    remove(sortedHeaders, header => header.dataName === 'ip_paths');
    remove(sortedHeaders, header => header.dataName === 'licensee_category_path');

    const scrollHandler = () => {
        const distanceToBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
        const tableElement = table.current;
        const rect = tableElement.getBoundingClientRect();
        const tableDistanceToBottom = Math.max(0, document.documentElement.scrollHeight - (rect.bottom + window.pageYOffset));

        if (window.scrollY > 140) {
            document.getElementById('siteHeader').style.boxShadow = 'none';

        } else {
            document.getElementById('siteHeader').style.boxShadow = '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
        }


        if (window.scrollY > 90) {
            if (!!stickyHeader && stickyHeader > 0) {
                tableWrapper.current.classList.add('sticky-inside');
                header.current.classList.add('sticky');
                document.getElementById('v3_table_header').classList.add('sticky');
            }
        } else {

            if (!!stickyHeader && stickyHeader > 0) {
                tableWrapper.current.classList.remove('sticky-inside');
                header.current.classList.remove('sticky');
                document.getElementById('v3_table_header').classList.remove('sticky');
            }
        }

        if (distanceToBottom < tableDistanceToBottom + 40) {
            scrollbar.current.classList.add('bottom_fixed');
        } else {
            scrollbar.current.classList.remove('bottom_fixed');
        }

    };

    const scrollHandlerBound = scrollHandler.bind();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (refPopover.current && !refPopover.current.contains(event.target)) {
                setSearchBox(null);
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        document.addEventListener('scroll', scrollHandlerBound);

        setTimeout(() => {
            setTableWidth(mainTable.current.offsetWidth);
        }, 1000);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('scroll', scrollHandlerBound);
            document.getElementById('siteHeader').style.boxShadow = '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
        };
    }, []);

    useEffect(() => {
        setPage(!!agentMetaData && !!agentMetaData.page && agentMetaData.page || page);
        setPageSize(!!agentMetaData && !!agentMetaData.pageSize && agentMetaData.pageSize || pageSize);
    }, [list]);

    const handleChangeSearch = (event) => {
        setInputValue(event.target.value);
    };

    const openSearchBox = (el) => {
        let searchText = searchBox;

        if ((el && el.dataName === searchBox) || (el && el.label === searchBox)) {
            searchText = '';
        } else {
            searchText = el.dataName || el.label;
        }

        setSearchBox(searchText);
        setInputValue(searchObj[el.dataName]);
    };

    const clearInput = () => {
        setInputValue('');
    };

    const clearSearch = (e, key, all) => {
        e.stopPropagation();

        let cloneSearchObj = cloneDeep(searchObj);

        if (all) {
            cloneSearchObj = {};
        } else {
            delete cloneSearchObj[key.dataName];
        }

        setInputValue('');
        setSearchObj(cloneSearchObj);

        let parameters = {
            exact: filtersObj || {}, partial: cloneSearchObj || {}
        };

        return updateTable(parameters, { page: 1, page_size: pageSize });
    };

    const handleScroll = (e, ref) => {
        if (ref === 'table') {
            scrollableheader.current.scrollTo(table.current.scrollLeft, 0);
            scrollbar.current.scrollTo(table.current.scrollLeft, 0);
        }

        if (ref === 'header') {
            table.current.scrollTo(scrollableheader.current.scrollLeft, 0);
            scrollbar.current.scrollTo(scrollableheader.current.scrollLeft, 0);
        }

        if (ref === 'scrollbar') {
            scrollableheader.current.scrollTo(scrollbar.current.scrollLeft, 0);
            table.current.scrollTo(scrollbar.current.scrollLeft, 0);
        }
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const filterTable = (group, filter) => {
        let filteredItem = group === 'is_concept' ? !!includes(toLower(filter), 'not') ? 'false' : 'true' : filter;

        if (!!filtersObj && !filtersObj[group]) {
            filtersObj[group] = [];
            filtersObj[group].push(filteredItem);
        } else {
            if (!(includes(filtersObj[group], filteredItem))) {
                filtersObj[group].push(filteredItem);
            } else {
                remove(filtersObj[group], item => {
                    return item === filteredItem;
                });

                if (!filtersObj[group].length) {
                    delete filtersObj[group];
                }
            }
        }

        setFiltersObj(filtersObj);

        let parameters = {
            exact: filtersObj || {}, partial: searchObj || {}
        };

        return updateTable(parameters, { page: 1, page_size: pageSize });
    };

    const clearFilters = () => {
        let parameters = {
            exact: {}, partial: searchObj || {}
        };

        setFiltersObj({});

        return updateTable(parameters, { page: 1, page_size: pageSize });
    };

    const fetchCheckboxSelector = (optionSelected) => {
        const excludeFromThisPage = differenceBy(selectedRows, data, 'record_id');
        switch (optionSelected) {
            case 'all_on_page':
                return dispatch(selectAllFunction(uniqBy([...selectedRows, ...data],'record_id'), false, uniqBy([...selectedRows, ...data],'record_id')));
            case 'clear_on_page':
                return dispatch(selectAllFunction(excludeFromThisPage, false, excludeFromThisPage));
            case 'clear_all':
                return dispatch(selectAllFunction([], false, []));
        }
    };

    const searchTable = (event, group) => {
        let value = !!event && event.target.value;

        if (event.key === 'Enter' && size(value) > 0) {
            if (!searchObj[group.dataName]) {
                searchObj[group.dataName] = [];
                searchObj[group.dataName] = value;
            } else {
                searchObj[group.dataName] = value;
            }

            setSearchObj(searchObj);
            setSearchBox(null);

            let parameters = {
                exact: filtersObj || {}, partial: searchObj || {}
            };

            return updateTable(parameters, { page: 1, page_size: pageSize });
        }
    };

    const getValue = (data, dataName) => {
        let categoryDataName = 'licensor_category_path';

        if (includes(dataName, categoryDataName)) {
            let index = dataName.split('.')[1];
            return data[categoryDataName] && data[categoryDataName][index] && data[categoryDataName][index] || '';
        }

        return data[dataName];
    };

    const openProduct = (record_id) => {
        window.location.href = `/product/catalog/${record_id}`
    };

    const func = (record_id) => openProduct(record_id);
    const allIds = map(data, 'record_id');

    const filteredSelectedData = selectedRows.filter((item) => allIds.includes(item.record_id));
    const allChecked = size(data) === size(filteredSelectedData);
    return (
        <Fragment>
            <div className={`ui-table ${layoutType}`} ref={tableWrapper}>
                <HeaderSection
                    aggregations={aggregations || []}
                    showFilters={showFilters}
                    headersArr={headersArr || {}}
                    filtersObj={filtersObj || {}}
                    filterTable={(group, filter) => filterTable(group, filter)}
                    closeFilters={() => setShowFilters(false)}
                    clearFilters={() => clearFilters()}
                    selectedRows={selectedRows}
                    dispatch={dispatch}
                    categoryLevels={categoryLevels}
                    activeHeaders={activeHeaders}
                    headerLayouts={headerLayouts}
                    headers={headers}
                    selectAll={selectAll}
                    setLayout={setLayout}
                    layoutType={layoutType}
                    data={list}
                    toggleFilters={() => toggleFilters()}>
                </HeaderSection>

                {layoutType === 'grid' && (
                    <Grid
                        data={list}
                        selectedRows={selectedRows}
                        dispatch={dispatch}
                        previousRow={previousRow} />
                )}

                {layoutType === 'table' && (
                    <div className="ui-table__wrapper table_ui">
                        <div className="ui-table__header-container ">
                            <div
                                className="c-rec-table__table-holder table_header_wrapper"
                                ref={header}
                            >
                                <div className={`checkbox-selector-wrapper-fixed dark-table-head ${allChecked ? 'full_selected' : (filteredSelectedData.length > 0 || selectedRows.length > 0) ? 'partial_selected' : 'unselected'}`}>
                                    <UiCheckbox
                                        extraClass={allChecked ? 'full_selected' : filteredSelectedData.length > 0 ? 'partial_selected' : ''}
                                        type="checkbox"
                                        checked={allChecked}
                                        onChange={() => {
                                            if (allChecked) {
                                                fetchCheckboxSelector('clear_on_page', allIds);

                                            } else {
                                                fetchCheckboxSelector('all_on_page', allIds);
                                            }
                                        }}>
                                    </UiCheckbox>

                                    <div className="checkbox-action-counter-indicator">
                                        {selectedRows.length > 0 && (<span
                                            className="checkbox-counter">{selectedRows.length}</span>)}
                                        <CustomSelect
                                            onChange={(e) => {
                                                fetchCheckboxSelector(e, allIds);
                                            }}
                                            values={checkSelectorOptionList}
                                            allowEmpty={true}
                                        />
                                    </div>
                                </div>
                                <div ref={scrollableheader}
                                    onScroll={(e) => handleScroll(e, 'header')}
                                    className="table_wrapper_header">
                                    <table className="ui-table__top-header -dark">
                                        <thead>
                                            <tr ref={mainTable}>
                                                <th className="ui-table__head-cell c-rec-table__approval checkbox-cell">

                                                </th>

                                                <th className="ui-table__head-cell c-rec-table__approval">
                                                    <div className="c-rec-table__icon-holder">
                                                        <Icon
                                                            icon="LOCK_FULL"
                                                            fill="white"
                                                            width="12"
                                                            height="10"
                                                            right="2"
                                                            top="2"
                                                        />
                                                        {t('table_headers.concept')}
                                                    </div>
                                                </th>
                                                <th className="ui-table__head-cell">
                                                    <div className="c-rec-table__icon-holder">
                                                        <Icon
                                                            icon="LOCK_FULL"
                                                            fill="white"
                                                            width="10"
                                                            height="10"
                                                            right="2"
                                                            top="2"
                                                        />

                                                        {t('table_headers.registered_by')}
                                                    </div>
                                                </th>
                                                <th className="ui-table__head-cell -image">
                                                    <div className="c-rec-table__icon-holder">
                                                        <Icon
                                                            icon="LOCK_FULL"
                                                            fill="white"
                                                            width="10"
                                                            height="10"
                                                            right="2"
                                                            top="2"
                                                        />
                                                        {t('table_headers.image')}
                                                    </div>
                                                </th>

                                                {toArray(sortedHeaders).map((el) => {
                                                    const key = el.dataName;

                                                    return (<th
                                                        className={`ui-table__head-cell ${el.multiple === 'true' ? 'big-cell' : ''}`}
                                                        key={`${key}-mrhead`}
                                                    >
                                                        <div className="u-flex-align c-table-search">
                                                            {!!includes(searchableFileds, el.dataName) && (<Icon
                                                                className="u-clickable marg-r-5"
                                                                icon="search"
                                                                fill="white"
                                                                onClick={() => openSearchBox(el)}
                                                            />)}

                                                            {searchBox === key && (<div
                                                                ref={refPopover}
                                                                className="c-table-search__popover"
                                                            >
                                                                <input
                                                                    className="form-control"
                                                                    autoFocus
                                                                    value={inputValue}
                                                                    onChange={(e) => handleChangeSearch(e)}
                                                                    onKeyPress={(e) => searchTable(e, el)}
                                                                    placeholder={`${t('search_items.search_for')} ` + el.label}
                                                                />
                                                                <Icon
                                                                    className="u-clickable clear-icon"
                                                                    onClick={() => clearInput()}
                                                                    icon="BIN"
                                                                    fill="gray"
                                                                />
                                                            </div>)}

                                                            {(!includes(el.dataName, 'ips') && !includes(el.dataName, 'licensor_category_path')) ? t(`table_headers.${el.dataName}`) : el.label}
                                                        </div>
                                                    </th>);
                                                })}
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                {!!toArray(searchObj).length && (<AggregatedSearch
                                    tableWidth={tableWidth}
                                    headersArr={sortedHeaders}
                                    clearSearch={(e, key, all) => clearSearch(e, key, all)}
                                    searchObj={searchObj}
                                    openSearchBox={(el) => openSearchBox(el)}
                                    handleChangeSearch={(e) => handleChangeSearch(e)}>
                                </AggregatedSearch>)}
                            </div>
                        </div>

                        {!!list && size(list) > 0 && (
                            <div className="c-rec-table">
                                <div
                                    className="c-rec-table__table-holder c-rec-table__main-table"
                                    ref={table}
                                    onScroll={(e) => handleScroll(e, 'table')}
                                    style={{ minHeight: 100 }}
                                >
                                    <table>
                                        <tbody>
                                            {list.map((el, index) => {
                                                const image = getMainImage(el['images']);

                                                return (<tr key={`ui-table__body-${index}`}>
                                                    <td className="ui-table__body-cell c-rec-table__approval checkbox-cell">
                                                        <UiCheckbox
                                                            type="checkbox"
                                                            checked={selectedRows.filter(item => item.record_id === el.record_id).length > 0}
                                                            onClick={(e) => dispatch(selectRow(e, el, selectedRows, false, // isMyRecords
                                                                el, [], data, previousRow))}>
                                                        </UiCheckbox>
                                                    </td>
                                                    <td className="ui-table__body-cell c-rec-table__approval">
                                                        <div className="c-rec-table__status-icons">
                                                            <TooltipApprovedData
                                                                record={el}
                                                                openProduct={() => openProduct(el.record_id)}
                                                                licensor={true}
                                                            />
                                                        </div>
                                                    </td>

                                                    <Auth restrictTo="agent">
                                                        <td className="ui-table__body-cell">
                                                            {el.company}
                                                        </td>
                                                    </Auth>

                                                    <td className="ui-table__body-cell -image">
                                                        <div className="c-rec-table__image-cell"
                                                            onClick={() => openProduct(el.record_id)}>
                                                            <div className="c-rec-table__image-icon">
                                                                <MediaView
                                                                    src={image}
                                                                    alt={'product_name'}
                                                                    fallbackSrc={recordPlaceholder}
                                                                    useImgTag
                                                                />
                                                            </div>
                                                            {!!image && (<MediaView
                                                                src={image}
                                                                className="c-rec-table__hover-image"
                                                                fallbackSrc={recordPlaceholder}
                                                                useImgTag
                                                            />)}
                                                        </div>
                                                    </td>

                                                    {toArray(sortedHeaders).map(({ form: { type }, dataName }, i) => (<td
                                                        className="ui-table__body-cell"
                                                        key={`${type}-${dataName}-table-row-${i}`}
                                                    >
                                                        <span
                                                            onClick={() => dataName === 'product_name' ? openProduct(el.record_id) : null}>
                                                            {renderElement(el, type, getValue(el, dataName), dataName, func, false)}
                                                        </span>
                                                    </td>))}
                                                </tr>);
                                            })}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        )}

                        <div
                            ref={scrollbar}
                            className="c-rec-table__bottom-scrollbar"
                            onScroll={(e) => handleScroll(e, 'scrollbar')}
                        >
                            <div
                                style={{
                                    height: 20, width: (table.current && table.current.scrollWidth) || 0
                                }}
                            />
                        </div>
                    </div>
                )}

                {!!list && size(list) > 0 && (<DataViewPagination
                    extraclass={'marg-t-40'}
                    totalItems={!!agentMetaData && !!agentMetaData.totalResults && agentMetaData.totalResults || 0}
                    fetchData={(params) => updateTable({ exact: filtersObj, partial: searchObj }, params)}
                    pageLimit={pageSize}
                    pageNum={page}
                />)}
            </div>

            {!size(list) && !toArray(searchObj).length && (
                <div className="u-text-center u-margin-top-4X">
                    <h3 className="c-my-records__no-data-header">
                        {t('product_components.no_data.no_products_available')}
                    </h3>
                    <div className='no-data-catalogue'>
                        <p className='c-my-records__no-data-text'>
                            {t('product_components.no_data_catalogue.no_products')}
                        </p>
                    </div>
                </div>
            )}
        </Fragment>
    );
}
