import React from 'react';
import { useTranslation } from 'react-i18next';
import MediaView from 'components/MediaView';
import recordPlaceholder from '../../../../../statics/imgs/record-placeholder.png';

const NoteBox = ({ companyName, note, logoUrl }) => {
    const { t } = useTranslation();

    const getCompanyImageSrc = (id) => {
        return `https://assets.xelacore.io/org/${id}.png`;
    };

    return (
        <div className="note-box">
            <div className="note-content">
                <div className="note-header">
                    <div className="company-logo">
                        <MediaView
                            src={getCompanyImageSrc(logoUrl)}
                            alt={`${companyName} ${t('toolkit.trademark_authorization.note.logo')}`}
                            fallbackSrc={recordPlaceholder}
                            useImgTag
                        />
                    </div>
                    <h2>{t('toolkit.trademark_authorization.note.from', { companyName })}</h2>
                </div>
                <p>{note || t('toolkit.trademark_authorization.note.no_notes')}</p>
            </div>
        </div>
    );
};

export default NoteBox;
