import { countriesSortedByContinent } from 'src/js/constants/countries';
import languages from 'src/js/constants/languageConstants';

export const headers = [
    {
        // Product Name
        label: 'Product Name', // FE facing name
        dataName: 'product_name', // Name use in the backend and data objects
        description: {
            // Multiple descriptions based on the licesnee / licensor and can be extendable
            licensee:
                'This field defines the consumer facing name of your product. This section is important because it is the name that will be used to list this product on ecommerce channels and will affect searchability.',
            licensor: 'This is the customer facing name of the product',
            examples: [
                // Some areas use these so we put them here
                'Example: Fortnite Tower Recon Specialist Pocket Pop Keychain',
                'Example: Ben Sherman Men’s Long Sleeve Gingham Shirt Red',
                'Tip: Be as clear and as detailed as possible to improve the online product discoverability.'
            ]
        },
        // These could be differnt or the same as the product description so they're broken out into here
        tooltip: {
            licensee: 'This is the consumer facing name of your product.',
            licensor: 'This is the consumer facing name of the product.'
        },
        // Sets out the form elements and form variables that we use for rendering on the front end
        // The values here are directly linked to the components defined in the PrdouctFormElement component
        // Some values in this section are specifc and required for specific components
        form: {
            type: 'clickableText',
            placeholder: 'Product Name'
        },
        config: {
            value: 'primary_attributes',
            group: 'Primary Attributes', // Key for grouping
            alwaysSelected: true
        },
        isSearchable: true,
        active: true,
        showInList: true,
        order: 1
    },
    {
        // GTIN
        label: 'GTIN',
        dataName: 'gtin',
        descriptionLabel: 'GTIN/EAN/UPC',
        description: {
            licensee:
                'This is your product’s Global Trade Item Number (bar code). This field is mandatory to upload your product, register with your Licensor(s) and share with third-party channels.',
            licensor: 'This is the product’s Global Trade Item Number.'
        },
        tooltip: {
            licensee:
                'This is your product’s Global Trade Item Number (bar code).',
            licensor:
                'This is the product’s Global Trade Item Number (bar code)'
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'primary_attributes',
            group: 'Primary Attributes',
            keyField: true,
            alwaysSelected: true,
            hideOnBulkEdit: true
        },
        isSearchable: true,
        active: true,
        showInList: true,
        order: 2
    },
    {
        // Product Description
        dataName: 'product_description',
        label: 'Description',
        descriptionLabel: 'Product Description',
        description: {
            licensee:
                'A detailed description of the product, including what it does and any relevant information. List product features, technical specifications and visual attributes.',
            licensor:
                'A detailed description of the product, including what it does, its features and any relevant information. This field is mandatory register the product with your Licensor(s) and share with third-party channels.',
            examples: [
                'Example: A tropical print playsuit in a mesh finish featuring long sleeves and a plunge front. Please note: print may vary on each product. Regular fit Short - sits at upper thigh. 95% Polyester 5%. Model Elastane Noara wears a UK size 8 / EU size 36 / US size 4 and her height is 5\'9".'
            ]
        },
        tooltip: {
            licensee:
                'A detailed description of the product, including what it does and any relevant information. List product features, technical specifications and visual attributes.',
            licensor:
                'A detailed description of the product, including what it does and any relevant information. List product features, technical specifications and visual attributes.'
        },
        form: {
            type: 'textarea',
            placeholder: 'Product description'
        },
        config: {
            value: 'primary_attributes',
            group: 'Primary Attributes',
            alwaysSelected: false
        },
        active: true,
        showInList: true,
        order: 3
    },
    {
        // Target Market
        dataName: 'target_market',
        label: 'Target Market',
        description: {
            licensee:
                'This is the country where the product is intended to be sold. Use an ISO two or three letter country codes. If multiple entries are required per field, use a “;” to separate the values. This field is needed to register with your Licensor(s) and share with third-party channels. ',
            licensor:
                'This is the country where the product will be sold. Use an ISO two or three letter country codes. If multiple entries are required per field, use a “,” to separate the values. This field is not mandatory for you to upload your product onto Xelacore, but it is needed to register with your Licensor(s) and share with third-party channels.'
        },
        tooltip: {
            licensee:
                'This is the country where the product is intended to be sold.',
            licensor:
                'This is the country where the product is intended to be sold.'
        },
        form: {
            type: 'selectdropdown',
            placeholder: 'Target Market',
            values: countriesSortedByContinent,
            isGrouped: true,
            isMulti: true,
            showFullLabel: true
        },
        config: {
            value: 'primary_attributes',
            group: 'Primary Attributes'
        },
        active: true,
        showInList: true,
        order: 4
    },
    {
        // Language
        dataName: 'language',
        label: 'Language',
        description: {
            licensee:
                'The language that is on the labelling and packaging of the product. If multiple entries are required per field, use a “;” to separate the values. When you are ready to register your product with your Licensor(s) or share with third-party channels, language is mandatory to complete.',
            licensor:
                'The language that is on the labelling and packaging of the product.'
        },
        tooltip: {
            licensee:
                'The language that is on the labelling and packaging of the product.',
            licensor:
                'The language that is on the labelling and packaging of the product.'
        },
        form: {
            type: 'selectdropdown',
            values: languages.map(({ code, name }) => ({
                value: code,
                label: name
            })),
            placeholder: 'Language',
            isGrouped: false,
            isMulti: true
        },
        config: {
            value: 'primary_attributes',
            group: 'Primary Attributes'
        },
        active: false,
        showInList: true,
        order: 5
    },
    {
        // MPN
        dataName: 'mpn',
        label: 'MPN',
        description: {
            licensee:
                "This is your product's Manufacturer Part Number, unique reference number you are holding in your system to identify a specific product among all products you are making. This is mandatory to provide in order to upload your product and register with your Licensor(s).",
            licensor:
                "This is your product's Manufacturer Part Number, always assigned by the manufacturer."
        },
        tooltip: {
            licensee:
                "This is your product's Manufacturer Part Number; the unique reference number you are holding in your system to identify a specific product among all products you are making.",
            licensor:
                "This is the product's Manufacturer Part Number, always assigned by the manufacturer."
        },
        form: {
            type: 'text',
            placeholder: 'MPN '
        },
        config: {
            value: 'primary_attributes',
            group: 'Primary Attributes',
            keyField: true,
            hideOnBulkEdit: true,
            alwaysSelected: true
        },
        active: true,
        showInList: true,
        order: 6
    },
    {
        // Product Family
        dataName: 'product_family_name',
        label: 'Product Family',
        description: {
            licensee:
                "This is your product's Manufacturer Part Number, unique reference number you are holding in your system to identify a specific product among all products you are making. This is mandatory to provide in order to upload your product and register with your Licensor(s).",
            licensor:
                "This is your product's Manufacturer Part Number, always assigned by the manufacturer."
        },
        tooltip: {
            licensee:
                'Product family refers to a code connecting products part of the same collection. These could vary by colour, size or product type. E.g. where the same design is used across multiple t-shirt sizes and colours.',
            licensor:
                'Product family refers to a code connecting products part of the same collection. These could vary by colour, size or product type. E.g. where the same design is used across multiple t-shirt sizes and colours.'
        },
        form: {
            type: 'text',
            placeholder: 'Product Family'
        },
        config: {
            value: 'primary_attributes',
            group: 'Primary Attributes',
            keyField: true
        },
        active: true,
        showInList: true,
        order: 7
    },
    {
        // Mpn_combined
        dataName: 'mpn_combined',
        label: 'Associated MPNs',
        description: {
            licensee:
                "This is your product's Manufacturer Part Numbers, always assigned by the manufacturer.This is mandatory to register the product with your Licensor(s).This is critical for us to work our Xelacore magic!",
            licensor:
                "This is your product's Manufacturer Part Numbers, always assigned by the manufacturer."
        },
        tooltip: {
            licensee:
                'You may associate additional Manufacturer Part Numbers with your product.',
            licensor:
                'You may associate additional Manufacturer Part Numbers with your product.'
        },
        form: {
            type: 'array',
            hideIfEmpty: true
        },
        config: {
            value: 'primary_attributes',
            hideOnBulkEdit: true,
            group: 'Primary Attributes'
        },
        active: false,
        showInList: false
    },
    {
        // Licensor Product Category
        dataName: 'licensee_category_path',
        label: 'Product Category',
        description: {
            licensee:
                'The group the product belongs in. This field is mandatory to register your product with your Licensor(s) and share with third-party channels.',
            licensor:
                'The group the product belongs in. This field is mandatory to register with your Licensor(s) and share with third-party channels.',
            examples: ['Example: Toys', 'Example: Apparel']
        },
        tooltip: {
            licensee: 'This is the category of this product.',
            licensor: 'This is the category of this product.'
        },
        form: {
            type: 'dynamicCategory'
        },
        config: {
            value: 'categories',
            group: 'Categories',
            keyField: true
        },
        active: true,
        showInList: true,
        order: 8
    },
    {
        // Licensor
        dataName: 'licensor',
        label: 'Licensor',
        description: {
            licensee:
                'Use this field to specify which Licensor owns the Intellectual Property (IP). If the product is licensed this field is mandatory for you to complete.',
            licensor:
                'Use this field to specify which Licensor owns the Intellectual Property (IP).',
            examples: ['Example: Epic Games']
        },
        tooltip: {
            licensee:
                'This field specifies which Licensor owns the Intellectual Property (IP).',
            licensor: ''
        },
        form: {
            type: 'text',
            placeholder: 'Licensor',
            disabled: true
        },
        config: {
            value: 'licensed_properties',
            group: 'Licensed Properties',
            keyField: true,
            alwaysSelected: false,
            subGroup: false,
            isAssociatedIp: false,
            other: false
        },
        active: true,
        showInList: true,
        order: 10
    },
    {
        // Licensor Product Category
        dataName: 'ip_paths',
        label: 'Licensed IP',
        description: {
            licensee:
                'The group the product belongs in. This field is mandatory to register your product with your Licensor(s) and share with third-party channels.',
            licensor:
                'The group the product belongs in. This field is mandatory to register with your Licensor(s) and share with third-party channels.',
            examples: ['Example: Toys', 'Example: Apparel']
        },
        tooltip: {
            licensee: 'This is the licensed brand used for this product.',
            licensor: 'This is the licensed brand used for this product.'
        },
        form: {
            type: 'dynamicIps'
        },
        config: {
            value: 'licensed_properties',
            group: 'Licensed Properties',
            keyField: true
        },
        active: true,
        showInList: true,
        order: 11
    },
    {
        // Licensor Product Category
        dataName: 'agent_organisation',
        label: 'Agent Organisation',
        description: {
            licensee:
                'The group the product belongs in. This field is mandatory to register your product with your Licensor(s) and share with third-party channels.',
            licensor:
                'The group the product belongs in. This field is mandatory to register with your Licensor(s) and share with third-party channels.',
            examples: ['Example: Toys', 'Example: Apparel']
        },
        tooltip: {
            licensee: 'This is the agent linked to this product.',
            licensor: 'This is the agent linked to this product.'
        },
        form: {
            type: 'dynamicAgent'
        },
        config: {
            value: 'licensed_properties',
            group: 'Licensed Properties',
            subGroup: false,
            isAssociatedIp: false,
            keyField: false,
            other: true
        },
        active: true,
        showInList: true,
        order: 11
    },
    {
        // Licensor Concept Approval Code
        dataName: 'concept_code',
        label: 'Concept Approval Code',
        descriptionLabel: 'Licensor Concept Approval Code',
        description: {
            licensee:
                'This is a unique reference number assigned by the Licensor for each concept. This is an optional field.',
            licensor:
                'This is the unique product approval code you supplied to your Licensee that links the product to the original concept approval.'
        },
        tooltip: {
            licensee:
                'This is a unique reference number assigned by the Licensor for each concept.',
            licensor:
                'This is the unique product approval code you supplied to your Licensee that links the product to the original concept approval.'
        },
        form: {
            type: 'array',
            placeholder: 'Concept Approval Code'
        },
        config: {
            value: 'licensed_properties',
            group: 'Licensed Properties',
            subGroup: false,
            isAssociatedIp: false,
            keyField: false,
            other: true
        },
        active: false,
        showInList: true,
        order: 16
    },
    {
        // Company Brand
        dataName: 'company_brand',
        label: 'Owned Brand',
        description: {
            licensee:
                'If applicable, this is the name of your own brand. This is a mandatory field if the product is not licensed.',
            licensor: 'This is the name of your own (non-licensed) brand'
        },
        tooltip: {
            licensee: 'If applicable, this is the name of your own brand.',
            licensor:
                "If applicable, this is the name of your Licensee's own brand."
        },
        form: {
            type: 'text',
            placeholder: 'Owned Brand'
        },
        config: {
            value: 'owned_ip',
            group: 'Owned IP Properties',
            unLicensed: true
        },
        active: false,
        showInList: true,
        order: 17
    },
    {
        // Company Sub Brand / Franchise
        dataName: 'company_sub_brand',
        label: 'Owned Franchise / Sub-Brand',
        description: {
            licensee:
                'If applicable, this is the name of your own franchise / sub brand - these terms can be used interchangeably.',
            licensor:
                'This is the name of your own (non-licensed) franchise / sub brand - these terms can be used interchangeably.'
        },
        tooltip: {
            licensee:
                'If applicable, this is the name of your own franchise / sub brand - these terms can be used interchangeably.',
            licensor:
                "If applicable, this is the name of you’re your Licensee's franchise / sub brand - these terms can be used interchangeably."
        },
        form: {
            type: 'text',
            placeholder: 'Owned Franchise / Sub-Brand'
        },
        config: {
            value: 'owned_ip',
            group: 'Owned IP Properties',
            unLicensed: true
        },
        active: false,
        showInList: true,
        order: 18
    },
    {
        // Company Sub Brand / Franchise
        dataName: 'company_character',
        label: 'Owned Character',
        description: {
            licensee:
                'If applicable, this is the name of your character within your own portfolio.',
            licensor:
                'This is the name of your own (non-licensed) franchise / sub brand - these terms can be used interchangeably.'
        },
        tooltip: {
            licensee:
                'If applicable, this is the name of your character within your own portfolio.',
            licensor:
                "If applicable, this is the name of your Licensee's character."
        },
        form: {
            type: 'text',
            placeholder: 'Owned Character'
        },
        config: {
            value: 'owned_ip',
            group: 'Owned IP Properties',
            unLicensed: true
        },
        order: 19
    },
    {
        // Product URL
        label: 'Product URL',
        dataName: 'product_url',
        description: {
            licensee:
                'This is the URL for your product listing, if you have a product information page that you host and want to be associated with the registration.',
            licensor:
                'This URL was provided by the licensee. To report a problem, please contact the manufacturer. '
        },
        tooltip: {
            licensee:
                'This is the URL for your product listing, if you have a product information page that you host and want to be associated with the registration.',
            licensor: 'This is the URL for the product listing.'
        },
        form: {
            type: 'url',
            placeholder: 'https://www.example.com'
        },
        config: {
            value: 'digital_content',
            group: 'Digital Content'
        },
        active: false,
        showInList: true,
        order: 20
    },
    {
        // Video
        label: 'Video URL',
        dataName: 'video_url',
        description: {
            licensee:
                'Include a URL link to a demonstration of your product. This attribute is not mandatory for you to upload your products into Xelacore, register with your Licensor(s).',
            licensor: 'Include a URL link to a demonstration of your product.'
        },
        tooltip: {
            licensee: 'This is a URL link to a demonstration of your product.',
            licensor: 'This is a URL link to a demonstration of the product. '
        },
        form: {
            type: 'url',
            placeholder: 'https://www.example.com'
        },
        config: {
            value: 'digital_content',
            group: 'Digital Content'
        },
        active: false,
        showInList: true,
        order: 21
    },
    {
        // Images
        dataName: 'images',
        label: 'Images',
        descriptionLabel: 'Primary Image URL',
        description: {
            licensee:
                'This is the URL for your product image. The image should be a clear representation of the product as it is the primary image that will be displayed in your product catalogue. You will need to fill in this field to register with your Licensor(s). Please note the maximum file size is 2MB.',
            licensor:
                'These are your product & packaging images and you can upload up to 50 images per product. Recommended image size is 500px wide and should not exceed 500kb in size.',
            examples: [
                'Example: www.website.com/image.jpeg',
                'Tip: A valid URL must have the extension .jpeg, or .png. We advise you to upload your product image URL when you fill in your CVS file to save you from uploading individual .jpeg files from your local device.'
            ]
        },
        tooltip: {
            licensee:
                'This is the URL for your product image. The image should be a clear representation of the product as it is the primary image that will be displayed in your product catalogue.',
            licensor: 'This is the URL for the product image.'
        },
        form: {
            type: 'imageArray'
        },
        config: {
            value: 'digital_content',
            group: 'Digital Content'
        },
        active: false,
        showInList: false,
        order: 22
    },
    {
        // Market Availability Date
        dataName: 'market_availability_date',
        label: 'Market Availability Date',
        description: {
            licensee:
                'The date product will be available in the market from. Please use your configured date format or our format “YYYY-MM-DD”. This section will need to be filed in to register with your Licensor(s).',
            licensor:
                'The date product will be available in the market from. Please use your configured date format or our format “YYYY-MM-DD”. This section will need to be filed in to register with your Licensor(s).',
            examples: ['Example: 2021-10-03']
        },
        tooltip: {
            licensor: 'The date product will be available in the market from.',
            licensee:
                'The date product will be available in the market from.',
            examples: ['Example: 2021-10-03']
        },
        form: {
            type: 'date',
            placeholder: '“YYYY-MM-DD”'
        },
        config: {
            value: 'status',
            group: 'Status'
        },
        active: false,
        showInList: true,
        order: 23
    },
    {
        // Product Status
        label: 'Product Status',
        dataName: 'product_status',
        description: {
            licensee:
                'This field indicates the current status of the product - it could be one of the following: New Product Listing, Live, On-Hold, Sell-off or Inactive. This field is mandatory to register products with your Licensor(s). ',
            licensor:
                'This attribute indicates the current Product status of the product. It could be one of the following:  New Product Listing,  Live,  On-Hold,  Sell-off,  Inactive'
        },
        tooltip: {
            licensee:
                'The Product Status field indicates the current status of the product. You can select from one of the following options: New Product Listing, Live, On-Hold, Sell-off or Inactive.',
            licensor:
                'This attribute indicates the current Product status of the product. It could be one of the following:  New Product Listing,  Live,  On-Hold,  Sell-off,  Inactive'
        },
        form: {
            type: 'select',
            values: [
                {
                    label: 'New Product Listing',
                    name: 'New Product Listing'
                },
                {
                    label: 'Live',
                    name: 'Live'
                },
                {
                    label: 'On-Hold',
                    name: 'On-Hold'
                },
                {
                    label: 'Sell-Off',
                    name: 'Sell-Off'
                },
                {
                    label: 'Inactive',
                    name: 'Inactive'
                }
            ]
        },
        config: {
            value: 'status',
            group: 'Status'
        },
        active: false,
        showInList: true,
        order: 24
    },
    {
        // Manufacturing Status
        label: 'Manufacturing Status',
        dataName: 'manufacturing_status',
        description: {
            licensee:
                'This field indicates the current manufacturing status of the product - it could be one of the following: Pre-Production, In Production, On-Hold or Discontinued. This field is mandatory to register products with your Licensor(s).',
            licensor:
                'This attribute indicates the current Manufacturing status of the product. It could be one of the following:  Pre-Production,  In Production,  On-Hold,  Discontinued  ',
            examples: [
                'Tip: if the product is Live in the market but it is no longer in production, please put Discontinued.'
            ]
        },
        tooltip: {
            licensee:
                'This field indicates the current manufacturing status of the product - it could be one of the following: Pre-Production, In Production, On-Hold or Discontinued. This field is mandatory to register products with your Licensor(s).',
            licensor:
                'This field indicates the current manufacturing status of the product.'
        },
        form: {
            type: 'select',
            values: [
                {
                    label: 'In Production',
                    name: 'In Production'
                },
                {
                    label: 'Pre-Production',
                    name: 'Pre-Production'
                },
                {
                    label: 'On-Hold',
                    name: 'On-Hold'
                },
                {
                    label: 'Discontinued',
                    name: 'Discontinued'
                }
            ]
        },
        config: {
            value: 'status',
            group: 'Status'
        },
        active: false,
        showInList: true,
        order: 25
    },
    {
        dataName: 'asin',
        label: 'ASIN',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'asinArray',
            hideIfEmpty: true
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: true,
        order: 26
    },
    {
        dataName: 'flow_through_end_date',
        label: 'Flow through end date',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'date',
            placeholder: '“YYYY-MM-DD”'
        },
        config: {
            value: 'brand_attributes',
            hideOnBulkEdit: true,
            group: 'Brand Attributes'
        },
        active: true,
        showInList: false,
        order: 27
    },
    {
        dataName: 'flow_through_flag',
        label: 'Flow through flag',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'brand_attributes',
            hideOnBulkEdit: true,
            group: 'Brand Attributes'
        },
        active: true,
        showInList: false,
        order: 28
    },
    {
        dataName: 'flow_through_grouping_name',
        label: 'Flow through grouping name',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'brand_attributes',
            hideOnBulkEdit: true,
            group: 'Brand Attributes'
        },
        active: true,
        showInList: false,
        order: 29
    },
    {
        dataName: 'flow_through_start_date',
        label: 'Flow through start date',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'date',
            placeholder: '“YYYY-MM-DD”'
        },
        config: {
            value: 'brand_attributes',
            hideOnBulkEdit: true,
            group: 'Brand Attributes'
        },
        active: true,
        showInList: false,
        order: 30
    },
    {
        dataName: 'manufacturer',
        label: 'Manufacturer',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'brand_attributes',
            hideOnBulkEdit: true,
            group: 'Brand Attributes'
        },
        active: true,
        showInList: false,
        order: 31
    },
    {
        dataName: 'package_size',
        label: 'Package size',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'brand_attributes',
            hideOnBulkEdit: true,
            group: 'Brand Attributes'
        },
        active: true,
        showInList: false,
        order: 32
    },
    {
        dataName: 'packaging_materials',
        label: 'Packaging materials',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'brand_attributes',
            hideOnBulkEdit: true,
            group: 'Brand Attributes'
        },
        active: true,
        showInList: false,
        order: 33
    },
    {
        dataName: 'product_material',
        label: 'Product material',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'brand_attributes',
            hideOnBulkEdit: true,
            group: 'Brand Attributes'
        },
        active: true,
        showInList: false,
        order: 34
    },
    {
        dataName: 'promotion_period_end',
        label: 'Promotion period end',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'date',
            placeholder: '“YYYY-MM-DD”'
        },
        config: {
            value: 'brand_attributes',
            hideOnBulkEdit: true,
            group: 'Brand Attributes'
        },
        active: true,
        showInList: false,
        order: 35
    },
    {
        dataName: 'promotion_period_start',
        label: 'Promotion period start',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'date',
            placeholder: '“YYYY-MM-DD”'
        },
        config: {
            value: 'brand_attributes',
            hideOnBulkEdit: true,
            group: 'Brand Attributes'
        },
        active: true,
        showInList: false,
        order: 36
    },
    {
        dataName: 'promotional_flag',
        label: 'Promotional flag',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'brand_attributes',
            hideOnBulkEdit: true,
            group: 'Brand Attributes'
        },
        active: true,
        showInList: false,
        order: 37
    },
    {
        dataName: 'promotional_grouping_name',
        label: 'Promotional grouping name',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'brand_attributes',
            hideOnBulkEdit: true,
            group: 'Brand Attributes'
        },
        active: true,
        showInList: false,
        order: 38
    },
    {
        dataName: 'text_on_pack',
        label: 'Text on pack',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'textarea'
        },
        config: {
            value: 'brand_attributes',
            hideOnBulkEdit: true,
            group: 'Brand Attributes'
        },
        active: true,
        showInList: false,
        order: 39
    },
    {
        // Category Attributes
        dataName: 'category_attributes',
        description: {
            licensee:
                '',
            licensor:
                ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'dynamicArray'
        },
        config: {
            value: 'category_attributes',
            group: 'Category Attributes'
        },
        active: true,
        showInList: true,
        order: 40
    },
    {
        dataName: 'size',
        label: 'Size',
        description: {
            licensee: 'This is the size of the product.',
            licensor: 'Size description'
        },
        tooltip: {
            licensee: 'This is the size of the product.',
            licensor: 'This is the size of the product.'
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: true,
        order: 41
    },
    {
        dataName: 'colour',
        label: 'Colour',
        description: {
            licensee:
                "This describes your product's colour. If your product has variants that vary by colour, provide that information here.",
            licensor: 'Colour description'
        },
        tooltip: {
            licensee:
                "This describes your product's colour. If your product has variants that vary by colour, provide that information here.",
            licensor: "This describes the product's colour."
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: true,
        order: 42
    },
    {
        dataName: 'flavour',
        label: 'Flavour',
        description: {
            licensee:
                'This defines the flavour of product that varies by taste.',
            licensor: 'Flavour description'
        },
        tooltip: {
            licensee:
                'This defines the flavour of product that varies by taste.',
            licensor:
                'This defines the flavour of product that varies by taste.'
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: true,
        order: 43
    },
    {
        dataName: 'scent',
        label: 'Scent',
        description: {
            licensee: 'This defines the specific scent of the product.',
            licensor: 'Scent description'
        },
        tooltip: {
            licensee: 'This defines the specific scent of the product.',
            licensor: 'This defines the specific scent of the product.'
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: true,
        order: 44
    },
    {
        dataName: 'height',
        label: 'Height',
        description: {
            licensee: 'This is the height of the packaging.',
            licensor: 'Height description'
        },
        tooltip: {
            licensee: 'This is the height of the packaging.',
            licensor: 'This is the height of the packaging.'
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: false,
        order: 45
    },
    {
        dataName: 'height_unit',
        label: 'Height Unit',
        description: {
            licensee: 'This is the height unit of the packaging.',
            licensor: 'Height description'
        },
        tooltip: {
            licensee: 'This is the height unit of the packaging.',
            licensor: 'This is the height unit of the packaging.'
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: false,
        order: 46
    },
    {
        dataName: 'width',
        label: 'Width',
        description: {
            licensee: 'This is the width of the packaging.',
            licensor: 'Width description'
        },
        tooltip: {
            licensee: 'This is the width of the packaging.',
            licensor: 'This is the width of the packaging.'
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: false,
        order: 47
    },
    {
        dataName: 'width_unit',
        label: 'Width Unit',
        description: {
            licensee: 'This is the width unit of the packaging.',
            licensor: 'Width description'
        },
        tooltip: {
            licensee: 'This is the width unit of the packaging.',
            licensor: 'This is the width unit of the packaging.'
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: false,
        order: 48
    },
    {
        dataName: 'length',
        label: 'Length',
        description: {
            licensee: 'This is the length of the packaging.',
            licensor: 'Length description'
        },
        tooltip: {
            licensee: 'This is the length of the packaging.',
            licensor: 'This is the length of the packaging.'
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: false,
        order: 49
    },
    {
        dataName: 'length_unit',
        label: 'Length Unit',
        description: {
            licensee: 'This is the length unit of the packaging.',
            licensor: 'Length description'
        },
        tooltip: {
            licensee: 'This is the length unit of the packaging.',
            licensor: 'This is the length unit of the packaging.'
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: false,
        order: 50
    },
    {
        dataName: 'weight',
        label: 'Weight',
        description: {
            licensee: 'This is the weight of the product.',
            licensor: 'Weight description'
        },
        tooltip: {
            licensee: 'This is the weight of the product.',
            licensor: 'This is the weight of the product.'
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: false,
        order: 51
    },
    {
        dataName: 'weight_unit',
        label: 'Weight Unit',
        description: {
            licensee: 'This is the weight unit of the product.',
            licensor: 'Weight description'
        },
        tooltip: {
            licensee: 'This is the weight unit of the product.',
            licensor: 'This is the weight unit of the product.'
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: false,
        order: 52
    },
    {
        dataName: 'recommended_retail_price',

        label: 'Recommended Retail Price',
        description: {
            licensee:
                'This field indicates the recommended retail price of the product.',
            licensor:
                'This field indicates the recommended retail price of the product.'
        },
        tooltip: {
            licensee:
                'This field indicates the recommended retail price of the product.',
            licensor:
                'This field indicates the recommended retail price of the product.'
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'extended_attributes',
            group: 'Extended Attributes'
        },
        active: false,
        showInList: false,
        order: 53
    },
    {
        label: 'Attributes',
        dataName: 'additional',
        description: {
            licensee:
                'Specify any additional information regarding the product that you would like to upload or share with your Licensor(s) - these are optional.',
            licensor:
                'Specify any additional information regarding the product that you would like to upload or share with your Licensor(s) - these are optional.',
            examples: ['Example: packshot_image _url']
        },
        tooltip: {
            licensee: 'Additional information regarding the product.',
            licensor: 'Additional information regarding the product.'
        },
        form: {
            type: 'object'
        },
        config: {
            value: 'additional_attributes',
            group: 'Additional Attributes'
        },
        active: true,
        showInList: true,
        order: 54
    },
    {
        dataName: 'last_registered_at',
        label: 'Registration Date',
        description: {
            licensee: 'The date of registration',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'date',
            placeholder: 'DD/MM/YYYY'
        },
        config: {},
        active: true,
        showInList: true,
        order: 55
    },
    {
        dataName: 'registered_by_user',
        label: 'Registered By',
        description: {
            licensee: 'User that registered the record',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'meta',
            group: 'Meta',
            hideOnBulkEdit: true
        },
        active: true,
        showInList: true,
        showInForm: false,
        order: 56
    },
    {
        dataName: 'uploaded_by',
        label: 'Uploaded By',
        description: {
            licensee: 'User that uploaded the record',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'text'
        },
        config: {
            value: 'meta',
            group: 'Meta',
            hideOnBulkEdit: true
        },
        active: true,
        showInList: true,
        showInForm: false,
        order: 57
    },
    {
        dataName: 'updated_at',
        label: 'Modified Date',
        description: {
            licensee: 'Time of last update',
            licensor: 'Time of last update'
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'duration',
            placeholder: 'DD/MM/YYYY hh:mm'
        },
        config: {
            value: 'meta',
            group: 'Meta', // Key for grouping
            hideOnBulkEdit: true
        },
        active: true,
        showInList: true,
        showInForm: false,
        order: 58
    },
    {
        // Additional Attributes
        dataName: 'dynamic_attributes',
        description: {
            licensee: '',
            licensor: ''
        },
        tooltip: {
            licensee: '',
            licensor: ''
        },
        form: {
            type: 'dynamicArray'
        },
        config: {
            value: 'dynamic_attributes',
            group: 'Dynamic Attributes'
        },
        active: true,
        showInList: false,
        order: 59
    }
];
