import React, { useRef } from 'react';
import toArray from 'lodash/toArray';
import size from 'lodash/size';
import { Icon } from 'src/js/components/static';
import { useTranslation } from 'react-i18next';

export default function AggregatedSearch({
    tableWidth,
    headersArr,
    clearSearch,
    searchObj,
    openSearchBox
}){
    const { t } = useTranslation();
    const searchTableRef = useRef(null);

    return (
        <div>
            <table
                className="c-rec-table-search"
                style={{ width: tableWidth }}
                ref={searchTableRef}
            >
                <tbody>
                <tr className="c-rec-table__primary-row">
                    <td
                        className="c-rec-table__cell c-rec-table__checkbox"
                        colSpan={size(headersArr) + 5}
                    >
                        <div className="c-rec-table-search__applied-search">
                            <span className="title">
                                {t('search_items.applied_search')}{' '}
                            </span>

                            <span
                                onClick={(e) => clearSearch(e, {}, true)}
                                className="c-rec-table-search__applied-search__button c-rec-table-search__applied-search__button--clear"
                            >
                                {t('buttons.clear_all')}
                            </span>

                            {toArray(headersArr).map((item) => {
                                if(!!searchObj[item.key]) {
                                    return (
                                        <div
                                            className="c-search-items u_pointer"
                                            key={item.label}
                                        >
                                            <span className="c-search-items--item"
                                                    onClick={() => openSearchBox(item)}>
                                                <span className="name">
                                                    {
                                                        item.label
                                                    }
                                                </span>
                                                :
                                                <span className="value">
                                                    {' '}
                                                    {searchObj[item.key]}
                                                </span>
                                                <Icon
                                                    className="u-clickable clear-icon"
                                                    onClick={(event) => clearSearch(event, item)}
                                                    icon="close"
                                                    fill="black"
                                                />
                                            </span>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}
