import React, { Component, Fragment } from 'react';
import moment from 'moment';
import findIndex from 'lodash/findIndex';
import size from 'lodash/size';
import classnames from 'classnames';
import Button from 'modules/UiKit/components/Button/Button';
import { hideModal } from 'src/js/actions/xelacore';
import MediaView from 'src/js/components/MediaView';
import fallbackImageSmall from 'statics/imgs/fallback_img_small.png';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

class ModalCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected:
                findIndex(
                    props.images,
                    image => image.image_id === props.currentIndex.image_id || image.original_url === props.currentIndex.original_url
                ) || 0,
            isImgZoomed: false
        };
    }

    getZoomValue = img => {
        const currentWidth = img.offsetWidth;
        const currentHeight = img.offsetHeight;
        const naturalWidth = img.naturalWidth;
        const naturalHeight = img.naturalHeight;
        return Math.max(
            Math.min(
                naturalHeight / currentHeight,
                naturalWidth / currentWidth
            ),
            1
        ).toFixed(1);
    };

    handleClick = e => {
        const img = e.target;
        const zoomValue = this.state.isImgZoomed ? 1 : this.getZoomValue(img);
        img.style.transform = `scale(${zoomValue})`;
        this.setState(state => ({
            isImgZoomed: !state.isImgZoomed
        }));
    };

    turnZoomOff = () => {
        this.setState({ isImgZoomed: false });
    };

    handleMouseMove = e => {
        if (!this.state.isImgZoomed) {
            return;
        }
        const img = e.target;
        const { clientX, clientY } = e;
        const {
            top,
            left,
            height,
            width
        } = img.parentElement.getBoundingClientRect();
        if (
            clientY < top ||
            clientY > top + height ||
            clientX < left ||
            clientX > left + width
        ) {
            return;
        } else {
            const zoomValue = this.getZoomValue(img);
            img.style.transform = `scale(${zoomValue})`;
        }
        const xPosition = ((clientX - left) / width) * 100;
        const yPosition = ((clientY - top) / height) * 100;
        const transformOriginValue = `${xPosition}% ${yPosition}% 0`;
        img.style.transformOrigin = transformOriginValue;
    };

    handleMouseOut = e => {
        const img = e.target;
        img.style.transform = 'scale(1)';
    };

    render() {
        const { images, dispatch, t } = this.props;
        const { selected } = this.state;
        const fImages = images.filter(f => f !== null) || [];
        fImages.forEach(image=>{
            image.local_url = image.local_url || image.original_url;
        });

        return (
            <Fragment>
                <div
                    className={classNames(
                        'c-product__current-img-holder',
                        {
                            'c-product__current-img-holder-zoomable':
                                this.state.isImgZoomed === false
                        },
                        {
                            'c-product__current-img-holder-zoomed':
                                this.state.isImgZoomed === true
                        }
                    )}
                >
                    <MediaView
                        src={
                            fImages &&
                            fImages[selected] &&
                            fImages[selected].local_url
                        }
                        onMouseMove={this.handleMouseMove}
                        onMouseOut={this.handleMouseOut}
                        onClick={this.handleClick}
                        className="c-product__current-img-modal-carousel"
                        fallbackSrc={fallbackImageSmall}
                        useImgTag
                    />
                </div>

                <div className="c-product__source-info">
                    { fImages[selected] && fImages[selected].original_created_at && (
                        <div>
                            <span className="u-bold marg-r-5 u-color-grey2">
                                {t('static.modal_carousel.uploaded')}
                            </span>

                            <span className="u-color-grey2">
                                {moment(
                                    fImages[selected].original_created_at
                                ).format('YYYY-MM-DD')}
                            </span>
                        </div>
                    )}

                    { fImages[selected] && fImages[selected].original_url &&
                        fImages[selected].original_source &&
                        fImages[selected].original_source === 'ingest' && (
                        <div>
                            <span className="u-bold marg-r-5 u-color-grey2">
                                {t('static.modal_carousel.source')}
                            </span>

                                <span className="u-color-grey2">
                                    {fImages[selected] && fImages[selected].original_url.substring(
                                        0,
                                        50
                                    )}
                                </span>

                            { fImages[selected] && size(fImages[selected].original_url) >= 50 && (
                                <a href={fImages[selected].original_url}
                                   target="_blank"
                                   className="c-product__detail-listing-item-clickable-text">
                                    ...
                                </a>
                            )}
                        </div>
                    )}
                </div>

                <div className="c-carousel c-carousel--scroll-x">
                    {fImages && fImages.map((e, i) => {
                        const srcThumb = e && (e.thumb_url_200 || e.local_url);
                        const cx = classnames('c-carousel__item', {
                            'c-carousel__item--active': i === selected
                        });

                        return (
                            <div
                                key={`${e}-${i}`}
                                className={cx}
                                onClick={() => {
                                    this.setState({ selected: i });
                                    this.turnZoomOff();
                                }}
                            >
                                <MediaView
                                    src={srcThumb}
                                    className="c-product__dived-img"
                                    useImgTag
                                    fallbackSrc={fallbackImageSmall}
                                />
                            </div>
                        );
                    })}
                </div>

                <div className="u-text-center">
                    <Button
                        type="secondary"
                        size="small"
                        onClick={() => dispatch(hideModal())}>
                        {t('buttons.close')}
                    </Button>
                </div>
            </Fragment>
        );
    }
}

export default withTranslation()(ModalCarousel)