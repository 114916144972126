import React, { Fragment, Component } from 'react';
import {
    maxItemsInList
} from 'src/js/constants/advancedFilteringConstants';
import get from 'lodash/get';
import includes from 'lodash/includes';
import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import each from 'lodash/each';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import toLower from 'lodash/toLower';
import { filterByObj, getCorrectValue } from './AdvancedFilteringHelpers';
import { Icon } from 'src/js/components/static';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import { withTranslation } from 'react-i18next';

class AdvancedFilteringChildren extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: []
        };
    }

    handleSearch(e, name, specialValues) {
        const { filterGroup } = this.props;

        this.state.inputValue[name] = e.target.value;
        this.forceUpdate();
        filterGroup(e, name, specialValues);
    }

    formatAggregations(aggregations) {
        each(aggregations, (aggregation, key) => {
            if(key === 'Actions') {
                aggregations[key] = {
                    buckets: [{
                        key: 0,
                        doc_count: aggregation.doc_count
                    }],
                    meta: aggregation.meta
                }
            }
        })

        return aggregations;
    }

    render() {
        const {
            aggregations,
            originalAggregations,
            el,
            filterTerms,
            queryObject,
            expandedList,
            inputValues,
            toggleExpandedList,
            categoryLevels,
            licensor,
            t
        } = this.props;
        const { key, takeHeader, specialValues, className } = el;
        let name = key;
        if (!aggregations || !aggregations[name]) return null;
        const numbers = originalAggregations[name] && originalAggregations[name].buckets && originalAggregations[name].buckets.length;
        const limited = numbers > maxItemsInList && !expandedList[name];
        const expanded = numbers > maxItemsInList && expandedList[name];
        const categoryIndex = findIndex(categoryLevels, cat => key === cat) || 0;

        let clonedAggregations = this.formatAggregations(cloneDeep(aggregations));
        let filteredNumbers;

        if (inputValues && inputValues[name]) {
            clonedAggregations[name].buckets = filter(
                aggregations[name] && aggregations[name].buckets,
                (item) =>
                    includes(item.key.toLowerCase(), inputValues[name]) ||
                    (specialValues &&
                        specialValues[item.key] &&
                        includes(
                            specialValues[item.key].toLowerCase(),
                            inputValues[name]
                        ))
            );
        }

        filteredNumbers =
            clonedAggregations[name] && clonedAggregations[name].buckets && clonedAggregations[name].buckets.length;

        return (
            <Fragment key={`${name}-fragment`}>
                {(numbers > maxItemsInList || this.state.inputValue[name]) && (
                    <div
                        className="c-advanced-filtering__filter-bar"
                        key={`${name}-search`}
                    >
                        <div className="c-advanced-filtering__filter-input">
                            <input
                                type="text"
                                value={this.state.inputValue[name]}
                                placeholder={t('placeholders.type_here')}
                                onChange={(e) =>
                                    this.handleSearch(e, name, specialValues)
                                }
                            />
                            <div className="c-advanced-filtering__search">
                                <Icon fill="black" icon="search" size="14" />
                            </div>
                        </div>
                    </div>
                )}

                {clonedAggregations[name] && clonedAggregations[name].buckets && clonedAggregations[name].buckets.length === 0 && (
                    <div className="c-advanced-filtering__noItems">{`${t('filters.no_items_for')} ${name}`}</div>
                )}

                <ul
                    className="c-advanced-filtering__group-list"
                    key={`${name}-ul`}
                >
                    {clonedAggregations[name] && clonedAggregations[name].buckets && orderBy(clonedAggregations[name].buckets, [item => !!item && item.key && toLower(item.key)], ['asc']).map(
                        ({ key, doc_count }, idx) => {
                            if (name === 'Validation' && !key) return null;
                            const mainKey = !!aggregations[name] && !!aggregations[name].meta && aggregations[name].meta.field;
                            const must = 'must';
                            const val = get(
                                queryObject,
                                `query.query.bool[${must}]`,
                                false
                            );

                            const checked = val
                                ? val.some((f) =>
                                      filterByObj(
                                          f,
                                          !!categoryLevels && !!includes(categoryLevels, mainKey)
                                              ? `${licensor ? 'licensor' : 'licensee'}_category_path.${categoryIndex}.keyword`
                                            : mainKey,
                                          key,
                                          true
                                        )
                                  )
                                : false;

                            return (
                                <Fragment
                                    key={`${key}-${doc_count}-aggregations`}
                                >
                                    {(idx < maxItemsInList ||
                                        expandedList[name]) && (
                                        <li className="c-advanced-filtering__group-item">
                                            <UiCheckbox
                                                type="checkbox"
                                                onClick={() =>
                                                    filterTerms(
                                                        mainKey,
                                                        key,
                                                        checked
                                                    )
                                                }
                                                id={`${key}-${doc_count}-aggregations`}
                                                name={`${key}-${doc_count}-aggregations`}
                                                checked={checked}>
                                            </UiCheckbox>
                                            <label
                                                className={`${
                                                    className ? className : ''
                                                    } ${camelCase(name)}_filter 
                                                    ${getCorrectValue(
                                                        name,
                                                        takeHeader,
                                                        key,
                                                        specialValues
                                                    ) === '(empty)' ? 'empty-title' : ''}` }
                                                htmlFor={key}
                                                onClick={() =>
                                                    filterTerms(
                                                        mainKey,
                                                        key,
                                                        checked
                                                    )
                                                }
                                            >
                                                &nbsp;
                                                {getCorrectValue(
                                                    name,
                                                    takeHeader,
                                                    key,
                                                    specialValues
                                                )}
                                                <span
                                                    className={`${
                                                        className
                                                            ? 'c-advanced-filtering__count'
                                                            : ''
                                                    }`}
                                                >
                                                    {' '}
                                                    {className ? '' : '('}
                                                    {doc_count}
                                                    {className ? '' : ')'}
                                                </span>
                                            </label>
                                        </li>
                                    )}
                                </Fragment>
                            );
                        }
                    )}

                    {(limited || expanded) && filteredNumbers > maxItemsInList && (
                        <div
                            key={`${name}-see-all`}
                            className="c-advanced-filtering__seeAll u_pointer"
                            onClick={() => toggleExpandedList(name)}
                        >
                            {limited ? t('buttons.see_all') : t('buttons.see_less')}
                        </div>
                    )}
                </ul>
            </Fragment>
        );
    }
}

export default withTranslation()(AdvancedFilteringChildren)