import React, { Component } from 'react';
import { connect } from 'react-redux';
// import linkedIcon from 'statics/imgs/linked-icon.svg';
import {
    getMainImage
} from 'helpers/dataHelpers';
import map from 'lodash/map';
import classNames from 'classnames';
import { Icon } from 'components/static';
import { isString, join, isArray } from 'lodash';
import conceptPlaceholder from 'statics/imgs/no-photo.svg';
import angleLeftIcon from 'statics/imgs/icons/angle_left_icon.svg';
import angleRightIcon from 'statics/imgs/icons/angle_right_icon.svg';
import { truncate } from 'lodash/string';
import ZoomableImageItem from 'components/BulkLinkConcepts/ZoomableImage';
import ImageWrapped from 'components/BulkLinkConcepts/ImageWrapped';
import {
    DynamicIps
} from 'src/js/components/ProductListElements';

import {
    fetchIps
} from 'src/js/apicalls/other/ips';
import { withTranslation } from 'react-i18next';

class BulkLinkConceptsProductPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedConcepts: props.selectedConcepts,
            ips: []
        };
    }

    componentDidMount() {
        this.fetchIpsFunction();
    }

    arrayPaginate(array, pageSize, page) {
        return array.filter((item, index) => {
            return (index < pageSize * page) &&
                (index > (pageSize * (page - 1) - 1));
        });
    }

    singleProductCOnceptCodes(list) {
        return map(list, item => item.concept_code);
    }

    getFilteredConcept(concepts, codes) {
        return concepts.filter(concept => codes.includes(concept.xelacore_concept_id));
    }

    getCurrentImageIndex(list, current) {
        current = current || list[0];
        return list.indexOf(current);
    }

    fetchIpsFunction() {
        const { xelacore, currentProductItemPreview } = this.props;
        const {
            auth: {
                token,
                companyData: { organisation_id }
            }
        } = xelacore;

        fetchIps(organisation_id, token, currentProductItemPreview.licensor_organisation_id).then((response) => {
            this.setState({
                ips: response.data && response.data.ips
            });
        });
    }

    render() {
        const {
            currentProductItemPreview,
            data,
            currentImageProductItemPreview,
            currentProductItemGroupPreview,
            selectedProductGroupIndex,
            productPreview,
            removeProductFromList,
            getCurrentImageIndex,
            goToImageArrow,
            selectProductImage,
            filterProductList,
            singleProductData,
            groupedHeaders,
            ipLevels,
            xelacore,
            dispatch,
            t
        } = this.props;

        const { ips } = this.state;

        return (
            <div
                className={classNames(
                    'sidebar-body sidebar-body-product preview-product-wrapper',
                    `${!!singleProductData ? 'single-sidebar-product-wrapper' : ''}`
                )}
            >
                <div
                    className={classNames(
                        'current-product-preview-wrapper',
                        `${!!singleProductData ? 'single-current-product-preview-wrapper' : ''}`
                    )}
                >
                    <div className={'gallery-linked-concepts-wrapper'}>
                        <div className={'current-product-preview-gallery'}>
                            <div className={'current-product-preview-main-image'}>
                                {!currentImageProductItemPreview && (
                                    <ZoomableImageItem
                                        imageUrl={getMainImage(currentProductItemPreview.images, 800, conceptPlaceholder)}
                                        placeholderImage={conceptPlaceholder}
                                        imageName={currentProductItemPreview.product_name}
                                    ></ZoomableImageItem>
                                )}
                                {currentImageProductItemPreview && (
                                    <ZoomableImageItem
                                        imageUrl={currentImageProductItemPreview.thumb_url_800}
                                        imageName={currentProductItemPreview.product_name}
                                    ></ZoomableImageItem>
                                )}


                                {
                                    (<div className={'image-gallery-control'}>
                                        {getCurrentImageIndex(currentProductItemPreview.images, currentImageProductItemPreview) > 0 && (

                                            <div
                                                className={'icon-holder  arrow-left'}
                                                onClick={() => {
                                                    goToImageArrow('prev', currentProductItemPreview.images, currentImageProductItemPreview);
                                                }}
                                            >
                                                <img
                                                    src={angleLeftIcon}
                                                    alt={'prev'}
                                                />
                                            </div>
                                        )}
                                        {getCurrentImageIndex(currentProductItemPreview.images, currentImageProductItemPreview) < currentProductItemPreview.images.length - 1 && (
                                            <div
                                                className={'icon-holder  arrow-right'}
                                                onClick={() => {
                                                    goToImageArrow('next', currentProductItemPreview.images, currentImageProductItemPreview);
                                                }}
                                            >
                                                <img
                                                    src={angleRightIcon}
                                                    alt={'next'}
                                                />
                                            </div>)}
                                    </div>)
                                }
                            </div>
                            <div className={'current-product-preview-image-list'}>
                                {
                                    currentProductItemPreview.images.map((productImageItem, index) => (
                                        <div
                                            onClick={() => {
                                                selectProductImage(productImageItem);
                                            }}
                                            className={classNames(
                                                'image-thumb-item',
                                                `${((currentImageProductItemPreview === productImageItem) ||
                                                    (!currentImageProductItemPreview && index === 0)) ?
                                                    'active' : ''}`)}
                                        >

                                            <ImageWrapped
                                                imageUrl={productImageItem.thumb_url_200}
                                                imageName={''}
                                            ></ImageWrapped>
                                        </div>
                                    ))
                                }


                            </div>


                            {/*{singleProductData && (*/}
                            {/*    <div*/}
                            {/*        className={classNames(*/}
                            {/*            'product-linked-concepts',*/}
                            {/*            `${currentProductItemPreview.concept_code_confirmed_info.length < 1 ? 'empty-linked-concept-list' : ''}`*/}
                            {/*        )}*/}
                            {/*    >*/}
                            {/*        <div className={'concept-list-header'}>*/}
                            {/*            <h4>Linked Concepts</h4>*/}
                            {/*        </div>*/}
                            {/*        <div className={'concept-list-body'}>*/}

                            {/*            {currentProductItemPreview.concept_code_confirmed_info.length > 0 &&*/}
                            {/*            this.getFilteredConcept(filteredConceptItems, this.singleProductCOnceptCodes(currentProductItemPreview.concept_code_confirmed_info)).map(conceptItem => {*/}
                            {/*                return (*/}
                            {/*                    <div*/}
                            {/*                        className={'concept-image-wrapper'}*/}
                            {/*                        onClick={() => {*/}
                            {/*                            openConceptDetails(conceptItem);*/}
                            {/*                        }}*/}
                            {/*                    >*/}
                            {/*                        <img*/}

                            {/*                            src={getMainImage(conceptItem.images, 200, conceptPlaceholder)}*/}
                            {/*                            alt={conceptItem.product_name}*/}
                            {/*                        />*/}
                            {/*                    </div>*/}
                            {/*                );*/}
                            {/*            })}*/}

                            {/*            {currentProductItemPreview.concept_code_confirmed_info.length < 1 && (*/}
                            {/*                <div className={'no-linked-concepts-placeholder'}>*/}
                            {/*                    <img src={linkedIcon} alt="no concepts" />*/}
                            {/*                    <span>No Concepts Linked to this product</span>*/}
                            {/*                </div>*/}
                            {/*            )}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>

                    </div>
                    <div className={'current-product-preview-details'}>
                        <h4>{t('concept_product_item_preview.product_details')}</h4>

                        <div className={'current-product-preview-name'}>
                            <h3>{currentProductItemPreview.product_name}</h3>
                        </div>
                        <div className={'current-product-preview-info'}>
                            <p>{currentProductItemPreview.product_description}</p>
                            <div className={'current-product-preview-info-table'}>
                                {groupedHeaders.grouped && Object.keys(groupedHeaders.grouped).map(groupKey => {
                                    if (['primary_attributes', 'licensed_properties', 'status'].includes(groupKey)) {
                                        return (<div className={'product-info-table-group'}>
                                            <div className={'product-info-table-group-head'}>
                                                <h4>{t(`headers_config.${groupKey}`)}</h4>
                                            </div>
                                            <div className={'product-info-table-group-body'}>
                                                {Object.keys(groupedHeaders.grouped[groupKey]).map(groupChildItemKey => {
                                                    if (['images', 'product_name', 'product_description','mpn_combined'].includes(groupChildItemKey)) {
                                                        return;
                                                    }

                                                    return (
                                                        <div className={'child-field-item'}>
                                                            <b className={'child-label u-capitalize'}>
                                                                {t(`table_headers.${groupChildItemKey}`)}:
                                                            </b>

                                                            {groupChildItemKey === 'ip_paths' && (
                                                                <DynamicIps
                                                                    validations={[]}
                                                                    xelacore={xelacore}
                                                                    dispatch={dispatch}
                                                                    value={currentProductItemPreview[groupChildItemKey]}
                                                                    data={currentProductItemPreview}
                                                                    ips={ips}
                                                                    ipLevels={ipLevels}
                                                                />
                                                            )}

                                                            {isArray(currentProductItemPreview[groupChildItemKey]) && (
                                                                <span className={'child-data'}>
                                                                    {join(currentProductItemPreview[groupChildItemKey], ', ')}
                                                                </span>
                                                            )}

                                                            {isString(currentProductItemPreview[groupChildItemKey]) && (
                                                                <span className={'child-data'}>
                                                                    {currentProductItemPreview[groupChildItemKey]}
                                                                </span>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                        </div>);
                                    }
                                })}
                            </div>
                        </div>


                    </div>
                </div>
                {(!!currentProductItemGroupPreview && data[selectedProductGroupIndex].list.length > 1) && (
                    <div className={'current-product-siblings-list'}>

                        <div className={classNames('product-group-wrapper')}>
                            <div className={'group-header'}>
                                <h3>
                                    <span>{t('concept_product_item_preview.products_in_group.products_in')}</span>
                                    <b>{currentProductItemGroupPreview.name} </b>
                                    <span>{t('concept_product_item_preview.products_in_group.group')}</span>
                                    <span> ({data[selectedProductGroupIndex].list.length})</span>
                                </h3>
                            </div>
                            <div className={'group-body'}>
                                {filterProductList() && (
                                    <div className={'product-list'}>
                                        {filterProductList().map(productItem => (

                                            <div className="product-tail-item">
                                                <div
                                                    className="product-image"
                                                >
                                                    <div className={'product-image-bg'}>
                                                        <ImageWrapped
                                                            imageUrl={getMainImage(productItem.images, 200, conceptPlaceholder)}
                                                            imageName={productItem.product_name}
                                                        ></ImageWrapped>
                                                    </div>

                                                    <ImageWrapped
                                                        imageUrl={getMainImage(productItem.images, 200, conceptPlaceholder)}
                                                        imageName={productItem.product_name}
                                                    ></ImageWrapped>

                                                </div>
                                                <div className="product-title">
                                                    <h4
                                                        onClick={() => {
                                                            productPreview(productItem, currentProductItemGroupPreview);
                                                        }
                                                        }

                                                    >
                                                        {
                                                            truncate(productItem.product_name, {
                                                                'length': 40,
                                                                'separator': ' '
                                                            })
                                                        }
                                                    </h4>
                                                </div>

                                                <div className="product-action">
                                                    {currentProductItemGroupPreview.list.length > 1 && (
                                                        <button
                                                            onClick={() => {
                                                                removeProductFromList(data, currentProductItemGroupPreview, productItem);
                                                            }}
                                                        >
                                                            <Icon
                                                                fill={'#999'} icon="close" size="14"
                                                            />
                                                            <span>{t('concept_product_item_preview.remove_from_selection')}</span>
                                                        </button>)
                                                    }
                                                </div>

                                            </div>
                                        ))}


                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                )
                }
            </div>
        );
    }
}

export default connect()(withTranslation()(BulkLinkConceptsProductPreview));
