import React, { Component } from 'react';
import moment from 'moment';
import Button from 'components/UiKit/Button';
import { hideModal } from 'src/js/actions/xelacore';
import { withTranslation } from 'react-i18next';

class ProductCodes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numberOfCodes: null,
            codesConfirmed: '',
            fromDate: null,
            toDate: null,
            errorMsg: null
        };
    }

    componentWillMount() {
        const { numberOfCodes } = this.props;
        this.setState({ numberOfCodes });
    }

    handleChange(e, key) {
        this.setState({ [key]: e.target.value });
    }

    checkDates() {
        const { t } = this.props;
        const { fromDate, toDate } = this.state;
        const today = moment().format('YYYY-MM-DD');

        this.setState({ errorMsg: null });

        if ((fromDate && !toDate) || (!fromDate && toDate)) {
            this.setState({
                errorMsg: t('modules.product_detail.codes_modal.enter_valid_date')
            });
            return false;
        }

        if (
            moment(fromDate).isBefore(today) ||
            moment(toDate).isBefore(today)
        ) {
            this.setState({
                errorMsg:
                    t('modules.product_detail.codes_modal.ensure_dates_future')
            });
            return false;
        }

        return true;
    }

    render() {
        const { codesConfirmed, numberOfCodes, errorMsg } = this.state;
        const { dispatch, data, t } = this.props;
        const { images, gtin, product_name } = data;
        const formattedImages = images && images.find(el => el && el.tag && el.tag === 'primary');
        const primary_image_url = formattedImages && (formattedImages.local_url || formattedImages.original_url);

        const validNumber = Number(numberOfCodes) && Number(numberOfCodes) > 0;

        const CODES_CSV_LINK = `https://s3.eu-west-1.amazonaws.com/redemption-demo.xelacore.io/redemption-demo_${numberOfCodes}.csv`;

        return (
            <div className="u-text-left">
                <p className="u-color-black u-fat-bottom">
                    <b>{t('modules.product_detail.codes_modal.genetaring_one_use')}</b>
                </p>

                <div className="u-flex u-flex-1">
                    <div style={{ width: '30%' }}>
                        <div className="c-product__code-modal-image-holder">
                            <img
                                src={primary_image_url}
                                className="c-product__code-modal-image"
                            />
                        </div>
                    </div>

                    <div className="u-margin-left">
                        <h2 className="u-color-green u-margin-bottom">
                            {product_name}
                        </h2>
                        <ul className="o-list">
                            <li className="u-margin-bottom">
                                <b>{t('modules.product_detail.codes_modal.gtin')}</b> {gtin}
                            </li>
                            <li className="u-margin-bottom">
                                <b>{t('modules.product_detail.codes_modal.serialised_codes')}</b>
                                <input
                                    className="c-product__entry-box"
                                    value={numberOfCodes}
                                    onChange={e =>
                                        this.handleChange(e, 'numberOfCodes')
                                    }
                                />
                                {!validNumber && (
                                    <small className="u-color-red">
                                        {t('modules.product_detail.codes_modal.valid_number')}
                                    </small>
                                )}
                            </li>
                            <li className="u-margin-bottom">
                                <b>{t('modules.product_detail.codes_modal.reach_campaign')}</b> {t('modules.product_detail.codes_modal.nobodys_child_summer')}
                            </li>
                            <li className="u-margin-bottom">
                                <div className="u-small-bottom">
                                    <b>{t('modules.product_detail.codes_modal.activation_date')}</b>
                                    <small>
                                        <i>{t('modules.product_detail.codes_modal.object_field')}</i>
                                    </small>
                                </div>
                                <div>
                                    <b>{t('modules.product_detail.codes_modal.from')}</b>
                                    <input
                                        type="date"
                                        className="c-product__entry-box"
                                        onChange={e =>
                                            this.handleChange(e, 'fromDate')
                                        }
                                        min={moment().format('YYYY-MM-DD')}
                                    />
                                    <b>{t('modules.product_detail.codes_modal.to')}</b>
                                    <input
                                        type="date"
                                        className="c-product__entry-box"
                                        onChange={e =>
                                            this.handleChange(e, 'toDate')
                                        }
                                        min={moment().format('YYYY-MM-DD')}
                                    />
                                </div>
                                {errorMsg && (
                                    <p
                                        className="u-color-red"
                                        style={{ fontWeight: 500 }}
                                    >
                                        {errorMsg}
                                    </p>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="u-text-center">
                    <div className="u-margin-bottom">
                        <p>{t('modules.product_detail.codes_modal.type_confirm')}</p>
                        <input
                            className="c-product__entry-box c-product__entry-box--large"
                            type="text"
                            onChange={e =>
                                this.handleChange(e, 'codesConfirmed')
                            }
                        />
                    </div>

                    <div className="u-flex-align--center">
                        <Button to={CODES_CSV_LINK}
                            className="u-margin-half-right"
                            download
                            target
                            disabled={codesConfirmed !== 'Confirm' || !validNumber}
                            type="action">
                            {t('buttons.generate')}
                        </Button>

                        <Button onClick={() => dispatch(hideModal())}>
                            {t('buttons.cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ProductCodes)