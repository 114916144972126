import React from 'react';
import { useTranslation } from 'react-i18next';

const EmptyTablePlaceholder = () => {
    const { t } = useTranslation();
    return (
        <div className="empty-state-placeholder">
            <div className="empty-state-content">
                <h2 className="empty-state-subtitle">{t('toolkit.trademark_authorization.table.placeholder_h2')}</h2>
                <h1 className="empty-state-title">
                    {t('toolkit.trademark_authorization.table.placeholder_h1')}
                </h1>
                <p className="empty-state-description">
                    {t('toolkit.trademark_authorization.table.placeholder_p')}
                </p>
            </div>
        </div>
    );
};

export default EmptyTablePlaceholder;
