import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FloatingMenu } from 'src/js/components/static';
import { displayNotification } from 'src/js/actions/xelacore';
import { setUserState } from 'src/js/actions/userDetails';
import { helperSetUserStateForSaving } from 'src/js/actions/userSettings';
import Button from 'modules/UiKit/components/Button/Button';
import { withTranslation } from 'react-i18next';

class CustomizedButtomBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createTableViewSetting: false,
            settingName: ''
        };
    }

    setSettingName(e) {
        const { target: { value } = {} } = e;
        this.setState({ settingName: e ? value : '' });
    }

    checkForDefault(arr) {
        const { t } = this.props;
        const check = arr.filter(
            item => item.name === t('buttons.default_headers') && item.isDefault
        );

        return !!(Array.isArray(check) && check.length);
    }

    makeWarning(title) {
        const { dispatchDisplayNotification } = this.props;
        this.setState({ settingName: '', createTableViewSetting: false });
        dispatchDisplayNotification(title);
    }

    checkForAlreadyExisted(arr, settingName) {
        const { t } = this.props;

        if (!settingName || settingName === '') {
            this.makeWarning(
                t('table_v3.customise_bottom_block.configuration_not_empty')
            );
            return false;
        }
        const check = arr.filter(item => item.name === settingName);
        if (Array.isArray(check) && check.length) {
            this.makeWarning(
                `${t('table_v3.customise_bottom_block.this_name')} "${settingName}"  ${t('table_v3.customise_bottom_block.already_used')}`
            );
            return false;
        }
        return true;
    }

    makeAllPreviousDefaultsToFalse() {
        const { headerLayouts = [] } = this.props;
        return headerLayouts.map(item => (item.byDefault = false));
    }

    createHeaderSetting() {
        const {
            dispatchHeaderSetting,
            userSettings,
            headers: { list } = {},
            updateActiveHeaders,
            dontShowRedirectModal,
            t
        } = this.props;
        const { settingName } = this.state;
        const { headerLayouts = [] } = userSettings;
        if (!this.checkForAlreadyExisted(headerLayouts, settingName)) return;
        const newSetting = { name: settingName, list, byDefault: true };
        this.makeAllPreviousDefaultsToFalse();
        headerLayouts.unshift(newSetting);
        if (!this.checkForDefault(headerLayouts)) {
            headerLayouts.push({
                name: t('buttons.default_headers'),
                isDefault: true,
                byDefault: false
            });
        }

        dispatchHeaderSetting(headerLayouts, dontShowRedirectModal);
        newSetting.isFromList = true;
        this.setState({ settingName: '', createTableViewSetting: false });
        updateActiveHeaders(newSetting, false);
    }

    updateHeaderSetting() {
        const {
            dispatchHeaderSetting,
            userSettings,
            updateActiveHeaders,
            activeHeaders,
            headers: { list, isFromList } = {},
            dontShowRedirectModal
        } = this.props;
        const { headerLayouts = [] } = userSettings;
        const idx =
            activeHeaders &&
            headerLayouts.findIndex(item => item.name === activeHeaders);
        const newSetting = { name: activeHeaders, list, byDefault: true };

        this.makeAllPreviousDefaultsToFalse();
        headerLayouts[idx] = newSetting;

        dispatchHeaderSetting(headerLayouts, dontShowRedirectModal);
        newSetting.isFromList = isFromList;
        this.setState({ createTableViewSetting: false });
        updateActiveHeaders(newSetting, false);
    }

    deleteHeaderSetting() {
        const {
            dispatchHeaderSetting,
            userSettings,
            activeHeaders,
            updateActiveHeaders,
            dontShowRedirectModal
        } = this.props;
        const { headerLayouts = [] } = userSettings;
        const idx = headerLayouts.findIndex(
            item => item.name === activeHeaders
        );
        headerLayouts.splice(idx, 1);

        dispatchHeaderSetting(headerLayouts, dontShowRedirectModal);
        this.setState({ settingName: '', createTableViewSetting: false });
        return updateActiveHeaders({}, true);
    }

    showTableSettings() {
        this.setState({ createTableViewSetting: true });
    }

    render() {
        const { toggleHeaderOptions, headers, t } = this.props;
        const { isHeadersChanged, isDefaultHeader, isFromList } = headers;
        const { createTableViewSetting, settingName } = this.state;

        const isCreatableFromList =
            isFromList && isHeadersChanged && !createTableViewSetting;
        const isEditable =
            (!isFromList || !isHeadersChanged) && !createTableViewSetting;
        const isNewNameInput = isHeadersChanged && createTableViewSetting;
        return (
            <div className="c-rec-panel__footer-content-outer">
                {(isHeadersChanged || !isDefaultHeader) && (
                    <div className="c-rec-panel__footer-content-div">
                        {isCreatableFromList && (
                            <FloatingMenu
                                inline
                                customButton={
                                    <Button
                                        type="secondary-2"
                                        size="small"
                                        iconRight={
                                            <span
                                                style={{
                                                    fontSize: '30px',
                                                    marginTop: '-28px'
                                                }}
                                            >
                                                ˬ
                                            </span>
                                        }
                                    >
                                        {t('customised_table_header.save_custom_configuration')}
                                    </Button>
                                }
                                className="c-rec-panel__hovered-state"
                            >
                                <li onClick={() => this.updateHeaderSetting()}>
                                    {t('customised_table_header.update_current_configuration')}
                                </li>
                                <li onClick={() => this.showTableSettings()}>
                                    {t('customised_table_header.create_custom_configuration')}
                                </li>
                            </FloatingMenu>
                        )}
                        {isEditable && (
                            <Button
                                type="secondary-2"
                                size="small"
                                onClick={() =>
                                    isDefaultHeader
                                        ? this.showTableSettings()
                                        : this.updateHeaderSetting()
                                }
                                disabled={!isHeadersChanged}
                            >
                                {t('customised_table_header.save_custom_configuration')}
                            </Button>
                        )}
                        {isNewNameInput && (
                            <div className="c-rec-panel__name-input">
                                <input
                                    maxLength="50"
                                    type="text"
                                    name="name"
                                    placeholder="Custom view name"
                                    value={settingName}
                                    onChange={e => this.setSettingName(e)}
                                />
                                <Button
                                    type="secondary-2"
                                    size="small"
                                    className="c-rec-panel__name-submit"
                                    onClick={() => this.createHeaderSetting()}
                                >
                                    {t('buttons.save')}
                                </Button>
                            </div>
                        )}
                        {!isDefaultHeader && (
                            <Button
                                type="secondary-danger"
                                size="small"
                                className="u-margin-left"
                                onClick={() => this.deleteHeaderSetting()}
                            >
                                {t('customised_table_header.delete_custom_configuration')}
                            </Button>
                        )}
                        <Button
                            type="secondary-2"
                            size="small"
                            className="u-margin-left"
                            onClick={toggleHeaderOptions}
                        >
                            {t('buttons.close')}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        xelacore: {
            userSettings,
            userSettings: {
                lists = [],
                headers = {},
                activeHeaders = '',
                headerLayouts = [],
                dontShowRedirectModal = false
            } = {}
        } = {}
    } = state;

    return {
        userSettings,
        lists,
        headers,
        activeHeaders,
        headerLayouts,
        dontShowRedirectModal
    };
}

const mapDispatchToProps = dispatch => ({
    dispatchHeaderSetting(newArray, dontShowRedirectModal) {
        const my_records_custom_table_views = helperSetUserStateForSaving(
            newArray
        );
        dispatch(
            setUserState('settings', {
                state: {
                    settings: {
                        my_records_custom_table_views,
                        dontShowRedirectModal
                    }
                }
            })
        );
    },
    dispatchDisplayNotification(message) {
        dispatch(
            displayNotification({
                message: message,
                type: 'warning'
            })
        );
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(CustomizedButtomBlock));
