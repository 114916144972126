import React from 'react';
import { useTranslation } from 'react-i18next';
import ContractSection from './ContractSection';

const SECTION_TYPES = ['territories', 'ips', 'categories'];

const ContractDetailsItem = ({
                                 contract,
                                 getAllTags,
                                 formatDate,
                                 exceptionRawInputs,
                                 handleExceptionTagsChange,
                                 onToggleSection,
                                 getTagStatus,
                                 onToggleTag,
                                 sectionTypes = SECTION_TYPES
                             }) => {
    const { t } = useTranslation();

    const getSectionTitle = (sectionType) => {
        const titleMap = {
            'territories': 'toolkit.contract_details.contracted_territories',
            'ips': 'toolkit.contract_details.ips',
            'categories': 'toolkit.contract_details.categories'
        };

        return t(titleMap[sectionType] || `toolkit.contract_details.${sectionType}`);
    };

    return (
        <div className="contract-box" key={contract.agreement_id}>
            <div className="contract-section">
                <div className="section-title">
                    {t('toolkit.contract_details.contract_number')}
                </div>
                <div className="section-value">
                    {contract.agreement_id || t('toolkit.trademark_authorization.details.business_info.not_provided')}
                </div>
            </div>

            <div className="contract-section">
                <div className="section-title">
                    {t('toolkit.contract_details.contract_name')}
                </div>
                <div className="section-value">
                    {contract.agreement_name || t('toolkit.trademark_authorization.details.business_info.not_provided')}
                </div>
            </div>

            <div className="contract-section">
                <div className="section-title">
                    {t('toolkit.contract_details.contract_status')}
                </div>
                <div className="section-value">
                    {contract.contract_status || t('toolkit.trademark_authorization.details.business_info.not_provided')}
                </div>
            </div>

            <div className="contract-section">
                <div className="section-title">
                    {t('toolkit.contract_details.terms')}
                </div>
                <div className="section-value terms-row">
                    <div className="term-item">
                        <span className="term-label">{t('toolkit.contract_details.begin_date')}:</span>
                        <span className="term-value">{formatDate(contract.start_date)}</span>
                    </div>
                    <div className="term-item">
                        <span className="term-label">{t('toolkit.contract_details.end_date')}:</span>
                        <span className="term-value">{formatDate(contract.expiration_date)}</span>
                    </div>
                </div>
            </div>

            {sectionTypes.map(sectionType => {
                const tags = getAllTags(contract, sectionType);

                // if (!tags || tags.length === 0) return null;

                return (
                    <ContractSection
                        key={`${contract.agreement_id}-${sectionType}`}
                        contract={contract}
                        section={sectionType}
                        tags={tags}
                        title={getSectionTitle(sectionType)}
                        exceptionRawInputs={exceptionRawInputs}
                        handleExceptionTagsChange={handleExceptionTagsChange}
                        onToggleSection={onToggleSection}
                        getTagStatus={getTagStatus}
                        onToggleTag={onToggleTag}
                    />
                );
            })}
        </div>
    );
};

export default ContractDetailsItem;
