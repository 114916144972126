import React, { Component } from 'react';
import { truncate } from 'lodash/string';
import RetailerListingModal from './RetailerListingModal';
import conceptPlaceholder from 'statics/imgs/no-photo.svg';
import {
    fetchRetailerListings
} from 'src/js/apicalls/other/retailers';
import {
    Modal
} from 'src/js/components/static';
import { showModal } from 'src/js/actions/xelacore';
import { Pagination } from 'src/js/components/static';
class RetailerListings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            retailersList: [],
            page: 1,
            retailersPerPage: 10,
            totalCount: 0
        };
    }

    componentDidMount() {
        this.fetchListingData();
    }

    fetchListingData(currentPage) {
        const { page, retailersPerPage } = this.state;
        const { data } = this.props;

        let params = {
            page: currentPage || page,
            page_size: retailersPerPage
        }
    
        this.setState({
            page: currentPage || page
        })

        fetchRetailerListings(data.record_id, params).then(response => {
            this.setState({
                retailersList: !!response && !!response.data && response.data || []
                // totalCount: response._metadata && response._metadata.pagination.total_records || 0
            })
        })
    }

    openDetailsModal(retailer) {
        const { retailersList } = this.state;
        const { dispatch, t } = this.props;
        const modal = (
            <Modal
                title={t('modules.product_detail.retailer_listings.retailer_listing')}
                className="retailer-listing-modal-box"
                body={
                    <RetailerListingModal
                        retailersList={retailersList}
                        retailer={retailer}
                    />
                }
            />
        );

        return dispatch(showModal(modal));
    }

    render () {
        const { t } = this.props;
        const { retailersList, page, retailersPerPage, totalCount } = this.state;

        return (
            <div>
                {!!retailersList && retailersList.length > 0 && (
                    <div className="c-product__detail-listing c-product__general-block c-product__retailers">
                        <h3 className="c-product__header u-margin-bottom">
                            {t('modules.product_detail.retailer_listings.retailer_listing')}
                        </h3>

                        <table className="c-conflicts-popover-body-table c-product__retailers-table">
                            <thead className="c-conflicts-popover-body-table-header">
                                <tr>
                                    <th className="image-th"></th>
                                    <th
                                        key="name"
                                    >
                                        {t('modules.product_detail.retailer_listings.table_headers.product_name')}
                                    </th>
                                    <th
                                        key="domain"
                                    >
                                        {t('modules.product_detail.retailer_listings.table_headers.retailer_domain')}
                                    </th>
                                    <th
                                        key="id"
                                    >
                                        {t('modules.product_detail.retailer_listings.table_headers.listing_id')}
                                    </th>
                                </tr>
                            </thead>

                            { retailersList.length > 0 && (
                                <tbody className="c-conflicts-popover-body-table-body">
                                    { !!retailersList && retailersList.map(retailer => {
                                        return (
                                            <tr key={retailer.retailerListingId} className="" onClick={() => this.openDetailsModal(retailer)}>
                                                <td className="retailer-domain-image">
                                                    <img
                                                        src={retailer.retailerDetail.retailerLogoImage || conceptPlaceholder}
                                                        alt={retailer.title}
                                                    />
                                                </td>
                                                <td>
                                                    <div className="title">
                                                        { truncate(retailer.title,
                                                            {
                                                                'length': 50,
                                                                'separator': ' '
                                                            })
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    { retailer.retailerDetail.retailerDomain }
                                                </td>
                                                <td>
                                                    <div className="two-rows">
                                                        {/* <span className="row-title">
                                                            {retailer.retailerDetail.retailerCodeName}:
                                                        </span> */}
                                                        
                                                        <span>{retailer.listingId}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            )}

                            { retailersList.length === 0 && (
                                <tbody className="no-data">
                                    <tr>
                                        <td colSpan={4}>{t('modules.product_detail.retailer_listings.no_retailer_listing_data')}</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>

                        <Pagination
                            updateItemsPerPageHandler={() => this.fetchListingData()}
                            currentPage={parseInt(page)}
                            totalItems={parseInt(totalCount)}
                            itemsPerPage={parseInt(retailersPerPage)}
                            updatePageHandler={(page) => this.fetchListingData(page)}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default RetailerListings;
