import React from 'react';
import toLower from 'lodash/toLower';
import orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';

export default function Select({
    name,
    type,
    onChange,
    value,
    values,
    id,
    isMultipleValues
}) {
    const { t } = useTranslation();

    return (
        <div className="c-select__styled-select">
            <select
                name={name}
                type={type}
                id={id}
                onChange={onChange}
                value={toLower(value)}
            >
                <option value={''} disabled hidden>
                    {isMultipleValues
                        ? t('placeholders.multiple_values')
                        : t('placeholders.choose_an_option') }
                </option>

                {orderBy(values, [value => value.label.toLowerCase()], ['asc']).map(({ name, label, isHide }) => (
                    <option
                        key={`${Math.random()}-${id}-${value}`}
                        value={toLower(name)}
                        disabled={isHide}
                    >
                        {label}
                    </option>
                ))}
            </select>
        </div>
    );
}
