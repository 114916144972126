import React, { Fragment } from 'react';
import md5 from 'md5';
import classnames from 'classnames';
import cookies from 'js-cookie';
import { browserHistory } from 'react-router';
import { ORG_ID_COOKIE, XELACORE_DOMAIN } from 'src/js/constants/actions/xelacore';
import { Link, Triangle, FloatingMenu, Auth } from 'src/js/components/static';
import { AccountModuleLink } from 'constants/xelacoreModulesDefinitions';
import {
    logout
} from 'src/js/lib/auth';

import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

export default function HeaderUserInfo({
    organisations,
    user,
    companyName,
    companyData,
    xelacore
}) {
    const { t } = useTranslation();

    const {
        email,
        information = {},
        information: { profile_image } = {}
    } = user;
    const { information: { company_logo = null } = {} } = companyData;

    const hashedEmail = email ? md5(email) : null;
    const cx = classnames('u-rounded o-media-object__rounded-image-div', {
        'u-hide': !hashedEmail
    });
    const img =
        company_logo ||
        (profile_image
            ? profile_image
            : `//www.gravatar.com/avatar/${hashedEmail}?s=50&d=identicon`);

    const customButton = () => (
        <div className="u-flex-align">
            <div className="o-media-object__text u-margin-right">
                <p className="u-flex-align">
                    <span>
                        <strong>
                            {information.first_name} {information.last_name}
                        </strong>
                        {companyName && (
                            <Fragment>
                                {/* <br /> */}
                                <small className="link-color">
                                    {companyName}
                                </small>
                            </Fragment>
                        )}
                    </span>
                    <Triangle />
                </p>
            </div>
            <div style={{ backgroundImage: `url(${img})` }} className={cx} />
        </div>
    );

    const selectOrg = () => {
        cookies.remove(ORG_ID_COOKIE, { domain: XELACORE_DOMAIN });
        const storageKeys = keys(localStorage);
        
        localStorage.removeItem('selectedLicensor');

        forEach(storageKeys, (key) => {
            if (includes(key, 'dashboard')) {
                localStorage.removeItem(key);
            }
        });

        browserHistory.push('/select-organisation');
    };

    const hasPosPermission = () => {
        const posManage = get(
            xelacore,
            'auth.userPermissions.features.licensor_pos_export.can',
            []
        );

        return (posManage || []).indexOf('view') !== -1;
    }

    return (
        <div className="o-media-object account-menu">
            <FloatingMenu right customButton={customButton()} top={50}>
                <li className="">
                    <a href={AccountModuleLink} target="_blank" className="u-block">
                        {t('header_user_info.my_account')}
                    </a>
                </li>

                {hasPosPermission() && (
                    <Auth restrictTo="licensor">
                        <li className="">
                            <a href="/pos-export" className="u-block">
                                {t('header_user_info.my_exports')}
                            </a>
                        </li>
                    </Auth>
                )}

                {organisations.length > 1 && (
                    <li className="">
                        <Link
                            className="u-block"
                            onClick={() => selectOrg()}
                        >
                            {t('header_user_info.change_account')}
                        </Link>
                    </li>
                )}

                <li className="">
                    <Link onClick={() => logout(true)} className="u-block">
                        {t('header_user_info.sign_out')}
                    </Link>
                </li>
            </FloatingMenu>
        </div>
    );
}
