import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
    FloatingMenu,
    Modal,
    Auth,
    Tooltip
} from 'src/js/components/static';
import get from 'lodash/get';
import size from 'lodash/size';
import includes from 'lodash/includes';
import { showModal, hideModal } from 'src/js/actions/xelacore';
import CustomizedTableHeader from 'src/js/components/CustomizedTableHeader/CustomizedTableHeader';
import { changeHeaders } from 'src/js/actions/userSettings';
import { registerProductsModal } from 'src/js/actions/registerModal';
import BulkEdit from 'src/js/components/BulkEdit/BulkEdit';
import BulkDelete from 'src/js/components/BulkDelete/BulkDelete';
import { getFilteredCatalogueInformation } from 'src/js/actions/productCatalogue';
import errorHandler from 'src/js/actions/errorHandler';
import { headers as defaultHeaders } from 'src/js/constants/productConstants/headers';
import { selectAll } from 'src/js/actions/productsFetch';
import { conflictsCount } from 'src/js/actions/conflicts';
import {
    readyToRegister,
    filterQuarantineRows
} from 'src/js/helpers/dataHelpers';

import { isUpdated } from 'src/js/helpers/dataHelpers';

import { searchableFileds } from 'src/js/constants/advancedFilteringConstants';
import Button from 'modules/UiKit/components/Button/Button';

import { ReactComponent as FilterIcon } from 'components/static/Icon/svg-icons/filter-bars.svg';
import { ReactComponent as Tile } from 'components/static/Icon/svg-icons/tile.svg';
import { ReactComponent as List } from 'components/static/Icon/svg-icons/list.svg';
import { ReactComponent as ExportListIcon } from 'components/static/Icon/svg-icons/export-list-icon.svg';
import { ReactComponent as EditIcon } from 'components/static/Icon/svg-icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'components/static/Icon/svg-icons/delete.svg';
import { ReactComponent as CustomiseIcon } from 'components/static/Icon/svg-icons/customise-table.svg';

import TableRegistration from 'modules/MyRecords/routes/MyRecords/MyRecordsTable/TableRegistration';
import BulkLinkConcepts from 'components/BulkLinkConcepts/BulkLinkConcepts';
import DragAndDropProductExport from 'components/DragAndDropProductExport/DragAndDropProductExport';
import { isLicensee, isLicensor } from 'helpers/permissions';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import {
    posExport
} from 'src/js/apicalls/other/posExport';

import { displayNotification } from 'src/js/actions/xelacore';
import { withTranslation } from 'react-i18next';

class HeaderSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHeaderOptions: false,
            activeConflictsCount: 0,
            selectedPPTItems: [],
            minutesCount: 1,
            secondsRemaining: props.startingTime || 60,
            isCounting: false,
            selectedLicensor: !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {}
        };
        this.interval = null;
    }

    componentDidUpdate() {
        const messages = get(this, 'props.xelacore.socket.messages', []);
        if (isUpdated(messages, ['actions', 'elastic'], this.notifiedAt)) {
            this.notifiedAt = Math.max(...messages.map((m) => m.timestamp));
            setTimeout(() => {
                this.getConflictsCount();
            }, 1000);
        }
    }

    componentDidMount() {
        this.getConflictsCount();
        const presavedCatalogueRows = JSON.parse(localStorage.getItem('presavedCatalogueRows')) || [];
        this.setState({
            selectedPPTItems: presavedCatalogueRows
        });
        window.addEventListener('scroll', this.handleScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll() {
        const headerBoxElement = document.getElementById('headerBoxID');
        const headerBoxElementWrapper = document.getElementById('headerBoxID').parentElement;
        if (window.scrollY > 80) {
            headerBoxElement.classList.add('sticky');
            headerBoxElementWrapper.style.paddingTop='50px';
        } else  {
            headerBoxElement.classList.remove('sticky');
            headerBoxElementWrapper.style.paddingTop='0';
        }
    }

    getConflictsCount() {
        const { selectedLicensor } = this.state;
        const { dispatch } = this.props;

        return dispatch(conflictsCount(!!selectedLicensor && selectedLicensor.licensor_organisation_id)).then((resp) => {
            this.setState({
                activeConflictsCount: resp.data.active_conflict_count
            });
        });
    }

    configModal() {
        const { dispatch, t } = this.props;
        const { configName } = this.state;

        const ModalContent = (
            <div>
                <p>{t('product_components.header_section.custom_configuration')}</p>
                <div
                    className="c-form-element u-margin-center"
                    style={{ width: '65%' }}
                >
                    <input
                        type="text"
                        onChange={(e) =>
                            this.setState({ configName: e.target.value })
                        }
                        placeholder={t('product_components.header_section.config_name')}
                    />
                </div>
                <Button
                    type="secondary-danger"
                    size="small"
                    onClick={() => dispatch(hideModal())}>
                    {t('buttons.cancel')}
                </Button>

                <Button
                    type="secondary"
                    size="small"
                    onClick={() => false}
                    disabled={configName.length === 0}
                >
                    {t('buttons.confirm')}
                </Button>
            </div>
        );

        const modal = (
            <Modal title={t('product_components.header_section.confirm_config_name')} body={ModalContent} />
        );

        return dispatch(showModal(modal));
    }

    stopCounting = () => {
        clearInterval(this.interval);
        this.setState({ isCounting: false });
    };

    startCounting = () => {
        this.setState({ isCounting: true });

        this.interval = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.secondsRemaining > 0) {
                    return { secondsRemaining: prevState.secondsRemaining - 1 };
                } else {
                    clearInterval(this.interval);
                    return { secondsRemaining: 0, isCounting: false };
                }
            });
        }, 1000);
    };

    postPostExport() {
        const { dispatch, t } = this.props;

        posExport().then(() => {
            dispatch(displayNotification({
                timeOut: 5000,
                type: 'success',
                message: t('product_components.header_section.pos_export.pos_successfully_exported')
            }));

            this.startCounting();
            dispatch(hideModal());
        }, () => {
            dispatch(displayNotification({
                timeOut: 5000,
                type: 'error',
                message: t('product_components.header_section.pos_export.failed_to_export')
            }));

            dispatch(hideModal());
        })
    }

    posExport() {
        const { minutesCount, isCounting } = this.state;
        const { dispatch, t } = this.props;

        const ModalContent = (
            <div className="u-full-width">
                <div className="u-color-black u-margin-bottom">
                    {isCounting && (<div>{t('product_components.header_section.pos_export.already_generated.already_generated')} {minutesCount} {t('product_components.header_section.pos_export.already_generated.minute')}</div>)}
                    {!isCounting && (<div>{t('product_components.header_section.pos_export.exporting_the_pos')}</div>)}
                </div>

                {!isCounting && (
                    <div className="u-flex-gap u-flex-align--right">
                        <Button
                            type="secondary-danger"
                            size="small"
                            onClick={() => dispatch(hideModal())}>
                            {t('buttons.cancel')}
                        </Button>

                        <Button
                            type="secondary"
                            size="small"
                            onClick={() => this.postPostExport()}
                        >
                            {t('buttons.confirm')}
                        </Button>
                    </div>
                )}

                {isCounting && (
                    <div className="u-flex-gap u-flex-align--right">
                        <Button
                            type="secondary-danger"
                            size="small"
                            onClick={() => dispatch(hideModal())}>
                            {t('buttons.close')}
                        </Button>
                    </div>
                )}
            </div>
        );

        const modal = (
            <Modal title={t('product_components.header_section.pos_export.pos_export')} body={ModalContent} isSmall/>
        );

        return dispatch(showModal(modal));
    }

    setIncludeList(isAll) {
        const headers = this.props;
        const givenList = isAll ? defaultHeaders : headers;
        const func = isAll
            ? givenList
            : givenList.filter((item) => item.active);
        return func.map((item) => item.name);
    }

    fetchFilteredData(isAll) {
        const { dispatch, initialCount, filters, page, page_size } = this.props;
        const pagination = {
            page: page ? page : 1,
            page_size: isAll ? initialCount : page_size
        };
        const columns = this.setIncludeList(isAll);
        return dispatch(
            getFilteredCatalogueInformation(
                isAll ? {} : filters,
                'records',
                pagination,
                { columns },
                true
            )
        )
            .then(() => {
                return true;
            })
            .catch((error) => errorHandler(dispatch, error));
    }

    toggleHeaderOptions() {
        if (!this.state.showHeaderOptions) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
        this.setState({ showHeaderOptions: !this.state.showHeaderOptions });
    }

    bulkEditModal() {
        const { dispatch, selectedRows, fetchData, ipLevels, ips, t } = this.props;
        const modal = (
            <Modal
                title={t('product_components.header_section.bulk_editing')}
                className="c-bulk-update__modal"
                body={
                    <BulkEdit
                        rows={selectedRows}
                        fetchData={fetchData}
                        ipLevels={ipLevels}
                        ips={ips}
                        resetRows={() => dispatch(selectAll([], true, []))}
                    />
                }
            />
        );
        return dispatch(showModal(modal));
    }

    bulkLinkConcepts() {
        const { dispatch, fetchData, resetConceptsRows, selectedRows, companyData, ipLevels, xelacore, t } = this.props;
        const modal = (
            <Modal
                title={t('product_components.header_section.concept_linking')}
                className="c-bulk-update__modal bulk-link-concepts-modal"
                body={
                    <BulkLinkConcepts
                        licensor={isLicensor(companyData)}
                        rows={selectedRows}
                        fetchData={fetchData}
                        resetConceptsRows={resetConceptsRows}
                        xelacore={xelacore}
                        dispatch={dispatch}
                        ipLevels={ipLevels}
                        resetRows={() => dispatch(selectAll([], true, []))}
                    />
                }
            />
        );
        return dispatch(showModal(modal));
    }

    addToExportSelection() {
        const { selectedPPTItems } = this.state;
        const { selectedRows, dispatch } = this.props;
        let newSelection = selectedPPTItems.concat(selectedRows);
        let addedRowsList = uniqBy(newSelection, 'record_id');
        sessionStorage.setItem('presavedCatalogueRows', JSON.stringify(addedRowsList));
        this.setState({ selectedPPTItems: addedRowsList });
        return dispatch(selectAll([], false, []));
    }

    resetSelectedRows() {
        this.setState({ selectedPPTItems: [] });
    }

    openExportPPT() {
        const { dispatch, fetchData, companyData, t } = this.props;
        const { selectedPPTItems } = this.state;
        const modal = (
            <Modal
                title={t('product_components.header_section.export_to_ppt')}
                className="c-bulk-update__modal draggable-ppt-export-modal"
                dispatch={dispatch}
                body={
                    <DragAndDropProductExport
                        licensee={isLicensee(companyData)}
                        licensor={isLicensor(companyData)}
                        rows={selectedPPTItems}
                        fetchData={fetchData}
                        resetSelectedRows={() => {
                            this.resetSelectedRows();
                        }}
                    />
                }
            />
        );
        return dispatch(showModal(modal));
    }

    customButtonTooltip = () => {
        const { t } = this.props;

        return (
            <div className="c-rec-table__tooltip c-rec-table__tooltip--grey">
                <div>
                    {t('product_components.header_section.tooltips.contact_manager_to_upload_records')}
                </div>
            </div>
        );
    };

    linkConcepts = () => {
        const { t } = this.props;

        return (
            <div className="c-rec-table__tooltip c-rec-table__tooltip--grey">
                <div>
                    {t('product_components.header_section.tooltips.link_concepts_tooltip')}
                </div>
            </div>
        );
    };

    addToExportTooltip = () => {
        const { t } = this.props;

        return (
            <div className="c-rec-table__tooltip c-rec-table__tooltip--grey">
                <div>
                    {t('product_components.header_section.tooltips.add_to_export_tooltip')}
                </div>
            </div>
        );
    }

    tooltipPosText = () => {
        const { xelacore, t } = this.props;
        const {
            auth: {
                posExportConfig = []
            }
        } = xelacore

        return (
            <div className="c-rec-table__tooltip c-rec-table__tooltip--grey">
                <div>
                    {posExportConfig.length === 0 ? t('product_components.header_section.tooltips.pos_tooltip_contact_manager') : t('product_components.header_section.tooltips.pos_tooltip_download_data')}
                </div>
            </div>
        );
    }

    showFiltersToggle() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    bulkDeleteModal() {
        const { dispatch, selectedRows, fetchData, data } = this.props;
        const modalContent = (
            <BulkDelete
                dispatch={dispatch}
                selectedRows={filterQuarantineRows(data, selectedRows)}
                data={data}
                customFetch={() => fetchData()}
                resetRows={() => dispatch(selectAll([], true, []))}
            />
        );
        dispatch(showModal(modalContent));
    }

    hasPosPermission() {
        const posManage = get(
            this,
            'props.xelacore.auth.userPermissions.features.licensor_pos_export.can',
            []
        );

        return (posManage || []).indexOf('view') !== -1;
    }

    render() {
        const {
            headers,
            activeHeaders,
            headerLayouts,
            fetchData,
            selectedRows,
            setLayout,
            layoutType,
            dispatch,
            viewing,
            setViewing,
            data,
            quarantineCount = 0,
            isMyRecords,
            dontShowRedirectModal,
            registrableIds,
            basicFilterObj,
            noData,
            categoryLevels,
            validationsExist,
            toggleUploader,
            showUploader,
            showFiltersToggle,
            queryObject,
            updateFiltersState,
            ignoreValidations,
            xelacore,
            t
        } = this.props;

        const {
            auth: {
                posExportConfig = []
            }
        } = xelacore

        const {
            showHeaderOptions,
            activeConflictsCount,
            selectedLicensor,
            selectedPPTItems
        } = this.state;
        const showHeaderSettings =
            headerLayouts &&
            headerLayouts.length > 0 &&
            !(headerLayouts.length === 1 && headerLayouts[0].isDefault);

        const filtersCounter = Object.keys(basicFilterObj).filter(
            (key) => key !== 'is_quarantine' && !includes(searchableFileds, key)
        ).length;

        const isOnlyRegisteredSelected = !!(
            data &&
            selectedRows &&
            data.filter(
                (record) =>
                    map(selectedRows, 'record_id').includes(record.record_id) &&
                    record.registered_with
            ).length === selectedRows.length
        );
        const allIds = map(data, 'record_id');

        return (
            <Fragment>
                <ul className="c-rec-panel__tabs">
                    {!isMyRecords && !!quarantineCount && (
                        <li
                            className={`c-rec-panel__tab ${
                                viewing === 'quarantine'
                                    ? 'c-rec-panel__tab--active'
                                    : ''
                            }`}
                            onClick={() => setViewing('quarantine')}
                        >
                            <small style={{ color: '#ed3b3b' }}>
                               {t('buttons.quarantine')}
                            </small>
                            <div className="c-rec-panel__counter c-rec-panel__counter--red">
                                <span>
                                    {quarantineCount !== null
                                        ? quarantineCount
                                        : '-'}
                                </span>
                            </div>
                        </li>
                    )}
                </ul>

                <div className="c-rec-panel__body c-rec-panel__body--mod-bod"
                     id="headerBoxID">
                    {/*TODO refactor this ugly piece*/}
                    {viewing !== 'conflicts' && (
                        <div className="c-rec-panel__nav">
                            {layoutType === 'grid' && (
                                <div className="checkbox-holder u-margin-half-right">
                                    <Auth restrictTo="licensee">
                                        <UiCheckbox
                                            type="checkbox"
                                            label={size(selectedRows) === size(data) ? t('buttons.unselect_all') : t('buttons.select_all')}
                                            checked={size(selectedRows) === size(data)}
                                            onClick={() => {
                                                const filteredCurrent =
                                                    selectedRows.filter(
                                                        (item) =>
                                                            !allIds.includes(
                                                                item.record_id
                                                            )
                                                    );
                                                const selected =
                                                    isEqual(selectedRows.sort(), data.sort())
                                                        ? filteredCurrent
                                                        : [
                                                            ...filteredCurrent,
                                                            ...data
                                                        ];

                                                dispatch(
                                                    selectAll(
                                                        selected,
                                                        false,
                                                        []
                                                    )
                                                );
                                            }}>
                                        </UiCheckbox>
                                    </Auth>
                                    <Auth restrictTo="licensor">
                                        <UiCheckbox
                                            type="checkbox"
                                            label={size(selectedRows) === size(data) ? t('buttons.unselect_all') : t('buttons.select_all')}
                                            checked={size(selectedRows) === size(data)}
                                            onClick={() => {
                                                const filteredCurrent =
                                                    selectedRows.filter(
                                                        (item) =>
                                                            !allIds.includes(
                                                                item.record_id
                                                            )
                                                    );
                                                const selected =
                                                    isEqual(selectedRows.sort(), data.sort())
                                                        ? filteredCurrent
                                                        : [
                                                            ...filteredCurrent,
                                                            ...data
                                                        ];

                                                dispatch(
                                                    selectAll(
                                                        selected,
                                                        false,
                                                        []
                                                    )
                                                );
                                            }}>
                                        </UiCheckbox>
                                    </Auth>
                                </div>
                            )}

                            {!noData && (
                                <Button
                                    type="link-primary"
                                    size="small"
                                    onClick={() => showFiltersToggle()}
                                    iconLeft={
                                        <FilterIcon height="14"></FilterIcon>
                                    }
                                >
                                    <div className="c-rec-panel__nav__content">
                                        {t('buttons.filters')}
                                        {filtersCounter > 0 && (
                                            <div className="c-rec-panel__filters_counter">
                                                <span>{filtersCounter}</span>
                                            </div>
                                        )}
                                    </div>
                                </Button>
                            )}

                            <Auth restrictTo="licensee">
                                {!isMyRecords && (
                                    <Button
                                        iconRight={selectedRows.length === 0 ?
                                            <Tooltip
                                                align={{ offset: [5, 30] }}
                                                overlay={() => this.linkConcepts()}
                                            /> : undefined
                                        }
                                        type="secondary"
                                        size="small"
                                        onClick={() => this.bulkLinkConcepts()}
                                        disabled={selectedRows.length === 0}
                                    >
                                        {t('buttons.link_concepts')}
                                    </Button>
                                )}
                            </Auth>

                            {!isMyRecords && (
                                <div>
                                    <Button
                                        disabled={!((!isMyRecords && selectedRows.length > 0) &&
                                            ((selectedPPTItems.length + selectedRows.length) < 101))}
                                        type="link-primary"
                                        size="small"
                                        tooltip={!((!isMyRecords && selectedRows.length > 0) &&
                                            ((selectedPPTItems.length + selectedRows.length) < 101)) ? t('product_components.header_section.select_the_records') : null}
                                        onClick={() => this.addToExportSelection()}
                                        iconLeft={
                                            <ExportListIcon height="14"></ExportListIcon>
                                        }
                                        iconRight={<Tooltip
                                            align={{ offset: [5, 30] }}
                                            overlay={() => this.addToExportTooltip()}
                                        />}
                                    >
                                        <div className="c-rec-panel__nav__content">
                                            {t('buttons.add_to_export')}
                                        </div>

                                    </Button>
                                </div>
                            )}

                            {(!isMyRecords && selectedPPTItems.length > 0) && (
                                <div>
                                    <Button
                                        type="link-primary"
                                        size="small"
                                        className="marg-l-10"
                                        onClick={() => this.openExportPPT()}
                                    >
                                        <span className="counter">{selectedPPTItems.length}</span>

                                        <span className="counter-label">{t('buttons.export')}</span>
                                    </Button>
                                </div>
                            )}

                            {isMyRecords && (
                                <Auth permission="registration.manage">
                                    <Button
                                        iconLeft={<EditIcon />}
                                        type="link-primary"
                                        size="small"
                                        disabled={selectedRows.length < 1}
                                        onClick={() => this.bulkEditModal()}
                                    >
                                        {t('buttons.edit')}
                                    </Button>

                                    <Button
                                        iconLeft={<DeleteIcon />}
                                        type="link-danger"
                                        size="small"
                                        disabled={
                                            isOnlyRegisteredSelected ||
                                            filterQuarantineRows(
                                                data,
                                                selectedRows
                                            ).length <= 0
                                        }
                                        onClick={() =>
                                            !isOnlyRegisteredSelected &&
                                            this.bulkDeleteModal()
                                        }
                                    >
                                        {t('buttons.delete')}
                                    </Button>

                                    <Button
                                        iconRight={!!validationsExist ?
                                            <Tooltip
                                                align={{ offset: [5, 30] }}
                                                overlay={() => this.customButtonTooltip()}
                                            /> : undefined
                                        }
                                        type="secondary"
                                        size="small"
                                        onClick={() => toggleUploader()}
                                        disabled={!!showUploader || !size(selectedLicensor) || !!validationsExist || !!ignoreValidations}
                                    >
                                        {t('buttons.add_products')}
                                    </Button>

                                    <Button
                                        iconRight={
                                            <TableRegistration
                                                selectedRows={selectedRows}
                                                dispatch={dispatch}
                                                data={data}
                                                fetchData={fetchData}
                                                registrableIds={registrableIds}
                                            />
                                        }
                                        type="secondary"
                                        size="small"
                                        disabled={
                                            !readyToRegister(
                                                data,
                                                filterQuarantineRows(
                                                    data,
                                                    selectedRows
                                                )
                                            ) ||
                                            filterQuarantineRows(
                                                data,
                                                selectedRows
                                            ).length <= 0
                                        }
                                        onClick={() =>
                                            readyToRegister(
                                                data,
                                                filterQuarantineRows(
                                                    data,
                                                    selectedRows
                                                )
                                            ) &&
                                            dispatch(
                                                registerProductsModal(
                                                    map(filterQuarantineRows(
                                                        data,
                                                        selectedRows
                                                    ), 'record_id'),
                                                    fetchData,
                                                    true,
                                                    registrableIds,
                                                    basicFilterObj,
                                                    queryObject,
                                                    updateFiltersState
                                                )
                                            )
                                        }
                                    >
                                        {t('buttons.register')}
                                    </Button>
                                </Auth>
                            )}

                            {isMyRecords && activeConflictsCount > 0 && (
                                <Button
                                    type="secondary"
                                    size="small"
                                    to="/bulk-conflict"
                                    // className="c-rec-panel__nav-item"
                                >
                                    <span>
                                        {t('buttons.resolve_conflicts')}
                                        <span className="c-rec-panel__nav-item-counter">
                                            {activeConflictsCount}
                                        </span>
                                    </span>
                                </Button>
                            )}

                            <div className="align-right">
                                <Auth restrictTo="licensor">
                                    {!!this.hasPosPermission() && (
                                        <Button
                                            type="secondary"
                                            size="small"
                                            disabled={posExportConfig.length === 0}
                                            onClick={() => this.posExport()}
                                            iconRight={<Tooltip
                                                align={{ offset: [5, 30] }}
                                                overlay={() => this.tooltipPosText()}
                                            />}
                                        >
                                            <div className="c-rec-panel__nav__content">
                                                {t('buttons.pos_export')}
                                            </div>

                                        </Button>
                                    )}
                                </Auth>

                                {showHeaderSettings && !viewing && layoutType !== 'grid' && (
                                    <li className="c-rec-panel__nav-item">
                                        <FloatingMenu
                                            top={35}
                                            customButton={
                                                <div
                                                    style={{
                                                        margin: '-20px',
                                                        padding: '20px'
                                                    }}
                                                >
                                                    <span>
                                                        {t('buttons.viewing')}{' '}
                                                        <span className="u-color-blue">
                                                            {activeHeaders
                                                                ? activeHeaders
                                                                : t('buttons.default_headers')}
                                                        </span>
                                                    </span>
                                                </div>
                                            }
                                        >
                                            {headerLayouts.map((el) => {
                                                const newEl = Object.assign(
                                                    {},
                                                    el,
                                                    {
                                                        isFromList: true
                                                    }
                                                );
                                                const { name } = newEl;
                                                const { isDefaultHeader } =
                                                    headers;
                                                return (
                                                    <li
                                                        className={`${
                                                            activeHeaders ===
                                                            name ||
                                                            (el.name ===
                                                                t('buttons.default_headers') &&
                                                                isDefaultHeader)
                                                                ? 'active'
                                                                : ''
                                                        }`}
                                                        key={name}
                                                        onClick={() =>
                                                            dispatch(
                                                                changeHeaders({
                                                                    names: newEl,
                                                                    headerLabel:
                                                                    name,
                                                                    activeHeaders,
                                                                    headers,
                                                                    headerLayouts,
                                                                    dontShowRedirectModal
                                                                })
                                                            )
                                                        }
                                                    >
                                                        {name}
                                                    </li>
                                                );
                                            })}
                                        </FloatingMenu>
                                    </li>
                                )}

                                {!viewing && layoutType !== 'grid' && (
                                    <Button
                                        iconLeft={
                                            <CustomiseIcon />
                                        }
                                        type="link-primary"
                                        size="small"
                                        onClick={() =>
                                            this.toggleHeaderOptions()
                                        }
                                    >
                                        {t('buttons.customise_table')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}

                    {!isMyRecords && !quarantineCount && (
                        <div className={`view-select-block ${showHeaderOptions ? '-disabled' : ''}`}>
                            <Tile
                                onClick={() => setLayout('grid')}
                                className={classNames('clickable-icon', {
                                    '-active': layoutType === 'grid'
                                })}
                            ></Tile>
                            <List
                                onClick={() => setLayout('table')}
                                className={classNames('clickable-icon', {
                                    '-active': layoutType === 'table'
                                })}
                            ></List>
                        </div>
                    )}
                </div>
                {(!isMyRecords && ((selectedPPTItems.length + selectedRows.length) > 100)) && (
                    <div className={'header-messages'}>
                        <p>{t('product_components.header_section.up_to_products_export')}</p>
                    </div>
                )}

                <div
                    className={classNames('customized-table-header-holder', {
                        '-open': showHeaderOptions && !viewing
                    })}
                >
                    {showHeaderOptions && !viewing && (
                        <CustomizedTableHeader
                            categoryLevels={categoryLevels}
                            isMyRecords={isMyRecords}
                            toggleHeaderOptions={() =>
                                this.toggleHeaderOptions()
                            }
                        />
                    )}
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state, props) {
    const { isMyRecords } = props;
    const key = isMyRecords ? 'myRecords' : 'productCatalogue';

    const {
        xelacore: {
            auth: {
                userPermissions
            } = {},
            userSettings: {
                lists = [],
                headers = {},
                activeHeaders = '',
                headerLayouts = [],
                dontShowRedirectModal
            } = {},
            [key]: {
                records: { data, totalItems } = {},
                fetching: { fetching, fetchErrors } = {},
                filters: { filters } = {},
                initialCount,
                selectedRows = [],
                registrableIds = []
            } = {}
        } = {}
    } = state;

    return {
        lists,
        headers,
        activeHeaders,
        headerLayouts,
        data,
        totalItems,
        initialCount,
        selectedRows,
        fetching,
        fetchErrors,
        filters,
        dontShowRedirectModal,
        registrableIds,
        userPermissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HeaderSection));
