import React from 'react';
import notFound from 'statics/imgs/desktop/concepts/not_found.svg';
import { useTranslation } from 'react-i18next';

export default function OpaSearchEmptySearch({ searchTerm }) {
    const { t } = useTranslation();

    return (
        <div className="c-product__search-no-data">
            <img
                src={notFound}
                className="u-padding"
                alt="No matching concepts"
            />
            <div className="u-text-center u-bold u-padding">
                {t('modules.product_detail.opa_search_empty_search.couldnt_find_concepts')}
                <span className="u-color-pink u-capitalize">{searchTerm}</span>
            </div>
            <div>
                {t('modules.product_detail.opa_search_empty_search.use_different_search')}
            </div>
        </div>
    );
}
