import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { RestrictedRoute } from 'components/static';
import AuthorizationsTable from './components/AuthorizationsTable';
import RequestDetails from './components/RequestDetails';
import { withTranslation } from 'react-i18next';
import { fetchAuthorizationRequests } from 'src/js/apicalls/other/authorization';
import UiButton from 'modules/UiKit/components/Button/Button';
import DraftLetterPreview from 'modules/AuthorizationRequest/components/DraftLetterPreview';
import PdfPreview from 'modules/AuthorizationRequest/components/PdfPreview';

class AuthorizationRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requests: [],
            currentRequestName: null,
            loading: true,
            error: null,
            pageParams: {
                page: 1,
                limit: 10
            }
        };
    }

    componentDidMount() {
        this.handleRouteChange();
    }

    componentDidUpdate(prevProps) {
        const prevQuery = prevProps.location.query;
        const currentQuery = this.props.location.query;

        if (this.hasQueryChanged(prevQuery, currentQuery)) {
            this.handleRouteChange();
        }
    }

    hasQueryChanged = (prevQuery, currentQuery) => {
        const prevParams = {
            requestId: prevQuery.requestId,
            draftPreview: prevQuery.draftPreview,
            pdfPreview: prevQuery.pdfPreview,
            contractId: prevQuery.contractId
        };
        const currentParams = {
            requestId: currentQuery.requestId,
            draftPreview: currentQuery.draftPreview,
            pdfPreview: currentQuery.pdfPreview,
            contractId: currentQuery.contractId
        };

        return JSON.stringify(prevParams) !== JSON.stringify(currentParams);
    };

    handleRouteChange = () => {
        const { location } = this.props;
        const { requestId, draftPreview, pdfPreview } = location.query;

        this.setState({ requests: [], loading: true, error: null });

        if (!requestId && !draftPreview && !pdfPreview) {
            this.fetchRequests();
        } else {
            const id = requestId || draftPreview || pdfPreview;

            const request = this.state.requests.find(r => r.id === id);

            if (request) {
                this.setState({
                    currentRequestName: request.company_info.legal_company_name || id,
                    loading: false
                });
            } else {
                this.setState({
                    currentRequestName: id,
                    loading: false
                });
            }
        }
    };

    fetchRequests = async () => {
        const { t } = this.props;
        try {
            const { pageParams } = this.state;
            this.setState({ loading: true, error: null });

            const response = await fetchAuthorizationRequests(pageParams);
            this.setState({
                requests: response.data || [],
                currentRequestName: null,
                loading: false
            });
        } catch (err) {
            this.setState({
                error: t('toolkit.trademark_authorization.request.failed_fetch'),
                loading: false
            });
        }
    };

    updateUrlParam = (params = {}) => {
        const { location } = this.props;
        const newQuery = { ...location.query };

        newQuery.requestId = params.requestId || undefined;
        newQuery.draftPreview = params.draftPreview || undefined;
        newQuery.pdfPreview = params.pdfPreview || undefined;
        newQuery.contractId = params.contractId || undefined;

        browserHistory.push({
            pathname: location.pathname,
            query: Object.fromEntries(
                Object.entries(newQuery).filter(([v]) => v !== undefined)
            )
        });
    };

    handleViewRequest = (requestId, isDraftPreview, isPdfPreview) => {
        const request = this.state.requests.find(r => r.id === requestId);

        if (request) {
            this.setState({
                currentRequestName: request.company_info.legal_company_name || requestId
            });
        } else {
            this.setState({
                currentRequestName: requestId
            });
        }

        if (isDraftPreview) {
            this.updateUrlParam({ draftPreview: requestId, contractId: requestId });
        } else if (isPdfPreview) {
            this.updateUrlParam({ pdfPreview: requestId, contractId: requestId });
        } else {
            this.updateUrlParam({ requestId });
        }
    };

    handleGeneratePreview = (data) => {
        if (data.trademarkRequest.company_info.legal_company_name) {
            this.setState({
                currentRequestName: data.trademarkRequest.company_info.legal_company_name
            });
        }

        this.updateUrlParam({
            draftPreview: data.trademarkRequest.id,
            contractId: data.contractId
        });
    };

    handleClosePreview = () => {
        this.setState({ currentRequestName: null });
        this.updateUrlParam({});
    };

    // handleBack = () => {
    //     const { requestId } = this.props.location.query;
    //     if (requestId) {
    //         this.updateUrlParam({ requestId });
    //     } else {
    //         this.setState({ currentRequestName: null });
    //         this.updateUrlParam({});
    //     }
    // };

    renderContentView = () => {
        const { requests, loading } = this.state;
        const { location } = this.props;
        const { requestId, contractId, draftPreview, pdfPreview } = location.query;

        const viewKey = draftPreview
            ? 'draftPreview'
            : pdfPreview
                ? 'pdfPreview'
                : requestId
                    ? 'requestDetails'
                    : 'table';

        const componentMap = {
            draftPreview: (
                <DraftLetterPreview
                    requestId={requestId}
                    contractId={contractId}
                    onClose={this.handleClosePreview}
                    // onBack={this.handleBack}
                />
            ),
            pdfPreview: (
                <PdfPreview
                    requestId={requestId}
                    contractId={contractId}
                />
            ),
            requestDetails: (
                <RequestDetails
                    key={requestId}
                    requestId={requestId}
                    onGeneratePreview={this.handleGeneratePreview}
                />
            ),
            table: (
                <AuthorizationsTable
                    requests={requests}
                    loading={loading}
                    onViewRequest={this.handleViewRequest}
                />
            )
        };

        return componentMap[viewKey] || null;
    };

    render() {
        const { t } = this.props;
        const { error } = this.state;

        return (
            <RestrictedRoute restrictTo="licensor">
                <div className="authorization-request-container">

                    {/*{(requestId || draftPreview || pdfPreview) && (*/}
                    {/*    <UiButton*/}
                    {/*        type="secondary-2"*/}
                    {/*        size="small"*/}
                    {/*        onClick={this.handleClosePreview}*/}
                    {/*        iconLeft={<Icon className="back-icon" fill="black" icon="ARROW_LEFT_STYLE_2" />}*/}
                    {/*    >*/}
                    {/*        {t('buttons.back')}*/}
                    {/*    </UiButton>*/}
                    {/*)}*/}

                    {error && (
                        <div className="error-message">
                            <span>{error}</span>
                            <UiButton type="secondary" size="small" onClick={this.fetchRequests}>
                                {t('buttons.retry')}
                            </UiButton>
                        </div>
                    )}

                    {this.renderContentView()}
                </div>
            </RestrictedRoute>
        );
    }
}

const mapStateToProps = ({ xelacore, dispatch }) => ({ xelacore, dispatch });

export default connect(mapStateToProps)(withTranslation()(AuthorizationRequest));
