import React, { Component } from 'react';
import { SidePanel, Icon } from 'src/js/components/static';
import { getPreviousUploads } from 'src/js/actions/records';
import { downloadFile } from 'src/js/actions/downloadFiles';
import UploadHistoryItem from './UploadHistoryItem';
import UploadHistoryGroup from './UploadHistoryGroup';
import Button from 'modules/UiKit/components/Button/Button';
import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import { withTranslation } from 'react-i18next';
class UploadHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originalHistory: [],
            history: [],
            collapsed: [],
            filterText: '',
            singleUpload: null,
            selectedLicensor: !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {}
        };
    }

    componentWillMount() {
        const { id } = this.props;
        if (id) return this.fetchSingle(id);

        return this.fetchHistory();
    }

    fetchSingle(id) {
        const { selectedLicensor } = this.state;
        const { dispatch } = this.props;

        dispatch(getPreviousUploads(id, selectedLicensor.licensor_organisation_id)).then(resp => {
            this.setState({ singleUpload: resp.data });
        });
    }

    fetchHistory() {
        const { selectedLicensor } = this.state;
        const { dispatch } = this.props;

        dispatch(getPreviousUploads(null, selectedLicensor.licensor_organisation_id)).then(resp => {
            this.groupByData(resp.data);
            this.setState({ originalHistory: resp.data });
        });
    }

    groupByData(data) {
        const year = groupBy(data, item => item.updatedAt.substring(0, 4));
        const history = Object.keys(year).map(el => {
            return groupBy(
                year[el],
                item => new Date(item.updatedAt).getMonth() + 1
            );
        });

        const historyWithYears = {};
        Object.keys(year).map((el, i) => {
            historyWithYears[el] = history[i];
        });

        this.setState({ history: historyWithYears });
    }

    collapseGroup(data) {
        const { collapsed } = this.state;

        let collapsedClone = cloneDeep(collapsed);

        if (!!includes(collapsedClone, data)) {
            remove(collapsedClone, item => item === data);
        } else {
            collapsedClone.push(data);
        }

        return this.setState({
            collapsed: collapsedClone
        });
    }

    filterResults(e) {
        this.setState({ filterText: e.target.value });

        const { originalHistory } = this.state;
        const value = e.target.value.toLowerCase();
        const searchResults = originalHistory.filter(f => {
            f.sourceInfo.label = f.label;
            const info = f.sourceInfo;
            // You'll need to merge user details from this
            return Object.keys(info).find(key => {
                const stringify =
                    info[key] && info[key] !== null
                        ? info[key].toString().toLowerCase()
                        : '';
                return stringify.indexOf(value) > -1;
            });
        });

        return this.groupByData(searchResults, originalHistory);
    }

    showAllDownloads() {
        this.setState({ singleUpload: null });
        this.fetchHistory();
    }

    render() {
        const { history, collapsed, filterText, singleUpload } = this.state;
        const { close, dispatch, t } = this.props;

        const MONTHS = [
            '',
            t('constants.months.january'),
            t('constants.months.february'),
            t('constants.months.march'),
            t('constants.months.april'),
            t('constants.months.may'),
            t('constants.months.june'),
            t('constants.months.july'),
            t('constants.months.august'),
            t('constants.months.september'),
            t('constants.months.october'),
            t('constants.months.november'),
            t('constants.months.december')
        ];

        return (
            <SidePanel closeBtn width="500px" close={close}>
                <div className="c-upload-history">
                    <div className="u-text-center u-margin-top-4X">
                        <div className="link-color">
                            <Icon icon="upload" size="30" fill="cyan" />
                            <h3 className="link-color">{t('upload_history.upload_history')}</h3>
                        </div>
                        <br />
                        <small className="u-block">
                            {t('upload_history.track_and_view')}
                        </small>
                    </div>
                </div>
                {singleUpload !== null && (
                    <div className="u-margin-top">
                        <div className="c-upload-history__date-bar">
                            <div className="c-upload-history__month">
                                {
                                    MONTHS[
                                        parseInt(
                                            singleUpload.createdAt.substring(
                                                5,
                                                7
                                            )
                                        )
                                        ]
                                }
                            </div>
                            <div className="c-upload-history__year">
                                {singleUpload.createdAt.substring(0, 4)}
                            </div>
                        </div>
                        <ul className="c-upload-history__list">
                            <UploadHistoryItem
                                upload={singleUpload}
                            />
                        </ul>
                        <div className="u-text-center">
                            <Button
                                type="secondary-2"
                                size="small"
                                onClick={() => this.showAllDownloads()}>
                                    {t('buttons.view_all_uploads')}
                            </Button>
                        </div>
                    </div>
                )}

                {singleUpload === null && (
                    <div>
                        <div className="c-upload-history__filter-bar">
                            <div className="c-upload-history__filter-input">
                                <input
                                    value={filterText}
                                    type="text"
                                    placeholder={t('upload_history.search_file_name')}
                                    onChange={e => this.filterResults(e)}
                                />
                                <div className="c-upload-history__search">
                                    <Icon
                                        fill="black"
                                        icon="search"
                                        size="14"
                                    />
                                </div>
                            </div>
                        </div>

                        {Object.keys(history)
                            .reverse()
                            .map((year, i) => (
                                <UploadHistoryGroup
                                    history={history}
                                    year={year}
                                    index={i}
                                    collapsed={collapsed}
                                    collapse={data => this.collapseGroup(data)}
                                    key={`${year}-${i}-UploadHistoryGroup`}
                                    downloadFile={(
                                        filename,
                                        ingestId,
                                        option
                                    ) =>
                                        dispatch(
                                            downloadFile(
                                                filename,
                                                ingestId,
                                                option
                                            )
                                        )
                                    }
                                />
                            ))}
                    </div>
                )}
            </SidePanel>
        );
    }
}

export default withTranslation()(UploadHistory)
