import React, { Component, Fragment } from 'react';
import { browserHistory } from 'react-router';
import classnames from 'classnames';
import moment from 'moment';
import get from 'lodash/get';
import includes from 'lodash/includes';

import { nullToString } from 'src/js/helpers/objects';

import { Triangle, Tooltip, Auth } from 'src/js/components/static';

import { selectRow } from 'src/js/actions/productsFetch';
import MyRecordsMetaRow from './MyRecordsMetaRow';
import TableRowTooltips from 'src/js/components/TableRow/TableRowTooltips';
import imgPlaceholder from 'statics/imgs/placeholder.jpg';
import imgPinkSpinner from 'statics/imgs/desktop/pink-spinner.gif';
import {
    getFiltersDataFromUrl,
    getMainImage,
    checkImageExists
} from 'src/js/helpers/dataHelpers';

import { renderElement } from 'src/js/components/ProductListElements';
import MediaView from 'src/js/components/MediaView';
import fallbackImageSmall from 'statics/imgs/fallback_img_small.png';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import { withTranslation } from 'react-i18next';

class TableRow extends Component {
    constructor(props) {
        super(props);
        this.newRecordsTime = 60;
        this.state = {
            metaVisible: false,
            uploader: null,
            profile_image: null,
            popoverWidth: 822,
            popoverHeight: 444,
            email: '',
            quarantineMode: false,
            modalActions: false,
            imageExists: null,
            imageCheckCount: 0
        };

        this.popoverRef = React.createRef();
    }

    async setMeta() {
        const { uploader, metaVisible } = this.state;

        const otherStates = {
            metaVisible: !metaVisible
        };

        if (uploader === null) {
            return this.setState({
                uploader,
                ...otherStates
            });
        }

        return this.setState({ ...otherStates });
    }

    componentDidMount() {
        const { data } = this.props;
        const { imageExists } = this.state;
        const image = getMainImage(data.images);

        let counter = 0;

        if (!imageExists) {
            let interval = setInterval(() => {
                checkImageExists(
                    image,
                    () => {
                        this.setState({
                            imageExists: true
                        });

                        clearInterval(interval);
                    },
                    () => {
                        if (counter > 15) {
                            clearInterval(interval);
                        }

                        counter++;

                        this.setState({
                            imageExists: false,
                            imageCheckCount: counter
                        });
                    }
                );
            }, 2000);
        }
    }

    checkDuration(uploaded) {
        const now = moment(new Date());
        const end = moment(uploaded);
        const duration = moment.duration(now.diff(end));
        return duration.asSeconds() < this.newRecordsTime;
    }

    openProduct = () => {
        const { data: { record_id } = {} } = this.props;
        return browserHistory.push(`/product/records/${record_id}`);
    };

    getRegisteredLink = () => {
        const { data } = this.props;
        return browserHistory.push(`/product/catalog/${data.record_id}`);
    };

    getValue = (data, dataName) => {
        const { licensor } = this.props;
        let categoryDataName = `${licensor ? 'licensor' : 'licensee'}_category_path`;

        if (dataName === 'uploaded_by') {
            return get(data, 'meta.upload_by_user', '');
        }

        if (includes(dataName, 'licensee_category_path')) {
            let index = dataName.split('.')[1];
            return data[categoryDataName] && data[categoryDataName][index] && data[categoryDataName][index] || '';
        }

        return data[dataName];
    };

    setDefaultSizes = () => {
        this.setState({ popoverWidth: 822, popoverHeight: 444 });
    };

    onResize = (event, { size }) => {
        this.setState({ popoverWidth: size.width, popoverHeight: size.height });
    };

    render() {
        const {
            showUpload,
            selectedRows,
            data,
            editRow,
            dispatch,
            fetchData,
            sortedHeaders,
            isConflicts = false,
            markedForDeletion,
            rowsToDelete = [],
            highlighted,
            handleClick,
            registrableIds,
            allProducts,
            previousRow,
            key,
            setConflicts,
            t
        } = this.props;
        const {
            metaVisible,
            uploader,
            email,
            profile_image,
            imageExists,
            imageCheckCount
        } = this.state;
        const {
            record_id,
            product_name,
            concept_code_confirmed_info,
            conflicts_total_count,
            images,
            version_id,
            validation_version_id,
            conflict_with
        } = data;
        const validation_state = validation_version_id !== version_id;
        const isConflictedRecord = !!(conflicts_total_count
            ? parseInt(conflicts_total_count)
            : 0 !== 0);
        const cleanedData = nullToString(data);

        const fullLength = Object.keys(cleanedData).length + 3;

        const cx2 = classnames('c-rec-table__primary-row', {
            'c-rec-table__processing': validation_state,
            'c-rec-table__marked-for-deletion': markedForDeletion,
            'c-rec-table__highlighted': highlighted
        });

        const productApproval =
            (concept_code_confirmed_info &&
                !!concept_code_confirmed_info.length) ||
            null;
        const defaultImage = { imgPlaceholder };

        const image = getMainImage(images);

        const func = () => this.openProduct();

        const imageCellCx = classnames(
            'c-rec-table__cell c-rec-table__image-cell',
            { 'u-clickable': !isConflicts && !isConflictedRecord }
        );

        const contentJsx = (
            <Fragment>
                <tr className={cx2} onClick={handleClick}>
                    <td className="c-rec-table__cell c-rec-table__checkbox checkbox-holder">
                        {!isConflicts && (
                            <div className="">
                                {!validation_state && (
                                    <Auth
                                        restrictTo="licensee"
                                        permission="registration.manage"
                                    >
                                        <UiCheckbox
                                            key={key}
                                            type="checkbox"
                                            checked={selectedRows.filter(item => item.record_id === data.record_id).length > 0}
                                            onClick={(e) =>
                                                dispatch(
                                                    selectRow(
                                                        e,
                                                        data,
                                                        selectedRows,
                                                        true, // isMyRecords
                                                        data,
                                                        registrableIds,
                                                        allProducts,
                                                        previousRow
                                                    )
                                                )
                                            }>

                                        </UiCheckbox>
                                    </Auth>
                                )}

                                {validation_state && !isConflictedRecord && (
                                    <div
                                        className="u-clickable spinner-cell"
                                        onClick={() =>
                                            fetchData(
                                                null,
                                                getFiltersDataFromUrl()
                                            )
                                        }
                                    >
                                        <Tooltip
                                            button={
                                                <div>
                                                    <img
                                                        src={imgPinkSpinner}
                                                    />
                                                </div>
                                            }
                                        >
                                            {t('modules.my_records.my_records_table_row.record_is_updating')}
                                            <span
                                                onClick={() =>
                                                    fetchData(
                                                        null,
                                                        getFiltersDataFromUrl()
                                                    )
                                                }
                                                className="c-rec-table__refresh-data"
                                            >
                                                {t('modules.my_records.my_records_table_row.refresh')}
                                            </span>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        )}

                        <div
                            className={`c-rec-table__meta-button ${
                                metaVisible
                                    ? 'c-rec-table__meta-button--active'
                                    : ''
                            }`}
                            onClick={() => this.setMeta()}
                        >
                            <span>
                                <Triangle purple up={metaVisible} />
                            </span>
                        </div>
                    </td>

                    <td
                        ref={(node) => {
                            this.popoverRef = node;
                        }}
                        className={`c-rec-table__cell c-rec-table__status ${
                            rowsToDelete.length > 0
                                ? 'c-rec-table__status--no-click'
                                : ''
                        }`}
                    >
                        <TableRowTooltips
                            productApproval={productApproval}
                            data={data}
                            editRow={() =>
                                isConflicts
                                    ? editRow(record_id, data)
                                    : editRow(record_id)
                            }
                            errors={data.errors}
                            warnings={data.warnings}
                            conflicts={data.conflicts_total_count}
                            getRegisteredLink={this.getRegisteredLink}
                            openProduct={this.openProduct}
                            setConflicts={(modalActions) => setConflicts(modalActions)}
                            conflicted={!!conflict_with}
                        />
                    </td>

                    <td onClick={() => func()} className={imageCellCx}>
                        {isConflicts && markedForDeletion && (
                            <div className="c-rec-table__marked-for-deletion-indicator">
                                {t('modules.my_records.my_records_table_row.marked_for_deletion')}
                            </div>
                        )}
                        <div className="c-rec-table__image-icon u-flex-align">
                            {(imageExists !== false ||
                                imageCheckCount >= 15) && (
                                <MediaView
                                    src={image}
                                    alt={product_name}
                                    fallbackSrc={fallbackImageSmall}
                                    useImgTag
                                />
                            )}

                            {imageExists === false && imageCheckCount < 15 && (
                                <Tooltip
                                    button={
                                        <div>
                                            <img
                                                src={imgPinkSpinner}
                                                height="15"
                                            />
                                        </div>
                                    }
                                ></Tooltip>
                            )}
                        </div>
                        {image !== defaultImage && (
                            <MediaView
                                src={image}
                                className="c-rec-table__hover-image"
                                fallbackSrc={fallbackImageSmall}
                                useImgTag
                            />
                        )}
                    </td>
                    {sortedHeaders.map(({ form: { type }, dataName }, i) => (
                        <td
                            className="c-rec-table__cell"
                            key={`${type}-${dataName}-table-row-${i}`}
                        >
                            {renderElement(
                                data,
                                conflict_with && dataName === 'product_name'
                                    ? 'text'
                                    : type,
                                this.getValue(data, dataName),
                                dataName,
                                func,
                                false
                            )}
                        </td>
                    ))}
                    <td
                        className="c-rec-table__cell u-no-text-transform"
                        key="duration-uploaded_at-table-row"
                    >
                        {renderElement(
                            data,
                            'duration',
                            data['uploaded_at'],
                            'uploaded_at',
                            func,
                            false
                        )}
                    </td>
                    <td
                        className="c-rec-table__cell u-no-text-transform"
                        key="duration-updated_at-table-row"
                    >
                        {renderElement(
                            data,
                            'duration',
                            data['updated_at'],
                            'updated_at',
                            func,
                            false
                        )}
                    </td>
                </tr>

                {metaVisible && (
                    <MyRecordsMetaRow
                        data={data}
                        fullLength={fullLength}
                        email={email}
                        profile_image={profile_image}
                        uploader={uploader}
                        activeHeaders={sortedHeaders}
                        showUpload={showUpload}
                        isConflictedRecord={isConflictedRecord}
                    />
                )}
            </Fragment>
        );

        return isConflicts ? contentJsx : <tbody>{contentJsx}</tbody>;
    }
}

export default withTranslation()(TableRow)