import React from 'react';
import { Auth, Tooltip } from 'src/js/components/static';
import { readyToRegister } from 'src/js/helpers/dataHelpers';
import { useTranslation } from 'react-i18next';

export default function TableRegistration({ selectedRows, data }) {
    const { t } = useTranslation();

    return (
        <Auth permission="registration.manage">
            <Tooltip>
                {t('modules.my_records.table_registration.fix_all_errors')}
            </Tooltip>
            {readyToRegister(data, selectedRows)}
        </Auth>
    );
}
