import React, { Component, Fragment } from 'react';
import OpaSearchPanelItems from '../OpaSearchPanel/OpaSearchPanelItems';
import ApprovalNoData from './ApprovalNoData';
import { withTranslation } from 'react-i18next';

class ApprovalWithoutData extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            toggleOpaPanel,
            approvalCodes,
            toggleProductInfo,
            matchingData,
            t
        } = this.props;
        return (
            <Fragment>
                {!matchingData && (
                    <div className="c-product__matching-data u-height u-padding-bottom">
                        <ApprovalNoData toggleOpaPanel={toggleOpaPanel} />
                    </div>
                )}

                {matchingData && (
                    <div className="c-product__matching-data">
                        <div
                            className={`c-product__matching-data-header ${approvalCodes &&
                                'c-product__matching-data-header-code'}`}
                        >
                            {!approvalCodes &&
                                'Based on your product data, these concepts may match.'}
                            {!!approvalCodes && (
                                <span >
                                    <div className="u-text-center u-bold">
                                        {t('modules.product_detail.approval_no_data.based_on')}
                                    </div>
                                </span>
                            )}
                        </div>
                        <div className="u-flex-evenly">
                            <OpaSearchPanelItems
                                items={matchingData}
                                toggleProductInfo={toggleProductInfo}
                                approvalCodes={approvalCodes}
                            />
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}

export default withTranslation()(ApprovalWithoutData)