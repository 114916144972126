import React from 'react';

export default function Textarea({
    name,
    type,
    onChange,
    onBlur,
    onFocus,
    value,
    id,
    required,
    disabled,
    placeholder,
    maxLength
}) {
    return (
        <textarea
            name={name}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value}
            id={id}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            maxLength={maxLength}
        />
    );
}
