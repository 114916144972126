import React from 'react';
import TagsInput from '../../Components/FormElements/TagsInput';
import moment from 'moment';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import size from 'lodash/size';
import toArray from 'lodash/toArray';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'components/static/Icon/svg-icons/close-icon.svg';

function ContractDetails({
                             contractDetails,
                             expandedTables,
                             exceptionVisibility,
                             toggleTable,
                             toggleException,
                             toggleItem,
                             handleExceptionTagsChange,
                             exceptionRawInputs,
                             handleSelectAll,
                             handleRowCheckboxChange
                         }) {
    const { t } = useTranslation();

    let allSelected =
        contractDetails &&
        contractDetails.length > 0 &&
        contractDetails.every((contract) => {
            return contract.selected;
        });

    return (
        <div className="contract-details-table">
            <div className="c-my-records">
                <div className="ui-table table">
                    <div className="ui-table__wrapper table_ui">
                        <div className="ui-table__header-container">
                            <div className="c-rec-table__table-holder table_header_wrapper">
                                <div className="table_wrapper_header">
                                    <table className="ui-table__top-header -dark">
                                        <thead>
                                        <tr>
                                            <th className="ui-table__head-cell checkbox-cell">
                                                {!!size(contractDetails) && (<UiCheckbox
                                                    onChange={handleSelectAll}
                                                    type="checkbox"
                                                    label={''}
                                                    checked={allSelected}
                                                    id="select_all_contracts"
                                                />)}
                                            </th>
                                            <th className="ui-table__head-cell">Contract Number</th>
                                            <th className="ui-table__head-cell">Contract</th>
                                            <th className="ui-table__head-cell">Contract Status</th>
                                            <th className="ui-table__head-cell">Term Begin Date</th>
                                            <th className="ui-table__head-cell">Term End Date</th>
                                            <th className="ui-table__head-cell"></th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="c-rec-table">
                            <div className="c-rec-table__table-holder c-rec-table__main-table">
                                {contractDetails.map((contract, index) => (
                                    <table key={index} className={expandedTables[index] ? 'expanded' : ''}>
                                        <tbody>
                                        <tr className="c-pointer" onClick={() => toggleTable(index)}>
                                            <td className='ui-table__body-cell checkbox-cell'>
                                                <UiCheckbox
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                    onChange={(e) => {
                                                        e.stopPropagation();
                                                        handleRowCheckboxChange(index, e);
                                                    }}
                                                    type='checkbox'
                                                    label=''
                                                    checked={contract.selected}
                                                    id={'contract_checkbox_' + index}
                                                />
                                            </td>
                                            <td className='ui-table__body-cell'>{contract.agreement_id}</td>
                                            <td className='ui-table__body-cell'>{contract.agreement_name}</td>
                                            <td className='ui-table__body-cell'>{contract.contract_status}</td>
                                            <td className='ui-table__body-cell'>{moment(contract.start_date).format('DD MMM YYYY')}</td>
                                            <td className='ui-table__body-cell'>{moment(contract.expiration_date).format('DD MMM YYYY')}</td>
                                            <td className="ui-table__body-cell">
                                                <div className="select-drop-down--control--arrow">
                                                    <span className="select-drop-down--control--arrow--triangle" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={7} className="ui-table__body-cell u-no-padding">
                                                <div className="contract-details-wrapper_tags-header">
                                                    <strong>Contracted Territories</strong>
                                                    <span
                                                        className="link-color u-bold c-pointer"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            toggleException(index, 'contracted');
                                                        }}
                                                    >
                                                            Add Exception
                                                        </span>
                                                </div>
                                                <div className="contract-details-wrapper_tags-wrapper">
                                                    {contract.contracted_territories.original.map((item, i) => {
                                                        const isEnabled = contract.contracted_territories.licensee_request.includes(item);
                                                        return (
                                                            <span
                                                                key={i}
                                                                className={`c-pointer contract-details-wrapper_tags-wrapper__item ${isEnabled ? 'enabled' : 'disabled'}`}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    toggleItem(index, 'contracted_territories', item);
                                                                }}
                                                            >
                                                                    <span>{item}</span>
                                                                    <CloseIcon></CloseIcon>
                                                                </span>
                                                        );
                                                    })}
                                                </div>
                                            </td>
                                        </tr>
                                        {exceptionVisibility[index] && exceptionVisibility[index].contracted && (
                                            <tr>
                                                <td colSpan={7} className="ui-table__body-cell u-no-padding">
                                                    <div className="except-label">Contracted Territories Exception</div>
                                                    <div className="form-item">
                                                        <div className="field-item-wrapper">
                                                            <div className="extra-wrapper input-wrapper">
                                                                <TagsInput
                                                                    tags={
                                                                        exceptionRawInputs[`${index}_contracted_territories`] !== undefined
                                                                            ? exceptionRawInputs[`${index}_contracted_territories`]
                                                                            : contract.contracted_territories.licensee_exception
                                                                    }
                                                                    onChange={(newTags) =>
                                                                        handleExceptionTagsChange(index, 'contracted_territories', newTags)
                                                                    }
                                                                    placeholder="Type exception and press Enter..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td colSpan={7} className="ui-table__body-cell u-no-padding">
                                                <div className="contract-details-wrapper_tags-header">
                                                    <strong>Ips</strong>
                                                    <span
                                                        className="link-color u-bold c-pointer"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            toggleException(index, 'ips');
                                                        }}
                                                    >
                                                            Add Exception
                                                        </span>
                                                </div>
                                                <div className="contract-details-wrapper_tags-wrapper">
                                                    {contract.ips.original.map((item, i) => {
                                                        const isEnabled = contract.ips.licensee_request.includes(item);
                                                        return (
                                                            <span
                                                                key={i}
                                                                className={`c-pointer contract-details-wrapper_tags-wrapper__item ${isEnabled ? 'enabled' : 'disabled'}`}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    toggleItem(index, 'ips', item);
                                                                }}
                                                            >
                                                                <span>{item}</span>
                                                                <CloseIcon></CloseIcon>
                                                            </span>
                                                        );
                                                    })}
                                                </div>
                                            </td>
                                        </tr>
                                        {exceptionVisibility[index] && exceptionVisibility[index].ips && (
                                            <tr>
                                                <td colSpan={7} className="ui-table__body-cell u-no-padding">
                                                    <div className="except-label">Ips Exception</div>
                                                    <div className="form-item">
                                                        <div className="field-item-wrapper">
                                                            <div className="extra-wrapper input-wrapper">
                                                                <TagsInput
                                                                    tags={
                                                                        exceptionRawInputs[`${index}_ips`] !== undefined
                                                                            ? exceptionRawInputs[`${index}_ips`]
                                                                            : contract.ips.licensee_exception
                                                                    }
                                                                    onChange={(newTags) =>
                                                                        handleExceptionTagsChange(index, 'ips', newTags)
                                                                    }
                                                                    placeholder="Type exception and press Enter..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td colSpan={7} className="ui-table__body-cell u-no-padding">
                                                <div className="contract-details-wrapper_tags-header">
                                                    <strong>Categories</strong>
                                                    <span
                                                        className="link-color u-bold c-pointer"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            toggleException(index, 'categories');
                                                        }}
                                                    >
                                                            Add Exception
                                                        </span>
                                                </div>
                                                <div className="contract-details-wrapper_tags-wrapper">
                                                    {contract.categories.original.map((item, i) => {
                                                        const isEnabled = contract.categories.licensee_request.includes(item);
                                                        return (
                                                            <span
                                                                key={i}
                                                                className={`c-pointer contract-details-wrapper_tags-wrapper__item ${isEnabled ? 'enabled' : 'disabled'}`}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    toggleItem(index, 'categories', item);
                                                                }}
                                                            >
                                                                    <span>{item}</span>
                                                                    <CloseIcon></CloseIcon>
                                                                </span>
                                                        );
                                                    })}
                                                </div>
                                            </td>
                                        </tr>
                                        {exceptionVisibility[index] && exceptionVisibility[index].categories && (
                                            <tr>
                                                <td colSpan={7} className="ui-table__body-cell u-no-padding">
                                                    <div className="except-label">Categories Exception</div>
                                                    <div className="form-item">
                                                        <div className="field-item-wrapper">
                                                            <div className="extra-wrapper input-wrapper">
                                                                <TagsInput
                                                                    tags={
                                                                        exceptionRawInputs[`${index}_categories`] !== undefined
                                                                            ? exceptionRawInputs[`${index}_categories`]
                                                                            : contract.categories.licensee_exception
                                                                    }
                                                                    onChange={(newTags) =>
                                                                        handleExceptionTagsChange(index, 'categories', newTags)
                                                                    }
                                                                    placeholder="Type exception and press Enter..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!size(contractDetails) && !toArray(contractDetails).length && (
                <div className="u-text-center u-margin-top">
                    <h3 className="c-my-records__no-data-header">
                        {t('modules.royalty_reporting.no_data_available')}
                    </h3>
                    <div className='no-data-catalogue'>
                        <p className='c-my-records__no-data-text'>
                            {t('modules.royalty_reporting.you_have_no_data_here')}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ContractDetails;
