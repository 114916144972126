import React, { useEffect, useState } from 'react';
import { fetchQuicksightDashboard } from 'src/js/apicalls/other/quicksightDashboard';
import Button from 'modules/UiKit/components/Button/Button';
import emptyReport from 'statics/imgs/report-empty.png';
import { useTranslation } from 'react-i18next';

function QuickSightDashboard() {
    const { t } = useTranslation();
    const [dashboardUrl, setDashboardUrl] = useState(
        localStorage.getItem('dashboard_main_url') || ''
    );
    const [errorDashboard, setDashboardError] = useState(false);
    const [busyReport, setBusyReport] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchDashboard();
    }, []);

    const fetchDashboard = () => {
        setBusyReport(true);
        setIsLoading(true);

        fetchQuicksightDashboard()
            .then(response => {
                setDashboardUrl(response.view_url_init);
                setDashboardError(false);
                setBusyReport(false);
            })
            .catch(() => {
                setDashboardError(true);
                setBusyReport(false);
                setIsLoading(false);
            });
    };

    return (
        <div style={{ position: 'relative' }}>
            {isLoading && !errorDashboard && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 10
                    }}
                >
                    <div className='dashboard-slider'>
                        <ul>
                            <li>{t('placeholders.loading_dashboard')}</li>
                            <li>{t('placeholders.gathering_data')}</li>
                            <li>{t('placeholders.crunching_numbers')}</li>
                            <li>{t('placeholders.consolidating_insights')}</li>
                            <li>{t('placeholders.creating_charts')}</li>
                            <li>{t('placeholders.optimizing_visuals')}</li>
                            <li>{t('placeholders.finalizing_dashboard')}.</li>
                            <li>{t('placeholders.preparing_view')}</li>
                            <li>{t('placeholders.almost_there')}</li>
                            <li>{t('placeholders.loading_dashboard')}</li>
                        </ul>
                    </div>
                </div>
            )}

            {!errorDashboard && (
                <iframe
                    src={dashboardUrl}
                    style={{
                        height: 'calc(100vh - 120px)',
                        overflowY: 'auto',
                        width: '100%',
                        boxShadow: 'none',
                        border: '0'
                    }}
                    onLoad={() => setIsLoading(false)}
                ></iframe>
            )}

            {errorDashboard && (
                <div className='empty-report'>
                    <img src={emptyReport} alt="No report available" />
                    <p>{t('modules.quick_sight.something_went_wrong')}</p>
                    <Button
                        type="primary"
                        size="small"
                        disabled={busyReport}
                        onClick={() => fetchDashboard()}
                    >
                        {t('buttons.refresh')}
                    </Button>
                </div>
            )}
        </div>
    );
}

export default QuickSightDashboard;
