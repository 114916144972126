import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from 'modules/UiKit/components/Button/Button';
import {
    uploadFilePeriod,
    createUpdateReportPeriod
} from 'src/js/apicalls/other/royaltyReporting';
import { SmartUploader, Icon } from 'src/js/components/static';
import { ReactComponent as CloseIcon } from 'src/js/components/static/Icon/svg-icons/close-icon.svg';
import CustomSelect from 'modules/UiKit/components/FormElements/CustomSelect';
import find from 'lodash/find';
import toArray from 'lodash/toArray';
import {
    displayNotification
} from 'src/js/actions/xelacore';
import { cloneDeep, toLower, each } from 'lodash';
import { useTranslation } from 'react-i18next';

function UploadSalesData({
    period,
    years,
    quarters,
    countries,
    setOpenUploadArea,
    openUploadArea,
    year,
    quarter,
    setYear,
    setQuarter,
    periodExist,
    salesData,
    fetchData,
    wholesale,
    reportId,
    dispatch
}) {
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const [files, setFiles] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [firstScreenConfirmed, setScreenConfirmed] = useState(false);
    const [, setStartDate] = useState('');
    const [, setEndDate] = useState('');
    const [importType, setImportType] = useState('');
    const [saleDataType, setSalesDataType] = useState('');
    const [destinationGeo, setDestinationGeo] = useState('');
    const [quartersArr, setQuarters] = useState(cloneDeep(quarters));

    const confirmFileDrop = (droppedFiles) => {
        if(!allFieldsCompleted()) {
            setError(t('modules.royalty_reporting.upload_sales_data.drag_and_drop'))
        }

        Promise.all(droppedFiles).then(resp => {
            const files = resp.filter(f => f !== null);

            if (files[0].rowslength <= 1) {
                return;
            }

            if (files[0].rowslength > 20001) {
                return;
            }

            setFiles(files);
            setConfirm(true);
        });
    }

    const handleFilesDrop = (files) => {
        setUploading(true);

        let findQuarter = find(quarters, val => {
            return quarter === val.value
        });

        setStartDate(year + '-' + findQuarter.startDate);
        setEndDate(year + '-' + findQuarter.endDate);

        let params = {
            startDate: year + '-' + findQuarter.startDate,
            endDate: year + '-' + findQuarter.endDate
        }

        if(!!reportId) {
            return uploadFunc(files, reportId);
        } else {
            createUpdateReportPeriod(params, reportId).then(response => {
                return uploadFunc(files, response.id);
            }, () => {
                setError(t('modules.royalty_reporting.error'));
                setUploading(false);
            })
        }
    }
    
    const uploadFunc = (files, id) => {
        let formatImportType = !!find(wholesale, val => toLower(val.value) === importType) && find(wholesale, val => toLower(val.value) === importType).value || importType;
        let formatSaleType = !!find(period, val => toLower(val.value) === saleDataType)  && find(period, val => toLower(val.value) === saleDataType).value || saleDataType;
        let formatDestinationType = find(countries, val => toLower(val.value) === destinationGeo) && find(countries, val => toLower(val.value) === destinationGeo).value || destinationGeo;

        return uploadFilePeriod(files[0], id, formatImportType, formatSaleType, formatDestinationType).then(() => {
            setUploading(false);
            setOpenUploadArea(false);

            dispatch(
                displayNotification({
                    message: t('modules.royalty_reporting.upload_sales_data.period_created_uploaded'),
                    type: 'success',
                    timeOut: 5000
                })
            );

            fetchData({ page: 1, pageSize: 1000 });
        }, () => {
            setError(t('modules.royalty_reporting.error'));
            setUploading(false);
        }
        );
    }

    const createNewReport = () => {
        setScreenConfirmed(true);
    }

    const allFieldsCompleted = () => {
        return !!year && !!quarter && !!importType && !!saleDataType && !!destinationGeo
    }

    const isQuarterDisabled = (yearVal, quarterVal) => {
        return salesData.some(
            (item) => {
                return item.year === parseInt(yearVal) && item.quarter === quarterVal
            }
        );
    };

    const handleYearChange = (value) => {
        let quartersArrClone = cloneDeep(quartersArr);

        setYear(value);
        setQuarter('');

        each(quartersArrClone, val => {
            val.isHide = isQuarterDisabled(value, val.label);
        })

        setQuarters(quartersArrClone);
    }

    return (
        <div className='royalty-report-assistant upload-data'>
            <div className="c-my-records__button-container">
                {firstScreenConfirmed && (
                    <a onClick={() => setScreenConfirmed(false)}>
                        <Icon className="back-icon" fill="black" icon="ARROW_LEFT_STYLE_2" size="14" />
                    </a>
                )}
                <h1>{t('modules.royalty_reporting.royalty_report_title')}</h1>
            </div>

            {!firstScreenConfirmed && !uploading &&  (
                <div className='report-widget'>
                    <b>{t('modules.royalty_reporting.upload_sales_data.lets_get_started')}</b>
                    <h2>{t('modules.royalty_reporting.upload_sales_data.upload_sales_data')}</h2>
                    <p>{t('modules.royalty_reporting.upload_sales_data.choose_between')}</p>

                    <div className='period-wrapper'>
                        <CustomSelect
                            onChange={(event) => setImportType(event)}
                            values={wholesale}
                            value={importType}
                            allowEmpty={false}
                            selectplaceholder={t('modules.royalty_reporting.upload_sales_data.shipment_type')}
                        />

                        <CustomSelect
                            onChange={(event) => setSalesDataType(event)}
                            values={period}
                            value={saleDataType}
                            allowEmpty={false}
                            selectplaceholder={t('modules.royalty_reporting.upload_sales_data.wholesale')}
                        />

                        <CustomSelect
                            enableSearch={true}
                            onChange={(event) => setDestinationGeo(event)}
                            values={countries}
                            value={destinationGeo}
                            allowEmpty={false}
                            selectplaceholder={t('modules.royalty_reporting.upload_sales_data.destination_geo')}
                        />

                        {!periodExist  && (<CustomSelect
                            onChange={(event) => handleYearChange(event)}
                            values={years}
                            value={year}
                            allowEmpty={true}
                            selectplaceholder={t('modules.royalty_reporting.upload_sales_data.year')}
                        />)}

                        {!periodExist && (<CustomSelect
                            values={quartersArr}
                            value={quarter}
                            allowEmpty={true}
                            onChange={(event) => setQuarter(event)}
                            disabled={!year}
                            selectplaceholder={t('modules.royalty_reporting.upload_sales_data.quarter')}
                        />)}
                    </div>

                    { openUploadArea && (toArray(salesData).length > 0) && (
                        <Button
                            type="secondary"
                            size="medium"
                            onClick={() => setOpenUploadArea(false)}
                        >
                            {t('buttons.cancel')}
                        </Button>
                    )}

                    <Button
                        type="primary"
                        size="medium"
                        onClick={() => createNewReport()}
                    >
                        {t('buttons.continue')}
                    </Button>
                </div>
            )}

            { firstScreenConfirmed && (
                <div className='upload-sales-data'>
                    <div className='period-wrapper header'>
                        <CustomSelect
                            onChange={(event) => setImportType(event)}
                            values={wholesale}
                            value={importType}
                            allowEmpty={true}
                            selectplaceholder={t('modules.royalty_reporting.upload_sales_data.import_type')}
                            fieldHasErrors={!!error && error.length > 0 && !importType.length}
                        />

                        <CustomSelect
                            onChange={(event) => setSalesDataType(event)}
                            values={period}
                            value={saleDataType}
                            allowEmpty={true}
                            selectplaceholder={t('modules.royalty_reporting.upload_sales_data.data_type')}
                        />

                        <CustomSelect
                            onChange={(event) => setDestinationGeo(event)}
                            values={countries}
                            value={destinationGeo}
                            allowEmpty={true}
                            selectplaceholder={t('modules.royalty_reporting.upload_sales_data.destination_geo')}
                        />

                        {!periodExist && (<CustomSelect
                            onChange={(event) => handleYearChange(event)}
                            values={years}
                            value={year}
                            allowEmpty={false}
                            selectplaceholder={t('modules.royalty_reporting.upload_sales_data.year')}
                        />)}

                        {!periodExist && (<CustomSelect
                            values={quarters}
                            value={quarter}
                            allowEmpty={false}
                            disabled={!year}
                            onChange={(event) => setQuarter(event)}
                            selectplaceholder={t('modules.royalty_reporting.upload_sales_data.quarter')}
                        />)}
                    </div>

                    {!uploading && (
                        <div className='report-widget'>
                            <b>{t('modules.royalty_reporting.upload_sales_data.start_your_product_upload')}</b>
                            <h2>{t('modules.royalty_reporting.upload_sales_data.upload_sales_data')}</h2>
                            <p>{t('modules.royalty_reporting.upload_sales_data.drag_and_drop')}</p>

                            <div className={'c-panel c-panel--curved'}>
                                <div className="c-panel__body">
                                    <div className="c-file-upload">
                                        {!confirm && (
                                            <SmartUploader
                                                onDrop={files => confirmFileDrop(files)}
                                                link={null}
                                                openHelp={null}
                                                className={'c-file-upload__dropzone'}
                                            />
                                        )}

                                        {confirm && !uploading && (
                                            <div className='c-file-upload__dropzone confirmed'>
                                                <div className="c-file-upload__confirm-panel">
                                                    <span
                                                        className="c-file-upload__close-confirm-panel"
                                                        onClick={() => setConfirm(false)}
                                                    >
                                                        <CloseIcon></CloseIcon>
                                                    </span>

                                                    <Icon size={40} icon="csv" fill="cyan" />

                                                    {!!files && files.map((el, i) => (
                                                        <div className="file-name" key={`${el.name}-${i}`}>{el.name}</div>
                                                    ))}

                                                    <Button
                                                        onClick={() => handleFilesDrop(files)}
                                                        size={'small'}
                                                        type={'primary'}
                                                        disabled={!allFieldsCompleted()}
                                                    >
                                                        {t('buttons.upload')}
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {error.length > 0 && (<div className='error-message'>{error}</div>)}
                        </div>
                    )}

                    { uploading && (
                        <div className='report-widget'>
                            <b>{t('modules.royalty_reporting.upload_sales_data.start_your_product_upload')}</b>
                            <h2>{t('modules.royalty_reporting.upload_sales_data.upload_sales_data')}</h2>
                            <p>{t('modules.royalty_reporting.upload_sales_data.drag_and_drop')}</p>

                            <div className='loader-wrapper'>
                                <p>{t('modules.royalty_reporting.upload_sales_data.uploading_data')}</p>
                                <div className='loader'></div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

const mapStateToProps = ({
    xelacore,
    dispatch
}) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(UploadSalesData);