import React, { Suspense } from 'react';
import { Provider, connect } from 'react-redux';
import Raven from 'raven-js';
import { hotjar } from 'react-hotjar';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import config from 'config';
const ravenUrl = `https://${config.sentry.key}@sentry.io/${config.sentry.app}`;
const LoadingFallback = () => <div>Loading...</div>;
const App = connect(
    state => state,
    dispatch => ({ dispatch })
)(({ children }) => {
    return <div role="app">{children}</div>;
});

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie']
        },
        lng: null,
        fallbackLng: 'en',
        backend: {
            loadPath: '/locales/{{lng}}/translation.json'
        },
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: true
        }
    });

export default function AppProvider({ children, store }) {
    if (config.ENV === 'production')
        Raven.config(ravenUrl, { environment: config.env }).install();
    if (config.hotjar && config.hotjar.trackingCode)
        hotjar.initialize(
            config.hotjar.trackingCode,
            config.hotjar.snippetVersion || 6
        );
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <Suspense fallback={<LoadingFallback />}>
                    <App>{children}</App>
                </Suspense>
            </I18nextProvider>
        </Provider>
    );
}
