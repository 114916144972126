import React from 'react';
import classnames from 'classnames';
import { Icon, Tooltip } from 'src/js/components/static';
import { approvedContent } from 'src/js/components/TableRow/common';
import { useTranslation } from 'react-i18next';

const conceptStatus = (record, licensor) => {
    if ((!record.concept_code_confirmed_info || !record.concept_code_confirmed_info.length)) {
        return 'nodata';
    }

    if (licensor && record.concept_code_confirmed_info.length) return 'approved';
    if (licensor && !record.concept_code_confirmed_info.length) return 'nodata';

    const anyConfirmed = record.concept_code_confirmed_info.length > 0;
    // If we have at least one concept_code_confirmed_info(and concept_code_confirmed_by) for any concept code, we consider it as approved.
    // As per the Business rule from Product Team.
    if (anyConfirmed && record.concept_code_confirmed_info.every(ci => ci.concept_code_confirmed_by)) {
        return 'approved';
    }
    return 'linked';
};

export default function TooltipApprovedData({
                                                openProduct,
                                                record,
                                                licensor
                                            }) {
    const { t } = useTranslation();
    let status = conceptStatus(record, licensor);
    if (status === 'linked') status = 'nodata';

    const cxApproved = classnames(
        'c-rec-table__status-icons-icon marg-r-5',
        {
            'c-pointer c-rec-table__status-icons-icon--green': status === 'approved',
            'c-pointer c-rec-table__status-icons-icon--orange': status === 'linked',
            'c-pointer c-rec-table__status-icons-icon--grey': status === 'nodata',
            'c-pointer u-clickable': status === 'approved'
        }
    );

    return (
        <div
            className={cxApproved}
            onClick={() =>
                openProduct()
            }
        >
            <Tooltip
                align={{ offset: [15, 30] }}
                className="c-tooltip__centered"
                button={
                    <span>
                        <Icon icon="link" size="10" fill="white" tooltip />
                    </span>
                }
                overlay={() => approvedContent(t, status)}
            />
        </div>
    );
}
