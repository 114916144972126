import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { getMainImage } from 'src/js/helpers/dataHelpers';
import find from 'lodash/find';
import { isAgent, isLicensor } from 'helpers/permissions';
import { withTranslation } from 'react-i18next';

const DEFAULT_CONCEPT_TYPE = 'Product Concept';

class ApprovalListOfLinkedConcepts extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const {
            conceptInfo = [],
            toggleProductInfo,
            matchingData,
            xelacore,
            t
        } = this.props;

        const { auth: { companyData } = {} } = xelacore;

        return (
            <Fragment>
                <div className="c-product__concept-block__linked_list">
                    {conceptInfo.length && conceptInfo.map(conceptCode => {
                        let findConceptInfo = find(matchingData, concept => {
                            return concept.xelacore_concept_id === conceptCode.xelacore_concept_id
                        });

                        return (
                            <div
                                key={`concept-list-${conceptCode.xelacore_concept_id}`}
                                className="c-product__concept-block__linked_list__item"
                                onClick={() => toggleProductInfo(findConceptInfo || conceptCode)}
                            >
                                <div
                                    style={{ backgroundImage: `url(${getMainImage(conceptCode.images)})` }}
                                    className="c-product__concept-block__linked_list__item__image"
                                />
                                <div className="c-product__concept-block__linked_list__item__info">
                                    <div className="c-product__concept-block__linked_list__item__info__name">
                                        {conceptCode.product_name}
                                    </div>

                                    <div className="c-product__concept-block__linked_list__item__info__other">
                                        {conceptCode.concept_type || DEFAULT_CONCEPT_TYPE }
                                    </div>

                                    {(isLicensor(companyData) || isAgent(companyData)) && (
                                        <div className="c-product__concept-block__linked_list__item__stage-status">
                                            <div><span>{t('modules.product_detail.approval_list_of_linked_concepts.status')}</span> <b className={classnames(
                                                {
                                                    'green': conceptCode.approval_status === 'Fully Approved',
                                                    'orange': conceptCode.approval_status !== 'Fully Approved'
                                                }
                                            )}>{conceptCode.approval_status}</b></div>
                                            <div><span>{t('modules.product_detail.approval_list_of_linked_concepts.status')}</span> <b>{conceptCode.approval_stage}</b></div>
                                        </div>
                                    )}

                                    {!isLicensor(companyData) && !isAgent(companyData) && (
                                        <span
                                            className={classnames(
                                                'c-product__concept-block__linked_list__item__info__other__status',
                                                {
                                                    'is-confirmed': !!conceptCode.confirmed_by,
                                                    'is-unconfirmed': !conceptCode.confirmed_by
                                                }
                                            )}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Fragment>
        );
    }
}

export default withTranslation()(ApprovalListOfLinkedConcepts)
