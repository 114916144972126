import React, { Component } from 'react';
import each from 'lodash/each';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import size from 'lodash/size';
import { deleteConflicts } from 'src/js/actions/conflicts';
import { updateBatch } from 'src/js/apicalls/mixed/myRecords';
import { conflictsList, coreFields } from 'src/js/constants/conflictsConstants';
import Toggler from 'src/js/components/static/Toggler/Toggler';
import Button from 'modules/UiKit/components/Button/Button';
import { hideModal } from 'src/js/actions/xelacore';
import { withTranslation } from 'react-i18next';

class ConflictsFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmation: null
        };
    }

    setDataForUpdate(all) {
        let formatedData = {};

        each(conflictsList, item => {
            if (
                item.key !== 'checkbox' &&
                item.key !== 'registered' &&
                item.key !== 'image'
            ) {
                formatedData.record_id = all.record_id;
                formatedData.version_id = all.version_id;
                formatedData[item.key] = all[item.key] || '';
            }
        });

        return formatedData;
    }

    resolutionType = data => {
        const { cloneData } = this.props;

        var resType = '';
        var initialData = map(cloneData, row => {
            return this.setDataForUpdate(row);
        });
        var initialIndex = findIndex(initialData, item => {
            return item.record_id === data.record_id;
        });

        if (data.gtin_waiver) {
            resType = initialData[initialIndex].mpn === data.mpn;
        } else {
            resType = true;

            each(coreFields, field => {
                if (!isEqual(initialData[initialIndex][field], data[field])) {
                    resType = false;
                }
            });
        }

        return resType;
    };

    updateConflictsFunction = () => {
        const {
            rowsToUpdate,
            updateRows,
            noErrors,
            data,
            handleError,
            dispatch
        } = this.props;

        let idsMap = map(rowsToUpdate, 'record_id');

        let dataToUpdate = map(rowsToUpdate, row => {
            return this.setDataForUpdate(row);
        });

        each(dataToUpdate, data => {
            data.conflicts_resolved_local = this.resolutionType(data);
        });

        updateBatch(dataToUpdate).then(response => {
            this.confirmAction('close');

            if (noErrors(response.data.responses)) {
                updateRows();

                if (includes(idsMap, data.record_id)) {
                    setTimeout(() => {
                        dispatch(hideModal());
                    }, 3000);
                }
            } else {
                handleError(true);
            }
        });
    };

    deleteConflictsFunction = () => {
        const { rowsToUpdate, id, updateRows, data, setConflicts } = this.props;

        let idsMap = map(rowsToUpdate, 'record_id');

        deleteConflicts(id, idsMap).then(() => {
            this.confirmAction('close');
            updateRows();

            if (includes(idsMap, data.record_id)) {
                setTimeout(() => {
                    setConflicts();
                }, 3000);
            }
        });
    };

    confirmAction = label => {
        this.setState({
            confirmation: label === 'close' ? null : label
        });
    };

    render() {
        const { confirmation } = this.state;
        const {
            rowsToUpdate,
            isBusy,
            additionalAttr,
            toggleExtendedAttrs,
            t
        } = this.props;

        return (
            <div className="c-conflicts-popover-footer">
                {!confirmation && (
                    <div className="c-conflicts-popover-footer-default">
                        <div
                            className="c-conflicts-popover-footer-actions u-flex u-flex-between"
                        >
                            <Toggler
                                onToggle={()=>{toggleExtendedAttrs()}}
                                value={additionalAttr[0]}
                                rightTitle={'View more'}
                            />

                            <Button
                                type="secondary-danger-2"
                                size="small"
                                onClick={() => this.confirmAction('delete')}
                                disabled={!size(rowsToUpdate) || isBusy}
                            >
                                {t('buttons.delete_selected')}
                            </Button>
                            
                            <Button
                                type="secondary"
                                size="small"
                                onClick={() => this.confirmAction('confirm')}
                                disabled={!size(rowsToUpdate) || isBusy}
                            >
                                {t('buttons.confirm_information')}
                            </Button>
                        </div>
                    </div>
                )}

                {confirmation && (
                    <div className="c-conflicts-popover-footer-confirmation">
                        <div>
                            <label>
                                {t('modules.my_records.conflicts.are_you_sure_you_want')} {confirmation} {t('modules.my_records.conflicts.selected_records')}
                            </label>
                        </div>
                        <div>
                            <Button
                                type="secondary"
                                size="small"
                                onClick={() => this.confirmAction('close')}>
                                {t('buttons.no')}
                            </Button>
                            <Button
                                type="secondary"
                                size="small"
                                onClick={
                                    confirmation === 'delete'
                                        ? () => this.deleteConflictsFunction()
                                        : () => this.updateConflictsFunction()
                                }
                            >
                                {t('buttons.yes')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(ConflictsFooter)
