import React, { Component } from 'react';
import NoDataMyRecods from './NoDataMyRecods';
import NoDataCatalogue from './NoDataCatalogue';
import { withTranslation } from 'react-i18next';
import Button from 'modules/UiKit/components/Button/Button';

class NoData extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            showUploader,
            toggleUploader,
            toggleDataFormat,
            isMyRecords,
            dispatch,
            t
        } = this.props;

        return (
            <div className="u-text-center u-margin-top-4X">
                <h3 className="c-my-records__no-data-header">
                    {isMyRecords ? t('product_components.no_data.ready_to_register') : t('product_components.no_data.no_products_available')}
                </h3>

                {isMyRecords && (
                    <NoDataMyRecods
                        showUploader={showUploader}
                        toggleUploader={toggleUploader}
                        toggleDataFormat={toggleDataFormat}
                        dispatch={dispatch}
                    />
                )}
                {!isMyRecords && (
                    <NoDataCatalogue
                        showUploader={showUploader}
                        toggleUploader={toggleUploader}
                    />
                )}


                {!showUploader && (
                    <Button
                        type="secondary"
                        size="small"
                        onClick={() => toggleUploader()}>
                        {t('buttons.add_products')}
                    </Button>

                )}
            </div>
        );
    }
}

export default withTranslation()(NoData)
