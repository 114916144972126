import {listOfTargetMarketsByCode} from '../../constants/countries';

export const licenseeFilter = [
    {
        key: 'Alerts',
        collapse: true,
        keyName: 'alerts',
        subgroups: [
            {
                key: 'Errors',
                color: 'red',
                noChidren: true,
                takeHeader: true,
                className: 'u-color-bright-red',
                keyName: 'errors'
            },
            {
                key: 'Warnings',
                color: 'orange',
                noChidren: true,
                takeHeader: true,
                className: 'u-color-light-orange',
                keyName: 'warnings'
            },
            {
                key: 'Conflicts',
                color: 'orange',
                noChidren: true,
                takeHeader: true,
                className: 'u-color-light-orange',
                keyName: 'conflicts'
            }
        ]
    },
    // {
    //     key: 'Licensor',
    //     collapse: true,
    //     search: true,
    //     limit: true,
    //     showSearch: true
    // },
    {
        key: 'GTIN',
        collapse: true,
        search: true,
        onlySearch: true,
        field: 'gtin',
        keyName: 'gtin'
    },
    {
        key: 'Product Name',
        collapse: true,
        search: true,
        onlySearch: true,
        field: 'product_name',
        keyName: 'product_name'

    },
    {
        key: 'Statuses',
        collapse: true,
        keyName: 'statuses',
        subgroups: [
            /*{
                key: 'Validation',
                subcategoryTitle: true,
                icon: 'validation',
                collapse: true,
                showSearch: false,
                noChidren: true
            },*/
            {
                key: 'Product Status',
                subcategoryTitle: true,
                icon: 'validation',
                collapse: true,
                showSearch: true,
                noChidren: true,
                keyName: 'product_status'
            },
            {
                key: 'Manufacturing Status',
                subcategoryTitle: true,
                icon: 'status',
                collapse: true,
                showSearch: true,
                noChidren: true,
                keyName: 'manufacturing_status'
            },
            {
                key: 'Registration',
                subcategoryTitle: true,
                icon: 'share',
                collapse: true,
                showSearch: false,
                noChidren: true,
                specialValues: {
                    1: 'Registered With Licensors',
                    0: 'Not Registered With Licensors'
                },
                keyName: 'registration'
            },
            {
                key: 'Actions',
                subcategoryTitle: true,
                icon: 'status',
                collapse: true,
                showSearch: true,
                noChidren: true,
                specialValues: {
                    0: 'Ready To Register'
                },
                keyName: 'actions'
            }
        ]
    },
    {
        key: 'Product Information',
        collapse: true,
        keyName: 'product_information',
        subgroups: [
            {
                key: 'Target Market',
                subcategoryTitle: true,
                collapse: true,
                showSearch: true,
                noChidren: true,
                specialValues: listOfTargetMarketsByCode(),
                keyName: 'target_market'
            },
            {
                key: 'Other IP',
                subcategoryTitle: true,
                collapse: true,
                showSearch: true,
                noChidren: true,
                keyName: 'other_ip'
            }
        ]
    }
];

export const licenseeCatalogueFilter = [
    // {
    //     key: 'Licensor',
    //     collapse: true,
    //     search: true,
    //     limit: true,
    //     showSearch: true
    // },
    {
        key: 'GTIN',
        collapse: true,
        search: true,
        onlySearch: true,
        field: 'gtin',
        keyName: 'gtin'
    },
    {
        key: 'Product Name',
        collapse: true,
        search: true,
        onlySearch: true,
        field: 'product_name',
        keyName: 'product_name'

    },
    {
        key: 'Statuses',
        collapse: true,
        keyName: 'statuses',
        subgroups: [
            {
                key: 'Product Status',
                subcategoryTitle: true,
                icon: 'validation',
                collapse: true,
                showSearch: true,
                noChidren: true,
                keyName: 'product_status'
            },
            {
                key: 'Manufacturing Status',
                subcategoryTitle: true,
                icon: 'status',
                collapse: true,
                showSearch: true,
                noChidren: true,
                keyName: 'manufacturing_status'
            },
            {
                key: 'Concept',
                subcategoryTitle: true,
                icon: 'status',
                collapse: true,
                showSearch: false,
                noChidren: true,
                specialValues: {
                    1: 'Linked to Concept(s)',
                    0: 'Not Linked to Concept(s)'
                },
                keyName: 'concept'
            }
        ]
    },
    {
        key: 'Product Information',
        collapse: true,
        keyName: 'product_information',
        subgroups: [
            {
                key: 'Target Market',
                subcategoryTitle: true,
                collapse: true,
                showSearch: true,
                noChidren: true,
                specialValues: listOfTargetMarketsByCode(),
                keyName: 'target_market'
            },
            {
                key: 'Other IP',
                subcategoryTitle: true,
                collapse: true,
                showSearch: true,
                noChidren: true,
                keyName: 'other_ip'
            }
        ]
    }
];

export const licensorFilter = [
    {
        key: 'Licensee',
        collapse: true,
        search: true,
        limit: true,
        showSearch: true,
        keyName: 'licensee'
    },
    {
        key: 'GTIN',
        collapse: true,
        search: true,
        onlySearch: true,
        field: 'gtin',
        keyName: 'gtin'
    },
    {
        key: 'Product Name',
        collapse: true,
        search: true,
        onlySearch: true,
        field: 'product_name',
        keyName: 'product_name'

    },
    {
        key: 'Statuses',
        keyName: 'statuses',
        collapse: true,
        subgroups: [
            {
                key: 'Product Status',
                subcategoryTitle: true,
                icon: 'validation',
                collapse: true,
                showSearch: true,
                noChidren: true,
                keyName: 'product_status'
            },
            {
                key: 'Manufacturing Status',
                subcategoryTitle: true,
                icon: 'status',
                collapse: true,
                showSearch: true,
                noChidren: true,
                keyName: 'manufacturing_status'
            },
            {
                key: 'Concept',
                subcategoryTitle: true,
                icon: 'status',
                collapse: true,
                showSearch: false,
                noChidren: true,
                specialValues: {
                    1: 'Linked to Concept(s)',
                    0: 'Not Linked to Concept(s)'
                },
                keyName: 'concept'
            }
        ]
    },
    {
        key: 'Product Information',
        collapse: true,
        keyName: 'product_information',
        subgroups: [
            {
                key: 'Target Market',
                subcategoryTitle: true,
                collapse: true,
                showSearch: true,
                noChidren: true,
                specialValues: listOfTargetMarketsByCode(),
                keyName: 'target_information'
            },
            {
                key: 'Other IP',
                subcategoryTitle: true,
                collapse: true,
                showSearch: true,
                noChidren: true,
                keyName: 'other_ip'
            }
        ]
    }
];

export const AGENT_FILTERS = [
    'licensor',
    'company',
    'gtin',
    'product_name',
    'product_status',
    'manufacturing_status',
    'is_concept',
    'product_category',
    'target_market',
    'licensor_brand',
    'licensor_other_ip'
  ]
  
