import React from 'react';
import RequestHeader from './RequestHeader';
import ContractDetailsBlock from './ContractDetailsBlock';

const RequestDetailsWrapper = ({ data, onUpdateContractDetails }) => {
    if (!data) return null;

    const trademarkRequest = data.trademarkRequest || {};

    return (
        <div className="request-details-wrapper">
        <div className="request-details-container">
            <RequestHeader data={data} />
            <ContractDetailsBlock
                contractDetails={trademarkRequest.contract_details || []}
                onUpdateContractDetails={onUpdateContractDetails}
            />
        </div>
        </div>
    );
};

export default RequestDetailsWrapper;
