import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';

export function fetchAuthorizationRequests(params = {}) {
    const queryParams = {
        page: params.page || 1,
        limit: params.page_size || 999
    };

    return axios
        .request({
            url: [config.baseUrl, 'trademark-letter/api/requests'].join('/'),
            method: 'get',
            params: queryParams
        })
        .then(responseHandler);
}

export function fetchAuthorizationRequest(requestId) {
    return axios
        .request({
            url: [config.baseUrl, 'trademark-letter/api/requests', requestId].join('/'),
            method: 'get'
        })
        .then(response => {
            if (!response || !response.data) {
                throw new Error('Invalid response received');
            }
            const handledResponse = responseHandler(response);
            if (!handledResponse || !handledResponse.trademarkRequest) {
                throw new Error('Invalid trademark request data');
            }
            return handledResponse;
        })
        .catch(error => {
            throw error;
        });
}

export function submitAuthorizationRequest(data) {
    return axios
        .request({
            url: [config.baseUrl, 'trademark-letter/api/requests'].join('/'),
            method: 'post',
            data
        })
        .then(response => responseHandler(response, null, null, {
            checkSuccess: true,
            checkErrorsMessage: 'Authorization request submission'
        }));
}

export function updateAuthorizationRequest(requestId, data = {}) {
    return axios
        .request({
            url: [config.baseUrl, 'trademark-letter/api/requests', requestId, 'update'].join('/'),
            method: 'put',
            data: {
                ...data
            }
        })
        .then(responseHandler);
}

export function generateAuthorizationPreview(data) {
    return axios
        .request({
            url: [config.baseUrl, 'trademark-letter/api/preview'].join('/'),
            method: 'post',
            data,
            responseType: 'arraybuffer'
        })
        .then(response => {
            if (response.status >= 400) {
                throw new Error('Failed to generate preview');
            }
            const blob = new Blob([response.data], { type: 'application/pdf' });
            return window.URL.createObjectURL(blob);
        });
}

export function updateAuthorizationStatus(requestId, data) {
    return axios
        .request({
            url: [config.baseUrl, 'trademark-letter/api/requests', requestId, 'status'].join('/'),
            method: 'put',
            data
        })
        .then(response => {
            if (response.status === 200) {
                return response.data;
            }
            return responseHandler(response, null, null, {
                checkSuccess: true,
                checkErrorsMessage: 'Status update'
            });
        });
}

export function downloadAuthorizationLetter(requestId) {
    return axios
        .request({
            url: [config.baseUrl, 'trademark-letter/api/requests', requestId, 'download'].join('/'),
            method: 'get',
            responseType: 'arraybuffer'
        })
        .then(response => {
            if (response.status >= 400) {
                throw new Error('Failed to download letter');
            }
            const blob = new Blob([response.data], { type: 'application/pdf' });
            return window.URL.createObjectURL(blob);
        });
}

export function deleteAuthorizationRequest(requestId) {
    return axios
        .request({
            url: [config.baseUrl, 'trademark-letter/api/requests', requestId].join('/'),
            method: 'delete'
        })
        .then(response => responseHandler(response, null, null, {
            checkSuccess: true,
            checkErrorsMessage: 'Authorization request deletion'
        }));
}

export function previewAuthorizationLetter(requestId) {
    return axios
        .request({
            url: [config.baseUrl, 'trademark-letter/api/requests', requestId, 'preview-pdf'].join('/'),
            method: 'get',
            responseType: 'arraybuffer'
        })
        .then(response => {
            if (response.status >= 400) {
                throw new Error('Failed to generate preview');
            }
            const blob = new Blob([response.data], { type: 'application/pdf' });
            return window.URL.createObjectURL(blob);
        });
}

export function updateDraftLetter(requestId, data = {}) {
    return axios
        .request({
            url: [config.baseUrl, 'trademark-letter/api/requests', requestId, 'update-draft-letter'].join('/'),
            method: 'put',
            data: {
                ...data
            }
        })
        .then(responseHandler);
}

export function generateDraftLetter(requestId, data = {}) {
    return axios
        .request({
            url: [config.baseUrl, 'trademark-letter/api/requests', requestId, 'generate-draft-letter'].join('/'),
            method: 'post',
            data: {
                ...data
            }
        })
        .then(responseHandler);
}

