import React, { useEffect, useState } from 'react';
import toArray from 'lodash/toArray';
import classNames from 'classnames';
import AggregatedFilters from '../components/AggregatedFilters';
import { ReactComponent as FilterIcon } from 'components/static/Icon/svg-icons/filter-bars.svg';
import { ReactComponent as ExportListIcon } from 'components/static/Icon/svg-icons/export-list-icon.svg';
import {
    FloatingMenu,
    Modal,
    Auth
} from 'src/js/components/static';
import uniqBy from 'lodash/uniqBy';
import { showModal } from 'src/js/actions/xelacore';
import DragAndDropProductExport from 'components/DragAndDropProductExport/DragAndDropProductExport';
import Button from 'modules/UiKit/components/Button/Button';
import { isAgent } from 'helpers/permissions';
import { selectAll as selectAllFunction } from 'src/js/actions/productsFetch';
import { ReactComponent as CustomiseIcon } from 'components/static/Icon/svg-icons/customise-table.svg';
import CustomiseTableHeader from './CustomiseTableHeader';
import { changeHeaders } from 'src/js/actions/userSettings';
import { ReactComponent as Tile } from 'components/static/Icon/svg-icons/tile.svg';
import { ReactComponent as List } from 'components/static/Icon/svg-icons/list.svg';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import size from 'lodash/size';
import { useTranslation } from 'react-i18next';

export default function HeaderSection({
    aggregations,
    headersArr,
    showFilters,
    filtersObj,
    filterTable,
    closeFilters,
    clearFilters,
    toggleFilters,
    selectedRows,
    companyData,
    updateTable,
    dispatch,
    isMyRecords,
    categoryLevels,
    headerLayouts,
    headers,
    activeHeaders,
    dontShowRedirectModal,
    selectAll,
    setLayout,
    layoutType,
    data
}){
    const { t } = useTranslation();
    const [selectedPPTItems, setSelectedPPTItems] = useState([]);
    const [showHeaderOptions, setShowHeaderOptions] = useState(false);
    const showHeaderSettings =
        headerLayouts &&
        headerLayouts.length > 0 &&
        !(headerLayouts.length === 1 && headerLayouts[0].isDefault);

    const handleScroll = () => {
        const headerBoxElement = document.getElementById('v3_table_header');
        const headerBoxElementWrapper = document.getElementById('v3_table_header').parentElement;
        if (window.scrollY > 80) {
            headerBoxElement.classList.add('sticky');
            headerBoxElementWrapper.style.paddingTop = '50px';
        } else {
            headerBoxElement.classList.remove('sticky');
            headerBoxElementWrapper.style.paddingTop = '0';
        }
    }

    const scrollHandlerBound = handleScroll.bind();

    useEffect(() => {
        const presavedCatalogueRows = JSON.parse(localStorage.getItem('presavedCatalogueRows')) || [];

        document.addEventListener('scroll', scrollHandlerBound);
        setSelectedPPTItems(presavedCatalogueRows);

        return () => {
            document.removeEventListener('scroll', scrollHandlerBound);
            document.getElementById('siteHeader').style.boxShadow = '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
        };
    }, [])

    const addToExportSelection = () => {
        let newSelection = selectedPPTItems.concat(selectedRows);
        let addedRowsList = uniqBy(newSelection, 'record_id');
        sessionStorage.setItem('presavedCatalogueRows', JSON.stringify(addedRowsList));
        setSelectedPPTItems(addedRowsList);
        dispatch(selectAllFunction([], false, []));
    }

    const openExportPPT = () => {
        const modal = (
            <Modal
                title={t('table_v3.header_section.export_to_ppt')}
                className="c-bulk-update__modal draggable-ppt-export-modal"
                dispatch={dispatch}
                body={
                    <DragAndDropProductExport
                        licensor={isAgent(companyData)}
                        rows={selectedPPTItems}
                        fetchData={updateTable}
                        resetSelectedRows={() => setSelectedPPTItems([])}
                    />
                }
            />
        );
        return dispatch(showModal(modal));
    }

    const toggleHeaderOptions = () => {
        if (!showHeaderOptions) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
        setShowHeaderOptions(!showHeaderOptions);
    }

    const fetchCheckboxSelector = () => {
        return size(selectedRows) === size(data)
            ? dispatch(selectAllFunction([], false, []))
            : dispatch(selectAllFunction(uniqBy([...selectedRows, ...data], 'record_id'), false, uniqBy([...selectedRows, ...data], 'record_')))
    };


    return (
        <div>
            <div className="ui-table__panel-nav align-left" id={'v3_table_header'}>
                {layoutType === 'grid' && (
                    <div className="checkbox-holder u-margin-half-right">
                        <UiCheckbox
                            type="checkbox"
                            label={size(selectedRows) === size(data) ? t('buttons.unselect_all') : t('buttons.select_all')}
                            checked={size(selectedRows) === size(data)}
                            onClick={() => fetchCheckboxSelector()}>
                        </UiCheckbox>
                    </div>
                )}

                <AggregatedFilters
                    aggregations={aggregations || []}
                    showFilters={showFilters}
                    headersArr={headersArr || {}}
                    filtersObj={filtersObj || {}}
                    filterTable={filterTable}
                    closeFilters={closeFilters}
                    clearFilters={clearFilters}
                    toggleFilters={toggleFilters}>
                </AggregatedFilters>

                <Button
                    type="link-primary"
                    size="small"
                    onClick={() => toggleFilters()}
                    iconLeft={
                        <FilterIcon height="14"></FilterIcon>
                    }
                >
                    <div className="c-rec-panel__nav__content">
                        {t('buttons.filters')}
                        {!!filtersObj && toArray(filtersObj).length > 0 && (
                            <div className="c-rec-panel__filters_counter">
                                <span>{toArray(filtersObj).length}</span>
                            </div>
                        )}
                    </div>
                </Button>

                <Auth restrictTo="agent">
                    <Button
                        disabled={!((!isMyRecords && selectedRows.length > 0) &&
                            ((selectedPPTItems.length + selectedRows.length) < 101))}
                        type="link-primary"
                        size="small"
                        tooltip={!((!isMyRecords && selectedRows.length > 0) &&
                            ((selectedPPTItems.length + selectedRows.length) < 101)) ? t('table_v3.header_section.select_the_records') : null}
                        onClick={() => addToExportSelection()}
                        iconLeft={
                            <ExportListIcon height="14"></ExportListIcon>
                        }
                    >
                        <div className="c-rec-panel__nav__content">
                            {t('buttons.add_to_export')}
                        </div>

                    </Button>
                </Auth>

                <Auth restrictTo="agent">
                    {(!isMyRecords && selectedPPTItems.length > 0) && (
                        <div>
                            <Button
                                type="link-primary"
                                size="small"
                                className="marg-l-10"
                                onClick={() => openExportPPT()}
                            >
                                <span className="counter">{selectedPPTItems.length}</span>

                                <span className="counter-label">{t('buttons.export')}</span>
                            </Button>
                        </div>
                    )}
                </Auth>

                <div className="align-right">
                    {showHeaderSettings && layoutType !== 'grid' && (
                        <div className="c-rec-panel__nav-item">
                            <FloatingMenu
                                top={35}
                                customButton={
                                    <div
                                        style={{
                                            margin: '-20px',
                                            padding: '20px'
                                        }}
                                    >
                                        <span>
                                            {t('table_v3.customise_bottom_block.viewing')}{' '}
                                            <span className="u-color-blue">
                                                {activeHeaders
                                                    ? activeHeaders
                                                    : t('buttons.default_headers')}
                                            </span>
                                        </span>
                                    </div>
                                }
                            >
                                {headerLayouts.map((el) => {
                                    const newEl = Object.assign(
                                        {},
                                        el,
                                        {
                                            isFromList: true
                                        }
                                    );
                                    const { name } = newEl;
                                    const { isDefaultHeader } =
                                        headers;
                                    return (
                                        <li
                                            className={`${activeHeaders ===
                                                    name ||
                                                    (el.name ===
                                                        'Default Headers' &&
                                                        isDefaultHeader)
                                                    ? 'active'
                                                    : ''
                                                }`}
                                            key={name}
                                            onClick={() =>
                                                dispatch(
                                                    changeHeaders({
                                                        names: newEl,
                                                        headerLabel: name,
                                                        activeHeaders,
                                                        headers,
                                                        headerLayouts,
                                                        dontShowRedirectModal
                                                    })
                                                )
                                            }
                                        >
                                            {name}
                                        </li>
                                    );
                                })}
                            </FloatingMenu>
                        </div>
                    )}

                    { layoutType === 'table' && (<Button
                        iconLeft={
                            <CustomiseIcon />
                        }
                        type="link-primary"
                        size="small"
                        onClick={() =>
                            toggleHeaderOptions()
                        }
                    >
                        {t('buttons.customise_table')}
                    </Button>)}


                    <div className={`view-select-block ${showHeaderOptions ? '-disabled' : ''}`}>
                        <Tile
                            onClick={() => setLayout('grid')}
                            className={classNames('clickable-icon', {
                                '-active': layoutType === 'grid'
                            })}
                        ></Tile>
                        <List
                            onClick={() => setLayout('table')}
                            className={classNames('clickable-icon', {
                                '-active': layoutType === 'table'
                            })}
                        ></List>
                    </div>

                </div>
            </div>

            {(!isMyRecords && ((selectedPPTItems.length + selectedRows.length) > 100)) && (
                <div className={'header-messages'}>
                    <p>{t('table_v3.header_section.up_to_products_export')}</p>
                </div>
                )}

                <div
                    className={classNames('customized-table-header-holder', {
                        '-open': showHeaderOptions
                    })}
                >
                    {showHeaderOptions && (
                        <CustomiseTableHeader
                            headers={headers}
                            dispatch={dispatch}
                            companyData={companyData}
                            dontShowRedirectModal={dontShowRedirectModal}
                            selectAll={selectAll}
                            headerLayouts={headerLayouts}
                            activeHeaders={activeHeaders}
                            categoryLevels={categoryLevels}
                            isMyRecords={isMyRecords}
                            toggleHeaderOptions={() =>
                                toggleHeaderOptions()
                            }
                        />
                    )}
                </div>
        </div>
    );
}
