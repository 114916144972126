import Button from 'modules/UiKit/components/Button/Button';
import React from 'react';
import { ReactComponent as NoFilterIcon } from 'src/js/components/static/Icon/svg-icons/no-filter.svg';
import { useTranslation } from 'react-i18next';

const AdvancedFilteringFiltersEmptyBody = ({ resetState }) => {
    const { t } = useTranslation();

    return (
        <div className="c-advanced-filtering__body u-flex-column u-flex-gap">
            <div className="u-full-width u-flex u-flex-align--center u-margin-bottom">
                <NoFilterIcon className="c-advanced-filtering__body__no-filter-icon"></NoFilterIcon>
            </div>
            <h3 className="u-text-center u-margin-bottom" dangerouslySetInnerHTML={{ __html: t('filters.no_result') }}>
            </h3>
            <p className="u-text-center u-margin-bottom">
                {t('filters.clear_any_applied_filter')}
            </p>
            <div className="u-full-width u-flex u-flex-align--center">
                <Button
                    size={'small'}
                    type={'secondary'}
                    onClick={() => resetState()}>
                    {t('buttons.clear_filters')}
                </Button>
            </div>
        </div>
    );
};

export default AdvancedFilteringFiltersEmptyBody;
