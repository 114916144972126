import React, { Component } from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import filter from 'lodash/filter';
import clone from 'lodash/clone';
import each from 'lodash/each';
import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';
import size from 'lodash/size';
import toArray from 'lodash/toArray';

import {
    fetchCategories
} from 'src/js/apicalls/other/categories';
import FormItemWrapper from 'modules/UiKit/components/FormElements/FormItem';

const mapStateToProps = ({ xelacore }) => ({ xelacore });
const mapDispatchToProps = (dispatch) => ({ dispatch });

@connect(mapStateToProps, mapDispatchToProps)
export default class DynamicCategory extends Component {
    constructor(props) {
        super(props);

        const { value = [] } = props;
        this.state = {
            value: value || [],
            categoryLevels: [],
            categories: [],
            groupedCategories: {
                parent: []
            },
            parentCategory: []
        };
    }

    componentDidMount() {
        const { reliesOn } = this.props;
        if (!reliesOn) return this.fetchData();
    }

    fetchData() {
        const { xelacore } = this.props;
        const {
            auth: {
                token,
                companyData: { organisation_id }
            }
        } = xelacore;

        fetchCategories(organisation_id, token).then((response) => {
            this.setState({
                categories: response.data && response.data.categories,
                categoryLevels: response.data && response.data.category_levels
            });

            this.groupCategories();
        });
    }

    groupCategories() {
        const { categories, groupedCategories } = this.state;
        let newArr = cloneDeep(groupedCategories);

        each(categories, category => {
            let catId = !!category.parent_category_id && category.parent_category_id || '';
            let formattedCategory = {
                label: category.string_path[category.string_path.length - 1],
                name: category.category_id
            };
            let level = category.parent_category_id === null ? 'parent' : catId;
            let categoryExists = find(newArr[level], level => {
                return level.name === formattedCategory.name;
            });

            newArr[catId] = newArr[catId] || [];

            if (!categoryExists) {
                newArr[level].push(formattedCategory);
            }
        });

        this.fillValues();
        this.setState({
            groupedCategories: newArr
        });
    }

    fillValues() {
        const { categoryLevels, value, parentCategory } = this.state;

        each(categoryLevels, (cat, i) => {
            let findCategory = !!this.findCategory(value[i], i, value[i - 1]) && this.findCategory(value[i], i, value[i - 1]);

            if (i === 0) {
                value[i] = !!value[i] && value[i] || '';
                parentCategory[i] = findCategory.category_id || '';
            } else {
                value[i] = !!value[i] && !!value[i - 1] && value[i] || '';
                parentCategory[i] = !!parentCategory[i - 1] && findCategory.category_id || '';
            }
        });

        return this.setState({
            value,
            parentCategory
        });
    }

    findCategory(path, i, parent) {
        const { categories } = this.state;

        return find(categories, cat => {
            let paths = cat.string_path;

            return i === 0
                ? paths[i] === path && paths.length === 1
                : paths[i] === path && !!includes(paths, parent) && paths.length === i + 1;
        });
    }

    handleChange(e, key) {
        const { onChange } = this.props;
        const { value, parentCategory, groupedCategories } = this.state;

        let newValue = clone(value);
        let dataName = '';
        let filteredIds = [];
        let categoriesToFind = key === 0 ? groupedCategories['parent'] : groupedCategories[parentCategory[key - 1]] || [];

        let assignableCategory = find(categoriesToFind, category => {
            return category.name === e;
        });

        each(newValue, (val, i) => {
            if (i > key) {
                newValue[i] = '';
                parentCategory[i] = '';
            }
        });

        newValue[key] = !!assignableCategory && assignableCategory.label || '';
        parentCategory[key] = !!assignableCategory && assignableCategory.name || '';

        filteredIds = filter(parentCategory, val => {
            return !!val;
        });

        dataName = key === 0 && filteredIds.length > 1
            ? 'licensee_category_root_id'
            : 'licensee_category_id';

        this.setState({
            value: newValue,
            parentCategory
        });

        onChange(
            key > 0
                ? !!filteredIds[filteredIds.length - 1] && filteredIds[filteredIds.length - 1]
                : e,
            dataName,
            newValue
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
            this.fetchData();
        }
    }

    render() {
        const { value, categoryLevels, groupedCategories, parentCategory } = this.state;
        const { validations, disabled } = this.props;
        return (
            <div className="">
                {categoryLevels.map((level, key) => {
                    let parent = !!groupedCategories && groupedCategories.parent || [];
                    let categoryLevel = !!groupedCategories[parentCategory[key - 1]] && groupedCategories[parentCategory[key - 1]] || [];
                    let valuesArray = key === 0 ? parent : categoryLevel;
                    let disabledAttr = key > 0 && categoryLevel.length === 0 || key > 0 && !size(parentCategory[key - 1]) || disabled;

                    let validation = find(validations, item => item.field === `licensee_category_path.${key}`);
                    let validationExists = !!validation && validation.level && !size(parentCategory[key]) || !size(parentCategory[key - 1]);
                    let error = validationExists && !!value && !!this.props.value && (value[key] === this.props.value[key])
                        ? !!validation && validation.level
                        : '';

                    return (
                        <div className="dynamic-category-wrapper"
                             key={level + key}>
                            <FormItemWrapper
                                type="select"
                                vertical={false}
                                label={level}
                                disabledElement={disabledAttr}
                                enableSearch={!!valuesArray && toArray(valuesArray).length >= 5}
                                options={valuesArray}
                                values={valuesArray}
                                allowEmpty={false}
                                value={parentCategory[key]}
                                onSelect={(e) => this.handleChange(e, key)}
                                onChange={(e) => this.handleChange(e, key)}
                                isMultiple={false}
                                isMulti={false}
                                entityName={value[key]}
                                id={level + key}
                                validations={validations}
                                itmHasError={!!error}
                            />

                            {!!error && (
                                <div className="validation-box">
                                    <p>{validation.message}</p>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}
