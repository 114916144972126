import {
    countableRange,
    booleanCase,
    searchableFileds,
    actionsFilter
} from 'src/js/constants/advancedFilteringConstants';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';
import without from 'lodash/without';
import map from 'lodash/map';
import each from 'lodash/each';
import isArray from 'lodash/isArray';

export const sanitizeValue = (value) => {
    const regex_to_remove = /[\<\>]/gm;
    const regex_to_escape = /([\+\-\=\&\|\!\(\)\[\]\^\"\~\*\?\:\\\/])/gm;
    const escape = '$1';
    const blank = '';
    const value_cleared = value.replace(regex_to_remove, blank);

    return value_cleared.replace(regex_to_escape, escape);
};

export const parsedNewFilters = (key, event, basicFilterObj) => {
    const val = get(event, 'target.value') || event;
    let newFilters = { ...basicFilterObj };

    if (newFilters[key] || !val) delete newFilters[key];
    if (val) newFilters[key] = getNewFiltersValue(key, sanitizeValue(val));

    return newFilters;
};

export const parsedNewQuery = (key, event, queryObject, categoryLevels) => {
    const val = get(event, 'target.value') || event;
    let newQueryObj = cloneDeep(queryObject);
    let musts = get(newQueryObj, 'query.query.bool.must', []).filter(
        (item) => !item.query_string || item.query_string.default_field !== key
    );

    musts.push(buildFilterObject(key, !!val && sanitizeValue(val), categoryLevels));
    newQueryObj.query.query.bool.must = musts;

    return newQueryObj;
};

export const parseSearchItems = (newFilters) => {
    return without(
        map(newFilters, (filter, key) => {
            if (includes(searchableFileds, key)) {
                return {
                    label:
                        filter.query_string &&
                        filter.query_string.default_field,
                    value:
                        filter.query_string &&
                        filter.query_string.query.replace(/\*/g, '')
                };
            }
        }),
        undefined
    );
};

export const filterInitialization = (
    parsedUrlFilterParam,
    newFilters,
    newQueryObj,
    categoryLevels,
    licensor
) => {
    each(parsedUrlFilterParam, (value, key) => {
        if (value && value.length > 1) {
            each(value, (item) => {
                newFilters[key].push(...getNewFiltersValue(key, item));
                newQueryObj.query.query.bool.must.push(
                    buildFilterObject(key, item, categoryLevels)
                );
            });
        } else {
            const desc = searchableFileds.includes(key)
                ? parsedUrlFilterParam[key].query_string.query
                : isArray(parsedUrlFilterParam[key])
                ? parsedUrlFilterParam[key][0]
                : parsedUrlFilterParam[key];
            const isMust = 'must';

            newFilters[key] = getNewFiltersValue(key, desc);
            newQueryObj.query.query.bool[isMust].push(
                buildFilterObject(key, desc, categoryLevels, licensor)
            );
        }
    });
};

export const formatFilterObject = (object, levels, licensor) => {
    each(!!object && object.query && object.query.aggregations, (item, key) => {
        if (key === 'Category') {
            each(levels, (level, i) => {
                object.query.aggregations[level] = {
                    terms: {
                        field: `${licensor ? 'licensor' : 'licensee'}_category_path.${i}.keyword`,
                        size: '250'
                    },
                    meta: {
                        field: level
                    }
                }
            })
        }
    })

    return object;
}

export const buildFilterObject = (key, desc, categoryLevels, licensor) => {
    if (searchableFileds.includes(key))
        return { query_string: { query: desc, default_field: key } };
    if (countableRange.includes(key)) return { range: { [key]: { gte: 1 } } };
    if (booleanCase.includes(key)) return { term: { [key]: !!desc } };

    if (!!categoryLevels && key === categoryLevels[0]) {
        if (licensor) {
            return {
                term: {
                    'licensor_category_path.0.keyword': desc
                }
            }
        } else {
            return {
                term: {
                    'licensee_category_path.0.keyword': desc
                }
            }
        }
    }

    if (key === 'Actions') {
        return actionsFilter
    }

    return { term: { [key]: desc } };
};

export const getNewFiltersValue = (key, desc) =>
    countableRange.includes(key)
        ? { gte: 1 }
        : searchableFileds.includes(key)
        ? { query_string: { query: desc, default_field: key } }
        : booleanCase.includes(key)
        ? [!!desc]
        : [desc];

export const filterByObj = (filterValue, key, desc, isEqual) => {
    const operations = {
        '===': function (operand1, operand2) {
            return operand1 === operand2;
        },
        '!==': function (operand1, operand2) {
            return operand1 !== operand2;
        }
    };

    const operator = isEqual ? operations['==='] : operations['!=='];

    if (countableRange.includes(key)) {
        const filtered = filterValue.range && filterValue.range[key];
        return isEqual ? !!filtered : !filtered;
    }

    if (booleanCase.includes(key))
        return filterValue.term && operator(filterValue.term[key], !!desc);
    
    if (key === 'Actions') {
        return filterValue.bool && filterValue.bool.must && filterValue.bool.must[0].bool.should && filterValue.bool.must[0].bool.should[1].term.conflicts_resolved_local !== undefined;
    }

    return filterValue.term && operator(filterValue.term[key], desc);
};

export const getCorrectValue = (name, takeHeader, key, specialValues, keyName, t) => {
    if (takeHeader) return t(`constants.filter_constants.${keyName}`);
    if (specialValues && specialValues[key]) return specialValues[key];
    return key;
};

export const addLevelstoQuery = (queryObject, levels) => {
    each(levels, level => {
        queryObject.query.aggregations[level.level_name] = {
            terms: {
                field: `ips.${level.ip_level_id}`,
                size: 250
            },
            meta: {
                field: `ips.${level.ip_level_id}`
            }
        }
    })

    return queryObject;
}
