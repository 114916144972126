import React, { useEffect, useState } from 'react';
import { getEsData } from 'src/js/actions/esData';
import {
    fetchLinkedLicensors,
    fetchAgentLicensors,
    fetchAgentLicensorsCatalog
} from 'src/js/apicalls/mixed/myRecords';

import {
    parsedNewFilters,
    parsedNewQuery
} from 'src/js/components/AdvancedFiltering/AdvancedFilteringHelpers';

import find from 'lodash/find';
import each from 'lodash/each';
import toArray from 'lodash/toArray';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import get from 'lodash/get';

import Button from 'modules/UiKit/components/Button/Button';
import { isAgent } from 'src/js/helpers/permissions';
import { parseV1Response } from 'src/js/helpers/arrays';
import { useTranslation } from 'react-i18next';

export default function SelectLicensor({
    xelacore,
    selectLicensor,
    dispatch,
    type,
    updateFiltersState,
    queryObject,
    basicFilterObj,
    categoryLevels,
    isMyRecords
}) {
    const { t } = useTranslation();

    const [licensors, setLicensors] = useState([]);
    const [pageTitle] = useState(isMyRecords ? t('select_licensor.my_records') : t('select_licensor.product_catalogue'));
    const [pageDescription] = useState(isMyRecords ? t('select_licensor.select_view_records') : t('select_licensor.select_view_products'));
    const orgId = xelacore.auth.companyData.organisation_id;
    const companyData = xelacore.auth.companyData;

    useEffect(() => {
        fetchInfo();
    }, []);

    const selectLicensorFilter = (licensor) => {
        let clonedQueryObject = cloneDeep(queryObject);
        clonedQueryObject.query.query.bool.must = filter(get(queryObject, 'query.query.bool.must', []), (item) => !!item && !!item.query_string && item.query_string.query && item.query_string.query === 'licensor_organisation_id');
        let newFilters = parsedNewFilters('licensor_organisation_id', licensor.licensor_organisation_id, basicFilterObj);
        let newQueryObj = parsedNewQuery('licensor_organisation_id', licensor.licensor_organisation_id, clonedQueryObject, categoryLevels);

        updateFiltersState(newQueryObj, newFilters, true, { page: 1 });
        selectLicensor(parseV1Response(licensor));
    };

    const fetchAgentLinkedLicensors = (data) => {
        return fetchAgentLicensors().then(response => {
            each(response.data, licensor => {
                let findLicensor = find(data, agg => {
                    return agg.licensorOrganisationId === licensor.licensorOrganisationId
                });
                
                licensor.count = !!findLicensor && !!findLicensor.records && findLicensor.records || 0;
                licensor.org_image = `https://assets.xelacore.io/org/${licensor.licensorOrganisationId}.png`;
            })

            setLicensors(response.data);
            localStorage.setItem('licensorsCount', response.data && response.data.length || 0);

            if(response.data.links && response.data.links.length === 1) {
                selectLicensorFilter(response.data[0]);
            }
        })
    }

    const fetchLicensors = (data) => {
        return fetchLinkedLicensors(orgId).then(response => {
            each(response.data.links, licensor => {
                let findLicensor = find(data, agg => {
                    return agg.key === licensor.licensor_organisation_id
                });

                licensor.count = !!findLicensor && !!findLicensor.doc_count && findLicensor.doc_count || 0;
                licensor.org_image = `https://assets.xelacore.io/org/${licensor.licensor_organisation_id}.png`;
            })

            setLicensors(response.data.links);
            localStorage.setItem('licensorsCount', response.data.links && response.data.links.length || 0);

            if(response.data.links && response.data.links.length === 1) {
                selectLicensorFilter(response.data.links[0]);
            }
        })
    }

    const fetchInfo = () => {
        if(!isAgent(companyData)) {
            let queryObj = {
                query: {
                    size: 0,
                    query: {
                        bool: {
                            must: [],
                            must_not: []
                        }
                    },
                    aggregations: {
                        Licensor: {
                            terms: {
                                field: 'licensor_organisation_id',
                                size: 250
                            },
                            aggregations: {
                                Licensor: {
                                    terms: {
                                        field: 'licensor',
                                        size: 250
                                    }
                                }
                            }
                        }
                    }
                }
            }
    
            return dispatch(getEsData(queryObj, type)).then((resp) => {
                fetchLicensors(!!resp.aggregations && !!resp.aggregations.Licensor && resp.aggregations.Licensor.buckets || []);
            });
        } else {
            return fetchAgentLicensorsCatalog(orgId).then((resp) => {
                fetchAgentLinkedLicensors(!!resp && !!resp.data && resp.data);
            })
        }
    }

    return (
        <div className='select-licensor'>
            <div className='header'>
                <h2>
                    {pageTitle}
                </h2>
                <p>
                    {pageDescription}
                </p>
            </div>

            <div className='body'>
                <div className='licensors'>
                    {toArray(licensors).map((el,index) => {
                        return (
                            <div className='licensors--item' key={'el-'+index}>
                                <div className='licensors--item-header'>
                                    <span className='licensors--item-header-title'>
                                        {t('select_licensor.no_records')}
                                    </span>
                                    <span className='licensors--item-header-subtitle'>
                                        {el.count || 0}
                                    </span>
                                </div>

                                <div className='licensors--item-body'>
                                    <div className='licensors--item-body-logo'>
                                        <img src={el.org_image} onError={(event) => event.target.style.display = 'none'}/>
                                    </div>

                                    <span className='licensors--item-body-name'>
                                        {el.licensor_organisation_name || el.licensorOrganisationName || ''}
                                    </span>
                                </div>

                                <div className='licensors--item-footer'>
                                    <Button
                                        type={'secondary'}
                                        size={'small'}
                                        onClick={() => selectLicensorFilter(el)}>
                                        {t('buttons.view')}
                                    </Button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}
