import React from 'react';
import { connect } from 'react-redux';
import { ReactComponent as SubmittedIcon } from 'src/js/components/static/Icon/svg-icons/submitted.svg';
import { useTranslation } from 'react-i18next';


function ContractDetails({
     count
 }) {
    const { t } = useTranslation();

    return (
        <div className='widget'>
            <div className='widget-header'>
                <b>{t('toolkit.trademark_letter_title')}</b>
                <h2>{t('toolkit.trademark_request_form.submission_sent')}</h2>
            </div>

            <div className={'form-wrapper u-text-center'}>
                <SubmittedIcon></SubmittedIcon>
                <div className={'u-margin-top u-bold'}>
                    {t('toolkit.trademark_request_form.thank_you_message')}
                </div>
                <h3 className={'u-margin-top'}>{t('toolkit.trademark_request_form.redirected_in')}{count}...</h3>
            </div>
        </div>
    )
}

const mapStateToProps = ({ xelacore, dispatch }) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(ContractDetails);
