import React, { Component } from 'react';
import get from 'lodash/get';
import { hideModal, showModal } from 'src/js/actions/xelacore';
import { connect } from 'react-redux';
import { pluralise } from 'src/js/helpers/strings';
import { Modal } from 'src/js/components/static';
import { deleteRecords } from 'src/js/apicalls/mixed/myRecords';
import BulkDeletionModalOutput from './BulkDeletionModalOutput';
import { isUpdated } from 'src/js/helpers/dataHelpers';
import Button from 'modules/UiKit/components/Button/Button';
import { withTranslation } from 'react-i18next';

class BulkDelete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            errored: false,
            ids: []
        };
    }

    componentDidUpdate() {
        const { messages, dispatch, customFetch } = this.props;
        const { ids, submitting, timestamp } = this.state;
        if (
            !submitting ||
            !timestamp ||
            !messages ||
            !messages.length ||
            !ids ||
            !ids.length
        )
            return;

        if (isUpdated(messages, ['actions'], timestamp, ids)) {
            dispatch(showModal(this.setOutputModal(ids)));
            setTimeout(() => {
                customFetch();
            }, 1000);
        }
    }

    setOutputModal = (updated, error) => (
        <BulkDeletionModalOutput
            all={updated}
            dispatch={this.props.dispatch}
            error={error}
        />
    );

    submitProductsForDeletion() {
        const { selectedRows, dispatch, resetRows } = this.props;
        const listForDelete = selectedRows
            .filter(
                item => {
                    return !item.registered_with
                }
            )
            .map(record => record.record_id);
        if (!listForDelete.length) return dispatch(hideModal());

        this.setState({
            submitting: true,
            ids: listForDelete,
            timestamp: Date.now()
        });

        deleteRecords({ record_ids: listForDelete })
            .then(() => {
                resetRows();

                setTimeout(() => {
                    dispatch(hideModal());
                }, 1000);
            })
            .catch(error => {
                const message = get(error, 'response.data.message');
                return dispatch(
                    showModal(
                        this.setOutputModal(
                            listForDelete,
                            message || error.message
                        )
                    )
                );
            });
    }

    setCounts = () => {
        const { selectedRows, data } = this.props;
        const filteredData = data.filter(
            el => selectedRows.indexOf(el.record_id) > -1
        );
        const counts = new Map();
        filteredData.forEach(row => {
            const { count = 0, errored = false } =
                counts.get(row.licensor) || {};
            const isErrors = !!row.registered_with;
            if (!this.state.errored && isErrors)
                this.setState({ errored: true });
            counts.set(row.licensor, {
                count: count + 1,
                errored: errored || isErrors
            });
        });
        return counts;
    };

    buildItemsForDeletion = () => {
        const counts = this.setCounts();

        const items = [];
        counts.forEach((item, key) => {
            const { count, errored } = item;
            return items.push(
                <div
                    className="o-box--registration"
                    key={`${key}-${count}-product-registration`}
                >
                    <div className="u-color-blue">
                        <span className="u-fw--bold">{count}</span>
                        &nbsp;
                        {pluralise('product', count)}
                        {errored && <span className="o-box--errored" />}
                    </div>
                    <div className="u-color-blue">
                        <span className="u-color-blue">{key}</span>
                    </div>
                </div>
            );
        });
        return items;
    };

    render() {
        const { submitting, errored } = this.state;
        const { selectedRows, dispatch, t } = this.props;

        const submittingBody = <div>{t('bulk_delete.deleting_records')}</div>;

        const body = (
            <div className="o-box--block">
                <span className="u-color-black">
                    {t('bulk_delete.you_are_about_to_delete')} {selectedRows.length}{' '}
                    {pluralise(t('bulk_delete.product'), selectedRows.length)}.
                </span>

                {errored && (
                    <small className="u-color-error">
                        {t('bulk_delete.registered_records')}
                    </small>
                )}
                <div
                    className={`u-flex-gap u-flex-align--right ${
                        errored ? 'u-margin-top' : 'u-margin-top-2x'
                    }`}
                >
                    <Button
                        type="secondary"
                        size="small"
                        onClick={() => dispatch(hideModal())}>
                        {t('buttons.cancel')}
                    </Button>

                    <Button
                        type="secondary-danger-2"
                        size="small"
                        onClick={() => this.submitProductsForDeletion()}>
                        {t('buttons.delete')}
                    </Button>
                </div>
            </div>
        );

        return (
            <Modal
                centered
                isSmall
                title={t('bulk_delete.bulk_deletion')}
                body={submitting ? submittingBody : body}
            />
        );
    }
}

function mapStateToProps(state) {
    const {
        xelacore,
        xelacore: { socket: { messages = [] } = {} } = {}
    } = state;

    return {
        xelacore,
        messages
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BulkDelete));
