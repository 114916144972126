import React, { Fragment, Component } from 'react';
import classnames from 'classnames';
import { get, size, toArray, cloneDeep } from 'lodash';
import { Badge, Auth, Icon, Tooltip } from 'src/js/components/static';
import { getStatusInfo } from 'src/js/constants/productConstants/index';
import RegistrationSyndication from '../RegistrationSyndication/RegistrationSyndication';
import Button from 'modules/UiKit/components/Button/Button';

import { ReactComponent as ArrowLeft } from 'src/js/components/static/Icon/svg-icons/arrow-left.svg';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';

import map from 'lodash/map';

class Statuses extends Component {
    constructor(props) {
        super(props);

        this.popoverRef = React.createRef();
    }

    cancelData(e) {
        const { cancelEditMode, cloneData } = this.props;
        
        let cloneDataObj = cloneDeep(cloneData);

        cancelEditMode(e, cloneDataObj);
    }

    goBackFunction = () => {
        browserHistory.goBack();
    };

    translateStatusesList = (field, list) => {
        const { t } = this.props;
        let newList = [];

        newList = map(list, item => {
            return {
                name: item.name,
                label: t(`constants.${field}.${item.label}`)
            }
        })

        return newList;
    }

    render() {
        const {
            data,
            editRecord,
            type,
            updateData,
            editMode,
            switchTo,
            isQuarantine,
            modalView,
            licensor,
            openQuarantineFunction,
            linkSingleProduct,
            dispatch,
            originalData,
            getProductData,
            toggleView,
            view,
            esgData,
            hasEsgPermission,
            agent,
            t
        } = this.props;

        const registered = data.shared_to || get(data, 'registered_with');
        const linked = !!size(get(data, 'concept_code_confirmed_info'));

        const customButtonStatus = (fieldName, editable) => {
            const statusData = getStatusInfo(data, fieldName);

            return (
                <div className="u-flex-align status-box">
                    <Badge
                        text={t(`constants.${statusData.field}.${statusData.field}`)}
                        dataName={statusData.field}
                        value={t(`constants.${statusData.field}.${statusData.selectedLabel}`)}
                        values={this.translateStatusesList(statusData.field, statusData.list)}
                        editable={editable}
                        updateData={updateData}
                        label={statusData.selectedLabel}
                        className={editable ? '' : 'c-badge__no-click'}
                    />

                    <Tooltip
                        className={classnames('c-tooltip__no-left', {
                            'u-margin-right': type === 'Manufacturing'
                        })}
                    >
                        <Auth restrictTo="licensee">
                            <div>{statusData.tooltip.licensee}</div>
                        </Auth>
                        <Auth restrictTo="licensor">
                            <div>{statusData.tooltip.licensor}</div>
                        </Auth>
                        <Auth restrictTo="agent">
                            <div>{statusData.tooltip.licensor}</div>
                        </Auth>
                    </Tooltip>
                </div>
            );
        };

        return (
            <Fragment>
                <div className="c-product__detail">
                    <div className="c-product__detail-status-section">
                        <div className='c-product__detail-status-section-actions'>
                            { !!hasEsgPermission && (
                                <div className='u-flex u-flex-align u-flex-align--center'>
                                    <div className='back-icon c-pointer'
                                        onClick={() => this.goBackFunction()}>
                                            <ArrowLeft className="u-margin-small-right" height="10" width="16"></ArrowLeft>
                                    </div>

                                    <div className='ui-button-group'>
                                        <Button
                                            size={'small'}
                                            type={`${view === 'product' ? 'primary' : 'secondary'}`}
                                            onClick={() => toggleView('product')}
                                        >
                                            {t('buttons.product_view')}
                                        </Button>

                                        <Button
                                            disabled={!!esgData && toArray(esgData).length === 0}
                                            size={'small'}
                                            type={`${view === 'esg' ? 'primary' : 'secondary'}`}
                                            onClick={() => toggleView('esg')}
                                        >
                                            {t('buttons.esg_view')}
                                        </Button>
                                    </div>
                                </div>
                            )}

                            {!hasEsgPermission && (
                                <span className='back-icon c-pointer back-button'
                                    onClick={() => this.goBackFunction()}>
                                        <ArrowLeft className="u-margin-small-right" height="10" width="16"></ArrowLeft>
                                    <span className='u-bold'>{t('buttons.back')}</span>
                                </span>
                            )}
                        </div>
                        {customButtonStatus('product_status', !licensor && !agent)}
                        {customButtonStatus('manufacturing_status', !licensor && !agent)}
                        {type === 'catalog' && (
                            <div className="u-flex-align status-box">
                                <strong className="name">{t('modules.product_detail.statuses.concept')}</strong>
                                <span
                                    className={`c-product__detail--status
                                            ${
                                        linked
                                            ? 'c-product__detail--status-green'
                                            : 'c-product__detail--status-red'
                                    }`}
                                >
                                    {linked ? t('modules.product_detail.statuses.linked') : t('modules.product_detail.statuses.not_linked')}
                                </span>
                            </div>
                        )}
                        {type === 'records' && (
                            <div className="u-flex-align status-box">
                                <strong className="name">{t('modules.product_detail.statuses.registration')}</strong>
                                <span
                                    className={`c-product__detail--status
                                            ${
                                        registered
                                            ? 'c-product__detail--status-green'
                                            : 'c-product__detail--status-red'
                                    }`}
                                >
                                    {registered ? t('modules.product_detail.statuses.registered') : t('modules.product_detail.statuses.not_registered')}
                            </span>
                            </div>
                        )}

                        {modalView && (
                            <div className="status-box">
                                <strong className="name">{t('modules.product_detail.statuses.status')}</strong>
                                <span
                                    ref={node => {
                                        this.popoverRef = node;
                                    }}
                                    className={`c-product__detail--status u_pointer u-flex-align
                                            ${
                                        modalView === 'conflicts' &&
                                        !data.conflicts_resolved_local
                                            ? 'c-product__detail--status-red'
                                            : modalView ===
                                            'conflicts' &&
                                            data.conflicts_resolved_local
                                                ? 'c-product__detail--status-black'
                                                : modalView === 'quarantine'
                                                    ? 'c-product__detail--status-black'
                                                    : ''
                                    }`}
                                    onClick={() =>
                                        openQuarantineFunction(
                                            modalView === 'quarantine',
                                            false
                                        )
                                    }
                                >
                                    <Icon icon="duplicates" size="12" />
                                    <span>
                                        {modalView === 'conflicts'
                                            ? t('modules.product_detail.statuses.in_conflict')
                                            : modalView === 'quarantine'
                                                ? t('modules.product_detail.statuses.quarantine')
                                                : ''}
                                    </span>
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                {type === 'catalog' && !isQuarantine && (
                    <Auth
                        restrictTo="licensee"
                        permission="registration.manage"
                    >
                        <div className="u-flex-align u-flex-gap">
                            <Button
                                size={'small'}
                                type={'secondary'}
                                onClick={linkSingleProduct}
                            >
                                {t('buttons.link_concepts')}
                            </Button>
                            <Button
                                size={'small'}
                                type={'secondary'}
                                onClick={() => editRecord()}
                            >
                                {t('buttons.edit')}
                            </Button>
                        </div>
                    </Auth>
                )}

                {type === 'catalog' && isQuarantine && (
                    <Auth
                        restrictTo="licensee"
                        permission="registration.manage"
                    >
                        <div className="c-product__edit-details u-auto-cursor">
                            {t('modules.product_detail.statuses.record_cant_be_edited')}
                        </div>
                    </Auth>
                )}

                {type !== 'catalog' && (
                    <Auth
                        restrictTo="licensee"
                        permission="registration.manage"
                    >
                        <div className="u-flex-align">
                            {!data.is_quarantine && (
                                <Auth
                                    restrictTo="licensee"
                                    permission="registration.manage"
                                >
                                    <RegistrationSyndication
                                        data={originalData}
                                        dispatch={dispatch}
                                        editMode={editMode}
                                        licensor={licensor}
                                        type={type}
                                        getProductData={getProductData}
                                    />
                                </Auth>
                            )}
                            {/*<button className="c-button c-button--square c-button--shadow">Register Product</button>*/}
                            <Button
                                size="small"
                                type="secondary"
                                onClick={e =>
                                    !editMode
                                        ? switchTo('edit')
                                        : this.cancelData(e)
                                }
                            >
                                {editMode ? t('buttons.cancel') : t('buttons.edit')}
                            </Button>
                        </div>
                    </Auth>
                )}
            </Fragment>
        );
    }
}

export default withTranslation()(Statuses)