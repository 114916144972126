import React, { useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { checkSetUrl } from 'src/js/helpers/dataHelpers';
import { useTranslation } from 'react-i18next';

export default function FormElement({ ...props }) {
    const {
        name,
        type,
        onChange,
        onBlur,
        value = '',
        required,
        id,
        error,
        disabled,
        cols,
        rows,
        nolabel,
        flex,
        inline,
        formRef = null,
        capitalize,
        tooltip,
        modalLayout,
        placeholder = '',
        defaultValue,
        values = [],
        defaultData,
        classNameInner
    } = props;
    const { t } = useTranslation();
    const [isFocused, setIsFocused] = useState(false);

    const cx = classnames('c-form-element', props.className, {
        'c-form-element__error': error,
        'c-form-element__flex': flex,
        'c-form-element__focus': isFocused,
        'c-form-element--inline': inline,
        disabled: disabled
    });

    const datePlaceholder = 'DD MMM YYYY';
    const urlPlaceholder = 'https://example.com';
    const defaultUrlVal = 'https://';

    const placeholderVal = () => {
        if (type === 'url') return urlPlaceholder;
        if (type === 'date') return datePlaceholder;
        return placeholder;
    };

    const setValue = () => {
        const len = value ? value.length : '';
        if (type === 'url' && len > 0) {
            // Check if the user only started to input
            // plus handle situations like ba.com
            const uxTry = len < 15 && value.indexOf('.') === -1;
            if (
                value.indexOf('http://') > -1 ||
                value.indexOf('https://') > -1 ||
                uxTry
            )
                return value;
            return checkSetUrl(defaultUrlVal + value);
        }
        return value;
    };

    const renderInput = (
        <input
            name={name}
            type={type}
            onChange={onChange}
            onBlur={() => {
                setIsFocused(false);
                onBlur();
            }}
            onFocus={() => setIsFocused(true)}
            value={setValue()}
            id={id}
            required={required}
            disabled={disabled}
            defaultValue={defaultValue}
            placeholder={placeholderVal()}
            ref={formRef}
        />
    );

    const renderInputDate = () => {
        const handleChange = e => {
            const newDate = moment(e).format('DD MMM YYYY');
            const obj = { target: { value: newDate } };
            return onChange(obj);
        };
        const newValue = moment(value, 'DD MMM YYYY');

        return (
            <div className="c-form-element__date-block" ref={formRef}>
                <DatePicker
                    onChange={e => handleChange(e)}
                    selected={newValue.isValid() ? newValue.toDate() : null}
                    placeholderText={t('product_form_elements.date.please_select_date')}
                    dateFormat="dd MMM yyyy"
                    showYearDropdown
                />
            </div>
        );
    };

    const renderSelect = (
        <div className="c-select__styled-select" ref={formRef}>
            <select
                name={name}
                type={type}
                id={id}
                onChange={onChange}
                value={value}
                className={capitalize ? 'u-capitalize' : ''}
            >
                {defaultData ? (
                    <option value={defaultValue} disabled="disabled">
                        {defaultData}
                    </option>
                ) : (
                    <option value={''}>Choose an option</option>
                )}
                {props.children}
            </select>
        </div>
    );

    const renderTextArea = (
        <textarea
            name={name}
            type={type}
            onChange={onChange}
            value={value}
            id={id}
            placeholder={placeholderVal()}
            required={required}
            disabled={disabled}
            cols={cols || '30'}
            rows={rows || '5'}
            ref={formRef}
        />
    );

    const renderMultiSelect = () => {
        return (
            <div className="c-form-element__multi-select">
                {values.map(({ value, label }) => (
                    <div
                        className="c-form-element__multi-select-item"
                        key={`${label}-${value}--multiselct-checkbox`}
                    >
                        <input
                            name={label}
                            type={'checkbox'}
                            onChange={onChange}
                            value={value}
                            id={`${label}-${value}--multiselct-checkbox`}
                        />
                        <label htmlFor={id}>{label}</label>
                    </div>
                ))}
            </div>
        );
    };

    const useType = () => {
        switch (type) {
            case 'textarea':
                return renderTextArea;
            case 'select':
                return renderSelect;
            case 'multiSelect':
                return renderMultiSelect();
            case 'text':
            case 'checkbox':
            case 'number':
            case 'url':
                return renderInput;
            case 'date':
                return renderInputDate();
        }
    };

    const renderErrorMessage = () => {
        return <small className="u-color-red">{error}</small>;
    };

    const theLabel = (
        <label htmlFor={id} className="c-form-element__label">
            {props.label}
            {props.required && <span className="u-color-pink">&nbsp;*</span>}:
        </label>
    );

    return (
        <div className={cx}>
            {modalLayout && !nolabel && theLabel}
            <div
                className={`c-form-element__wrapper ${
                    classNameInner ? classNameInner : ''
                }`}
            >
                {!modalLayout && !nolabel && theLabel}
                {useType()}
                {tooltip && (
                    <div className="c-form-element__tooltip">{tooltip}</div>
                )}
            </div>
            {renderErrorMessage()}
        </div>
    );
}
