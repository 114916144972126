import React, { Component } from 'react';
import { destroyPageNotification } from 'src/js/actions/xelacore';
import { checkCurrentlyIngested } from 'src/js/actions/ingestions';
import { ReactComponent as SwitchIcon } from 'src/js/components/static/Icon/svg-icons/switch.svg';
import { withTranslation } from 'react-i18next';
class ButtonSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ingestBroken: false,
            brokenIngestInfo: {},
            selectedLicensor: !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {},
            licensorsCount: !!localStorage.getItem('licensorsCount') && localStorage.getItem('licensorsCount') || 0
        };
    }

    setBrokenIngestInfo(info) {
        window.scrollTo(0, 0);
        this.setState({
            ingestBroken: true,
            brokenIngestInfo: info
        });
    }

    closeMapping() {
        const { dispatch, pageNotification } = this.props;
        this.setState(
            {
                ingestBroken: false,
                brokenIngestInfo: {}
            },
            () => {
                dispatch(destroyPageNotification({ type: 'my-records' }));
                checkCurrentlyIngested(dispatch, pageNotification);
            }
        );
    }

    cancelIngest() {
        const { toggleUploader, dispatch } = this.props;
        this.setState({ ingestBroken: false }, () => toggleUploader(false));
        return dispatch(destroyPageNotification({ type: 'my-records' }));
    }

    selectLicensorFunction() {
        const { selectLicensor, dispatch } = this.props;
        selectLicensor('');
        dispatch(destroyPageNotification({ type: 'my-records' }));
        localStorage.removeItem('selectedLicensor');
    }

    render() {
        const {
            totalItems,
            t
        } = this.props;
        const { selectedLicensor, licensorsCount } = this.state;

        return (
            <div className="c-my-records__button-container">
                <div className="c-my-records__flex-header">
                    <div className="c-my-records__flex-counters">
                        <div className="u-flex">
                            <h1>{t('modules.my_records.button_section.my_records')}</h1>
                            <div className="c-rec-panel__counter">
                                <span>
                                    {!!totalItems && totalItems || 0}
                                </span>
                            </div>
                        </div>
                    </div>
                    {!!licensorsCount && licensorsCount > 1 && (
                        <div className="c-rec-panel__buttons-container licensor-container">
                            <div
                                className="select-licensor-button"
                                onClick={() => {
                                    document.dispatchEvent(new CustomEvent('advancedFilterClear'));
                                    setTimeout(() => {
                                        this.selectLicensorFunction();
                                    }, 1)

                                }}>
                                <SwitchIcon
                                    height="14"
                                    width="14"
                                ></SwitchIcon>

                                <span className="name">{selectedLicensor.licensor_organisation_name}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslation()(ButtonSection)
