import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'src/js/components/static';
import Button from 'modules/UiKit/components/Button/Button';

import { ReactComponent as SwitchIcon } from 'src/js/components/static/Icon/svg-icons/switch.svg';
import { withTranslation } from 'react-i18next';

class ButtonSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHeaderOptions: false,
            showOpaUploader: true,
            selectedLicensor: !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {},
            licensorsCount: !!localStorage.getItem('licensorsCount') && localStorage.getItem('licensorsCount') || 0
        };
    }

    selectLicensorFunction() {
        const { selectLicensor } = this.props;

        selectLicensor('');
        localStorage.removeItem('selectedLicensor');
        sessionStorage.removeItem('presavedCatalogueRows');
        sessionStorage.removeItem('catalogueExportTitlesPPT');
        sessionStorage.removeItem('catalogueExportRowsPPT');
        sessionStorage.removeItem('catalogueExportDataPPT');
        sessionStorage.clear();

    }

    toggleHeaderOptions() {
        this.setState({ showHeaderOptions: !this.state.showHeaderOptions });
    }

    render() {
        const { toggleUploader, totalItems, isLicensor, t } = this.props;
        const { showOpaUploader, selectedLicensor, licensorsCount} = this.state;

        return (
            <Fragment>
                <div className="c-my-records__flex-header">
                    <div className="c-my-records__button-container">
                        <div className="u-flex">
                            <h1>{t('modules.product_catalogue.button_section.product_catalogue')}</h1>
                            <div className="c-rec-panel__counter">
                                <span>
                                    {!!totalItems && totalItems || 0}
                                </span>
                            </div>
                        </div>
                    </div>

                    { (!isLicensor && !!selectedLicensor && !!selectedLicensor.licensor_organisation_id) && (!!licensorsCount && licensorsCount > 1) && (
                        <div className="c-rec-panel__buttons-container licensor-container">
                            <div
                                className="select-licensor-button"
                                onClick={() => {
                                    document.dispatchEvent(new CustomEvent('advancedFilterClear'));
                                    setTimeout(()=>{
                                        this.selectLicensorFunction();
                                    },1)

                                }}>
                                <SwitchIcon
                                    height="14"
                                    width="14"
                                ></SwitchIcon>

                                <span className="name">{selectedLicensor.licensor_organisation_name}</span>
                            </div>
                        </div>
                    )}
                </div>

                {showOpaUploader && (
                    <Auth  restrictTo="licensor">
                        <div className="u-hidden">
                            <Button
                                type="secondary-2"
                                size="medium"
                                onClick={() => toggleUploader()}
                                // className="u-inline-block"
                            >
                                {t('buttons.upload_product_approval_records')}
                            </Button>
                        </div>
                    </Auth>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {
        xelacore: {
            userSettings: {
                lists = [],
                headers = {},
                activeHeaders = '',
                headerLayouts = []
            } = {},
            productCatalogue: {
                records: { data } = {},
                fetching: { fetching, fetchErrors } = {},
                filters: { filters, activeList } = {},
                selectedRows = [],
                initialCount
            } = {}
        } = {}
    } = state;

    return {
        lists,
        headers,
        activeHeaders,
        headerLayouts,
        data,
        // totalItems,
        selectedRows,
        fetching,
        fetchErrors,
        filters,
        activeList,
        initialCount
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(ButtonSection));
