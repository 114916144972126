import SocketV3 from 'components/static/SocketV3/SocketV3';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from 'src/js/components/Header';
import ZendeskWrapper from 'src/js/components/ZendeskWrapper/ZendeskWrapper';
import { GlobalNotification, PageNotification } from 'src/js/components/static';

import includes from 'lodash/includes';

class AppLayout extends Component {
    componentDidUpdate() {
        if (window.location.pathname === '/' || window.location.pathname === '/analytics') {
            document.body.classList.add('dashboard');
        } else {
            document.body.classList.remove('dashboard');
        }

        if (includes(window.location.pathname, '/royalty-report-assistant')) {
            document.body.classList.add('royalty-report-assistant-wrapper');
        } else {
            document.body.classList.remove('royalty-report-assistant-wrapper');
        }
    }

    render() {
        const {
            xelacore,
            xelacore: { modal, notification, lockBody },
            children
        } = this.props;
        const { tags = [] } = xelacore.auth.userData;

        return (
            <div id="container" className={lockBody.lockBody ? 'l-body--foxed' : ''}>
                <div className={'appLayout'}>
                    <div>
                        {modal.displayModal && modal.content}
                        {notification.displayNotification &&
                            notification.content}
                        <Header type={tags[0]} />
                        <GlobalNotification />
                        <SocketV3 />

                        <div
                            className={`l-body ${modal.displayModal ? 'u-blurred' : ''
                                }`}
                        >
                            <PageNotification />
                            <div className="l-body__content">
                                {/* <BreadcrumbsWrapper {...children.props} /> */}
                                {children}
                            </div>
                        </div>
                    </div>
                </div>

                <ZendeskWrapper />
            </div>
        );
    }
}

export default connect(
    ({ xelacore }) => ({ xelacore }),
    dispatch => ({ dispatch })
)(AppLayout);
