import React from 'react';
import { Component } from 'react';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught in ErrorBoundary: ", error, errorInfo); //eslint-disable-line
    }

    render() {
        if (this.state.hasError) {
            return <h1></h1>;
        }

        return this.props.children;
    }
}