import React, { useState, useEffect, useRef, useCallback } from 'react';
import uniq from 'lodash/uniq';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import { ReactComponent as IconSearch } from 'components/static/Icon/svg-icons/search-icon.svg';
import { ReactComponent as CloseIcon } from 'components/static/Icon/svg-icons/close-icon.svg';
import { useTranslation } from 'react-i18next';

const MultipleDropdown = ({
    value = [],
    values = [],
    onChange,
    name,
    label,
    selectplaceholder,
    enableSearch,
    disabled = false,
    unselectOption,
    validations,
    fieldHasErrors,
    selectedLimit,
    disabledOptions
}) => {
    const { t } = useTranslation();
    const [showList, setShowList] = useState(false);
    const [selectedData, setSelectedData] = useState(value);
    const [searchedValue, setSearchedValue] = useState('');
    const [filteredList, setFilteredList] = useState(values);

    const containerRef = useRef(null);
    const handleClickOutside = useCallback((e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setShowList(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [handleClickOutside]);

    useEffect(() => {
        setSelectedData(value);
    }, [value]);

    useEffect(() => {
        if (searchedValue.trim() === '') {
            setFilteredList(values);
        } else {
            const lowerSearch = searchedValue.toLowerCase();
            const newFiltered = values.filter(item =>
                item.label && item.label.toLowerCase().includes(lowerSearch)
            );
            setFilteredList(newFiltered);
        }
    }, [searchedValue, values]);

    const handleSearchChange = (e) => {
        setSearchedValue(e.target.value);
    };

    const clearSearch = () => {
        setSearchedValue('');
    };

    const getOptionKey = (option) => option.name || option.value;

    const isSelected = (option) => {
        const key = getOptionKey(option);
        return selectedData.includes(key);
    };

    const handleSelect = (e, option) => {
        e.stopPropagation();
        const key = getOptionKey(option);

        if (!selectedData.includes(key) && selectedLimit && selectedData.length >= selectedLimit) {
            return;
        }
        let newSelected;
        if (selectedData.includes(key)) {
            newSelected = selectedData.filter(item => item !== key);
        } else {
            newSelected = [...selectedData, key];
        }
        setSelectedData(newSelected);
        onChange && onChange(newSelected, name);
    };

    const selectAll = (e, clear = false) => {
        e.stopPropagation();
        const allKeys = filteredList.map(getOptionKey);
        let updatedSelected;
        if (clear) {
            updatedSelected = selectedData.filter(key => !allKeys.includes(key));
        } else {
            const keysToAdd = allKeys.filter(key => !selectedData.includes(key));
            if (selectedLimit) {
                const availableSlots = selectedLimit - selectedData.length;
                if (availableSlots <= 0) {
                    updatedSelected = selectedData;
                } else {
                    updatedSelected = uniq([...selectedData, ...keysToAdd.slice(0, availableSlots)]);
                }
            } else {
                if (allKeys.every(key => selectedData.includes(key))) {
                    updatedSelected = selectedData.filter(key => !allKeys.includes(key));
                } else {
                    updatedSelected = uniq([...selectedData, ...allKeys]);
                }
            }
        }
        setSelectedData(updatedSelected);
        onChange && onChange(updatedSelected, name);
    };

    const selectedPlaceholder = () => {
        const defaultPlaceholder = selectplaceholder || t('product_form_elements.custom_select.select');
        if (selectedData.length === 0) {
            return `${defaultPlaceholder} ${label || ''}`;
        }
        return `${selectedData.length} ${label || 'items'} selected`;
    };

    useEffect(() => {
        if (validations && validations[0] && validations[0].value) {
            const erroredValues = validations[0].value.split(';');
            fieldHasErrors && fieldHasErrors(erroredValues);
        }
    }, [validations, fieldHasErrors]);

    const sortedOptions = orderBy(filteredList, [(item) => item.label.toLowerCase()], ['asc']);

    const initialOptions = unselectOption
        ? [{
            label: t('product_form_elements.custom_select.none'),
            value: 'unselected',
            name: 'unselected',
            unselect: true
        }]
        : [];

    const combinedOptions = [...initialOptions, ...sortedOptions];

    const checkedList = combinedOptions.filter(option => isSelected(option));
    const uncheckedList = combinedOptions.filter(option => !isSelected(option));

    return (
        <div
            className={`select-drop-down ${disabled ? 'disabled' : ''} ${enableSearch ? 'enabled-search' : ''}`}
            ref={containerRef}
        >
            <div className="select-drop-down--control" onClick={() => setShowList(prev => !prev)}>
                <span className="control-placeholder">{selectedPlaceholder()}</span>
                <div className="select-drop-down--control--arrow">
                    <span className={`select-drop-down--control--arrow--triangle ${showList ? 'expanded' : ''}`} />
                </div>
            </div>

            {showList && (
                <div className="select-drop-down--multilist select-single-option-wrapper">
                    {enableSearch && (
                        <div className="select-drop-down--header">
                            <div className="search-action-wrapper">
                                <div>
                                    <input
                                        type="text"
                                        name="search"
                                        placeholder={t('placeholders.search')}
                                        className="search-input"
                                        value={searchedValue}
                                        onChange={handleSearchChange}
                                    />
                                    <span className="search-icon">
                                        <IconSearch />
                                    </span>
                                </div>
                                <CloseIcon className="close-icon c-pointer" onClick={clearSearch} />
                            </div>
                        </div>
                    )}

                    {combinedOptions.length > 0 ? (
                        <div className="select-option-list">
                            {checkedList.map((option, i) => (
                                <div
                                    key={`select-option-checked-${i}`}
                                    className={`select-option-item ${option.isHide || (includes(disabledOptions, option.name)) ? 'disabled' : ''}`}
                                    onClick={(e) => handleSelect(e, option)}
                                >
                                    <UiCheckbox
                                        label={option.label}
                                        type="checkbox"
                                        className="checkbox"
                                        checked={true}
                                        onChange={() => {}}
                                    />
                                </div>
                            ))}

                            {uncheckedList.map((option, i) => {
                                const isOptionDisabled = option.isHide || (selectedLimit && checkedList.length === selectedLimit) || includes(disabledOptions, option.name) || selectedData && selectedData.length >= selectedLimit;
                                return (
                                    <div
                                        key={`select-option-unchecked-${i}`}
                                        className={`select-option-item ${isOptionDisabled ? 'disabled' : ''} ${option.label === t('product_form_elements.custom_select.none') ? 'none' : ''}`}
                                        onClick={isOptionDisabled ? undefined : (e) => handleSelect(e, option)}
                                    >
                                        <UiCheckbox
                                            label={option.label}
                                            type="checkbox"
                                            className="checkbox"
                                            checked={false}
                                            onChange={() => {}}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        searchedValue.length > 0 && (
                            <div className="no-data-placeholder">
                                <h4>{t('product_form_elements.custom_select.no_data_found')}</h4>
                            </div>
                        )
                    )}

                    {combinedOptions.length > 0 && !selectedLimit && (
                        <div className="select-drop-down--multilist--footer">
                            <div className="u-flex-align">
                                <div className="select-all" onClick={(e) => selectAll(e)}>
                                    {t('buttons.select_all')}
                                </div>
                                <div className="select-all marg-l-10" onClick={(e) => selectAll(e, true)}>
                                    {t('buttons.clear_all')}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MultipleDropdown;
