import React from 'react';
import UiButton from 'modules/UiKit/components/Button/Button';
import { Icon } from 'src/js/components/static';
import { useTranslation } from 'react-i18next';

export default function AdvancedFilteringHeader({ resetState, isFiltersReturnedNoData, close }) {
    const { t } = useTranslation();

    return(
        <div className="ui-filters-header">
            <div>
                <span>{t('filters.filters')}</span>

                {!isFiltersReturnedNoData && (
                    <UiButton
                        type={'secondary'}
                        size={'small'}
                        onClick={() => resetState()}>
                        {t('buttons.clear')}
                    </UiButton>
                )}
            </div>

            <Icon
                className="c-rec-panel__close-menu"
                onClick={() => close()}
                icon="close"
                fill="black"
            />
        </div>
    )
}