import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth, Modal } from 'src/js/components/static';
import { browserHistory } from 'react-router';
import { showModal } from 'src/js/actions/xelacore';
import CatalogueProductEditModal from 'src/js/components/Modals/CatalogueProductEditModal';
import Button from 'modules/UiKit/components/Button/Button';
import { withTranslation } from 'react-i18next';

class MetaCatalogue extends Component {
    constructor(props) {
        super(props);
    }

    openRecordForEditing = record_id =>
        browserHistory.push(`/product/records/${record_id}?edit=true`);

    displayModal = record_id => {
        const { dispatch, t } = this.props;
        const body = (
            <CatalogueProductEditModal
                openRecordForEditing={this.openRecordForEditing}
                record_id={record_id}
            />
        );
        const title = t('modules.product_catalogue.redirect_to_my_records');
        const contentOfTheModal = <Modal title={title} body={body} />;
        return dispatch(showModal(contentOfTheModal));
    };

    render() {
        const { recordId, fullLength, dontShowRedirectModal, t } = this.props;
        const func = dontShowRedirectModal
            ? this.openRecordForEditing
            : this.displayModal;

        return (
            <tr className="c-rec-table__meta-row">
                <td
                    className="c-rec-table__cell c-rec-table__meta-row"
                    colSpan={fullLength}
                >
                    <div className="u-flex-align">
                        <div className="u-margin-right">
                            <Button
                                type="secondary-2"
                                size="small"
                                to={`/product/catalog/${recordId}`}>
                                {t('buttons.view_product')}
                            </Button>
                        </div>
                        <Auth
                            restrictTo="licensee"
                            permission="registration.manage"
                        >
                            <div className="u-margin-right">
                                <Button
                                    type="secondary-2"
                                    size="small"
                                    onClick={() => func(recordId)}>
                                    {t('buttons.edit_in_my_records')}
                                </Button>
                            </div>
                        </Auth>
                    </div>
                </td>
            </tr>
        );
    }
}
function mapStateToProps(state) {
    const {
        xelacore: { userSettings: { dontShowRedirectModal } = {} } = {}
    } = state;
    return { dontShowRedirectModal };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MetaCatalogue));