import React, { Component } from 'react';
import classnames from 'classnames';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import size from 'lodash/size';
import filter from 'lodash/filter';

import { getDroppedFile } from 'src/js/apicalls/files/fileUpload';
import { SmartUploader, Icon } from 'src/js/components/static';
import { csvFileErrors } from 'src/js/constants/errorConstants';
import Button from 'modules/UiKit/components/Button/Button';
import { getPreSignedUrl, uploadFile, triggerIngest } from './Upload';
import { ReactComponent as HistoryIcon } from 'src/js/components/static/Icon/svg-icons/history-icon.svg';
import { ReactComponent as CloseIcon } from 'src/js/components/static/Icon/svg-icons/close-icon.svg';
import { withTranslation } from 'react-i18next';

// import axios from 'axios';
// import config from 'config';


class UploadPanel extends Component {
    constructor(props) {
        super(props);

        // This comes from the licensor/licensee link table but it would be better as a prop giving just the licensor & licensee id
        const selectedLicensor = JSON.parse(localStorage.getItem('selectedLicensor') || '{}');

        this.state = {
            licenseeOrganisationId: selectedLicensor.licensee_organisation_id,
            licensorOrganisationId: selectedLicensor.licensor_organisation_id,
            category: props.isOpaUploader ? 'concepts/ingest' : 'catalog/ingest',
            destination: props.isOpaUploader ? 'xelacore-concepts' : 'xelacore-catalog',
            uploading: false,
            confirm: false,
            files: null,
            errorMessage: '',
            helpLink: '',
            mandatoryHeaders: ['GTIN', 'MPN', 'Product Name']
        };
    }


    componentDidMount() {
        const { close } = this.props;
        document.addEventListener('onlyRejectedRecordsIngested', () => {
            close(true);
        });
    }

    componentWillUnmount() {
        document.removeEventListener('onlyRejectedRecordsIngested', () => {
        });

    }


    checkTotalFileSizes(files) {
        const limit = 10000000; //10mb
        const amount = files.reduce((acc, el) => {
            return acc + el.size;
        }, 0);
        if (amount > limit) return false;
        return true;
    }

    checkFileTypes(files) {
        return files.some(el => !el.name.endsWith('.csv'));
    }

    setError = (message, link) => {
        this.setState({
            errorMessage: message,
            helpLink: link
        });
    };

    checkCSVLengths(files) {
        const { mandatoryHeaders } = this.state;
        const { gtinWaiver } = this.props;

        let requiredHeaders = mandatoryHeaders;

        if (gtinWaiver && includes(requiredHeaders, 'GTIN')) {
            remove(requiredHeaders, head => {
                return head === 'GTIN';
            });
        }

        return files.map(el => {
            return Promise.resolve(
                getDroppedFile(URL.createObjectURL(el))
            ).then(resp => {
                el.rowslength = size(
                    filter(resp.data.split('\n'), item => {
                        return !!size(item.replace(/[, ]+/g, ' ').trim());
                    })
                );

                return el;
            });
        });
    }

    confirmFileDrop(droppedFiles) {
        this.setError(null);

        // Check to allow only one csv file upload at a time
        if (droppedFiles.length > 1) {
            this.setError(csvFileErrors.MAX_FILE_LIMIT);
            return;
        }

        // Check they're all CSVs
        if (this.checkFileTypes(droppedFiles)) {
            this.setError(csvFileErrors.NO_TYPE_MATCH);
            return;
        }

        // Check their combined size is less 10mb
        if (!this.checkTotalFileSizes(droppedFiles)) {
            this.setError(csvFileErrors.MAX_SIZE);
            return;
        }

        Promise.all(this.checkCSVLengths(droppedFiles)).then(resp => {
            const files = resp.filter(f => f !== null);

            if (files[0].rowslength <= 1) {
                this.setError(csvFileErrors.EMPTY_ROWS);
                return;
            }

            if (files[0].rowslength > 20001) {
                this.setError(csvFileErrors.MAX_ROWS);
                return;
            }

            this.setState({
                confirm: true,
                files
            });
        });
    }

    handleUpload(files) {
        let sourceInfo = null;
        getPreSignedUrl('private', this.state.category, 'csv')
            .then(presignedUrl => {
                sourceInfo = presignedUrl.sourceInfo;
                return presignedUrl;
            })
            .then(presignedUrl => {
                return uploadFile(files[0], presignedUrl);
            })
            .then(() => triggerIngest(sourceInfo, this.state.licenseeOrganisationId, this.state.licensorOrganisationId, files[0].name || 'undefined.csv', this.state.destination, 'insert'))
            .then(() => this.props.close());
    }

    reset() {
        this.setState({
            uploading: false,
            confirm: false,
            files: null
        });
    }

    render() {
        const {
            uploading,
            confirm,
            files,
            errorMessage,
            helpLink
        } = this.state;
        const { close, isOpaUploader = true, toggleDataFormat, toggleUploads, t } = this.props;
        const panelCx = classnames('c-panel c-panel--curved', {
            'c-panel--collapsed': confirm
        });
        const smartCx = classnames('c-file-upload__dropzone', {
            'c-file-upload--uploading': uploading
        });
        return (
            <div>
                <div className={panelCx}>
                    <div className="c-panel__body">
                        <div className="c-file-upload">
                            <div className="u-flex">
                                {isOpaUploader && (
                                    <span className="u-small">
                                        {t('product_components.upload_panel.upload_product_approval_records')}
                                    </span>
                                )}

                                <span className="c-file-upload__history" onClick={() => toggleUploads()}>
                                    <HistoryIcon></HistoryIcon>
                                    <span>{t('buttons.upload_history')}</span>
                                </span>

                                <span
                                    className="c-file-upload__close-panel"
                                    onClick={() => close()}
                                >
                                        <CloseIcon></CloseIcon>
                                    </span>
                            </div>

                            <SmartUploader
                                errorMessage={errorMessage}
                                onDrop={files => this.confirmFileDrop(files)}
                                link={helpLink}
                                openHelp={toggleDataFormat}
                                className={`${smartCx} ${errorMessage ? 'error-dropzone' : ''
                                }`}
                                isOpaUploader={isOpaUploader}
                            />
                        </div>
                    </div>
                </div>

                {confirm && !uploading && (
                    <div className="c-file-upload__confirm-panel">
                        <span
                            className="c-file-upload__close-confirm-panel"
                            onClick={() => this.reset()}
                        >
                            <CloseIcon></CloseIcon>
                        </span>

                        <div className="c-file-upload__ripple">
                            <div className="c-file-upload__ripple--mr-ripple" />
                            <Icon size={40} icon="csv" fill="cyan" />
                        </div>

                        {files.map((el, i) => (
                            <p key={`${el.name}-${i}`}>{el.name}</p>
                        ))}

                        <Button
                            onClick={() => this.handleUpload(files)}
                            size={'medium'}
                            type={'primary'}
                        >
                            {t('product_components.upload_panel.upload')} {isOpaUploader ? t('product_components.upload_panel.opa_data') : t('product_components.upload_panel.data')}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(UploadPanel)