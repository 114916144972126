import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import {
    hideModal,
    displayNotification,
    showModal
} from 'src/js/actions/xelacore';
import { connect } from 'react-redux';
import { Modal } from 'src/js/components/static';
import {
    registerProducts
} from 'src/js/actions/records';
import Button from 'modules/UiKit/components/Button/Button';
import { ClickableText } from 'src/js/components/ProductListElements';
import recordPlaceholder from 'statics/imgs/record-placeholder.png';
import { getProductInfo } from 'src/js/actions/dataManager';
import {
    getMainImage
} from 'src/js/helpers/dataHelpers';
import { withTranslation } from 'react-i18next';

class MatchingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recordInfo: {},
            productError: false,
            submitting: false,
            errored: false,
            registered: false,
            filteredData: [],
            properSelected: [],
            uploadedData: [],
            selectedLicensor: !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {}
        };
    }

    componentDidMount() {
        this.getProductInfo();
    }

    openCatalog = () => {
        const { recordInfo } = this.state;
        const { setHideModal } = this.props;

        setHideModal();
        browserHistory.push(`/product/catalog/${recordInfo.record_id}`);
        return location.reload();
    };

    getProductInfo() {
        const { data, dispatch } = this.props;

        dispatch(getProductInfo('catalog', data.recordId)).then(resp => {
            this.setState({
                recordInfo: !!resp && !!resp.data && !!resp.data.record && resp.data.record || {}
            })
        }, () => {
            this.setState({
                productError: true
            })
        });
    }

    render() {
        const { recordInfo, productError } = this.state;
        const { data, setHideModal, t } = this.props;

        const image = getMainImage(recordInfo.images);

        const body = (
            <div className="o-box--block">
                <div className={`matched-wrapper ${!!productError ? ' -error' : ''}`}>
                    { !productError && (
                        <div className='matched-wrapper__product'>
                            <div className='matched-wrapper__product-body'>
                                <div className='matched-wrapper__product-body-product-image'>
                                    <img src={image || recordPlaceholder}/>
                                </div>

                                <ClickableText
                                    func={() => this.openCatalog()}
                                    value={recordInfo.product_name}
                                />
                            </div>
                            <div className='matched-wrapper__product-footer -blue'>
                                <span className='name'>{t('modules.retailer_listings.matching_modal.labels.listing_attributes')}</span>
                                <span>{t('modules.retailer_listings.matching_modal.labels.gtin')} {data.gtin}</span>
                                <span>{t('modules.retailer_listings.matching_modal.labels.mpn')} {data.mpn}</span>
                                <span>{t('modules.retailer_listings.matching_modal.labels.brand')} {data.brand}</span>
                                <span>{t('modules.retailer_listings.matching_modal.labels.category')} {data.category}</span>
                            </div>
                            <div className='matched-wrapper__product-footer -green'>
                                <span className='name'>{t('modules.retailer_listings.matching_modal.labels.product_attributes')}</span>
                                <span>{t('modules.retailer_listings.matching_modal.labels.gtin')} {recordInfo.gtin}</span>
                                <span>{t('modules.retailer_listings.matching_modal.labels.mpn')} {recordInfo.mpn}</span>
                                <span>{t('modules.retailer_listings.matching_modal.labels.brand')} {recordInfo.licensor_brand}</span>
                                <span>{t('modules.retailer_listings.matching_modal.labels.category')} {!!recordInfo.licensor_category_path && recordInfo.licensor_category_path[0] || ''}</span>
                            </div>
                        </div>
                    )}

                    { !!productError && (
                         <div className='matched-wrapper__product'>
                            <div className='matched-wrapper__product-body'>
                                <span className='error-text'>{t('modules.retailer_listings.matching_modal.failed_to_load_product')}</span>
                            </div>
                        </div>
                    )}
                </div>

                <div
                    className={"u-flex-gap u-flex-align--right u-margin-top"}
                >
                    <Button
                        type="secondary"
                        size="small"
                        onClick={() => setHideModal()}>
                        {t('buttons.cancel')}
                    </Button>

                    <Button
                        type="primary"
                        size="small"
                        disabled={!!productError}
                        onClick={() => this.openCatalog()}>
                        {t('buttons.view_in_catalogue')}
                    </Button>
                </div>
            </div>
        );

        return (
            <Modal
                isWide
                centered
                title={`${t('modules.retailer_listings.matching_modal.product_catalogue_match')} ${data.listingId}`}
                body={body}
            />
        );
    }
}

function mapStateToProps(state) {
    const { xelacore: { userSettings = {} } = {} } = state;
    return { userSettings };
}

const mapDispatchToProps = dispatch => ({
    setShowModal(content) {
        dispatch(showModal(content));
    },
    setHideModal() {
        dispatch(hideModal());
    },
    setRegisterProducts(obj) {
        return dispatch(registerProducts(obj));
    },
    setDisplayNotification(title, type) {
        dispatch(
            displayNotification({
                message: title,
                type,
                timeOut: 5000
            })
        );
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MatchingModal));
