import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getMainImage
} from 'helpers/dataHelpers';
import conceptPlaceholder from 'statics/imgs/no-photo.svg';
import map from 'lodash/map';
import classNames from 'classnames';
import { Icon } from 'components/static';
import { truncate } from 'lodash/string';
import ImageWrapped from 'components/BulkLinkConcepts/ImageWrapped';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import { withTranslation } from 'react-i18next';

class BulkLinkConceptsProductGroupList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedConcepts: props.selectedConcepts
        };
    }

    arrayPaginate(array, pageSize, page) {
        return array.filter((item, index) => {
            return (index < pageSize * page) &&
                (index > (pageSize * (page - 1) - 1));
        });
    }

    singleProductCOnceptCodes(list) {
        return map(list, item => item.concept_code);
    }

    getFilteredConcept(concepts, codes) {
        return concepts.filter(concept => codes.includes(concept.approval_code));
    }

    getCurrentImageIndex(list, current) {
        current = current || list[0];
        return list.indexOf(current);
    }

    render() {
        const {
            data,
            selectedProductGroupIndex,
            checkProductGroup,
            productPreview,
            removeProductFromList,
            singleProductData,
            t
        } = this.props;

        return (
            <div
                className={classNames(
                    'sidebar-body sidebar-body-product',
                    `${!!singleProductData ? 'single-sidebar-product' : ''}`
                )}
            >
                {data.map((groupItem, groupIndex) => (
                    <div
                        key={groupItem.id}
                        className={classNames(
                            'product-group-wrapper',
                            `${!!singleProductData ? 'single-sidebar-product-wrapper' : ''}`,
                            `${selectedProductGroupIndex === groupIndex ? 'selected-group' : ''}`
                        )}
                        onClick={
                            () => {
                                checkProductGroup(groupItem, groupIndex);
                            }
                        }
                    >
                        <div className={'group-header'}>
                            {data.length > 1 && (
                                <UiCheckbox
                                    type="checkbox"
                                    className="__checkbox check-marker"
                                    defaultChecked
                                    checked={selectedProductGroupIndex === groupIndex}>
                                </UiCheckbox>
                            )}
                            <h3>
                                <span>{t('group_list.brand')}:</span>
                                <b> {groupItem.name} </b>
                                <span>({groupItem.list.length})</span>
                            </h3>
                        </div>
                        <div className={'group-body'}>
                            <div className={'product-list'}>
                                {groupItem.list.map(productItem => (
                                    <div key={productItem.record_id} className="product-tail-item">
                                        <div
                                            className="product-image"
                                        >
                                            <div className={'product-image-bg'}>
                                                <ImageWrapped
                                                    imageUrl={getMainImage(productItem.images, 200, conceptPlaceholder)}
                                                    imageName={productItem.product_name}
                                                ></ImageWrapped>
                                            </div>
                                            <ImageWrapped
                                                imageUrl={getMainImage(productItem.images, 200, conceptPlaceholder)}
                                                imageName={productItem.product_name}
                                            ></ImageWrapped>

                                        </div>
                                        <div className="product-title">
                                            <h4
                                                onClick={() => {
                                                    productPreview(productItem, groupItem);
                                                }}
                                            >
                                                {
                                                    truncate(productItem.product_name, {
                                                        'length': 40,
                                                        'separator': ' '
                                                    })
                                                }
                                            </h4>
                                        </div>

                                        <div className="product-action">
                                            {groupItem.list.length > 1 && (
                                                <button
                                                    onClick={() => {
                                                        removeProductFromList(data, groupItem, productItem);
                                                    }}
                                                >
                                                    <Icon fill={'#999'} icon="close" size="14" />
                                                    <span>{t('group_list.remove_from_selection')}</span>
                                                </button>)
                                            }
                                        </div>

                                    </div>
                                ))}


                            </div>
                        </div>
                    </div>
                ))}
            </div>);
    }
}

export default connect()(withTranslation()(BulkLinkConceptsProductGroupList));
