import React, { Component } from 'react';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import orderBy from 'lodash/orderBy';
import isString from 'lodash/isString';
import { cloneDeep, findIndex, remove } from 'lodash';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import { ReactComponent as IconSearch } from 'src/js/components/static/Icon/svg-icons/search-icon.svg';
import { withTranslation } from 'react-i18next';

class CustomSelect extends Component {
    constructor(props) {
        super(props);
        const ungroupedList = props.isGrouped
            ? this.buildUngrouppedList(props.values)
            : props.values;

        this.state = {
            showList: false,
            selectedData: props.value || [],
            tempSelectedData: props.value || false,
            isGrouped: props.isGrouped,
            ungroupedList: ungroupedList,
            filteredList: [...ungroupedList],
            searchedValue: '',
            expandedGroups: [],
            showSelected: [],
            showSelectedAll: false
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    static getDerivedStateFromProps({ value }) {
        return value ? { selectedData: value } : {};
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    buildUngrouppedList(groupedList) {
        const ungroupedList = [];
        groupedList.forEach((option) => {
            option.items.forEach((item) => ungroupedList.push(item));
        });
        return ungroupedList;
    }

    showMenuList() {
        this.setState({
            showList: !this.state.showList
        });
    }

    handleClickOutside(event) {
        if (this.dropdownNode && !this.dropdownNode.contains(event.target)) {
            this.setState({
                showList: false
            });
        }
    }


    onChangeValue(e, value) {
        e.stopPropagation();
        const { onChange, name } = this.props;
        const { selectedData } = this.state;
        if (!this.isSelected(value)) {
            selectedData.push(value);
        } else {
            const selectedIndex = selectedData.findIndex(
                (selectedItem) => selectedItem === value
            );
            selectedData.splice(selectedIndex, 1);
        }
        this.setState({ selectedData });
        e.target.value = selectedData;
        onChange(selectedData, name);
    }

    selectValue(e, value) {
        const { onChange, name } = this.props;
        const { selectedData } = this.state;
        this.setState({ selectedData: value });
        e.target.value = value;
        onChange(selectedData, name);
        this.showMenuList();
    }

    onChangeSearch(e) {
        const { value } = e.target;
        const { isGrouped, values } = this.props;
        const { ungroupedList } = this.state;
        const tempUngroupedList = ungroupedList && ungroupedList.length > 0 ? ungroupedList : values;

        if (value.length === 0) {
            this.setState({
                searchedValue: value,
                filteredList: [...tempUngroupedList],
                isGrouped
            });
        } else {
            const newFilteredList = tempUngroupedList.filter(
                (item) => {
                    if(item) {
                        if(item.label) {
                            return item.label.toLowerCase().includes(value.toLowerCase())
                        } else if (item.value) {
                            return item.value.toLowerCase().includes(value.toLowerCase())
                        }
                    }
                }
            );

            this.setState({
                searchedValue: value,
                filteredList: newFilteredList,
                isGrouped: false
            });
        }

    }

    onChangeGroup(e, groupName) {
        e.stopPropagation();
        const { values, onChange, name } = this.props;
        const { selectedData } = this.state;
        const group = values.find((group) => group.label === groupName);
        const selectedVals = group.items.filter((item) =>
            selectedData.includes(item.value)
        );
        if (selectedVals.length) {
            group.items.forEach((item) => {
                const index = selectedData.findIndex((i) => i === item.value);
                if (index !== -1) {
                    selectedData.splice(index, 1);
                }
            });
        } else {
            group.items.forEach((item) => {
                selectedData.push(item.value);
            });
        }

        this.setState({ selectedData });
        e.target.value = selectedData;
        onChange(selectedData, name);
    }

    onChangeSelect(e, itemValue) {
        const { onChange, name, isMulti } = this.props;
        const { tempSelectedData } = this.state;
        let newSelectedData = cloneDeep(tempSelectedData);
        if (isMulti) {
            const findIndexItem = findIndex(newSelectedData, item => item.name === itemValue.name);
            if (findIndexItem > -1) {
                remove(newSelectedData, val => val.name === itemValue.name);
            } else {
                newSelectedData.push(itemValue);
            }
            this.setState({ tempSelectedData: newSelectedData });
            this.setState({ selectedData: newSelectedData });

            e.stopPropagation();
        } else {
            e.stopPropagation();
            let valueToSend = itemValue.unselect ? 'unselected' : toLower(itemValue.name || itemValue.value);
            onChange(valueToSend === 'unselected' ? null : valueToSend, name, this.props);
            if (valueToSend === 'unselected') {
                setTimeout(() => {
                    this.setState({ selectedData: 'unselected' });
                    this.setState({ tempSelectedData: false });
                }, 1);
            }
            this.showMenuList();
        }
    }

    isSelected(value) {
        const { isMulti } = this.props;

        return isMulti
            ? this.state.selectedData.includes(value)
            : this.state.selectedData === value;
    }

    filterAlreadySelectedItems(allData, selectedData) {
        return allData.filter((item) =>
            selectedData.findIndex((selectItem) => selectItem.name === item.name) > -1
        );
    }

    extractValuesFromItems(items) {
        return items.map((item) => {
            if (!item) return null;
            if (isString(item)) return item;
            return item.value || item.name || null;
        }).filter(Boolean);
    }

    selectAll(e, clear) {
        e.stopPropagation();
        const { isMulti, name, onChange } = this.props;
        const { ungroupedList, selectedData, searchedValue, filteredList, isGrouped } = this.state;

        const allData = searchedValue.length ? filteredList : ungroupedList;
        let updatedSelectedValues = [];

        if (clear) {
            updatedSelectedValues = [];
        } else {
            const alreadySelected = isMulti
                ? this.filterAlreadySelectedItems(allData, selectedData)
                : allData.filter((item) => selectedData.includes(item.value));

            if (alreadySelected.length === allData.length) {
                updatedSelectedValues = selectedData.filter(
                    (item) => !allData.find((i) => i.value === (item || item.value))
                ).map(item => item && isString(item) ? item : item.value);
            } else {
                updatedSelectedValues = isMulti && !isGrouped
                    ? this.extractValuesFromItems(allData)
                    : [...selectedData, ...this.extractValuesFromItems(allData)];

            }
        }
        window.setTimeout(() => {
            updatedSelectedValues = uniq(updatedSelectedValues).filter(Boolean);
            this.setState({ selectedData: updatedSelectedValues, tempSelectedData: updatedSelectedValues });
            onChange(updatedSelectedValues, name);
        }, 11);
    }


    showHideGroup(e, groupName) {
        if (e.target.type === 'checkbox') {
            return false;
        }
        const { expandedGroups } = this.state;
        if (expandedGroups.includes(groupName)) {
            const groupIndex = expandedGroups.findIndex(
                (selectedGroup) => selectedGroup === groupName
            );
            expandedGroups.splice(groupIndex, 1);
        } else {
            expandedGroups.push(groupName);
        }

        this.setState({
            expandedGroups
        });
    }

    showSelected(e, groupName) {
        e.stopPropagation();
        const { expandedGroups, showSelected } = this.state;

        if (showSelected.includes(groupName)) {
            const showSelectedIndex = showSelected.findIndex(
                (opt) => opt === groupName
            );
            showSelected.splice(showSelectedIndex, 1);
            this.setState({
                showSelected
            });
        } else {
            showSelected.push(groupName);
            if (!expandedGroups.includes(groupName)) {
                expandedGroups.push(groupName);
            }
            this.setState({
                expandedGroups,
                showSelected
            });
        }
    }

    showAllSelectedCount() {
        const { isGrouped } = this.props;
        const {
            searchedValue,
            ungroupedList,
            selectedData,
            filteredList,
            showSelectedAll
        } = this.state;
        if (isGrouped || searchedValue.length) {
            return {
                isShowSelectedAllButton: false,
                selectedCount: 0,
                lastSelected: 0,
                filteredOptions: filteredList,
                showSelectedAll: false
            };
        }

        const selectedOptions = ungroupedList.filter(
            (opt) => !!selectedData.includes(opt.value)
        );
        const selectedLength = selectedOptions.length;
        let items = [...ungroupedList];
        if (showSelectedAll) {
            const notSelectedOptions = ungroupedList.filter(
                (opt) => !selectedData.includes(opt.value)
            );
            items = [...selectedOptions, ...notSelectedOptions];
        }
        return {
            isShowSelectedAllButton: true,
            selectedCount: ungroupedList.filter(
                (item) => !!selectedData.includes(item.value)
            ).length,
            lastSelected: selectedLength - 1,
            filteredOptions: items,
            showSelectedAll
        };
    }

    showAllSelected() {
        this.setState({
            showSelectedAll: !this.state.showSelectedAll
        });
    }

    groupInfo(groupName) {
        const { values } = this.props;
        const { selectedData, expandedGroups, showSelected } = this.state;
        const group = values.find((group) => group.label === groupName);
        if (!group) {
            return {
                selectedCount: 0,
                isExpanded: false,
                items: [],
                lastSelected: 0,
                isShowSelected: false
            };
        }
        const selectedVals = group.items.filter((item) =>
            selectedData.includes(item.value)
        );

        let items = [...group.items];
        let lastSelected = 0;
        if (showSelected.includes(groupName)) {
            const selectedOptions = group.items.filter(
                (opt) => !!selectedData.includes(opt.value)
            );
            const notSelectedOptions = group.items.filter(
                (opt) => !selectedData.includes(opt.value)
            );
            items = [...selectedOptions, ...notSelectedOptions];
            lastSelected = selectedOptions.length - 1;
        }

        return {
            selectedCount: selectedVals.length,
            isExpanded: expandedGroups.includes(groupName),
            items: items,
            lastSelected,
            isShowSelected: showSelected.includes(groupName)
        };
    }

    renderGroup(option) {
        const { label, isMulti, t } = this.props;
        const {
            selectedCount,
            items,
            isExpanded,
            isShowSelected,
            lastSelected
        } = this.groupInfo(option.label);

        return (
            <div
                className="select-drop-down--results--group"
                key={`${name}_group_${option.label}`}
            >
                <div
                    className={`group-title ${
                        isExpanded ? 'expanded' : ''
                    }`}
                    onClick={(e) => this.showHideGroup(e, option.label)}
                >
                    <div className="group-title-name">
                        {isMulti && (
                            <React.Fragment>
                                <UiCheckbox
                                    onClick={(e) =>
                                        this.onChangeGroup(e, option.label)
                                    }
                                    type="checkbox"
                                    label={option.label}
                                    onChange={(e) =>
                                        this.onChangeGroup(e, option.label)
                                    }
                                    checked={selectedCount !== 0}
                                    id={`${name}_group_${option.label}`}
                                ></UiCheckbox>
                            </React.Fragment>
                        )}
                    </div>
                    {isMulti ? (
                        <div
                            className="selected-counter"
                            onClick={(e) => this.showSelected(e, option.label)}
                        >
                            {selectedCount} {label}(s) {t('product_form_elements.custom_select.selected')}
                        </div>
                    ) : (
                        <div className="selected-counter inactive">
                                {selectedCount} {label} {t('product_form_elements.custom_select.selected')}
                        </div>
                    )}

                    <div className="arrow-indicator">
                        <span
                            className={`triangle ${
                                isExpanded ? 'expanded' : ''
                            }`}
                        />
                    </div>
                </div>
                {isExpanded && (
                    <div className="item-list">
                        {this.renderItems(items, lastSelected, isShowSelected)}
                    </div>
                )}
            </div>
        );
    }

    renderItems(items, lastSelected = 0, isShowSelected = false) {
        const { name, isMulti, showFullLabel = false } = this.props;
        return items.map((item, i) => {
            if (isMulti) {
                return (
                    <label
                        htmlFor={`${name}_item_${item.value}`}
                        key={`${name}_item_${item.value}_${i}`}
                        className={`item-element ${
                            isShowSelected && i === lastSelected
                                ? 'last_selected'
                                : ''
                        }`}
                    >
                        <UiCheckbox
                            type="checkbox"
                            label={showFullLabel
                                ? `${item.label} (${item.value})`
                                : item.label}
                            className="checkbox"
                            onChange={(e) => this.onChangeValue(e, item.value)}
                            checked={this.isSelected(item.value)}
                            id={`${name}_item_${item.value}`}
                        ></UiCheckbox>
                    </label>
                );
            }
            return (
                <div
                    key={`${name}_item_${item.value}_${i}`}
                    className={`item-element single ${
                        this.isSelected(item.value) ? 'selected' : ''
                    }`}
                    onClick={(e) => this.selectValue(e, item.value)}
                >
                    <span>{item.label}</span>
                </div>
            );
        });
    }

    renderSearch() {
        const { searchedValue } = this.state;
        const { t } = this.props;
        return (
            <div className="search-action-wrapper">
                <input
                    type="text"
                    name="search"
                    placeholder={t('placeholders.search')}
                    className="search-input"
                    value={searchedValue}
                    onChange={(e) => this.onChangeSearch(e)}
                />
                <span className="search-icon">
                    <IconSearch
                        onClick={() => this.setFilterValue()}
                    />
                </span>
            </div>
        );
    }

    renderErrorBlock() {
        const { validations, label, t } = this.props;
        const { searchedValue } = this.state;
        const validationErroredValues = get(validations, '0.value', null);
        if (
            !validationErroredValues ||
            !validationErroredValues.length ||
            searchedValue.length
        ) {
            return null;
        }
        const erroredValues = validationErroredValues.split(';');
        return (
            <div className="select-drop-down--error-block">
                <div className="block-title">
                    {erroredValues.length} {label}(s) {t('product_form_elements.custom_select.could_not_be_read')}
                </div>
                <div className="block-subtitle">
                    {label}(s) {t('product_form_elements.custom_select.unable_to_read')}
                </div>
                <ul className="block-values">
                    {erroredValues.map((err) => (
                        <li key={`errored-value-${err}`}>{err}</li>
                    ))}
                </ul>
                <div className="block-footer">
                    {t('product_form_elements.custom_select.add_manually')}
                </div>
            </div>
        );
    }

    checkforErrors() {
        const { validations } = this.props;
        const validationErroredValues = get(validations, '0.value', null);
        if (
            !validationErroredValues ||
            !validationErroredValues.length
        ) {
            return null;
        }
        const erroredValues = validationErroredValues.split(';');
        if (!!this.props.fieldHasErrors) {
            this.props.fieldHasErrors(erroredValues);
        }

    }

    selectedPlaceholder() {
        const { isMulti, label, isMultipleValues, values, entityName, selectplaceholder, t } = this.props;
        const { ungroupedList, tempSelectedData, selectedData } = this.state;
        const selectPlaceholder = selectplaceholder || t('product_form_elements.custom_select.select');
        if (selectedData.length === 0 && (tempSelectedData.length === 0 || !tempSelectedData)) {
            return isMultipleValues
                ? t('placeholders.multiple_values')
                : `${selectPlaceholder} ${label || ''}`;
        }

        if (!isMulti && isString(selectedData)) {
            let filteredItem = values.filter(item => {
                let matchItem = !!item.name ? item.name : item.value;
                return !!matchItem && !!selectedData && matchItem.toLowerCase() === selectedData.toLowerCase();
            })[0];

            if (selectedData === 'unselected') {
                filteredItem = filteredItem || {
                    label: t('product_form_elements.custom_select.none'),
                    value: 'unselected'
                };
            }

            return filteredItem ? filteredItem.label :
                (selectplaceholder || `${t('product_form_elements.custom_select.select')} ${label || ''}`);
        }

        let val = selectedData[0];
        if (selectedData.length === 1 && isString(val)) {
            const found = ungroupedList.find((v) => v.value === val);
            return `${found && found.label} (${val.toUpperCase()})`;
        }

        return `${isMulti && tempSelectedData ? tempSelectedData.length : selectedData.length} ${label || entityName}s`;
    }

    render() {
        const {
            values = [],
            type,
            label,
            isMulti,
            enableSearch,
            disabled = false,
            unselectOption,
            t
        } = this.props;
        const { showList, isGrouped, selectedData, filteredList, tempSelectedData, searchedValue } = this.state;
        const {
            selectedCount,
            isShowSelectedAllButton,
            lastSelected,
            filteredOptions,
            showSelectedAll
        } = this.showAllSelectedCount();

        this.checkforErrors();

        return (
            <div
                className={`select-drop-down
              ${type !== 'selectdropdown' ? 'select-single' : ''}
              ${disabled ? 'disabled' : ''}
              ${enableSearch ? 'enabled-search' : ''}
              `}
                ref={(node) => this.dropdownNode = node}
            >
                <div
                    className="select-drop-down--control"
                    onClick={() => this.showMenuList()}
                >
                    <span className="control-placeholder">
                        {this.selectedPlaceholder()}
                    </span>

                    <div className="select-drop-down--control--arrow">
                        <span
                            className={`select-drop-down--control--arrow--triangle ${
                                showList ? 'expanded' : ''
                            }`}
                        />
                    </div>
                </div>

                {showList && type === 'selectdropdown' && (
                    <div
                        className="select-drop-down--multilist"
                        ref={(node) => (this.node = node)}
                    >

                        <div className="select-drop-down--header">
                            {isShowSelectedAllButton && isMulti && (
                                <div
                                    className="selected-counter"
                                    onClick={(e) => this.showAllSelected(e)}
                                >
                                    {selectedCount} {label}(s) {t('product_form_elements.custom_select.selected')}
                                </div>
                            )}
                            {this.renderSearch()}
                            {this.renderErrorBlock()}
                        </div>
                        <div className="select-drop-down--multilist--container">
                            {filteredOptions.length > 0 && (
                                <div className="select-drop-down--results">
                                    {isGrouped ? (
                                        values.map((option) => {
                                            return this.renderGroup(option);
                                        })
                                    ) : (
                                        <div className="item-list">
                                            {this.renderItems(
                                                filteredOptions,
                                                lastSelected,
                                                showSelectedAll
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}

                            {filteredOptions.length === 0 && (
                                <div className="select-drop-down--results no-results">
                                    {t('product_form_elements.custom_select.no_data_found')}
                                </div>
                            )}
                        </div>
                        {isMulti && (
                            <div className="select-drop-down--multilist--footer">

                                <div className="u-flex-align">
                                    <div
                                        className="select-all"
                                        onClick={(e) => this.selectAll(e)}
                                    >
                                        {t('buttons.select_all')}
                                    </div>

                                    <div
                                        className="select-all marg-l-10"
                                        onClick={(e) => this.selectAll(e, true)}
                                    >
                                        {t('buttons.clear_all')}
                                    </div>
                                </div>

                            </div>
                        )}
                    </div>
                )}

                {showList && type !== 'selectdropdown' && (
                    <div
                        className="select-drop-down--multilist select-single-option-wrapper"
                        ref={(node) => (this.node = node)}
                    >
                        {enableSearch && (
                            <div className="select-drop-down--header">
                                {this.renderSearch()}
                            </div>)}
                        <div className={'select-option-list'}>
                            {((filteredList.length > 0 && searchedValue.length > 0) || (values.length > 0 && searchedValue.length === 0)) && orderBy(searchedValue.length > 1 && (filteredList && filteredList.length > 0) ? filteredList : values, [value => value.label.toLowerCase()], ['asc']).reduce((accumulator, value) => [...accumulator, value], !!unselectOption && [{
                                label: t('product_form_elements.custom_select.none'),
                                value: 'unselected',
                                name: 'unselected',
                                unselect: true
                            }]).map(
                                (itemValue, i) => {
                                    let selected = (itemValue.name || itemValue.value) ? isString(selectedData) && (itemValue.name || itemValue.value).toLowerCase() === selectedData.toLowerCase() : null;
                                    return (<div
                                        key={`select-option-${i}`}
                                        className={`select-option-item
                                    ${itemValue.isHide ? 'disabled' : ''} ${itemValue.label === t('product_form_elements.custom_select.none') ? 'none' : ''}
                                    ${selected ? 'selected' : ''}
                                    `
                                        }
                                        onClick={
                                            (e) => {
                                                if (!selected) {
                                                    this.onChangeSelect(e, itemValue);
                                                } else {
                                                    this.showMenuList();
                                                }

                                            }
                                        }
                                    >
                                        {isMulti && (
                                            <UiCheckbox
                                                label={itemValue.label}
                                                type="checkbox"
                                                className="checkbox"
                                                checked={findIndex(tempSelectedData, item => item.name === itemValue.name) > -1}
                                                onChange={() => {
                                                }}
                                            ></UiCheckbox>
                                        )}
                                        {!isMulti && (
                                            <span className="option-text">{itemValue.label}</span>
                                        )}
                                    </div>);
                                }
                            )}

                            {searchedValue.length > 1 && filteredList.length < 1 && (
                                <div className={'no-data-placeholder'}>
                                    <h4>{t('product_form_elements.custom_select.no_data_found')}</h4>
                                </div>
                            )}
                        </div>

                        {isMulti && (
                            <div className="select-drop-down--multilist--footer">

                                <div className="u-flex-align">
                                    <div
                                        className="select-all"
                                        onClick={(e) => this.selectAll(e)}
                                    >
                                       {t('buttons.select_all')}
                                    </div>

                                    <div
                                        className="select-all marg-l-10"
                                        onClick={(e) => this.selectAll(e, true)}
                                    >
                                        {t('buttons.clear_all')}
                                    </div>
                                </div>

                            </div>)}

                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(CustomSelect)