import React from 'react';

import statusImg from 'statics/imgs/desktop/intro/registered.png';
import imgShoes from 'statics/imgs/desktop/intro/shoes.png';
import teddyBearImg from 'statics/imgs/desktop/intro/teddy-bear.png';

import imgIntro1 from 'statics/imgs/desktop/intro/intro-img.png';
import Button from 'modules/UiKit/components/Button/Button';
import { useTranslation } from 'react-i18next';

const HomeHtml = props => {
    const { t } = useTranslation();
    const {} = { ...props };
    return (
        <div className="intro">
            <div className="first-section">
                <div className="section-content">
                    <h1 className="main-heading" dangerouslySetInnerHTML={{ __html: t('modules.home_page.welcome_title') }}></h1>
                    <p className="text" dangerouslySetInnerHTML={{ __html: t('modules.home_page.description') }}></p>
                    <Button
                        type="primary"
                        size="medium"
                        to="my-records"
                    >
                        {t('buttons.get_started')}
                    </Button>
                </div>
                <div className="img-holder">
                    <img src={imgIntro1} alt="intro" />
                </div>
            </div>

            <div className="second-section">
                <div className="second-section-card-holder">
                    <div className="second-section-card">
                        <div className="close-btn-holder">
                            <div className="close-btn">
                                <div className="close-btn-symbol">×</div>
                            </div>
                        </div>
                        <h2>{t('modules.home_page.drag_and_drop')}</h2>
                        <p dangerouslySetInnerHTML={{ __html: t('modules.home_page.drag_and_drop_description') }}></p>
                        <Button
                            type="primary"
                            size="medium"
                        >
                            {t('buttons.choose_file')}
                        </Button>
                    </div>
                </div>
                <div className="section-content">
                    <h1 className="sub-heading" dangerouslySetInnerHTML={{ __html: t('modules.home_page.upload_title') }}></h1>
                    <p className="text" dangerouslySetInnerHTML={{ __html: t('modules.home_page.upload_description') }}></p>
                </div>
            </div>

            <div className="third-section">
                <div className="section-content third-section-content">
                    <h1 className="sub-heading" dangerouslySetInnerHTML={{ __html: t('modules.home_page.resolve_issues') }}></h1>
                    <p className="text" dangerouslySetInnerHTML={{ __html: t('modules.home_page.resolve_issues_description') }}></p>
                </div>
                <div className="third-section-card-holder">
                    <div className="third-section-card">
                        <div className="close-btn-holder">
                            <div className="close-btn">
                                <div className="close-btn-symbol">×</div>
                            </div>
                        </div>

                        <Button
                            type="secondary"
                            size="medium">
                            {t('buttons.view_product_page')}
                        </Button>

                        <img src={teddyBearImg} alt="Plush bear" />
                        <h3>{t('modules.home_page.teddy_bear')}</h3>
                        <p dangerouslySetInnerHTML={{ __html: t('modules.home_page.teddy_bear_description') }}></p>
                        <div className="form-element">
                            <span className="label">GTIN</span>
                            <div className="field -has-error">
                                4785865569683
                            </div>
                            <span className="error-message" dangerouslySetInnerHTML={{ __html: t('modules.home_page.gtin_validation') }}></span>
                        </div>
                        <div className="form-element margin-top">
                            <span className="label">MPN</span>
                            <div className="field">
                                4785865569683
                            </div>
                        </div>
                        <div className="submit-btn-holder">
                            <Button
                                type="secondary"
                                size="medium">
                                {t('buttons.save_changes')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="fourth-section">
                <div className="fourth-section-card-holder">
                    <div className="card card-1">
                        <Button type="secondary-danger-2"
                                size="small">
                            {t('buttons.delete')}
                        </Button>
                        <Button type="secondary" size="small">
                            {t('buttons.edit')}
                        </Button>
                        <Button type="secondary" size="small">
                            {t('buttons.register')}
                        </Button>
                    </div>
                    <div className="card card-2">
                        <div className="status-img-holder">
                            <img src={statusImg} alt="registered" />
                        </div>
                        <div className="square"></div>
                        <div className="img-holder">
                            <img src={imgShoes} alt="shoes" />
                        </div>
                        <div className="item-text">{t('modules.home_page.white_shoes')}</div>
                        <div className="item-number">4785865569683</div>
                        <div className="menu-symbol">⋮</div>
                    </div>
                </div>
                <div className="section-content">
                    <h1 className="sub-heading" dangerouslySetInnerHTML={{ __html: t('modules.home_page.register_products') }}></h1>
                    <p className="text" dangerouslySetInnerHTML={{ __html: t('modules.home_page.register_products_description') }}></p>
                </div>
            </div>
            <div className="final-section">
                <h1>{t('modules.home_page.your_ready')}</h1>
                <p>
                    {t('modules.home_page.your_ready_description')}
                </p>
                <Button
                    type="secondary"
                    size="medium"
                    to="my-records"
                    className="upload-btn"
                >
                    {t('buttons.upload')}
                </Button>
            </div>
        </div>
    );
};

export default HomeHtml;
